import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { ROUTE_PATH } from "./router_config/RoutePath";
import PrivateRoute from "./router_config/private_route/index";

import LoginPage from "./pages/login/login-page";
import Signup from "./pages/signup";
import ForgotPassword from "./pages/forgot-password";
import PasswordResetPage from "./pages/reset-password/Reset.page";

import Dashboard from "./pages/dashboard";
import Users from "./pages/users-list/UsersList.page";
import Operations from "./pages/actions/index";
import Campaigns from "./pages/campaigns/index";
import CampaignDetails from "./pages/campaign-details/index";
import Opportunities from "./pages/opportunities/index";
import OpportunityDetails from "./pages/opportunity-details/index";
import Automations from "./pages/sequences";
import AutomationsDetails from "./pages/sequence-details";
import Contacts from "./pages/contacts/index";
import ContactDetails from "./pages/contact-detail/index";
import Chat from "./pages/chat";
import Calender from "./pages/calendar";
import AccountSettings from "./pages/user-profile/profile.page";

import ForbiddenPage from "./404Page";

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route path={ROUTE_PATH.LOGIN.path} element={<LoginPage />} />
      <Route path={ROUTE_PATH.SIGNUP.path} element={<Signup />} />
      <Route
        path={ROUTE_PATH.FORGOT_PASSWORD.path}
        element={<ForgotPassword />}
      />
      <Route
        path={ROUTE_PATH.RESET_PASSWORD.path}
        element={<PasswordResetPage />}
      />
      <Route
        path={ROUTE_PATH.DASHBOARD.path}
        element={
          <PrivateRoute roles={ROUTE_PATH.DASHBOARD.roles}>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTE_PATH.DASHBOARD_ACTIVITY.path}
        element={
          <PrivateRoute roles={ROUTE_PATH.DASHBOARD_ACTIVITY.roles}>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTE_PATH.DASHBOARD_ASSIGNMENT.path}
        element={
          <PrivateRoute roles={ROUTE_PATH.DASHBOARD_ASSIGNMENT.roles}>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTE_PATH.DASHBOARD_SETTINGS.path}
        element={
          <PrivateRoute roles={ROUTE_PATH.DASHBOARD_SETTINGS.roles}>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTE_PATH.DASHBOARD_CHAT_TEAMS.path}
        element={
          <PrivateRoute roles={ROUTE_PATH.DASHBOARD_CHAT_TEAMS.roles}>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTE_PATH.USERS.path}
        element={
          <PrivateRoute roles={ROUTE_PATH.USERS.roles}>
            <Users />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTE_PATH.OPERATIONS.path}
        element={
          <PrivateRoute roles={ROUTE_PATH.OPERATIONS.roles}>
            <Operations />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTE_PATH.ALL_OPERATIONS.path}
        element={
          <PrivateRoute roles={ROUTE_PATH.ALL_OPERATIONS.roles}>
            <Operations />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTE_PATH.CAMPAIGNS.path}
        element={
          <PrivateRoute roles={ROUTE_PATH.CAMPAIGNS.roles}>
            <Campaigns />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTE_PATH.CAMPAIGN.path}
        element={
          <PrivateRoute roles={ROUTE_PATH.CAMPAIGN.roles}>
            <CampaignDetails />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTE_PATH.CAMPAIGN_EDIT.path}
        element={
          <PrivateRoute roles={ROUTE_PATH.CAMPAIGN.roles}>
            <CampaignDetails />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTE_PATH.CAMPAIGN_ASSIGNMENT.path}
        element={
          <PrivateRoute roles={ROUTE_PATH.CAMPAIGN.roles}>
            <CampaignDetails />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTE_PATH.CAMPAIGN_SETTINGS.path}
        element={
          <PrivateRoute roles={ROUTE_PATH.CAMPAIGN.roles}>
            <CampaignDetails />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTE_PATH.OPPORTUNITIES.path}
        element={
          <PrivateRoute roles={ROUTE_PATH.OPPORTUNITIES.roles}>
            <Opportunities />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTE_PATH.ALL_OPPORTUNITIES.path}
        element={
          <PrivateRoute roles={ROUTE_PATH.ALL_OPPORTUNITIES.roles}>
            <Opportunities />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTE_PATH.OPPORTUNITY_DEATILS.path}
        element={
          <PrivateRoute roles={ROUTE_PATH.OPPORTUNITY_DEATILS.roles}>
            <OpportunityDetails />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTE_PATH.AUTOMATION.path}
        element={
          <PrivateRoute roles={ROUTE_PATH.AUTOMATION.roles}>
            <Automations />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTE_PATH.AUTOMATION_DETAILS.path}
        element={
          <PrivateRoute roles={ROUTE_PATH.AUTOMATION_DETAILS.roles}>
            <AutomationsDetails />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTE_PATH.CONTACTS.path}
        element={
          <PrivateRoute roles={ROUTE_PATH.CONTACTS.roles}>
            <Contacts />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTE_PATH.CONTACT_DETAILS.path}
        element={
          <PrivateRoute roles={ROUTE_PATH.CONTACT_DETAILS.roles}>
            <ContactDetails />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTE_PATH.CHAT.path}
        element={
          <PrivateRoute roles={ROUTE_PATH.CHAT.roles}>
            <Chat />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTE_PATH.CALENDER.path}
        element={
          <PrivateRoute roles={ROUTE_PATH.CALENDER.roles}>
            <Calender />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTE_PATH.ACCOUNT_SETTINGS.path}
        element={
          <PrivateRoute roles={ROUTE_PATH.ACCOUNT_SETTINGS.roles}>
            <AccountSettings />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTE_PATH.NOTIFICATIONS.path}
        element={
          <PrivateRoute roles={ROUTE_PATH.NOTIFICATIONS.roles}>
            <AccountSettings />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTE_PATH.API_KEYS.path}
        element={
          <PrivateRoute roles={ROUTE_PATH.API_KEYS.roles}>
            <AccountSettings />
          </PrivateRoute>
        }
      />
      <Route path="*" element={<ForbiddenPage />} />
    </Routes>
  </BrowserRouter>
);

export default Router;
