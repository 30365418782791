import { getActions } from "../../../../services/campaign.services";
import { fetchActions, fetchFail, fetchLoading } from "./details.slice";

export const getAllActions = () => (dispatch) => {
  dispatch(fetchLoading());
  getActions()
    .then((res) => {
      dispatch(fetchActions(res.data.data));
    })
    .catch((err) => {
      dispatch(fetchFail("something went wrong."));
    });
};
