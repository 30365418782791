import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const CustomTemplate = ({ sequenceData, setSequenceData }) => {
  const { users } = useSelector((state) => state.users);
  return (
    <>
      <div>
        <TextField
          id="outlined-select-currency"
          label="Task Name"
          placeholder="Task Name"
          value={sequenceData.task_name}
          style={{ width: "100%" }}
          onChange={(e) =>
            setSequenceData({ ...sequenceData, task_name: e.target.value })
          }
          variant="outlined"
        />
        <FormControl fullWidth sx={{ mt: 2.5 }}>
          <InputLabel>Task Assignee</InputLabel>
          <Select
            label="Task Assignee"
            value={sequenceData.task_assignee}
            onChange={(e) =>
              setSequenceData({
                ...sequenceData,
                task_assignee: e.target.value,
              })
            }
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {users?.map((user) => (
              <MenuItem value={user.id}>{user.email}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
};

export default CustomTemplate;
