import React from "react";
import Alert from "@mui/material/Alert";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
// import SaveButton from "../../../components/widgets/button/button.widget";
import { createSeller } from "../../../services/contact.services";
import { addSellerValidation } from "../../../utils/validations.utils";
import { getContactSellers, setContactForm } from "../contact.action";
import {
  errorInFetch,
  openContactForm,
  setIsSellerLoading,
  stopSellerLoading,
} from "../contact.slice";
import AddForm from "./contact-form.add";
import VtechModal from "../../../components/vtech-modal/VtechModal";
import { showSnackbar } from "../../../snackbar/snackbarSlice";

const ContactAddForm = ({ open, setOpenModal }) => {
  const dispatch = useDispatch();
  const { isFormOpen, isSellerLoading, error } = useSelector(
    (state) => state.contact
  );

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
    },
    validationSchema: addSellerValidation,
    onSubmit: (values, { resetForm }) => {
      dispatch(setIsSellerLoading());
      createSeller(values)
        .then((res) => {
          dispatch(stopSellerLoading());
          dispatch(showSnackbar("Seller added successfully!"));
          dispatch(openContactForm());
          dispatch(getContactSellers(1, 10));
          setOpenModal(false);
          resetForm();
        })
        .catch((error) => {
          dispatch(errorInFetch(error.messages[0].errors.phone));
        });
    },
  });

  return (
    <>
      <VtechModal
        open={open}
        title="Add New Seller"
        primaryButtonLabel="Add Seller"
        primaryButtonAction={formik.handleSubmit}
        handleClose={() => setOpenModal(false)}
        buttonPosition="start"
        isLoading={isSellerLoading}
      >
        {error && <Alert severity="error">{error}</Alert>}
        <AddForm formik={formik} />
      </VtechModal>
    </>
  );
};

export default ContactAddForm;
