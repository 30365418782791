import React from "react";
import { EditOpportunityStepper } from "./stepper";
import { useDispatch } from "react-redux";
import { getAllCampaignWOL } from "../../../../campaigns/campaigns.action";
import { getPrimarySellers } from "../../../../opportunity-details/opportunity.action";
import { getAllUsersWOL } from "../../../../users-list/usersListAction";
import { getAllActions } from "../../../../campaign-details/redux/steps/details.actions";
import { useSelector } from "react-redux";

export const AddOpportunityForm = (props) => {
  const { sellers } = useSelector((state: any) => state.opportunityDetails);
  const { campaigns } = useSelector((state: any) => state.campaigns);
  const dispatch: any = useDispatch();

  // fetch all the data required for the form only once, when the form is opened for the first time
  React.useEffect(() => {
    if (campaigns.length === 0) {
      dispatch(getAllCampaignWOL());
      dispatch(getAllUsersWOL());
      dispatch(getAllActions());
    }
  }, []);
  React.useEffect(() => {
    if (sellers.length === 0) {
      dispatch(getPrimarySellers());
    }
  }, []);

  return <EditOpportunityStepper {...props} />;
};
