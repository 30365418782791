import axios from "axios";
import { http } from "../apiClient/axiosInterceptor";

export const getAllOpportunities = (page, limit, filters, query, id) => {
  const checkBoxes = filters?.checkBoxes;
  const campaignIds = filters?.campaignIds;
  const labelIds = filters?.labelIds;
  const motivationValues = filters?.motivationValues;
  const ownerIds = filters?.ownerIds;
  const selectedDateFrom = filters?.selectedDateFrom;
  const selectedDateTo = filters?.selectedDateTo;
  const statusIds = filters?.statusIds;
  let url = `/opportunity/get?page=${page}&limit=${limit}`;
  if (query) url = url + `&query=${query}`;
  if (id) url = url + `&owner[]=${id}`;

  if (checkBoxes) {
    Object.keys(checkBoxes).map((key) => {
      if (checkBoxes[key]) {
        url = url + `&category[]=${key}`;
      }
      return url;
    });
  }

  if (campaignIds && campaignIds.length > 0) {
    campaignIds.map((r) => (url = url + `&campaign[]=${r}`));
  }

  if (labelIds && labelIds.length > 0) {
    labelIds.map((r) => (url = url + `&label[]=${r}`));
  }
  if (motivationValues && motivationValues.length > 0) {
    motivationValues.map((r) => (url = url + `&motivation[]=${r}`));
  }
  if (ownerIds && ownerIds.length > 0) {
    ownerIds.map((r) => (url = url + `&owner[]=${r}`));
  }
  if (selectedDateFrom) {
    url = url + `&date_from=${selectedDateFrom}`;
  }

  if (selectedDateTo) {
    url = url + `&date_to=${selectedDateTo}`;
  }

  if (statusIds && statusIds.length > 0) {
    statusIds.map((r) => (url = url + `&status[]=${r}`));
  }
  // if (filters) url = url + `&category[]=cancelled`; // to fetch cancelled opportunities (backend update)
  return http.get(url);
};

export const exportOpportunities = (type, ids) => {
  let url = `/opportunity/get?type=export`;
  if (type === "export") {
    ids.map((r) => (url = url + `&opportunity_id[]=${r}`));
  } else {
    url = `/opportunity/get?type=export_all`;
  }

  return http.get(url);
};

export const getAllLabels = () => {
  return http.get(`/opportunity/label/get`);
};

export const getOpportunityById = (id) => {
  return http.get(`/opportunity/get/${id}`);
};

export const updateOwner = (oppoId, ownerId) => {
  return http.post(`opportunity/owner/update`, {
    opportunity_id: oppoId,
    owner_id: ownerId,
  });
};

export const deleteOpportunity = (id) => {
  return http.post(`/opportunity/delete`, { id });
};

export const postPropertyDetails = (data) => {
  return http.post(`/opportunity/update`, data);
};

export const addLink = (data) => {
  return http.post(`/opportunity/add/link`, data);
};

export const deleteLink = (id) => {
  return http.delete(`/opportunity/delete/link/${id}`);
};

export const addAttachment = (data) => {
  // return http.post(`/opportunity/add/attachment`, data);
  // this api now uses multipart/form-data, using axios instead of http to change the headers
  const token = localStorage.getItem("userToken");
  axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/opportunity/add/attachment`,
    data
  );
};

export const deleteAttachment = (id) => {
  return http.delete(`/opportunity/attachment/delete/${id}`);
};

export const assignCampaignToOpportunity = (data) => {
  return http.post(`/opportunity/assignCampaign`, data);
};

export const addNotesAgainstOpportunity = (data) => {
  return http.post(`/opportunity/add/note`, data);
};

export const createOpportunity = (data) => {
  return http.post(`/opportunity/create`, data);
};

export const getOpportunityPipeline = () => {
  return http.get(`/opportunity/pipeline`);
};

export const getOpportunitiesForActions = () => {
  return http.get(`/opportunity/get?type=action`);
};
