import * as yup from "yup";

export const resetPasswordValidation = yup.object({
  password: yup
    .string("Enter your password")
    .min(8, "Must be at least 8 characters")
    .required("Password is required"),
  confirmPassword: yup
    .string("Enter your password")
    .min(8, "Must be at least 8 characters")
    .required("Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export const userLoginValidation = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Must be at least 8 characters")
    .required("Password is required"),
});

export const userSignupValidation = yup.object({
  firstName: yup
    .string("Enter your First Name")
    .required("First Name is required"),
  lastName: yup
    .string("Enter your Last Name")
    .required("Last Name is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  country: yup.string("Enter your Country").required("Country is required"),
  details: yup.string("Enter details").required("Details field is required"),
  phone: yup.string("Enter your Phone").required("Phone is required"),
});

export const resetEmailValidation = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

export const userRegistrationValidation = yup.object({
  firstName: yup
    .string()
    .min(3, "At least 3 character required")
    .required("First Name is required"),
  lastName: yup
    .string()
    .min(3, "At least 3 character required")
    .required("Last Name is required"),
  title: yup
    .string()
    .min(3, "At least 3 character required")
    .required("Title is required"),
  email: yup
    .string()
    .email("Enter valid email address")
    .required("Email is required"),
});

export const addCampaignValidation = yup.object({
  campaignName: yup
    .string("Enter your Campaign Name")
    .min(3, "At least 3 character required")
    .max(180, "Maximum 180 character allowed")
    .required("Campaign Name is required"),
  purpose: yup
    .string("Enter your Purpose")
    .min(3, "At least 3 character required")
    .max(180, "Maximum 180 character allowed")
    .required("Purpose is required"),
});

export const campaignEditForm = yup.object({
  campaignName: yup
    .string("Enter your Campaign Name")
    .min(3, "At least 3 character required")
    .required("Campaign Name is required"),
  purpose: yup
    .string("Enter your Purpose")
    .min(3, "At least 3 character required")
    .required("Purpose is required"),
  campaignStatus: yup
    .string("Enter your Status")
    .required("Campaign status is required"),
});

export const campaignAudienceValidation = yup.object({
  cost: yup
    .number("Must be number")
    .required("Cost is required")
    .min(1, "Cost must be greater than 0"),
  audienceSize: yup
    .number("Must be number")
    .required("Audience size is required")
    .min(1, "Audience size must be greater than 0"),
});

const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
export const userValidationSchema = yup.object({
  firstName: yup
    .string("Enter your First Name")
    .min(3, "At least 3 character required")
    .required("First Name is required"),
  lastName: yup
    .string("Enter your Last Name")
    .min(3, "At least 3 character required")
    .required("Last Name is required"),
  phone: yup
    .string()
    .min(11, "Enter valid 11 digit phone number.")
    .matches(phoneRegExp, "Enter valid phone number")
    .required("Phone is required"),
  email: yup
    .string()
    .email("Enter valid email address")
    .required("Email is required"),
  city: yup.string("Enter your City").required("City is required"),
  state: yup.string("Enter your State").required("State is required"),
});

export const primarySellerValidation = yup.object({
  firstName: yup
    .string("Enter your First Name")
    .min(3, "At least 3 character required")
    .required("First Name is required"),
  lastName: yup
    .string("Enter your Last Name")
    .min(3, "At least 3 character required")
    .required("Last Name is required"),
  phone: yup
    .string()
    .min(11, "Enter valid 11 digit phone number.")
    .matches(phoneRegExp, "Enter valid phone number")
    .required("Enter your phone"),
  email: yup.string().email("Enter valid email address"),
  mailingAddress: yup.string(),
  mailingCity: yup.string(),
  mailingState: yup.string(),
  mailingZipCode: yup.string(),
});

export const propertyValidation = yup.object({
  streetAddress: yup.string("Enter your Street Address"),
  city: yup.string("Enter your City"),
  zipcode: yup.number("Enter your Zip Code"),
  state: yup.string("Enter your State"),
  propertyType: yup.string("Enter your Property type"),
  yearBuilt: yup.number("Enter year build"),
  basementType: yup.string("Enter Basement Type"),
  noOfBedrooms: yup.number("Enter # of bedrooms"),
  noOfBathrooms: yup.number("Enter your bathrooms"),
  lotSize: yup.number("Enter lot Size"),
  size: yup.number("Enter Size in sqft"),
  subDivision: yup.string("Enter your Sub division"),
  estimatedRepairNeeded: yup.string("Enter Repair Needed"),
});

export const pricingValidation = yup.object({
  askingPrice: yup.number("Enter your Asking Price"),
  realtorListedprice: yup.number("Enter Realtor Listed Price"),
  fullMarketValue: yup.number("Enter Full Market Value"),
  mortgageAmount: yup.number("Enter Mortgage Amount"),
  monthlyPayment: yup.number("Enter Monthly Payment"),
  taxes: yup.number("Enter Taxes"),
  rentingFor: yup.number("Enter Renting For Price"),
  goingRentalRate: yup.number("Enter Going Rental Rate"),
});

export const detailValidation = yup.object({
  reasonForSelling: yup.string("Enter Reason For Selling"),
  sellerMotivation: yup.string("What is the Seller Motivation"),
  timeToSell: yup.string("Enter Time to sell"),
});

export const commentAndTextValidation = yup.object({
  message: yup
    .string("Enter your email")
    .required("This field cannot be blank"),
  subject: yup.string("Enter your email"),
});

export const addSellerValidation = yup.object({
  first_name: yup
    .string("Enter your First Name")
    .min(3, "At least 3 character required")
    .required("First Name is required"),
  last_name: yup
    .string("Enter your Last Name")
    .min(3, "At least 3 character required")
    .required("Last Name is required"),
  phone: yup
    .string()
    .min(11, "Enter valid 11 digit phone number.")
    .matches(phoneRegExp, "Enter valid phone number")
    .required("Enter your phone"),
  email: yup
    .string()
    .email("Enter valid email address")
    .required("Email is required"),
});

export const contactSellerValidation = yup.object({
  first_name: yup
    .string("Enter your First Name")
    .min(3, "At least 3 character required")
    .required("First Name is required"),
  last_name: yup
    .string("Enter your Last Name")
    .min(3, "At least 3 character required")
    .required("Last Name is required"),
  phone: yup
    .string()
    .min(11, "Enter valid 11 digit phone number.")
    .matches(phoneRegExp, "Enter valid phone number")
    .required("Enter your phone"),
  email: yup
    .string()
    .email("Enter valid email address")
    .required("Email is required"),
  mailing_street_address: yup
    .string("Enter your Address")
    .required("Address is required"),
  mailing_city: yup.string("Enter your City").required("City is required"),
  mailing_state: yup.string("Enter your State").required("State is required"),
  mailing_zipcode: yup
    .string("Enter your Zip Code")
    .required("Zip Code is required"),
});
