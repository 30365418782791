import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createOpportunity } from "../../../../../services/opportunities";
import { fetchAllOpportunities } from "../../../opportunitiesListAction";
import { SellerSelection } from "./seller-selection";
import { StepOne } from "./step-1";
import { StepThree } from "./step-3";
import { StepFour } from "./step-4";
import { StepFive } from "./step-5";
import { useMediaQuery } from "@mui/material";
import { showSnackbar } from "../../../../../snackbar/snackbarSlice";

function getSteps() {
  return [
    "Add Property Address",
    "Add Seller",
    "Attach Campaign",
    "Select New Owner",
    "Assign Next Operation",
  ];
}

export const EditOpportunityStepper = ({
  loading,
  setLoading,
  handleClose,
  setActiveStep,
  activeStep,
  handleNext,
}) => {
  const isVertical = useMediaQuery((theme: any) =>
    theme.breakpoints.down("lg")
  );
  const [propertyAddress, setPropertyAddress] = useState({
    street_address: "",
    city: "",
    state: "",
    zipcode: "",
  });
  const [sellerId, setSellerId] = useState(null);
  const [sellerData, setSellerData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    mailingAddress: "",
    mailingCity: "",
    mailingState: "",
    mailingZipCode: "",
  });
  const [campaignId, setCampaignId] = useState(null);
  const [ownerId, setOwnerId] = useState(1);
  const steps = getSteps();
  const { limit } = useSelector((state: any) => state.opportunities);
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const { user } = useSelector((state: any) => state.login);
  const { oppoFilters } = useSelector(
    (state: any) => state.opportunitiesFilters
  );

  const handleEditIndex = (index) => {
    if (index === 4 && sellerId !== null) {
      setActiveStep(index);
    } else if (index === 0 || index === 1 || index === 2 || index === 3) {
      setActiveStep(index);
    }
  };

  const handleEnd = (data) => {
    //if property address is empty then show error in snackbar and return
    if (Object.values(propertyAddress).some((val) => val === "")) {
      dispatch(
        showSnackbar({
          message: "Please fill out all fields of address",
          severity: "error",
        })
      );
      setActiveStep(0);
      return;
    }
    const payload = {
      street_address: propertyAddress.street_address,
      city: propertyAddress.city,
      state: propertyAddress.state,
      zipcode: propertyAddress.zipcode,
      seller_id: sellerId !== null ? sellerId : sellerData.id,
      campaign_id: campaignId,
      owner_id: ownerId === null ? 1 : ownerId,
      ...data,
    };
    setLoading(true);
    createOpportunity(payload)
      .then((result) => {
        dispatch(showSnackbar("Opportunity created successfully!"));
        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          navigate(`/opportunity/${result.data.data.id}`);
        }, 2000);
        dispatch(fetchAllOpportunities(1, limit, oppoFilters, "", user.id));
      })
      .catch((err) => {
        if (err.messages[0].errors.seller_id) {
          dispatch(
            showSnackbar({
              message: "Please add the opportunity seller",
              severity: "error",
            })
          );
        }
        setLoading(false);
        setActiveStep(1);
        console.log("====================================");
        console.log(err);
        console.log("====================================");
      });
  };

  return (
    <>
      <Stepper
        activeStep={activeStep}
        sx={{ my: 2, p: 0 }}
        orientation={isVertical ? "vertical" : "horizontal"}
      >
        {steps.map((label, index) => (
          <Step key={label} sx={{ p: 0, maxWidth: 120, mx: "6px" }}>
            <StepLabel onClick={() => handleEditIndex(index)}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <>
        {activeStep === 0 && (
          <StepOne
            handleNext={handleNext}
            setPropertyAddress={setPropertyAddress}
            propertyAddress={propertyAddress}
          />
        )}
        {activeStep === 1 && (
          <SellerSelection
            handleNext={handleNext}
            onSubmission={(id) => setSellerId(id)}
            setLoading={setLoading}
            sellerData={sellerData}
            setSellerData={setSellerData}
            sellerId={sellerId}
          />
        )}
        {activeStep === 2 && (
          <StepThree
            handleNext={handleNext}
            campaignId={campaignId}
            setCampaignId={setCampaignId}
          />
        )}
        {activeStep === 3 && (
          <StepFour
            handleNext={handleNext}
            ownerId={ownerId}
            setOwnerId={setOwnerId}
          />
        )}
        {activeStep === 4 && (
          <StepFive handleEnd={handleEnd} loading={loading} success={success} />
        )}
      </>
    </>
  );
};
