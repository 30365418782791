import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Alert from "@mui/material/Alert";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SaveButton from "../../../../components/widgets/button/button.widget";
import ProgressBar from "../../../../components/widgets/progress-bar";
import { campaignAudienceValidation } from "../../../../utils/validations.utils";
import {
  audienceCreation,
  audienceUpdating as audienceUpdating,
} from "../../redux/audience/edit.actions";
import { handleModal } from "../../redux/audience/edit.slice";
import VtechModal from "../../../../components/vtech-modal/VtechModal";
import VtechButton from "../../../../components/vtech-button/VtechButton";
import { ArrowDropDownIcon, DatePicker } from "@mui/x-date-pickers";

export default function SimpleModal({ campaignId }) {
  const dispatch = useDispatch();
  const { isLoading, isModalOpen, limit, currentAudienceId, audience, isEdit } =
    useSelector((state) => state.audience);
  const [audienceData, setAudienceData] = useState({
    date: moment().format("YYYY-MM-DD"),
    cost: "",
    audienceSize: "",
  });

  useEffect(() => {
    if (isEdit && currentAudienceId > 0 && audience) {
      const currAudience = audience.filter(
        (item) => item.id === currentAudienceId
      );
      setAudienceData({
        date: moment(currAudience[0].created_at).format("YYYY-MM-DD"), //TODO: have to change created_at to (log_data)
        cost: currAudience[0].cost,
        audienceSize: currAudience[0].audience_size,
      });
    } else {
      setAudienceData({
        date: moment().format("YYYY-MM-DD"),
        cost: "",
        audienceSize: "",
      });
      formik.resetForm();
    }
  }, [isModalOpen, isEdit]);

  const createAudience = (values) => {
    dispatch(
      audienceCreation(
        {
          campaign_id: campaignId,
          cost: values.cost,
          audience_size: values.audienceSize,
          log_data: values.date,
        },
        limit
      )
    );
  };

  const updateAudience = (values) => {
    dispatch(
      audienceUpdating(
        currentAudienceId,
        {
          campaign_id: campaignId,
          cost: values.cost,
          audience_size: values.audienceSize,
          log_data: values.date,
        },
        limit
      )
    );
  };

  const formik = useFormik({
    initialValues: audienceData,
    enableReinitialize: true,
    validationSchema: campaignAudienceValidation,
    onSubmit: (values, { resetForm }) => {
      if (!isEdit) {
        createAudience(values);
      } else {
        updateAudience(values);
      }
      resetForm();
    },
  });

  const body = (
    <>
      {isLoading && <ProgressBar />}
      {/* <h1 style={{ fontSize: "20px", fontWeight: "500" }}>
        Add a Campaign and/or Audience To Log
      </h1> */}
      {/* <TextField
        sx={{ mb: 2 }}
        fullWidth
        type="date"
        name="date"
        label="Date Cost and/or Audience Log"
        variant="outlined"
        defaultValue="2017-05-24"
        value={formik.values.date}
        onChange={formik.handleChange}
        inputProps={{
          min: moment().format("MM-DD-YYYY"),
        }}
      /> */}
      <DatePicker
        sx={{ mb: 2, width: 1 }}
        slots={{ openPickerIcon: ArrowDropDownIcon }}
        label="Date Cost and/or Audience Log"
        value={moment(formik.values.date)}
        onChange={(newValue) => {
          formik.setFieldValue("date", moment(newValue).format("YYYY-MM-DD"));
        }}
      />
      <Alert
        sx={{ mb: 2 }}
        severity="info"
        icon={<InfoOutlinedIcon style={{ color: "#0288D1" }} />}
      >
        The cost is whatever you paid to carry out the campaign. The price of
        direct mail is buying a list.
      </Alert>
      <TextField
        sx={{ mb: 2 }}
        fullWidth
        type="number"
        label="Cost"
        name="cost"
        variant="outlined"
        placeholder="Cost"
        value={formik.values.cost}
        onChange={formik.handleChange}
        inputProps={{ min: 0.0, step: "any" }}
        error={formik.touched.cost && Boolean(formik.errors.cost)}
        helperText={formik.touched.cost && formik.errors.cost}
      />
      <Alert
        sx={{ mb: 2 }}
        severity="info"
        icon={<InfoOutlinedIcon style={{ color: "#0288D1" }} />}
      >
        How many people will be exposed to your marketing for this campaign is
        known as the audience size. Examples include the size of the list you
        are cold calling or the quantity of mail sent. This might not always be
        possible for you to complete. For example, the audience size is
        uncertain for things like PPC or radio advertisements.
      </Alert>
      <TextField
        sx={{ mb: 4 }}
        fullWidth
        type="number"
        label="Audience Size"
        name="audienceSize"
        autoFocus={false}
        variant="outlined"
        placeholder="Audience Size"
        value={formik.values.audienceSize}
        onChange={formik.handleChange}
        inputProps={{
          min: 0.0,
          step: "any",
        }}
        error={
          formik.touched.audienceSize && Boolean(formik.errors.audienceSize)
        }
        helperText={formik.touched.audienceSize && formik.errors.audienceSize}
      />

      <VtechButton
        title={isEdit ? "Update" : "Add"}
        onClick={formik.handleSubmit}
        disabled={isLoading}
        sx={{ mb: 4 }}
      />
    </>
  );

  return (
    <>
      <VtechModal
        // title="Add a Campaign and/or Audience To Log"
        title={`${isEdit ? "Update" : "Add a"} Campaign and/or Audience To Log`}
        open={isModalOpen}
        handleClose={() => dispatch(handleModal(false))}
        hideActionButtons
      >
        {body}
      </VtechModal>
      {/* <Modal
        open={isModalOpen}
        onClose={() => dispatch(handleModal(false))}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{ overflow: "scroll" }}
      >
        {body}
      </Modal> */}
    </>
  );
}
