import moment from "moment";

export const isAuthenticated = () => {
  if (getUserToken()) {
    return true;
  } else {
    return false;
  }
};

export const setUserToken = (token) => {
  localStorage.setItem("userToken", token);
};

export const getUserToken = () => {
  return localStorage.getItem("userToken");
};

export const convertTimeFormate = (time) => {
  let hours = parseInt(time.substring(0, 2));
  let am = true;
  if (hours > 12) {
    am = false;
    let hr = hours - 12;
    time = time.replace(hours.toString(), hr.toString());
  }
  if (hours === 12) {
    am = false;
  }
  if (hours === 0) {
    hours = 12;
  }
  let currentTime = time.slice(0, -3) + (am ? " AM" : " PM");
  return currentTime;
};

export const numberWithCommas = (x) => {
  if (x || x === 0) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return "";
  }
};

export const checkDateTime = (dateTime, method) => {
  const dueDT = moment(dateTime, "YYYY-MM-DD");
  if (method === "due") {
    return dueDT.isBefore(moment().format("YYYY-MM-DD"), "day");
  } else if (method === "today") {
    return dueDT.isSame(moment().format("YYYY-MM-DD"), "day");
  } else if (method === "future") {
    return dueDT.isAfter(moment().format("YYYY-MM-DD"), "day");
  }
};

export const arraySortByKey = (array, key) => {
  return array.slice().sort((a, b) => a[key] - b[key]);
};

export const groupByKey = (data, key) => {
  var groups = {};
  for (var i in data) {
    if (!groups.hasOwnProperty(data[i][key])) groups[data[i][key]] = [];
    groups[data[i][key]].push(data[i]);
  }
  return groups;
};

export const formatPhone = (phone) => {
  if (!phone) return "";
  return phone.slice(0, 3) + "-" + phone.slice(3, 6) + "-" + phone.slice(6, 11);
};
