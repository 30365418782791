import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

export const LoggedOutLayout = ({ children }) => {
  return (
    <Grid container direction={{ xs: "column-reverse", sm: "row" }}>
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          minHeight: "100vh",
          flexDirection: "column",
          justifyContent: "space-between",
          p: 2,
          pt: 0,
          position: "relative",
        }}
      >
        <Box
          component="img"
          sx={{ position: "absolute", top: 15, left: 20, cursor: "pointer" }}
          src={"/images/vital-home-logo.png"}
          alt="logo"
        />
        {children}
        <Typography sx={{ fontWeight: 500, fontSize: 14, color: "#5A6479" }}>
          &copy; Vital Home Solutions {new Date().getFullYear()}
        </Typography>
      </Grid>
      <RightSideLayout />
    </Grid>
  );
};

export default LoggedOutLayout;

function RightSideLayout() {
  return (
    <Grid
      item
      xs={6}
      sx={{
        minHeight: "100vh",
        backgroundImage: `url("/images/login-page-bg.jpg")`,
        backgroundSize: "cover",
        position: "relative",
      }}
    >
      {/* this self-closing Box is an overlay with .93 opacity of blue color */}
      <Box
        sx={{
          bgcolor: "#131E57",
          width: 1,
          height: 1,
          position: "absolute",
          zIndex: 0,
          opacity: 0.93,
        }}
      />
      <Box
        sx={{
          width: "60%",
          mx: "auto",
          mt: "182px",
          position: "relative",
          zIndex: 1,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: "#fff",
            fontSize: "40px",
            fontWeight: 600,
            lineHeight: "55px",
          }}
        >
          --Simple, effective way to manage your real estate business
        </Typography>

        <Typography
          sx={{
            color: "#fff",
            fontSize: "14px",
            mt: 3,
            lineHeight: "26px",
            fontWeight: 500,
          }}
        >
          Vital Home Solutions allows you to keep track of important metrics to
          ensure your business is profitable.
        </Typography>
      </Box>
    </Grid>
  );
}
