import { Box } from "@mui/material";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import MenuSelector from "../../pages/sequence-details/seq-update-form/menu-selection/index";

function ReactDraftEditor({ editorState, onEditorStateChange, key, darkMode }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
      <Box
        sx={{
          border: 1,
          borderColor: darkMode ? "#535353" : "#c4c4c4",
          borderRadius: 1,
          p: 1,
          cursor: "text",
        }}
      >
        <Editor
          editorState={editorState}
          toolbarClassName={darkMode ? "darkToolbar" : ""}
          onEditorStateChange={onEditorStateChange}
          key={key}
          toolbar={{
            options: [
              "inline",
              "blockType",
              // "fontSize",
              // "fontFamily",
              "list",
              // "textAlign",
              // "colorPicker",
              // "link",
              // "embedded",
              // "emoji",
              // "image",
              // "remove",
              // "history",
            ],
            inline: {
              options: ["bold", "italic", "underline"],
            },
            blockType: {
              options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
            },
          }}
        />
      </Box>
      <MenuSelector onChange={onEditorStateChange} />
    </Box>
  );
}

export default ReactDraftEditor;
