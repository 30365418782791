import React from "react";
import { Backdrop } from "@mui/material";
import { useDispatch } from "react-redux";
import { setMenuState } from "../../shared_slice/menu-handler.slice";

function BackDrop() {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setMenuState(false));
  };

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
      open={true}
      onClick={handleClose}
    />
  );
}

export default BackDrop;
