import React from "react";
import { Typography } from "@mui/material";
import VtechModal from "../components/vtech-modal/VtechModal";
import { useSelector } from "react-redux";

function TooManyRequests() {
  const [seconds, setSeconds] = React.useState(60);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const { open } = useSelector((state: any) => state.modal);

  React.useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
    if (seconds === 0) {
      setIsDisabled(false);
    }
  }, [seconds]);

  return (
    <VtechModal
      title="Too Many Requests"
      open={open}
      primaryButtonLabel="Reload"
      primaryButtonAction={() => window.location.reload()}
      hideXButton
      handleClose={() => {}}
      isLoading={isDisabled}
    >
      <Typography>
        Too many requests. Please try again
        {seconds > 0 && " in " + seconds + " seconds"}.
      </Typography>
    </VtechModal>
  );
}

export default TooManyRequests;
