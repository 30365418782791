import { Box, IconButton, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React from "react";
import { Link } from "react-router-dom";
import { addTouch } from "../../../services/touches.service";
import style from "./filterpipline.module.css";
import { formatPhone } from "../../../utils/global.utils";
import VtechIcons from "../../../components/icons/VtechIcons";

const FUNCTION_UTILS = {
  getActionColumn: (props) => {
    return (
      <>
        <Link
          to={`/opportunity/${props.id}?newIndex=2`}
          style={{ textDecoration: "none", color: "black" }}
        >
          <Typography
            sx={{ color: "#E87063", fontSize: 12, fontWeight: 600 }}
          >{`Next Operation → ${props.action.title}`}</Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 12,
              lineHeight: "24px",
              color: "text.primary",
            }}
          >
            <span style={{ color: "#245EC1" }}>Assigned to: </span>
            {props.assignee.first_name} {props.assignee.last_name}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 12,
              lineHeight: "24px",
              color: "text.primary",
            }}
          >
            <span style={{ color: "#245EC1" }}>Due: </span>

            {props.action.converted_due_date_time}
          </Typography>
        </Link>
      </>
    );
  },

  getFilterPipline: () => {
    return (
      <>
        <div className={style.filterPipline}>
          <ul className={style.filterPipline}>
            <li className={style.filterPipline}>
              <span className={style.filterPipline}>6</span>
              <span className={style.filterPipline}>Qualification</span>
              <span className={style.filterPipline}></span>
            </li>
            <li className={style.filterPipline}>
              <span className={style.filterPipline}>8</span>
              <span className={style.filterPipline}>Pursue</span>
              <span className={style.filterPipline}></span>
            </li>
            <li className={style.filterPipline}>
              <span className={style.filterPipline}>7</span>
              <span className={style.filterPipline}>Transaction</span>
              <span className={style.filterPipline}></span>
            </li>
          </ul>
          <ul className={style.filterPipline}>
            <li className={style.filterPipline}>
              <span className={style.filterPipline}>0</span>
              <span className={style.filterPipline}>Dead</span>
              <span className={style.filterPipline}></span>
            </li>
            <li className={style.filterPipline}>
              <span className={style.filterPipline}>0</span>
              <span className={style.filterPipline}>Opt Out</span>
              <span className={style.filterPipline}></span>
            </li>
            <li className={style.filterPipline}>
              <span className={style.filterPipline}>0</span>
              <span className={style.filterPipline}>Realtor</span>
              <span className={style.filterPipline}></span>
            </li>
            <li className={style.filterPipline}>
              <span className={style.filterPipline}>1</span>
              <span className={style.filterPipline}>Follow Up</span>
              <span className={style.filterPipline}></span>
            </li>
          </ul>
        </div>
      </>
    );
  },

  getSellerColumn: (props, nav) => {
    const address = `${props.street_address} ${props.city} ${props.state} ${props.zipcode}`;

    const routeTo = () => {
      addTouch({
        opportunity_id: props.id,
        campaign_id: "",
        opportunity_step_history_id: "",
      });
    };

    const copyToClipboard = (e) => {
      e.stopPropagation();
      if (navigator.clipboard && navigator.clipboard !== undefined) {
        navigator.clipboard.writeText(address);
      } else {
        var textField = document.createElement("textarea");
        textField.innerText = address;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
      }
    };
    return (
      <>
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => nav(`/opportunity/${props.id}`)}
        >
          <Box onClick={routeTo}>
            <Typography variant="h3" fontWeight={600}>
              {props.street_address
                ? `${
                    address.length > 40
                      ? `${address.substring(0, 40)}...`
                      : address
                  }`
                : "No Address"}

              <IconButton onClick={copyToClipboard}>
                {/* <img src="/icons/copy-dark.svg" style={{ fontSize: "16px" }} /> */}
                <VtechIcons icon="copy" />
              </IconButton>
            </Typography>
            <Typography
              sx={{ fontWeight: 400, fontSize: 12, lineHeight: "24px" }}
            >
              <span style={{ color: "#245EC1" }}>Name: </span>
              {`${props.seller.first_name} ${props.seller.last_name}`}
            </Typography>
            <Typography
              sx={{ fontWeight: 400, fontSize: 12, lineHeight: "24px" }}
            >
              <span style={{ color: "#245EC1" }}>Campaign: </span>
              {props.campaign.title}
            </Typography>
            <Typography
              sx={{ fontWeight: 400, fontSize: 12, lineHeight: "24px" }}
            >
              <span style={{ color: "#245EC1" }}>Seller Contact: </span>
              {formatPhone(props.seller.phone)}
            </Typography>
            <Typography
              sx={{ fontWeight: 400, fontSize: 12, lineHeight: "24px" }}
            >
              <span style={{ color: "#245EC1" }}>Email: </span>
              {props.seller.email}
            </Typography>
          </Box>
          {/* <p style={{ margin: 0 }}>
            <a href={`tel:${props.seller.phone}`}>{props.seller.phone}</a>:{" "}
            <a href={`mailto:${props.seller.email}`}>{props.seller.email}</a>
          </p> */}
        </Box>
      </>
    );
  },

  getCheckBox: (props, isItemSelected, handleClick) => {
    return (
      <Checkbox
        onChange={(event) => handleClick(event, props.id)}
        checked={isItemSelected}
      />
    );
  },

  getMenuList: (
    anchorEl,
    handleOpenMenu,
    handleCloseMenu,
    setOpenNotes,
    setOpenStatus,
    setOpenAction,
    setOpportunityId,
    id
  ) => {
    const open = Boolean(anchorEl);
    const options = ["Change Status", "Edit Operation", "View Notes"];
    const handleOpenNotes = (e) => {
      if (e.target.innerText === "Change Status") {
        setOpenAction(false);
        setOpenNotes(false);
        setOpenStatus(true);
        setOpportunityId(id);
      } else if (e.target.innerText === "Edit Operation") {
        setOpenAction(true);
        setOpenNotes(false);
        setOpenStatus(false);
        setOpportunityId(id);
      } else {
        setOpenAction(false);
        setOpenStatus(false);
        setOpenNotes(true);
        setOpportunityId(id);
      }
    };

    return (
      <>
        <div>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleOpenMenu}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            style={{ marginLeft: "85%" }}
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleCloseMenu}
          >
            {options.map((option) => (
              <MenuItem key={option} onClick={(e) => handleOpenNotes(e)}>
                {option}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </>
    );
  },

  getTouchComponent: (props) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0 auto",
            padding: "10px",
          }}
        >
          <div style={{ width: "80px" }}>
            <p style={{ fontSize: "12.8px" }}>Last Touch</p>
            <div
              style={{
                border: "1px solid #e0e0e0",
                height: "64px",
                width: "68px",
                textAlign: "center",
                borderRadius: "4px",
                fontSize: "14px",
                position: "relative",
                margin: "auto",
              }}
            >
              <strong style={{ fontSize: "20px" }}>
                {props.updated_at ? props.updated_at.split(" ")[0] : ""}
              </strong>
              <p style={{ fontSize: "12px" }}>
                {`${props.updated_at ? props.updated_at.split(" ")[1] : ""} ${
                  props.updated_at ? props.updated_at.split(" ")[2] : ""
                }`}
              </p>
            </div>
          </div>
          <div style={{ width: "80px" }}>
            <p style={{ fontSize: "12.8px" }}>Touches</p>
            <div
              style={{
                border: "1px solid #e0e0e0",
                height: "64px",
                width: "68px",
                textAlign: "center",
                borderRadius: "4px",
                fontSize: "14px",
                position: "relative",
                margin: "auto",
              }}
            >
              <strong style={{ fontSize: "20px" }}>{`2`}</strong>
            </div>
          </div>
          <div style={{ width: "80px" }}>
            <p style={{ fontSize: "12.8px" }}>Created</p>
            <div
              style={{
                border: "1px solid #e0e0e0",
                height: "64px",
                width: "68px",
                textAlign: "center",
                borderRadius: "4px",

                position: "relative",
                margin: "auto",
              }}
            >
              <strong style={{ fontSize: "20px" }}>
                {props.created_at.split(" ")[0]}
              </strong>
              <p style={{ fontSize: "12px" }}>
                {`${props.created_at.split(" ")[1]} ${
                  props.created_at.split(" ")[2]
                }`}
              </p>
            </div>
          </div>
        </div>
      </>
    );
  },
};

export default FUNCTION_UTILS;
