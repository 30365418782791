import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isNotiOpen: false,
  unRead: [],
  archive: [],
  unReadCount: 0,
  error: "",
  isLoading: false,
};

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    fetchIsLoading: (state) => {
      state.isLoading = true;
    },
    fetchUnReadSuccess: (state, action) => {
      state.isLoading = false;
      state.unRead = action.payload;
    },
    fetchUnReadCount: (state, action) => {
      state.isLoading = false;
      state.unReadCount = action.payload;
    },
    fetchArchiveSuccess: (state, action) => {
      state.isLoading = false;
      state.archive = action.payload;
    },
    openNotiForm: (state) => {
      state.isNotiOpen = !state.isNotiOpen;
    },
    fetchIsLoadingFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  openNotiForm,
  fetchIsLoading,
  fetchUnReadSuccess,
  fetchArchiveSuccess,
  fetchIsLoadingFail,
  fetchUnReadCount,
} = notificationSlice.actions;
export default notificationSlice.reducer;
