import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CampaignAddForm from "./add-form-modal/index";
import CampaignTable from "./campaign-table/index";
import CampaignTotal from "./campaign-total/index";
import { getCampaigns } from "./campaigns.action";
import moment from "moment";
import MainLayout from "../../components/MainLayout/MainLayout";
import SearchCampaignsRow from "./campaign-table/search-campaigns-row";

const Campaigns = () => {
  const [fromDate, setFromDate] = useState("2001-01-01");
  // to date is current date + 1 day because the backend does not include the current date
  const [toDate, setToDate] = useState(
    moment().add(1, "days").format("YYYY-MM-DD")
  );
  const [openDateModal, setOpenDateModal] = useState(false);
  const dispatch: any = useDispatch();
  const { limit, page } = useSelector((state: any) => state.campaigns);

  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");

  useEffect(() => {
    dispatch(getCampaigns(page + 1, limit || 10, fromDate, toDate, query));
  }, [query]);

  const tableRef = useRef<any>();
  const table2Ref = useRef<any>();

  const handleScrollFirst = (e) => {
    table2Ref.current.scrollLeft = e.target.scrollLeft;
  };

  const handleScrollSecond = (e) => {
    tableRef.current.scrollLeft = e.target.scrollLeft;
  };

  return (
    <MainLayout title="Campaigns" divider>
      <CampaignAddForm
        fromDate={fromDate}
        toDate={toDate}
        open={open}
        handleClose={() => setOpen(false)}
      />
      <SearchCampaignsRow
        query={query}
        setQuery={(value) => setQuery(value)}
        setOpen={setOpen}
        fromDate={fromDate}
        toDate={toDate}
        setOpenDateModal={setOpenDateModal}
        openDateModal={openDateModal}
        setFromDate={setFromDate}
        setToDate={setToDate}
      />
      <CampaignTable
        query={query}
        tableRef={tableRef}
        handleScrollFirst={handleScrollFirst}
        fromDate={fromDate}
        toDate={toDate}
      />
      <CampaignTotal
        table2Ref={table2Ref}
        handleScrollSecond={handleScrollSecond}
      />
    </MainLayout>
  );
};

export default Campaigns;
