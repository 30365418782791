import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import React from "react";

export const MaterialCard = ({
  title,
  width,
  boxShadow,
  children,
  border,
  margin,
  headerStyles,
  marginTop,
  marginAuto,
  contentPadding,
  CustomComponent,
}) => {
  return (
    <Card
      style={{
        width: width ? width : "100%",
        boxShadow: boxShadow && boxShadow,
        border: border && "1px solid #cccccc",
        margin: marginAuto && "auto",
        marginTop: margin && "1px",
      }}
    >
      {title && (
        <>
          <CardHeader
            title={title}
            style={headerStyles && headerStyles}
            subheader={CustomComponent && CustomComponent}
          />
        </>
      )}
      <CardContent
        style={{
          marginTop: marginTop && marginTop,
          padding: contentPadding && contentPadding,
        }}
      >
        {children}
      </CardContent>
    </Card>
  );
};
