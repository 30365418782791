import React, { useState, useEffect } from "react";
import InitialStep from "./initial-steps/initial-step";
import SequenceQueue from "./sequence-queue/sequence-queue";
import { useDispatch, useSelector } from "react-redux";
import { updateInitialStep } from "../../../sequences/redux/sequence.actions";
import SequenceAppBar from "../app-bar";
import { showSnackbar } from "../../../../snackbar/snackbarSlice";
import { Paper } from "@mui/material";

const SequenceSteps = () => {
  const dispatch = useDispatch();
  const { initialStep, currentSequence } = useSelector(
    (state) => state.sequences
  );
  const [switchCheck, setSwitchCheck] = useState(initialStep.is_enabled);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [sequenceData, setSequenceData] = useState({
    message: "",
    action: "",
    subject: "",
    emailText: "",
    callback_number: "",
    task_name: "",
    task_assignee: "",
    voicemail: "",
    voicemail_name: "No file attached",
  });

  useEffect(() => {
    if (initialStep) {
      if (initialStep.is_enabled === 1) {
        setSwitchCheck(true);
      } else {
        setSwitchCheck(false);
      }
      setSequenceData({
        ...sequenceData,
        message: initialStep.message !== null ? initialStep.message : "",
        action: initialStep.action !== null ? initialStep.action : "",
        subject: initialStep.subject !== null ? initialStep.subject : "",
        emailText:
          initialStep.action === "email" && initialStep.message !== null
            ? initialStep.message
            : "",
        task_name: initialStep.task_name !== null ? initialStep.task_name : "",
        task_assignee:
          initialStep.task_assignee !== null ? initialStep.task_assignee : "",
        callback_number:
          initialStep.callback_number !== null
            ? initialStep.callback_number
            : "",
        voicemail:
          initialStep.voicemail !== null && initialStep.voicemail !== ""
            ? initialStep.voicemail
            : "",
        voicemail_name:
          initialStep.voicemail_name !== null &&
          initialStep.voicemail_name !== ""
            ? initialStep.voicemail_name
            : "No file attached",
      });
    }
  }, [initialStep]);

  const handleChange = () => {
    let message = "";
    if (sequenceData.action === "") {
      dispatch(showSnackbar("Please select any operation!"));
    } else if (sequenceData.action === "text" && sequenceData.message === "") {
      dispatch(showSnackbar("Please enter message!"));
    } else if (
      sequenceData.action === "custom_task" &&
      (sequenceData.task_name === "" || sequenceData.task_assignee === "")
    ) {
      if (sequenceData.task_name === "" && sequenceData.task_assignee === "") {
        message = "Please enter task name and select it's assignee.";
      } else if (sequenceData.task_name === "") {
        message = "Please enter task name.";
      } else if (sequenceData.task_assignee === "") {
        message = "Please select task assignee.";
      }
      dispatch(showSnackbar(message));
    } else if (
      sequenceData.action === "email" &&
      (sequenceData.emailText === "" || sequenceData.subject === "")
    ) {
      if (sequenceData.emailText === "" && sequenceData.subject === "") {
        message = "Please enter subject and message.";
      } else if (sequenceData.subject === "") {
        message = "Please enter subject.";
      } else if (sequenceData.emailText === "") {
        message = "Please enter message.";
      }
      dispatch(showSnackbar(message));
    } else if (
      sequenceData.action === "voicemail" &&
      sequenceData.callback_number === ""
    ) {
      dispatch(showSnackbar("Please enter callback number."));
    } else {
      let data = {
        is_enabled: switchCheck ? 1 : 0,
        message: "",
        action: sequenceData.action,
        subject: "",
        emailText: "",
        callback_number: "",
        voicemail: "",
        voicemail_name: "",
        task_name: "",
        task_assignee: "",
      };
      if (sequenceData.action === "email") {
        data = {
          ...data,
          subject: sequenceData.subject,
          message: sequenceData.emailText,
        };
      } else if (sequenceData.action === "text") {
        data = {
          ...data,
          message: sequenceData.message,
        };
      } else if (sequenceData.action === "voicemail") {
        data = {
          ...data,
          callback_number: sequenceData.callback_number,
          voicemail: sequenceData.voicemail ? sequenceData.voicemail : "",
          voicemail_name: sequenceData.voicemail_name,
        };
      } else {
        data = {
          ...data,
          task_name: sequenceData.task_name,
          task_assignee: sequenceData.task_assignee,
        };
      }
      dispatch(updateInitialStep(currentSequence.id, data));
      setSaveSuccess(true);

      setTimeout(() => {
        setSaveSuccess(false);
      }, 3000);
    }
  };

  const handleSwitchChange = (value) => {
    if (!value) {
      const data = {
        is_enabled: 0,
        ...sequenceData,
      };
      dispatch(updateInitialStep(currentSequence.id, data));
    }
    setSwitchCheck(value);
  };

  return (
    <>
      <SequenceAppBar />
      <Paper sx={{ p: 2 }}>
        <InitialStep
          sequenceData={sequenceData}
          setSequenceData={setSequenceData}
          switchCheck={switchCheck}
          setSwitchCheck={handleSwitchChange}
          handleChange={handleChange}
          saveSuccess={saveSuccess}
        />
        <SequenceQueue />
      </Paper>
    </>
  );
};

export default SequenceSteps;
