import { useTheme } from "@mui/material";
import React from "react";
import { AreaChart, Area, Tooltip, YAxis } from "recharts";

const AreaGraph = ({ data, percentage }) => {
  const theme = useTheme();
  return (
    <AreaChart
      width={150}
      height={80}
      data={data}
      margin={{ top: 18, bottom: 2 }}
    >
      <defs>
        <Gradients />
      </defs>
      <YAxis hide />
      <Tooltip
        labelFormatter={(v, entry) => {
          const monthname = entry[0]?.payload.key || "";
          return monthname;
        }}
        formatter={(value) => {
          return value === 0.5 ? 0 : value;
        }}
        contentStyle={{
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.paper,
        }}
        position={{ x: -95 }}
      />

      <Area
        type="monotone"
        dataKey="count"
        stroke={
          // #E87063 red / #289374 green / #2F74EB blue
          percentage > 0 ? "#289374" : percentage < 0 ? "#E87063" : "#2F74EB"
        }
        fill={
          percentage > 0
            ? "url(#greenGradient)"
            : percentage < 0
            ? "url(#redGradient)"
            : "url(#blueGradient)"
        }
      />
    </AreaChart>
  );
};

export default AreaGraph;

const Gradients = () => (
  <>
    <linearGradient id="redGradient" x1="0" y1="0" x2="0" y2="1">
      <stop offset="0%" stopColor="#E87063" />
      <stop offset="100%" stopColor="rgba(232, 112, 99, 0)" />
    </linearGradient>

    <linearGradient id="blueGradient" x1="0" y1="0" x2="0" y2="1">
      <stop offset="0%" stopColor="#245EC1" />
      <stop offset="100%" stopColor="rgba(47, 116, 235, 0)" />
    </linearGradient>

    <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
      <stop offset="0%" stopColor="#289374" />
      <stop offset="100%" stopColor="rgba(40, 147, 116, 0)" />
    </linearGradient>
  </>
);
