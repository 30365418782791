import { http } from "../apiClient/axiosInterceptor";

export const getActionAssignee = (id) => {
  return http.get(`/action/assignee?campaign_id=&step_id=${id}`);
};

export const getHistoryList = (id) => {
  return http.get(`/opportunity/step/history/${id}`);
};

export const updateActionStatus = (data) => {
  return http.post(`/opportunity/step/history/update/status`, data);
};

export const getActionList = (filters, activeTab, id) => {
  const motivationValues = filters?.motivationValues;
  const sortActionValues = filters?.sortActionValues;
  const loggedInUser = filters?.loggedInUser;
  const campaignIds = filters?.campaignIds;
  const labelIds = filters?.labelIds;
  const userIds = filters?.userIds;
  const stepIds = filters?.stepIds;
  const isTask = filters?.isTask;
  let url = `/actions?page=1`;
  if (activeTab === 0) {
    url = url + `&user_id[]=${id}`;
  }
  if (campaignIds?.length > 0) {
    campaignIds.map((r) => (url = url + `&campaign_id[]=${r}`));
  }

  if (labelIds?.length > 0) {
    labelIds.map((r) => (url = url + `&label[]=${r}`));
  }
  if (motivationValues?.length > 0) {
    motivationValues.map((r) => (url = url + `&motivation[]=${r}`));
  }

  if (sortActionValues) {
    url = url + `&sort_date[]=${sortActionValues}`;
  }

  if (userIds?.length > 0) {
    userIds.map((r) => (url = url + `&user_id[]=${r}`));
  }

  if (stepIds?.length > 0) {
    stepIds.map((r) => (url = url + `&step_id[]=${r}`));
  }

  if (isTask) {
    url = url + `&type=task`;
  }

  return http.get(url);
};
