import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activityLogs: [],
  isLoading: false,
  error: "",
};

const activityLogData = createSlice({
  name: "activityLogs",
  initialState,
  reducers: {
    fetchCommonLogsLoading: (state) => {
      state.isLoading = true;
    },
    fetchCommonLogsSuccess: (state, action) => {
      state.isLoading = false;
      state.activityLogs = action.payload;
    },
    fetchCommonLogsFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchCommonLogsLoading,
  fetchCommonLogsSuccess,
  fetchCommonLogsFail,
} = activityLogData.actions;
export default activityLogData.reducer;
