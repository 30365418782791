import Grid from "@mui/material/Grid";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProgressBar from "../../../components/widgets/progress-bar/index";
import { getAllUsersWOL } from "../../users-list/usersListAction";
import { Box } from "@mui/material";
const NewOpportunity = lazy(() => import("./new-opportunity"));
const OpportunityActions = lazy(() => import("./opportunity-actions"));
const GroupSection = lazy(() => import("./group-section"));

const Assignment = ({ campaignId }) => {
  const dispatch = useDispatch();
  const [selectionList, setSelectionList] = useState([]);
  const { limit, users } = useSelector((state) => state.users);
  const { groups } = useSelector((state) => state.assignment);

  useEffect(() => {
    dispatch(getAllUsersWOL());
  }, [dispatch]);

  useEffect(() => {
    setSelectionList([]);
    if (Object.keys(groups).length > 0) {
      groups.forEach((group) => {
        const newObj = Object.assign({ belongsTo: "Groups" }, group);
        setSelectionList((selectionList) => selectionList.concat(newObj));
      });
    }
    if (Object.keys(users).length > 0) {
      users.forEach((user) => {
        let newObj = Object.assign(
          { title: user.email },
          { belongsTo: "Users" },
          user
        );
        setSelectionList((selectionList) => selectionList.concat(newObj));
      });
    }
  }, [users, groups]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: 6.5,
        py: 4,
        flexDirection: { xs: "column", lg: "row" },
      }}
    >
      <Box flex={1}>
        <Suspense fallback={<ProgressBar />}>
          <NewOpportunity
            selectionList={selectionList}
            campaignId={campaignId}
          />
        </Suspense>
        <Suspense fallback={<ProgressBar />}>
          <GroupSection campaignId={campaignId} />
        </Suspense>
      </Box>
      <Box flex={2}>
        <Suspense fallback={<ProgressBar />}>
          <OpportunityActions
            selectionList={selectionList}
            campaignId={campaignId}
          />
        </Suspense>
      </Box>
    </Box>
  );
};

export default Assignment;
