import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  twilioCreds: [],
  isLoading: false,
  icsSettings: [],
  error: "",
  open: false,
  buyNumberError: false,
  buyNumberErrorText: "",
  searchedNumbers: [],
  myNumbers: [],
  releasedNumbers: [],
};

const twilioData = createSlice({
  name: "twilioCreds",
  initialState,
  reducers: {
    setSearchedNumbersSuccess: (state, action) => {
      state.searchedNumbers = action.payload;
      state.isLoading = false;
    },
    fetchIcsSettingsSuccess: (state, action) => {
      state.isLoading = false;
      state.icsSettings = action.payload;
    },
    fetchTwilioCredsLoading: (state) => {
      state.isLoading = true;
    },
    fetchTwilioCredsSuccess: (state, action) => {
      state.isLoading = false;
      state.twilioCreds = action.payload;
    },
    fetchTwilioCredsFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    fetchIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    setBuyNumberError: (state, action) => {
      state.buyNumberError = action.payload;
    },
    setBuyNumberErrorText: (state, action) => {
      state.buyNumberErrorText = action.payload;
    },
    setMyNumbers: (state, action) => {
      state.myNumbers = action.payload;
    },
    setReleasedNumbers: (state, action) => {
      state.releasedNumbers = action.payload;
    },
  },
});

export const {
  setReleasedNumbers,
  setMyNumbers,
  setBuyNumberErrorText,
  setSearchedNumbersSuccess,
  setBuyNumberError,
  fetchTwilioCredsLoading,
  fetchTwilioCredsSuccess,
  fetchTwilioCredsFail,
  fetchIcsSettingsSuccess,
  fetchIsLoading,
  setOpen,
} = twilioData.actions;
export default twilioData.reducer;
