import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function CustomSeparator({ crumb }) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (window.location.pathname.includes("contact")) {
      navigate(-1);
    } else {
      navigate(`/${crumb[0].toLowerCase()}`);
    }
  };

  return (
    <Breadcrumbs
      separator={<NavigateBeforeIcon fontSize="small" />}
      sx={{ alignSelf: { xs: "start", md: "center" } }}
    >
      <Typography
        onClick={handleClick}
        sx={{ cursor: "pointer", color: "text.secondary" }}
      >
        {crumb[0]}
      </Typography>
      <Typography color={"text.secondary"}>{crumb[1]}</Typography>
    </Breadcrumbs>
  );
}
