import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllActions } from "../../../../../pages/campaign-details/redux/steps/details.actions";

export const StepOne = ({ chooseFunc, handleChooseFunc }) => {
  const dispatch: any = useDispatch();
  const actionsList = [2, 3, 21, 12, 10, 14, 27, 19, 17, 20];
  const { actions } = useSelector((state: any) => state.campaignDetails);
  const { opportunity } = useSelector((state: any) => state.opportunityDetails);

  React.useEffect(() => {
    if (actions?.length === 0) {
      dispatch(getAllActions());
    }
  }, []);

  return (
    <FormControl variant="outlined" fullWidth sx={{ mb: 4 }}>
      <InputLabel>Choose a new Status</InputLabel>
      <Select
        value={chooseFunc}
        onChange={handleChooseFunc}
        label="choose a new Status"
      >
        {actions?.map(
          (item) =>
            opportunity?.action?.step_id !== item.id &&
            actionsList.includes(item.id) && (
              <MenuItem value={item.id}>{item.status}</MenuItem>
            )
        )}
      </Select>
    </FormControl>
  );
};
