import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ProgressBar from "../../../../components/widgets/progress-bar";
import CampaignTable from "./campaign.table";
import { Box, Typography } from "@mui/material";
import VtechButton from "../../../../components/vtech-button/VtechButton";
import { handleModal } from "../../redux/audience/edit.slice";

const CampaignLogs = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.audience);
  return (
    <Box flex={2}>
      <Typography variant="h2" mb={2.5}>
        Tracking the expenses and audience size of the campaign
      </Typography>
      <VtechButton
        sx={{
          justifyContent: "start",
          mb: "6px",
          whiteSpace: "initial",
        }}
        color="secondary"
        fullWidth
        title="Include the campaign cost and/or audience size in the log"
        startIcon={<img src="/icons/plus-dark.svg" />}
        onClick={() => dispatch(handleModal(true))}
      />
      {isLoading && <ProgressBar />}
      <CampaignTable />
    </Box>
  );
};

export default CampaignLogs;
