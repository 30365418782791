import { Alert, Box, TextField } from "@mui/material";
// import Alert from "@mui/material/Alert";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import ProgressBar from "../../../components/widgets/progress-bar/index";
import { createUser, updateUser } from "../../../services/userApi";
import { userRegistrationValidation } from "../../../utils/validations.utils";
import { addUserFail, addUserPending, addUserSuccess } from "../userSlice";
import { fetchAllUsers } from "../usersListAction";
import VtechModal from "../../../components/vtech-modal/VtechModal";
import { showSnackbar } from "../../../snackbar/snackbarSlice";

const AddUserModal = ({
  handleAddUserDialogClose,
  openAddUserDialog,
  isEdit,
  uid,
  setEdit,
}) => {
  const dispatch = useDispatch();
  const { isLoading, error, successMsg } = useSelector((state) => state.user);
  const { users, limit } = useSelector((state) => state.users);
  const [editUser, setEditUser] = useState({
    firstName: "",
    lastName: "",
    title: "",
    email: "",
  });

  useEffect(() => {
    if (!isEdit) {
      setEditUser({ firstName: "", lastName: "", title: "", email: "" });
      formik.resetForm();
    } else {
      const getUser = users && users.filter((user) => user.id === uid);
      setEditUser({
        firstName: getUser[0].first_name,
        lastName: getUser[0].last_name,
        title: getUser[0].user_type,
        email: getUser[0].email,
      });
    }
  }, [handleAddUserDialogClose, isEdit]);

  const createAgent = (values, resetForm) => {
    dispatch(addUserPending());
    createUser({
      first_name: values.firstName,
      last_name: values.lastName,
      user_type: values.title,
      email: values.email,
    })
      .then((res) => {
        dispatch(addUserSuccess("User created Successfully."));
        dispatch(fetchAllUsers(1, limit));
        handleAddUserDialogClose();
        dispatch(showSnackbar("User created Successfully!"));
        // setMsg("User Added Successfully!");
        // setOpenSnack(true);
        resetForm();
      })
      .catch((error) => {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
        dispatch(showSnackbar("Something went wrong!"));
        dispatch(addUserFail(error.messages[0].errors.email));
      });
  };

  const editAgent = (values, resetForm) => {
    dispatch(addUserPending());
    updateUser(uid, {
      first_name: values.firstName,
      last_name: values.lastName,
      user_type: values.title,
      email: values.email,
    })
      .then((res) => {
        dispatch(addUserSuccess("User update Successfully!"));
        dispatch(fetchAllUsers(1, limit));
        handleAddUserDialogClose();
        // setMsg("User Updated Successfully!");
        // setOpenSnack(true);
        dispatch(showSnackbar("User Updated Successfully!"));
        resetForm();
      })
      .catch((error) => {
        dispatch(addUserFail(error.messages[0].errors.email));
        dispatch(showSnackbar("Something went wrong!"));
      })
      .finally(() => {
        setTimeout(() => {
          setEdit(false);
        }, 100);
      });
  };

  const formik = useFormik({
    initialValues: editUser,
    enableReinitialize: true,
    validationSchema: userRegistrationValidation,
    onSubmit: (values, { resetForm }) => {
      if (isEdit) {
        editAgent(values, resetForm);
      } else {
        createAgent(values, resetForm);
      }
    },
  });

  return (
    <>
      <VtechModal
        title={isEdit ? "Edit user" : "Add user"}
        isLoading={isLoading}
        primaryButtonLabel={isEdit ? "Update" : "Add user"}
        primaryButtonAction={formik.handleSubmit}
        open={openAddUserDialog}
        handleClose={handleAddUserDialogClose}
      >
        {error && <Alert severity="error">{error}</Alert>}
        {successMsg && <Alert severity="success">{successMsg}</Alert>}
        <Box
          sx={{
            display: "flex",
            gap: 2.5,
            flexDirection: { xs: "column", md: "row" },
            my: 2.5,
          }}
        >
          <TextField
            required
            fullWidth
            id="firstName"
            label="First name"
            name="firstName"
            autoComplete="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
          <TextField
            required
            fullWidth
            id="lastName"
            label="Last name"
            name="lastName"
            autoComplete="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2.5,
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <TextField
            required
            fullWidth
            id="title"
            label="Position"
            name="title"
            autoComplete="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
          />

          <TextField
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            disabled={isEdit && isEdit}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Box>
      </VtechModal>
    </>
  );
};

export default AddUserModal;
