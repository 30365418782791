import { createTheme } from "@mui/material";

export const WHITE = "#FFFFFF";
export const PRIMARY_MAIN = "#245EC1";
export const SUCCESS_MAIN = "#289374";
export const SECONDARY_MAIN = "#9C27B0";
export const WHITE_LIGHT = "#FFFFFFB2";
export const DARK_MODE_DIVIDER = "#363636";
export const LIGHT_MODE_DIVIDER = "#E8E8E8";
export const DARK_MODE_BACKGROUND = "#121212";
export const LIGHT_MODE_BACKGROUND = "#F9F9FB";
export const LIGHT_MODE_TEXT_COLOR_1 = "#19243B";
export const LIGHT_MODE_TEXT_COLOR_2 = "#5A6479";
export const DARK_MODE_PAPER_BACKROUND = "#1F1F1F";
export const LIGHT_MODE_DRAWER_BACKGROUND = "#131E57";
const FONT_FAMILY = ["Manrope", "sans-serif"].join(",");

const baseTheme = createTheme({
  typography: {
    fontFamily: FONT_FAMILY,
    allVariants: {
      fontFamily: FONT_FAMILY,
      textTransform: "none",
    },
    h1: {
      fontSize: "30px",
      fontWeight: 700,
    },
    h2: {
      fontSize: "20px",
      fontWeight: 700,
    },
    h3: {
      fontSize: "16px",
      fontWeight: 700,
    },
    body1: {
      fontSize: "14px",
      fontWeight: 500,
    },
    body2: {
      fontSize: "14px",
      fontWeight: 500,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none", // Remove default gradient to avoid issues with background color of paper in dark mode
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "16px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
  },
});

export const darkTheme = createTheme({
  ...baseTheme,

  palette: {
    mode: "dark",
    primary: {
      main: PRIMARY_MAIN,
    },
    success: {
      main: SUCCESS_MAIN,
    },
    secondary: {
      main: SECONDARY_MAIN,
    },
    background: {
      default: DARK_MODE_BACKGROUND,
      paper: DARK_MODE_PAPER_BACKROUND,
    },
    text: {
      primary: WHITE,
      secondary: WHITE_LIGHT,
    },
    divider: DARK_MODE_DIVIDER,
  },

  typography: {
    ...baseTheme.typography,
    body2: {
      ...baseTheme.typography.body2,
      color: WHITE_LIGHT,
    },
  },
  components: {
    ...baseTheme.components,
    MuiButton: {
      variants: [
        {
          props: { variant: "text" },
          style: {
            color: "white",
          },
        },
      ],
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          ".MuiStepLabel-label.Mui-completed": {
            color: "#66BB6A",
          },
          ".MuiStepIcon-root.Mui-completed": {
            color: "#66BB6A",
          },
          ".MuiStepLabel-label.Mui-active": {
            fontWeight: 600,
          },
        },
      },
    },
  },
});

export const lightTheme = createTheme({
  ...baseTheme,

  palette: {
    mode: "light",
    primary: {
      main: PRIMARY_MAIN,
    },
    background: {
      default: LIGHT_MODE_BACKGROUND,
      paper: WHITE,
    },
    success: {
      main: SUCCESS_MAIN,
    },
    secondary: {
      main: SECONDARY_MAIN,
    },
    text: {
      primary: LIGHT_MODE_TEXT_COLOR_1,
      secondary: LIGHT_MODE_TEXT_COLOR_2,
    },
    divider: LIGHT_MODE_DIVIDER,
  },

  typography: {
    ...baseTheme.typography,
    body2: {
      ...baseTheme.typography.body2,
      color: LIGHT_MODE_TEXT_COLOR_2,
    },
  },
  components: {
    ...baseTheme.components,
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          ".MuiStepLabel-label.Mui-completed": {
            color: "#2E7D32",
          },
          ".MuiStepIcon-root.Mui-completed": {
            color: "#2E7D32",
          },
          ".MuiStepLabel-label.Mui-active": {
            fontWeight: 600,
          },
        },
      },
    },
  },
});
