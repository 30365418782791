import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePickers } from "../../../../../../components/widgets/date-time-picker";
import moment, { duration } from "moment";
import {
  ArrowDropDownIcon,
  DatePicker,
  TimePicker,
  renderTimeViewClock,
} from "@mui/x-date-pickers";

const ACTION_FORMS = {
  getSchedularSellerForm: (
    assignTo,
    setAssignee,
    users,
    setSelectedDate,
    setSelectedTime,
    setDuration,
    selectedDate,
    selectedTime,
    duration
  ) => {
    return (
      <>
        <div>
          <FormControl style={{ width: "100%" }} variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">
              Assign To
            </InputLabel>
            <Select
              label="Assign To"
              value={assignTo}
              onChange={(e) => setAssignee(e.target.value)}
            >
              {Object.keys(users).length > 0 ? (
                users.map((user) => (
                  <MenuItem value={user.id}>{user.email}</MenuItem>
                ))
              ) : (
                <MenuItem value="not found">Records not found</MenuItem>
              )}
            </Select>
          </FormControl>
          <Box sx={{ my: 2.5, display: "flex", gap: 2 }}>
            <DatePicker
              slots={{ openPickerIcon: ArrowDropDownIcon }}
              sx={{ width: 1 }}
              label="Select appointment date"
              value={selectedDate ? moment(selectedDate) : null}
              onChange={(value) => {
                setSelectedDate(moment(value).format("YYYY-MM-DD"));
                setSelectedTime(moment().format("HH:mm"));
              }}
              disablePast
            />
            <TimePicker
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
              }}
              sx={{ width: 1 }}
              label="Select Appointment Start time"
              value={selectedTime ? moment(selectedTime, "HH:mm") : null}
              onChange={(value) =>
                setSelectedTime(moment(value).format("HH:mm"))
              }
              slots={{ openPickerIcon: ArrowDropDownIcon }}
            />
          </Box>
          <FormControl fullWidth>
            <InputLabel htmlFor="outlined-age-native-simple">
              Duration
            </InputLabel>
            <Select
              id="outlined-age-native-simple"
              label="Duration"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            >
              <MenuItem value="00:15">15 minutes</MenuItem>
              <MenuItem value="00:30">Half hour</MenuItem>
              <MenuItem value="01:00">Full hour</MenuItem>
              <MenuItem value="12:00">Half day</MenuItem>
              <MenuItem value="23:59">Full day</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ width: "100%", my: 2.5 }}>
            <TextField
              label="Change Instructions"
              multiline
              rows={2}
              variant="outlined"
            />
          </FormControl>
        </div>
      </>
    );
  },

  getSequenceForm: (setSequenceId, sequences) => {
    return (
      <FormControl fullWidth sx={{ mb: 2.5 }}>
        <InputLabel>Select an Automation</InputLabel>
        <Select
          label="Select an Automation"
          onChange={(e) => setSequenceId(e.target.value)} // TODO: Dynamic sequence Ids pending
        >
          {sequences?.map((item) => (
            <MenuItem value={item.id}>{item.title}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  },
};

export default ACTION_FORMS;
