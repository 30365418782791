import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "reduxjs-toolkit-persist";
import storage from "reduxjs-toolkit-persist/lib/storage"; // defaults to localStorage for web
import loginReducer from "./components/layouts/login/loginSlice";
import editStateSlice from "./components/widgets/Edit-status-action/edit-state.slice";
import assignment from "./pages/campaign-details/redux/assignment/assignment.slice";
import campaignAudience from "./pages/campaign-details/redux/audience/edit.slice";
import currentCampaign from "./pages/campaign-details/redux/edit-campaign/update-campaign.slice";
import campaignSettings from "./pages/campaign-details/redux/settings/settings.slice";
import campaignDetails from "./pages/campaign-details/redux/steps/details.slice";
import campaignReducer from "./pages/campaigns/campaign.slice";
import dashboardAssignment from "./pages/dashboard/redux/assignment/assignment.slice";
import dashboardDetails from "./pages/dashboard/redux/steps/details.slice";
import opportunities from "./pages/opportunities/opportunitesListSlice";
import actionsState from "./pages/opportunity-details/actions/redux/actions-redux.slice";
import actionList from "./pages/actions/actionsListSlice";
import commonLogs from "./pages/opportunity-details/commonLog.slice";
import opportunityDetails from "./pages/opportunity-details/opportunity.slice";
import userReducer from "./pages/users-list/userSlice";
import usersListReducer from "./pages/users-list/usersListSlice";
import menuHandler from "./shared_slice/menu-handler.slice";
import contact from "./pages/contacts/contact.slice";
import contactDetails from "./pages/contact-detail/contactDetail.slice";
import userSettings from "./pages/user-profile/userProfile.settings.slice";
import DashboardActivityLog from "./pages/dashboard/activity-page/activityLog.slice";
import SequenceSlice from "./pages/sequences/redux/sequence.slice";
import Messaging from "./pages/dashboard/settings/secondary/settings.slice";
import notifications from "./components/layouts/top_nav_bar/notification/notification.slice";
import dashboardStats from "./pages/dashboard/dashboard/dashboardStatSlice";
import campaignStats from "./pages/campaign-details/dashboard/campaignStatSlice";
import opportunityFiltersSlice from "./pages/opportunities/opportunitiesComponents/oppo-component.slice";
import darkModeReducer from "./dark-mode/darkModeReducer";
import snackBarReducer from "./snackbar/snackbarSlice";
import tooManyReqReducer from "./too-many-requests/TooManyReqSlice";

const persistConfig = {
  key: "root",
  whitelist: ["darkMode", "login", "opportunitiesFilters", "actionList"],
  storage,
};

const reducer = combineReducers({
  modal: tooManyReqReducer,
  snackbar: snackBarReducer,
  darkMode: darkModeReducer,
  login: loginReducer,
  users: usersListReducer,
  user: userReducer,
  menuHandler: menuHandler,
  campaignDetails: campaignDetails,
  dashboardDetails: dashboardDetails,
  campaigns: campaignReducer,
  audience: campaignAudience,
  currentCampaign: currentCampaign,
  assignment: assignment,
  dashboardAssignment: dashboardAssignment,
  campaignSettings: campaignSettings,
  opportunities: opportunities,
  opportunityDetails: opportunityDetails,
  commonLogs: commonLogs,
  actions: actionsState,
  actionList: actionList,
  editState: editStateSlice,
  contact: contact,
  contactDetails: contactDetails,
  userSettings: userSettings,
  DashboardActivityLog: DashboardActivityLog,
  sequences: SequenceSlice,
  Messaging: Messaging,
  notifications: notifications,
  dashboardStats: dashboardStats,
  campaignStats: campaignStats,
  opportunitiesFilters: opportunityFiltersSlice,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

// new log

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
