import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersWOL } from "../../../pages/users-list/usersListAction";
import style from "./assignee.module.css";

export const AssigneeSelection = ({ userSelection, setUserSelection }) => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.users);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (users) {
      if (Object.keys(users).length <= 0) {
        dispatch(getAllUsersWOL());
      }
    }
  }, [dispatch, users]);

  const handleDeliveryChange = (event) => {
    if (setUserSelection) {
      setUserSelection(event.target.value);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <FormControl sx={{ my: 2 }} fullWidth>
        <InputLabel>Assignee</InputLabel>
        <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={userSelection}
          label="Assignee"
          onChange={handleDeliveryChange}
        >
          {Object.keys(users).length > 0 ? (
            users.map((user) => <MenuItem value={user}>{user.email}</MenuItem>)
          ) : (
            <MenuItem value="not found">Records not found</MenuItem>
          )}
        </Select>
      </FormControl>
    </>
  );
};
