import Step from "@mui/material/Step";
// import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import React, { useState } from "react";
import { StepOne } from "./step-1";
import { StepTwo } from "./step-2";

export const EditOpportunityStepper = ({ id, setOpen }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [chooseFunc, setChooseFunc] = useState("");
  const steps = ["Update Status", "Assign Next Operation"];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleChooseFunc = (event) => {
    setChooseFunc(event.target.value);
    handleNext();
  };
  const handleEditIndex = (index) => {
    if (chooseFunc !== "") {
      setActiveStep(index);
    }
  };

  return (
    <>
      <Stepper activeStep={activeStep} sx={{ my: 4 }}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel onClick={() => handleEditIndex(index)}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === 0 && (
        <StepOne chooseFunc={chooseFunc} handleChooseFunc={handleChooseFunc} />
      )}
      {activeStep === 1 && (
        <StepTwo id={id} currentActionId={chooseFunc} setOpen={setOpen} />
      )}
    </>
  );
};
