import {
  getResponseTemplate,
  sendAutomatedResponse,
  updateResponseTemplate,
} from "../../../../services/campaignSettings.services";
import { showSnackbar } from "../../../../snackbar/snackbarSlice";
import {
  campaignFail,
  campaignLoading,
  fetchCampaignSuccess,
  stopLoading,
} from "./settings.slice";

export const createCampaignResponse = (data) => (dispatch) => {
  dispatch(campaignLoading());
  sendAutomatedResponse(data)
    .then((res) => {
      dispatch(stopLoading());
      dispatch(showSnackbar("Campaign response template added successfully!"));
      dispatch(getAllResponseTemplate(data.campaign_id));
    })
    .catch((err) => {
      dispatch(campaignFail(err.messages[0].message));
    });
};

export const getAllResponseTemplate = (id) => (dispatch) => {
  dispatch(campaignLoading());
  getResponseTemplate(id)
    .then((res) => {
      dispatch(fetchCampaignSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(campaignFail("something went wrong."));
    });
};

export const updateCampaignResponse = (id, data) => (dispatch) => {
  dispatch(campaignLoading());
  updateResponseTemplate(id, data)
    .then((res) => {
      dispatch(stopLoading());
      dispatch(
        showSnackbar("Campaign response template updated successfully!")
      );
      dispatch(getAllResponseTemplate(data.campaign_id));
    })
    .catch((err) => {
      dispatch(campaignFail(err.messages[0].message));
    });
};
