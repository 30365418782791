import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import Pipeline from "./actionComponents/pipeline/pipeline";
import ActionEvents from "./actionComponents/action-events";
import ActionTable from "./action-table/actionTable";
import { fetchAllActions } from "./actionsList.Action";
import { useDispatch, useSelector } from "react-redux";
// import { getAllUsersWOL } from "../users-list/usersListAction";
// import { getAllCampaignWOL } from "../campaigns/campaigns.action";
// import { getLabels } from "../opportunities/opportunitiesListAction";
// import { getAllActions } from "../campaign-details/redux/steps/details.actions";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { checkDateTime } from "../../utils/global.utils";
// import { getOpportunitiesForActions } from "../../services/opportunities";
import {
  setAppliedFilters,
  setMyActions,
  // setOpportunities,
} from "./actionsListSlice";
import MainLayout from "../../components/MainLayout/MainLayout";
// import OperationsTabs from "./tabs/operations-tabs";
import { PRIMARY_MAIN } from "../../theme";
import VtechTabs from "../../components/tabs/VtechTabs";
import { useNavigate } from "react-router-dom";
// import { getSequences } from "../sequences/redux/sequence.actions";

const getActiveTab = (url) => (url.includes("all-operations") ? 1 : 0);

const Actions = () => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const tabs = ["My Operations", "All Operations"];
  const url = window.location.pathname;
  const [activeTab, setActiveTab] = useState(getActiveTab(url));
  const { user } = useSelector((state: any) => state.login);
  const [filters, setFilters] = useState({ loggedInUser: user.id });
  const [topFiveArray, setTopFiveArray] = useState([]);
  const [currentFilter, setCurrentFilter] = useState<any>("");
  const [filterCheck, setFilterCheck] = useState(false);
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  const [filteredArray, setFilteredArray] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const { isLoading, actions, opportunities, appliedFilters } = useSelector(
    (state: any) => state.actionList
  );

  useEffect(() => {
    // dispatch(getAllActions());
    // dispatch(getAllUsersWOL());
    // dispatch(getAllCampaignWOL());
    // dispatch(getLabels());
    // dispatch(getSequences());
    // getOpportunitiesForActions().then((res) => {
    //   dispatch(setOpportunities(res.data.data));
    // });
    if (localStorage.getItem("pipeLineFilters")) {
      setCurrentFilter(
        JSON.parse(localStorage.getItem("pipeLineFilters") || "{}")
      );
    }
    if (localStorage.getItem("currentRowsPerPage")) {
      setRowsPerPage(localStorage.getItem("currentRowsPerPage") || 10);
    }
  }, [dispatch]);

  useEffect(() => {
    if (appliedFilters) {
      dispatch(fetchAllActions(JSON.parse(appliedFilters), activeTab, user.id));
    } else {
      dispatch(fetchAllActions(filters, activeTab, user.id));
    }
  }, [filters, activeTab, appliedFilters]);

  const groupByAuto = (data, key) => {
    var groups = {};
    for (var i in data) {
      if (!groups.hasOwnProperty(data[i][key])) groups[data[i][key]] = [];
      groups[data[i][key]].push(data[i]);
    }
    return groups;
  };

  const settingUpFilteredArray = () => {
    let tempArray = actions.map((row) => {
      const cond =
        currentFilter !== ""
          ? currentFilter === "due"
            ? checkDateTime(row.due_date_time, "due")
            : currentFilter === "dueToday"
            ? checkDateTime(row.due_date_time, "today")
            : currentFilter === "dueSchedule"
            ? checkDateTime(row.due_date_time, "future")
            : currentFilter === "task"
            ? row.type === "task"
            : row.name.toLowerCase() === currentFilter.toLowerCase()
          : row.status !== "completed";
      const queryText =
        row.name +
        " " +
        row.seller.first_name +
        " " +
        row.seller.last_name +
        " " +
        row.seller.email +
        " " +
        row.assignee.first_name +
        " " +
        row.assignee.last_name +
        " " +
        row.assignee.email +
        " " +
        row.seller.phone +
        " " +
        row.opportunity.street_address +
        " " +
        row.opportunity.city +
        " " +
        row.opportunity.state +
        " " +
        row.opportunity.zipcode;
      const querySearch = queryText.toLowerCase().includes(query.toLowerCase());
      return cond && querySearch && row;
    });
    let newTemp = tempArray
      ?.map((item) => {
        let check = opportunities?.find((x) => x.id === item?.opportunity?.id);
        if (
          check?.action?.step_id === 26 &&
          check?.offers &&
          Object.keys(check?.offers).length > 0
        ) {
          if (check.offers[0].status === "pending") {
            return item;
          } else {
            return null;
          }
        } else {
          return item;
        }
      })
      .filter((x) => x && x !== null);
    setTotalCount(newTemp.length);
    setFilteredArray(newTemp.slice(0, rowsPerPage));
    return tempArray;
  };

  useEffect(() => {
    if (actions && opportunities) {
      setPage(0);
      let finalFilteredArray = settingUpFilteredArray();
      finalFilteredArray = finalFilteredArray?.map((item) => {
        let check = opportunities?.find((x) => x.id === item?.opportunity?.id);
        if (
          check?.action?.step_id === 26 &&
          check?.offers &&
          Object.keys(check.offers).length > 0
        ) {
          if (check.offers[0].status === "pending") {
            return item;
          } else {
          }
        } else {
          return item;
        }
      });

      finalFilteredArray = finalFilteredArray.filter((x) => x !== null);
      if (currentFilter === "") {
        let finalArray: any = [];
        const groupsArray: any = groupByAuto(
          finalFilteredArray.filter((item) => item && item),
          "type"
        );

        const steps = groupByAuto(groupsArray.step, "name");
        const stepsArray: any = Object.keys(steps).reduce(
          (acc, item) => [...acc, { name: item, length: steps[item].length }],
          []
        );
        if (groupsArray.task) {
          const tasks = {
            name: "tasks",
            length: Object.values(groupsArray.task).length,
          };
          finalArray = stepsArray.concat(tasks);
        } else {
          finalArray = stepsArray;
        }
        const sorted = finalArray
          .sort((a, b) => b.length - a.length)
          .slice(0, 5);
        setTopFiveArray(sorted);
      }
    }
  }, [actions, opportunities, currentFilter, query, rowsPerPage]);

  useEffect(() => {
    let currentPage: any = localStorage.getItem("currentPage") || page;
    if (currentPage >= 0 && actions && Object.keys(actions).length > 0) {
      const tempArray = actions.map((row) => {
        const cond =
          currentFilter !== ""
            ? currentFilter === "due"
              ? checkDateTime(row.due_date_time, "due")
              : currentFilter === "dueToday"
              ? checkDateTime(row.due_date_time, "today")
              : currentFilter === "dueSchedule"
              ? checkDateTime(row.due_date_time, "future")
              : currentFilter === "task"
              ? row.type === "task"
              : row.name.toLowerCase() === currentFilter.toLowerCase()
            : row.status !== "completed";
        const querySearch =
          row.name.toLowerCase().includes(query.toLowerCase()) ||
          row.seller.first_name.toLowerCase().includes(query.toLowerCase()) ||
          row.seller.last_name.toLowerCase().includes(query.toLowerCase()) ||
          row.seller.email.toLowerCase().includes(query.toLowerCase()) ||
          row.assignee.first_name.toLowerCase().includes(query.toLowerCase()) ||
          row.assignee.last_name.toLowerCase().includes(query.toLowerCase()) ||
          row.assignee.email.toLowerCase().includes(query.toLowerCase()) ||
          row.seller.phone.toLowerCase().includes(query.toLowerCase());
        return cond && querySearch && row;
      });
      setTotalCount(tempArray.filter((item) => item && item).length);
      setFilteredArray(
        tempArray
          .filter((item) => item && item)
          .slice(
            currentPage * rowsPerPage,
            currentPage * rowsPerPage + rowsPerPage
          )
      );
      setPage(parseInt(currentPage) || 0);
    }
  }, [page]);

  const handleActionEvents = (val, index) => {
    val === "tasks" ? setCurrentFilter("task") : setCurrentFilter(val);
    localStorage.setItem("actionEvents", JSON.stringify(val));
    localStorage.setItem("actionIndex", index);
    localStorage.removeItem("pipeLineIndex");
    localStorage.removeItem("pipeLineFilters");
  };

  const handlePipelineClear = () => {
    setCurrentFilter("");
    localStorage.removeItem("pipeLineIndex");
    localStorage.removeItem("pipeLineFilters");
    localStorage.removeItem("actionEvents");
    localStorage.removeItem("actionIndex");
    localStorage.removeItem("currentPage");
    localStorage.removeItem("currentRowsPerPage");
  };

  const handleActiveTab = (index) => {
    setActiveTab(index);
    switch (index) {
      case 0:
        navigate("/operations");
        break;
      case 1:
        navigate("/operations/all-operations");
    }
    // dispatch(setAppliedFilters(JSON.stringify(updatedFilters)));
  };

  useEffect(() => {
    if (window.location.pathname === "/operations") {
      setActiveTab(0);
    }
  }, [window.location.pathname]);

  return (
    <>
      <MainLayout title="Operations">
        <VtechTabs tabs={tabs} activeTab={activeTab} setTab={handleActiveTab} />
        <Paper sx={{ my: 2, p: 2.5 }}>
          <Box sx={{ position: "relative" }}>
            {currentFilter !== "" && (
              <Box
                sx={{
                  position: "absolute",
                  right: -30,
                  top: -30,
                  bgcolor: PRIMARY_MAIN,
                  borderRadius: "50%",
                }}
              >
                <IconButton
                  sx={{ width: 32, height: 32 }}
                  onClick={() => handlePipelineClear()}
                >
                  <CloseIcon style={{ color: "white", fontSize: "24px" }} />
                </IconButton>
              </Box>
            )}
            <Pipeline
              actions={actions}
              topFive={topFiveArray}
              currentFilter={currentFilter}
              setCurrentFilter={setCurrentFilter}
            />
          </Box>
          <ActionEvents
            topFive={topFiveArray}
            currentFilter={currentFilter}
            onClick={(val, index) => handleActionEvents(val, index)}
          />
        </Paper>
        <ActionTable
          actions={actions}
          filteredArray={filteredArray}
          page={page}
          filterCheck={filterCheck}
          rowsPerPage={rowsPerPage}
          query={query}
          totalCount={totalCount}
          isLoading={isLoading}
          setFilters={setFilters}
          setFilterCheck={setFilterCheck}
          setPage={setPage}
          setQuery={setQuery}
          setRowsPerPage={setRowsPerPage}
        />
      </MainLayout>
    </>
  );
};

export default Actions;
