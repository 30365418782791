import React from "react";
import TopNavbar from "../layouts/top_nav_bar/index";
import SideBar from "../layouts/menu_app_bar/sidebar";
import { Box, Divider, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setMenuState } from "../../shared_slice/menu-handler.slice";
import Snackbar from "../widgets/snackbar";
import { RootState } from "../../store";
import BurgerMenu from "./BurgerMenu";
import BackDrop from "./BackDrop";
import TooManyRequests from "../../too-many-requests";

type Props = {
  children: React.ReactNode;
  title: string;
  subTitle?: string;
  divider?: boolean;
  crumbs?: string[];
};

const getMainStyles = (isMobile: boolean) => ({
  ml: isMobile ? 0 : 30,
  pl: isMobile ? 0 : 6,
  pr: isMobile ? 0 : 4,
  my: 3,
});

function MainLayout({ children, title, subTitle, divider, crumbs }: Props) {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const dispatch = useDispatch();
  const open = useSelector((state: RootState) => state.menuHandler.isOpen);

  React.useEffect(() => {
    dispatch(setMenuState(!isMobile));
  }, [isMobile]);

  return (
    <>
      {open && <SideBar />}
      {open && isMobile && <BackDrop />}
      {!open && <BurgerMenu />}
      <Box component="main" sx={getMainStyles(isMobile)}>
        <TopNavbar title={title} crumbs={crumbs} subTitle={subTitle} />
        {divider && <Divider />}
        {children}
      </Box>
      <Snackbar />
      <TooManyRequests />
    </>
  );
}

export default MainLayout;
