import React, { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  loginFail,
  loginPending,
  loginSuccess,
} from "../../components/layouts/login/loginSlice";
import ProgressBar from "../../components/widgets/progress-bar/index";
import { updateProfile } from "../../services/settings.service";
import Box from "@mui/material/Box";
import MainLayout from "../../components/MainLayout/MainLayout";
import { showSnackbar } from "../../snackbar/snackbarSlice";
import { useNavigate } from "react-router-dom";
import VtechTabs from "../../components/tabs/VtechTabs";

const EditProfile = lazy(() => import("./edit-profile"));
const Notifications = lazy(() => import("./notifications"));
const ApiKeySection = lazy(() => import("./api-key"));

const getActiveIndex = (url) => {
  return url.includes("notifications") ? 1 : url.includes("api-keys") ? 2 : 0;
};

const AccountSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const url = window.location.href;
  const [activeTab, setActiveTab] = useState(getActiveIndex(url));
  const tabs = ["User profile", "Notifications", "Api keys"];
  const [saveSuccess, setSaveSuccess] = useState(false);

  const handleProfileSubmission = (value) => {
    dispatch(loginPending());
    updateProfile(value)
      .then((res) => {
        dispatch(loginSuccess(res.data.data));
        dispatch(showSnackbar("The data has been successfully updated!"));
        setSaveSuccess(true);
        setTimeout(() => {
          setSaveSuccess(false);
        }, 3000);
      })
      .catch((error) => {
        dispatch(loginFail("something went wrong"));
        dispatch(showSnackbar("Something Went Wrong!"));
      });
  };

  // for when the active tab is not 0 i.e. User Profile and sidebar is clicked
  useEffect(() => {
    if (window.location.pathname === "/account-settings") {
      setActiveTab(0);
    }
  }, [window.location.pathname]);

  const handleActiveTab = (index) => {
    setActiveTab(index);
    index === 0
      ? navigate("/account-settings")
      : navigate(
          `/account-settings/${tabs[index].toLowerCase().replace(" ", "-")}`
        );
  };

  return (
    <MainLayout title={tabs[activeTab]}>
      <Box>
        <VtechTabs tabs={tabs} activeTab={activeTab} setTab={handleActiveTab} />
        <Suspense fallback={<ProgressBar />}>
          {activeTab === 0 && (
            <EditProfile
              onSubmit={handleProfileSubmission}
              saveSuccess={saveSuccess}
            />
          )}
          {activeTab === 1 && <Notifications />}
          {activeTab === 2 && <ApiKeySection />}
        </Suspense>
      </Box>
    </MainLayout>
  );
};

export default AccountSettings;
