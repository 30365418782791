import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import QueueForm from "./queue-form";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewStep,
  deleteQueueStep,
  shuffleSteps,
} from "../../../../sequences/redux/sequence.actions";
import {
  arraySortByKey,
  convertTimeFormate,
} from "../../../../../utils/global.utils";
import { Box, IconButton, Paper } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { MaterialCard } from "../../../../../components/widgets/mat-card";
import AddNewStepBtn from "../../../../../components/widgets/button/button.widget";
import { useTheme } from "@emotion/react";
import { useDarkMode } from "../../../../../utils/useDarkMode";

export default function ControlledAccordions() {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [sortedArray, setSortedArray] = useState([]);
  const { currentSequence, stepsQueue, isLoading } = useSelector(
    (state) => state.sequences
  );

  const theme = useTheme();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleNewStep = () => {
    const data = {
      sequence_id: currentSequence.id,
      name: "New Step",
      send_on_day: "",
      selected_time: "",
      subject: "",
      message: "",
      action: "",
      type: "other",
      task_name: "",
      task_assignee: "",
    };
    dispatch(createNewStep(data));
  };

  useEffect(() => {
    if (stepsQueue) {
      let sortedVal = arraySortByKey(stepsQueue, "sortBy");
      setSortedArray(sortedVal);
    }
  }, [stepsQueue]);

  const handleDelete = (itemId) => {
    dispatch(deleteQueueStep(itemId, currentSequence.id));
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const startIndex = result.source.index;
    const endIndex = result.destination.index;

    if (startIndex === endIndex) {
      return;
    }

    const tempArr = arraySortByKey(sortedArray, "sortBy");
    const sourceStep = tempArr[startIndex];
    const destinationStep = tempArr[endIndex];

    if (startIndex < endIndex) {
      dispatch(
        shuffleSteps(currentSequence.id, sourceStep.id, destinationStep.id)
      );
    } else {
      dispatch(
        shuffleSteps(currentSequence.id, destinationStep.id, sourceStep.id)
      );
    }
  };

  return (
    <Paper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2.5,
        }}
      >
        <Typography variant="h2">Automation Queue</Typography>
        <AddNewStepBtn
          title="Add step to the queue"
          disable={isLoading}
          onClick={handleNewStep}
        />
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="sequence-items">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {sortedArray?.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id.toString()}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: 1,
                          alignItems: "center",
                        }}
                      >
                        <Accordion
                          expanded={expanded === `panel${index + 1}`}
                          onChange={handleChange(`panel${index + 1}`)}
                          sx={{
                            width: 1,
                            borderBottom:
                              index === sortedArray.length - 1
                                ? "none"
                                : `1px solid ${theme.palette.divider}`,
                            boxShadow: `0px 4px 17px -5px #C9C9C95E`,
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                          >
                            <Box display="flex" width={1}>
                              <Box flex={1}>
                                <Typography
                                  sx={{
                                    fontWeight: "600",
                                    fontSize: "16px",
                                    color: "text.primary",
                                  }}
                                >
                                  {item.name}
                                </Typography>
                              </Box>
                              <Box flex={1.3}>
                                <Typography color="text.secondary">
                                  {item.action === null
                                    ? "Select an operation"
                                    : item.action === "text"
                                    ? `Send a text in ${
                                        item.send_on_day
                                      } days @ ${convertTimeFormate(
                                        item.selected_time
                                      )}`
                                    : item.action === "email"
                                    ? `send an email in ${
                                        item.send_on_day
                                      } days @ ${convertTimeFormate(
                                        item.selected_time
                                      )}`
                                    : item.action === "custom_task"
                                    ? `Create a custom task in ${
                                        item.send_on_day
                                      } days @ ${convertTimeFormate(
                                        item.selected_time
                                      )}`
                                    : item.action === "voicemail" &&
                                      `send a voicemail in ${
                                        item.send_on_day
                                      } days @ ${convertTimeFormate(
                                        item.selected_time
                                      )}`}
                                </Typography>
                              </Box>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails style={{ display: "block" }}>
                            <QueueForm
                              stepDetails={item}
                              onClose={() => setExpanded(false)}
                            />
                          </AccordionDetails>
                        </Accordion>
                        <Box>
                          <IconButton onClick={() => handleDelete(item.id)}>
                            <img src="/icons/trash.svg" />
                          </IconButton>
                        </Box>
                      </Box>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Paper>
  );
}
