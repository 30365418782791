import { http } from "../apiClient/axiosInterceptor";

export const createGroup = (data) => {
  return http.post(`/userGroup/create`, data);
};

export const getAllGroups = (id) => {
  return http.get(`/userGroup/get?campaign_id=${id}`);
};

export const deleteGroup = (id) => {
  return http.delete(`userGroup/delete/${id}`);
};

export const assignGroupUser = (groupId, userId) => {
  return http.post(`userGroup/assignUser`, {
    group_id: groupId,
    user_id: userId,
  });
};

export const assignOwner = (campaignId, assignId, assigneeType) => {
  const data = {
    campaign_id: campaignId,
    assignee_id: assignId,
    assignee_type: assigneeType,
  };
  return http.post(`/campaign/assign/owner`, data);
};

export const getAllActionsOwners = (id) => {
  if (id) {
    return http.get(`/steps/assigned/get?campaign_id=${id}`);
  } else {
    return http.get(`/steps/assigned/get`);
  }
};

export const assignUserToAction = (
  stepId,
  campaignId,
  assigneeId,
  assigneeType
) => {
  const data = {
    step_id: stepId,
    campaign_id: campaignId,
    assignee_id: assigneeId,
    assignee_type: assigneeType,
  };
  return http.post(`/campaign/assign/user/step`, data);
};

export const getSystemOwner = () => {
  return http.get(`/system/owner`);
};

export const CampaignAssignment = (id, flag) => {
  return http.post(`/campaign/changeFlag`, { id, flag });
};
