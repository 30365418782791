import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  campaign: [],
  assignedAction: [],
  isLoading: false,
  isUpdateSuccess: false,
  error: "",
};

const currentCampaign = createSlice({
  name: "current-campaign",
  initialState,
  reducers: {
    campaignLoading: (state) => {
      state.isLoading = true;
    },
    fetchCampaignSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.campaign = payload;
    },
    stopLoading: (state) => {
      state.isLoading = false;
      state.isUpdateSuccess = true;
      state.error = "";
    },
    handleSuccess: (state) => {
      state.isUpdateSuccess = false;
    },
    campaignFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    fetchActions: (state, { payload }) => {
      state.assignedAction = payload;
    },
  },
});

export const {
  campaignLoading,
  campaignFail,
  stopLoading,
  fetchCampaignSuccess,
  handleSuccess,
  fetchActions,
} = currentCampaign.actions;
export default currentCampaign.reducer;
