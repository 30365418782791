import {
  Alert,
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
// import InfoIcon from "@mui/icons-material/Info";
import React from "react";
// import { DatePickers } from "../../../../date-time-picker";
// import { TimeMenu } from "../../../../date-time-picker/time-menu";
// import Tooltip from "@mui/material/Tooltip";
import { PRIMARY_MAIN } from "../../../../../../theme";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
import { ArrowDropDownIcon, DatePicker, TimePicker } from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ScheduleIcon from "@mui/icons-material/Schedule";

const NEXT_ACTION_FORMS = {
  getSchedularSellerForm: (
    setAssignee,
    assignee,
    ownerEmail,
    users,
    selectedDate,
    setSelectedDate,
    selectedTime,
    setSelectedTime,
    setDuration,
    onChecked,
    instructions,
    setInstruction
  ) => {
    return (
      <>
        <div>
          <FormControl
            style={{ width: "100%", marginTop: "20px" }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-age-native-simple">
              Assign To
            </InputLabel>
            <Select
              id="outlined-age-native-simple"
              label="Assign To"
              value={assignee}
              onChange={(e) => setAssignee(e.target.value)}
            >
              {Object.keys(users).length > 0 ? (
                users.map((user) => (
                  <MenuItem value={user.id}>{user.email}</MenuItem>
                ))
              ) : (
                <MenuItem value="not found">Records not found</MenuItem>
              )}
            </Select>
          </FormControl>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <FormControlLabel
              sx={{ mb: 2 }}
              control={<Checkbox onChange={onChecked} defaultChecked={false} />}
              label={
                <>
                  Check the box to set assignee as the opportunity owner.
                  <span style={{ color: PRIMARY_MAIN }}> {ownerEmail}</span> is
                  the current opportunity owner.
                </>
              }
            />
            {/* <Checkbox
              color="default"
              onChange={onChecked}
              defaultChecked={false}
            />
            Check the box to set assignee as the opportunity owner. {ownerEmail}{" "}
            is the current opportunity owner.{" "} */}
            {/* <Tooltip
              style={{ fontSize: "30px" }}
              title="The opportunity owner is the person “in charge” of this seller. They would receive any notifications of inbound communication from the seller."
            >
              <InfoIcon />
            </Tooltip> */}
          </div>
          <Box display={"flex"} gap={2} mb={2}>
            <DatePicker
              slots={{ openPickerIcon: ArrowDropDownIcon }}
              label="Select Appointment date"
              value={selectedDate ? moment(selectedDate) : null}
              onChange={(value) => {
                setSelectedDate(moment(value).format("YYYY-MM-DD"));
                setSelectedTime(moment().format("HH:mm"));
              }}
              sx={{ width: 1 }}
              disablePast
            />
            <TimePicker
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
              }}
              label="Select Appointment Start time"
              value={selectedTime ? moment(selectedTime, "HH:mm") : null}
              onChange={(value) =>
                setSelectedTime(moment(value).format("HH:mm"))
              }
              slots={{ openPickerIcon: ArrowDropDownIcon }}
              sx={{ width: 1 }}
            />
          </Box>

          {/* <DatePickers
            type="date"
            label="Select Appointment date"
            value={selectedDate}
            onChange={(value) => setSelectedDate(value)}
            min={true}
            fullWidth
          />
          <DatePickers
            type="time"
            label="Select Appointment Start time"
            value={selectedTime}
            onChange={(value) => setSelectedTime(value)}
            min={true}
            fullWidth
          /> */}
          <FormControl style={{ width: "100%" }} variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">
              Duration
            </InputLabel>
            <Select
              id="outlined-age-native-simple"
              label="Duration"
              onChange={(e) => setDuration(e.target.value)}
            >
              <MenuItem value="00:15">15 minutes</MenuItem>
              <MenuItem value="00:30">Half hour</MenuItem>
              <MenuItem value="01:00">Full hour</MenuItem>
              <MenuItem value="12:00">Half day</MenuItem>
              <MenuItem value="23:59">Full day</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            variant="outlined"
            style={{ width: "100%", marginTop: "20px" }}
          >
            <TextField
              id="outlined-multiline-static"
              label="Add instructions for the next operation"
              multiline
              rows={1}
              value={instructions}
              onChange={(e) => setInstruction(e.target.value)}
              variant="outlined"
            />
          </FormControl>
        </div>
      </>
    );
  },

  getGenericForm: (
    setAssignee,
    assignee,
    ownerEmail,
    users,
    selectedDate,
    setSelectedDate,
    selectedTime,
    setSelectedTime,
    onChecked,
    instructions,
    setInstruction,
    handleDueIn,
    setDueIN,
    dueIn
  ) => {
    return (
      <>
        <FormControl fullWidth sx={{ mt: 2.5 }}>
          <InputLabel>Assign To</InputLabel>
          <Select
            label="Assign To"
            value={assignee}
            onChange={(e) => setAssignee(e.target.value)}
            // endAdornment={
            //   <IconButton
            //     onClick={() => setAssignee("")}
            //     sx={{ display: assignee ? "" : "none", mr: 1 }}
            //   >
            //     <ClearIcon />
            //   </IconButton>
            // }
          >
            {Object.keys(users).length > 0 ? (
              users.map((user) => (
                <MenuItem value={user.id}>{user.email}</MenuItem>
              ))
            ) : (
              <MenuItem value="not found">Records not found</MenuItem>
            )}
          </Select>
        </FormControl>
        <Box sx={{ mt: 2.5 }}>
          <Alert severity="info" sx={{ width: "100%" }}>
            The person in charge of this seller is the opportunity owner. Any
            notifications of incoming communications from the seller would be
            sent to them.
          </Alert>
          <FormControlLabel
            sx={{ mt: 2 }}
            control={<Checkbox onChange={onChecked} defaultChecked={false} />}
            label={
              <>
                To make the assignee the opportunity's owner, check this box.
                The current opportunity owner is the{" "}
                <span style={{ color: PRIMARY_MAIN }}>{ownerEmail}</span>
              </>
            }
          />
        </Box>
        <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
          <FormControl fullWidth>
            <InputLabel>Due In</InputLabel>
            <Select
              label="Due In"
              onChange={handleDueIn}
              value={dueIn}
              endAdornment={
                <IconButton
                  onClick={() => {
                    setDueIN("");
                    setSelectedDate(null);
                  }}
                  sx={{ display: dueIn ? "" : "none", mr: 1 }}
                >
                  <ClearIcon />
                </IconButton>
              }
            >
              <MenuItem value="today">Today</MenuItem>
              <MenuItem value="tomorrow">Tomorrow</MenuItem>
              <MenuItem value="week">In a week</MenuItem>
              <MenuItem value="month">In a month</MenuItem>
              <MenuItem value="custom_date">Select Custom Date</MenuItem>
            </Select>
          </FormControl>
          <Box width={1}>
            <DateTimePicker
              slots={{ openPickerIcon: ArrowDropDownIcon }}
              label="Change Due Date and Time"
              sx={{ width: 1 }}
              slotProps={{
                tabs: {
                  hidden: false,
                  dateIcon: <CalendarMonthIcon />,
                  timeIcon: <ScheduleIcon />,
                },
              }}
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
              }}
              value={
                selectedDate ? moment(selectedDate + " " + selectedTime) : null
              }
              onChange={(value) => {
                setSelectedDate(value);
                setSelectedTime(value);
              }}
            />
          </Box>

          {/* <DatePickers
            type="date"
            label="Change Due Date"
            value={selectedDate}
            onChange={(value) => setSelectedDate(value)}
            min={true}
          /> */}
          {/* <DatePickers
            type="time"
            label="Change Due Time"
            value={selectedTime}
            onChange={(value) => setSelectedTime(value)}
            min={true}
          /> */}
          {/* <div style={{ width: "5%" }}>
            <TimeMenu onChange={(value) => setSelectedTime(value)} />
          </div> */}
        </Box>
        <FormControl variant="outlined" sx={{ width: 1, mt: 2.5 }}>
          <TextField
            label="Add instruction for the next Operation"
            value={instructions}
            onChange={(e) => setInstruction(e.target.value)}
            multiline
          />
        </FormControl>
      </>
    );
  },

  getSequenceForm: (sequenceId, setSequenceId, filteredSequences) => {
    return (
      <>
        <div>
          <FormControl
            style={{ width: "100%", marginTop: "20px" }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-age-native-simple">
              Select an Automation
            </InputLabel>
            <Select
              id="outlined-age-native-simple"
              label="Select an Automation"
              value={sequenceId}
              onChange={(e) => setSequenceId(e.target.value)} // TODO: Dynamic sequence Ids pending
            >
              {filteredSequences?.map((item) => (
                <MenuItem value={item.id}>{item.title}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </>
    );
  },

  getTextMessage: (setTextMessage, textMessage, handleMenuItemClick) => {
    return (
      <>
        <div style={{ display: "flex" }}>
          <FormControl
            variant="outlined"
            style={{ width: "100%", marginTop: "20px" }}
          >
            <TextField
              id="outlined-static"
              label="Send a Text Message"
              rows={1}
              value={textMessage}
              onChange={(e) => setTextMessage(e.target.value)}
              variant="outlined"
            />
          </FormControl>
          <div style={{ marginTop: "25px" }}>
            <IconButton
              aria-label="show 4 new mails"
              color="inherit"
              onClick={() => handleMenuItemClick(1)}
            >
              <ClearIcon />
            </IconButton>
          </div>
        </div>
      </>
    );
  },

  getComment: (setComment, comment, handleMenuItemClick) => {
    return (
      <>
        <div style={{ display: "flex" }}>
          <FormControl
            variant="outlined"
            style={{ width: "100%", marginTop: "20px" }}
          >
            <TextField
              id="outlined-static"
              label="Add Comment"
              rows={2}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              variant="outlined"
            />
          </FormControl>
          <div style={{ marginTop: "25px" }}>
            <IconButton
              aria-label="show 4 new mails"
              color="inherit"
              onClick={() => handleMenuItemClick(2)}
            >
              <ClearIcon />
            </IconButton>
          </div>
        </div>
      </>
    );
  },

  getNote: (notes, setNotes, handleMenuItemClick) => {
    return (
      <>
        <div style={{ display: "flex" }}>
          <FormControl
            variant="outlined"
            style={{ width: "100%", marginTop: "20px" }}
          >
            <TextField
              id="outlined-static"
              label="Update Note"
              rows={1}
              onChange={(e) => setNotes(e.target.value)}
              value={notes}
              variant="outlined"
            />
          </FormControl>
          <div style={{ marginTop: "25px" }}>
            <IconButton
              aria-label="show 4 new mails"
              color="inherit"
              onClick={() => handleMenuItemClick(3)}
            >
              <ClearIcon />
            </IconButton>
          </div>
        </div>
      </>
    );
  },
};

export default NEXT_ACTION_FORMS;
