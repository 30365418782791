import { http } from "../apiClient/axiosInterceptor";

export const createAudience = (data) => {
  return http.post(`/campaign/cost/create`, data);
};

export const updateAudience = (id, data) => {
  return http.post(`/campaign/cost/update/${id}`, data);
};

export const getAllAudience = (page, limit, id) => {
  return http.get(`/campaign/cost/get?campaign_id=${id}&page=${page}&limit=${limit}`);
};
