import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import VtechButton from "../../../../../components/vtech-button/VtechButton";

export const StepThree = ({ handleNext, setCampaignId, campaignId }) => {
  const { campaigns } = useSelector((state) => state.campaigns);

  const handleChange = (e) => {
    if (e.target.value !== "") {
      setCampaignId(e.target.value);
      handleNext();
    }
  };

  const getTitle = (item) => {
    let { title, status } = item;
    if (title.length > 20) {
      title = title.slice(0, 20) + "...";
    }
    if (status === "in_active") {
      title = title + " (Inactive)";
    }
    return title;
  };

  return (
    <Box display={"flex"} alignItems={"center"} mt={4}>
      <FormControl sx={{ width: "400px", mr: 2 }}>
        <InputLabel>Choose campaign</InputLabel>
        <Select
          label="Choose campaign"
          value={campaignId}
          onChange={handleChange}
        >
          {Object.keys(campaigns).length > 0 &&
            campaigns.map((item) => (
              <MenuItem value={item.id} disabled={item.status === "in_active"}>
                {getTitle(item)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <VtechButton title="Next" onClick={handleNext} />
    </Box>
  );
};
