import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FiltersPopover from "./filterComponent";
import EnhancedTableHead from "./tablehead";
import SearchAddFilterOpportunities from "./enhance-table-toolbar";
import { ActionTableRow } from "./table-row";
import { fetchAllOpportunities } from "../opportunitiesListAction";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { LinearProgress, Paper } from "@mui/material";
import FiltersChips from "./filters-chips";

export default function EnhancedTable({ setOpen }) {
  const activeTab = window.location.href.includes("all-opportunities") ? 1 : 0;
  const { user } = useSelector((state: any) => state.login);
  const dispatch: any = useDispatch();
  const [selected, setSelected] = useState<any>([]);
  const [filterCheck, setFilterCheck] = useState(false);
  const [page, setPage] = useState<any>(0);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  const navigate = useNavigate();
  const { query, oppoFilters } = useSelector(
    (state: any) => state.opportunitiesFilters
  );
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (
      localStorage.getItem("tablePageNumber") &&
      localStorage.getItem("tableRowsPerPage")
    ) {
      setPage(parseInt(localStorage.getItem("tablePageNumber") || "1") - 1);
      setRowsPerPage(localStorage.getItem("tableRowsPerPage") || "10");
    } else {
      localStorage.setItem("tableRowsPerPage", "10");
      localStorage.setItem("tablePageNumber", "1");
    }
  }, []);

  const isSelected = (name) => {
    const check = selected.indexOf(name);
    return check === -1 ? false : true;
  };

  const { isLoading, opportunities, total, limit } = useSelector(
    (state: any) => state.opportunities
  );

  const handleFilterCheck = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangePage = (event, newPage) => {
    dispatch(
      fetchAllOpportunities(
        newPage + 1,
        limit,
        oppoFilters,
        query,
        activeTab === 0 ? user.id : null
      )
    );
    setPage(newPage);
    localStorage.setItem("tablePageNumber", newPage + 1);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      fetchAllOpportunities(
        page + 1,
        event.target.value,
        oppoFilters,
        query,
        activeTab === 0 ? user.id : null
      )
    );
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("tableRowsPerPage", event.target.value as string);
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = opportunities.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  return (
    <>
      <FiltersPopover
        filterCheck={filterCheck}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />

      <SearchAddFilterOpportunities
        isLoading={isLoading}
        handleFilterCheck={handleFilterCheck}
        opportunities={opportunities}
        query={query}
        setOpen={setOpen}
        setPage={setPage}
      />

      <FiltersChips />

      <EnhancedTableHead
        onSelectAllClick={handleSelectAllClick}
        numSelected={selected.length}
        opportunities={opportunities}
        selected={selected}
        rowCount={opportunities.length}
        user={user}
      />

      {isLoading && <LinearProgress />}

      <TableContainer>
        <Table sx={{ borderCollapse: "separate", borderSpacing: "0px 16px" }}>
          <TableBody>
            {opportunities?.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              const isItemSelected = isSelected(row.id);
              let lastTouch: any =
                row.last_touched !== null
                  ? moment(row.last_touched).local().fromNow()
                  : null;
              lastTouch = lastTouch !== null ? lastTouch.split(" ") : null;
              let createdAt = moment(row.created_at).local().fromNow();
              const queryText =
                row.street_address +
                " " +
                row.city +
                " " +
                row.state +
                " " +
                row.zipcode +
                " " +
                row.seller.first_name +
                " " +
                row.seller.last_name +
                " " +
                row.seller.email +
                " " +
                row.assignee.first_name +
                " " +
                row.assignee.last_name +
                " " +
                row.assignee.email +
                " " +
                row.seller.phone;
              return (
                queryText.toLowerCase().includes(query.toLowerCase()) && (
                  <ActionTableRow
                    row={row}
                    labelId={labelId}
                    isItemSelected={isItemSelected}
                    handleClick={handleClick}
                    history={navigate}
                    lastTouch={lastTouch}
                    createdAt={createdAt.split(" ")}
                    user={user}
                  />
                )
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Paper sx={{ mb: 1 }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
