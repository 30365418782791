import { IconButton } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import CreateIcon from "@mui/icons-material/Create";
import moment from "moment";
import { numberWithCommas } from "../../../../utils/global.utils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAudienceList } from "../../redux/audience/edit.actions";
import { handleEditModal } from "../../redux/audience/edit.slice";
import { PRIMARY_MAIN } from "../../../../theme";
import VtechIcons from "../../../../components/icons/VtechIcons";

const columns = [
  { id: "date", label: "Date", minWidth: 150 },
  { id: "cost", label: "Cost", minWidth: 100 },
  { id: "audience", label: "Audience Size", minWidth: 100 },
  { id: "edit", label: "Edit", minWidth: 100 },
];

export default function StickyHeadTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();

  const { total, limit, audience } = useSelector((state) => state.audience);
  const { campaign } = useSelector((state) => state.currentCampaign);

  useEffect(() => {
    if (campaign && Object.keys(campaign).length > 0)
      dispatch(getAudienceList(1, limit, campaign.id));
  }, [dispatch, campaign]);

  const handleChangePage = (event, newPage) => {
    dispatch(getAudienceList(newPage + 1, limit, campaign.id));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(getAudienceList(page + 1, +event.target.value, campaign.id));
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEdit = (id) => {
    dispatch(handleEditModal(id));
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  sx={{ bgcolor: PRIMARY_MAIN, color: "white" }}
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {audience.map((row) => {
              return (
                <TableRow
                  tabIndex={-1}
                  key={row.id}
                  sx={{
                    "& .MuiTableCell-root": {
                      borderBottom: 1,
                      borderColor: "divider",
                    },
                  }}
                >
                  <TableCell component="th" id={row.id} scope="row">
                    {moment(row.created_at).format("MM-DD-YYYY")}
                  </TableCell>
                  <TableCell>${numberWithCommas(row.cost)}</TableCell>
                  <TableCell>{numberWithCommas(row.audience_size)}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEdit(row.id)}>
                      <VtechIcons icon="edit" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          sx={{
            ".MuiTablePagination-selectLabel": {
              fontWeight: 400,
              mb: "11px",
              fontSize: 12,
            },
          }}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
