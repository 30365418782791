import { Chip } from "@mui/material";
import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import { useDarkMode } from "../../utils/useDarkMode";
import { chipColors } from "./chip-colors";

type MuiChipWithDotProps = {
  label: string;
  dot?: boolean;
  sx?: object;
};

function MuiChipWithDot({ label, dot, sx }: MuiChipWithDotProps) {
  const darkMode = useDarkMode();
  const colors = chipColors(darkMode)[label?.toLowerCase()];

  return (
    <Chip
      label={label}
      sx={{
        color: colors?.text,
        bgcolor: colors?.bg,
        fontWeight: 600,
        fontSize: 12,
        height: 22,
        ...sx,
      }}
      icon={
        dot ? (
          <CircleIcon style={{ width: "8px", color: colors?.dot }} />
        ) : (
          <></>
        )
      }
    />
  );
}

export default MuiChipWithDot;
