import { useSelector } from "react-redux";

interface RootState {
  darkMode: {
    darkMode: boolean;
  };
}

export const useDarkMode = () => {
  return useSelector((state: RootState) => state.darkMode.darkMode);
};
