export const ROUTE_PATH = {
  LOGIN: {
    path: "/",
    name: "Login",
  },
  SIGNUP: {
    path: "/signup",
    name: "Signup",
  },
  FORGOT_PASSWORD: {
    path: "/forgot-password",
    name: "ForgotPassword",
  },
  RESET_PASSWORD: {
    path: "/password/reset/:token",
    name: "Reset_password",
  },
  DASHBOARD: {
    path: "/dashboard",
    name: "Dashboard",
    roles: ["Agent", "SuperAdmin"],
  },
  DASHBOARD_ACTIVITY: {
    path: "/dashboard/activity/:page",
    name: "Activity",
    roles: ["Agent", "SuperAdmin"],
  },
  DASHBOARD_ASSIGNMENT: {
    path: "/dashboard/assignment",
    name: "Assignment",
    roles: ["Agent", "SuperAdmin"],
  },
  DASHBOARD_SETTINGS: {
    path: "/dashboard/settings",
    name: "Settings",
    roles: ["Agent", "SuperAdmin"],
  },
  DASHBOARD_CHAT_TEAMS: {
    path: "/dashboard/chat-teams",
    name: "ChatTeams",
    roles: ["Agent", "SuperAdmin"],
  },
  USERS: {
    path: "/users",
    name: "Users",
    roles: ["SuperAdmin"],
  },
  OPERATIONS: {
    path: "/operations",
    name: "Operations",
    roles: ["Agent", "SuperAdmin"],
  },
  ALL_OPERATIONS: {
    path: "/operations/all-operations",
    name: "Operations",
    roles: ["Agent", "SuperAdmin"],
  },
  CAMPAIGNS: {
    path: "/campaigns",
    name: "Campaigns",
    roles: ["SuperAdmin"],
  },
  CAMPAIGN: {
    path: "/campaigns/:id",
    name: "Campaign",
    roles: ["SuperAdmin"],
  },
  CAMPAIGN_EDIT: {
    path: "/campaigns/:id/edit",
    name: "Campaign",
    roles: ["SuperAdmin"],
  },
  CAMPAIGN_ASSIGNMENT: {
    path: "/campaigns/:id/assignment",
    name: "Campaign",
    roles: ["SuperAdmin"],
  },
  CAMPAIGN_SETTINGS: {
    path: "/campaigns/:id/settings",
    name: "Campaign",
    roles: ["SuperAdmin"],
  },
  OPPORTUNITIES: {
    path: "/opportunities",
    name: "Opportunities",
    roles: ["Agent", "SuperAdmin"],
  },
  ALL_OPPORTUNITIES: {
    path: "/opportunities/all-opportunities",
    name: "AllOpportunities",
    roles: ["Agent", "SuperAdmin"],
  },
  OPPORTUNITY_DEATILS: {
    path: "/opportunity/:id",
    name: "opportunityDetails",
    roles: ["Agent", "SuperAdmin"],
  },
  AUTOMATION: {
    path: "/automations",
    name: "automations",
    roles: ["SuperAdmin"],
  },
  AUTOMATION_DETAILS: {
    path: "/automations/:sequenceId",
    name: "sequenceDetails",
    roles: ["SuperAdmin"],
  },
  CONTACTS: {
    path: "/contacts/:page",
    name: "Contacts",
    roles: ["Agent", "SuperAdmin"],
  },
  CONTACT_DETAILS: {
    path: "/contact/:id",
    name: "contactDetails",
    roles: ["Agent", "SuperAdmin"],
  },
  CHAT: {
    path: "/chat",
    name: "Chat",
    roles: ["Agent", "SuperAdmin"],
  },
  CALENDER: {
    path: "/calender",
    name: "calender",
    roles: ["Agent", "SuperAdmin"],
  },
  ACCOUNT_SETTINGS: {
    path: "/account-settings",
    name: "Profile",
    roles: ["Agent", "SuperAdmin"],
  },
  NOTIFICATIONS: {
    path: "/account-settings/notifications",
    name: "Notifications",
    roles: ["Agent", "SuperAdmin"],
  },
  API_KEYS: {
    path: "/account-settings/api-keys",
    name: "ApiKeys",
    roles: ["Agent", "SuperAdmin"],
  },
};
