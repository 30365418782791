import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  opportunities: [],
  pipeline: [],
  isLoading: false,
  error: '',
  total: 0,
  limit: 10,
  labels: [],
};

const opportunityListSlice = createSlice({
  name: 'opportunitiesList',
  initialState,
  reducers: {
    setOpportunityLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    fetchOpportunitiesLoading: state => {
      state.isLoading = true;
    },
    fetchOpportunitiesSuccess: (state, action) => {
      state.isLoading = false;
      state.opportunities = action.payload;
    },
    fetchOpportunitiesFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    labelFetched: (state, { payload }) => {
      state.isLoading = false;
      state.labels = payload;
    },
    setPagination: (state, action) => {
      state.total = action.payload.total;
      state.limit = action.payload.limit;
    },
    setPipeline: (state, { payload }) => {
      state.pipeline = payload;
    },
  },
});

export const {
  fetchOpportunitiesLoading,
  fetchOpportunitiesSuccess,
  fetchOpportunitiesFail,
  setPagination,
  labelFetched,
  setPipeline,
  setOpportunityLoading,
} = opportunityListSlice.actions;
export default opportunityListSlice.reducer;
