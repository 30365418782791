import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
};

const TooManyReqSlice = createSlice({
  name: "tooManyReqModal",
  initialState,
  reducers: {
    setTooManyReqModal(state, action) {
      state.open = action.payload;
    },
  },
});

export const { setTooManyReqModal } = TooManyReqSlice.actions;

export default TooManyReqSlice.reducer;
