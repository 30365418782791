import Button from "@mui/material/Button";
import React from "react";
import CircularProgress from "../progress-bar/circular.progress";
import style from "./button.module.css";

export default function ContainedButtons({
  onClick,
  title,
  styles,
  loading,
  isWhite,
  disable,
  updateBtn,
  startIcon,
}) {
  const onLoading = [
    "Sign Out",
    "Save",
    "Update",
    "Assign",
    "Change Transaction Status",
    "Next Step",
    "Load more",
    "Complete Operation",
    "Send Test Message",
  ];
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleClick}
      sx={{ height: 47, px: 4, borderRadius: "6px" }}
      // className={
      //   updateBtn
      //     ? style.buttonGreen
      //     : isWhite === true
      //     ? style.buttonWhite
      //     : loading === true || disable === true
      //     ? style.buttonDisable
      //     : style.buttonUI
      // }
      style={styles && styles}
      disabled={(disable && true) || (loading && true)}
      startIcon={
        startIcon && !disable && !loading
          ? startIcon
          : !disable &&
            loading &&
            onLoading.includes(title) && <CircularProgress />
      }
    >
      {title}
    </Button>
  );
}
