import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import NextButton from "../../../../../components/widgets/button/button.widget";
import VtechButton from "../../../../../components/vtech-button/VtechButton";

export const StepFour = ({ handleNext, setOwnerId, ownerId }) => {
  const { users } = useSelector((state) => state.users);

  const handleChange = (e) => {
    if (e.target.value !== "") {
      setOwnerId(e.target.value);
      handleNext();
    }
  };

  return (
    <Box display={"flex"} alignItems={"center"} mt={4}>
      <FormControl sx={{ width: "400px", mr: 2 }}>
        <InputLabel>Select new owner</InputLabel>
        <Select
          label="Select new owner"
          value={ownerId}
          onChange={handleChange}
        >
          {Object.keys(users).length > 0 ? (
            users.map((user) => (
              <MenuItem value={user.id}>{user.email}</MenuItem>
            ))
          ) : (
            <MenuItem value="not found">Records not found</MenuItem>
          )}
        </Select>
      </FormControl>
      <VtechButton title="Next" onClick={handleNext} />
      {/* <NextButton
        title="Next"
        onClick={handleNext}
      /> */}
    </Box>
  );
};
