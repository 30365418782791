import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { addTouch } from "../../../services/touches.service";
import { deleteCampaignById, getCampaigns } from "../campaigns.action";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import { Box, IconButton, Typography } from "@mui/material";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import VtechIcons from "../../../components/icons/VtechIcons";
import { setCampaignLimit, setCampaignPage } from "../campaign.slice";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "title", numeric: false, label: "Campaign Name" },
  { id: "campaign_profit", numeric: true, label: "Campaign Profit" },
  { id: "deal_profit", numeric: true, label: "Deal Profit" },
  { id: "cost", numeric: true, label: "Cost" },
  { id: "audience_size", numeric: true, label: "Audience Size" },
  { id: "cpd", numeric: true, label: "CPD" },
  { id: "closed_deals", numeric: true, label: "Close Deals" },
  { id: "cancelled_deals", numeric: true, label: "Cancelled Deals" },
  { id: "cpc", numeric: true, label: "CPC" },
  { id: "contract", numeric: true, label: "Contracts" },
  { id: "offers", numeric: true, label: "Offers" },
  { id: "appointments", numeric: true, label: "Appointments" },
  { id: "cpo", numeric: true, label: "CPO" },
  { id: "opportunities", numeric: true, label: "Opportunity" },
  { id: "touches", numeric: true, label: "Touches" },
  { id: "actions", numeric: false, label: "" },
];
const last2index = [headCells.length - 2, headCells.length - 1];

const handleToolTip = (value) => {
  if (value === "campaign_profit") {
    return "This is your overall profit. The calculation is deal profit - cost";
  }
  if (value === "deal_profit") {
    return "This is a sum of the profit fields for all closed deals during the time period selected in the top right.";
  }
  if (value === "cost") {
    return "This is a sum of all campaign cost entries during the time period selected in the top right.";
  }
  if (value === "cpd") {
    return "This is the cost per deal. The calculation is cost divided by number of closed deals.";
  }
  if (value === "cpc") {
    return "This is the cost per signed contracts (when an opportunity is marked as a pending transaction). The calculation is cost divided by number of signed contracts.";
  }
  if (value === "cpo") {
    return "This is the cost per opportunities. The calculation is cost divided by number of opportunities.";
  }
};

function EnhancedTableHead({ order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const infoColumns = "campaign_profit deal_profit cost cpd cpc cpo";
  const underDevelopment = "cpc contracts";

  return (
    <>
      <TableHead>
        <TableRow>
          {headCells.map((headCell, index) => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{
                minWidth: last2index.includes(index) ? 120 : 200,
                position: index === 0 ? "sticky" : "initial",
                zIndex: index === 0 ? 1000 : "initial",
                left: index === 0 ? 0 : "initial",
                bgcolor: "primary.main",
                color: "white",
                py: 1.5,
                pr: 0,
              }}
            >
              <TableSortLabel
                sx={{
                  "&:hover": { color: "white" },
                  "&.Mui-active": { color: "white" },
                  "&.Mui-active .MuiTableSortLabel-icon": { color: "white" },
                }}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                disabled={underDevelopment.includes(headCell.id)}
              >
                {headCell.label}
                {underDevelopment.includes(headCell.id) && " (under dev.)"}{" "}
                {infoColumns.includes(headCell.id) && (
                  <Tooltip
                    title={handleToolTip(headCell.id)}
                    sx={{ pointerEvents: "all" }}
                    onClick={(e) => {
                      if (underDevelopment.includes(headCell.id))
                        e.stopPropagation();
                    }}
                  >
                    <InfoOutlinedIcon
                      style={{ color: "#29B6F6", marginLeft: 12 }}
                    />
                  </Tooltip>
                )}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

function EnhancedTable({
  query,
  fromDate,
  toDate,
  handleScrollFirst,
  tableRef,
}) {
  const [deleteId, setDeleteId] = useState(null);
  const dispatch: any = useDispatch();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const navigate = useNavigate();
  const { isLoading, campaigns, total, limit, page } = useSelector(
    (state: any) => state.campaigns
  );
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    dispatch(getCampaigns(newPage + 1, limit, fromDate, toDate, query));
    dispatch(setCampaignPage(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setCampaignPage(0));
    dispatch(getCampaigns(1, event.target.value, fromDate, toDate, query));
    dispatch(setCampaignLimit(parseInt(event.target.value, 10)));
  };

  const handleClick = (row) => {
    addTouch({
      opportunity_id: "",
      campaign_id: row.id,
      opportunity_step_history_id: "",
    });
    navigate(`/campaigns/${row.id}`);
  };

  const handleEdit = (row, edit) => {
    addTouch({
      opportunity_id: "",
      campaign_id: row.id,
      opportunity_step_history_id: "",
    });
    navigate(`/campaigns/${row.id}/${edit}`);
  };

  const handleDelete = (e, id) => {
    e.stopPropagation();
    setDeleteId(id);
    dispatch(deleteCampaignById(id, page + 1, limit, fromDate, toDate));
  };

  return (
    <>
      <TableContainer
        ref={tableRef}
        onScroll={handleScrollFirst}
        component={Paper}
      >
        <Table>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(campaigns, getComparator(order, orderBy)).map(
              (row, i) => (
                <TableRow
                  hover
                  onClick={() => handleClick(row)}
                  key={row.name}
                  sx={{
                    ".MuiTableCell-root": {
                      borderBottom: 1,
                      borderColor: "divider",
                      cursor: "pointer",
                    },
                  }}
                >
                  <TableCell
                    sx={{
                      minWidth: 200,
                      position: "sticky",
                      left: 0,
                      bgcolor: "background.paper",
                      zIndex: 1000,
                    }}
                    component="th"
                    scope="row"
                  >
                    <Typography sx={{ opacity: 0.7, fontWeight: 600 }}>
                      {row.title.length > 20 ? (
                        <Tooltip title={row.title}>
                          <span>
                            {row.title.slice(0, 20)}
                            ...
                          </span>
                        </Tooltip>
                      ) : (
                        row.title
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>{row.campaign_profit}$</TableCell>
                  <TableCell>{row.deal_profit}$</TableCell>
                  <TableCell>{row.cost}$</TableCell>
                  <TableCell>{row.audience_size}</TableCell>
                  <TableCell>{row.cpd.toFixed(2)}$</TableCell>
                  <TableCell>{row.closed_deals}</TableCell>
                  <TableCell>{row.cancelled_deals}</TableCell>
                  <TableCell>{row.cpc}$</TableCell>
                  <TableCell>{row.contracts}</TableCell>
                  <TableCell>{row.offers}</TableCell>
                  <TableCell>{row.appointments}</TableCell>
                  <TableCell>{row.cpo.toFixed(2)}$</TableCell>
                  <TableCell>{row.opportunities}</TableCell>
                  <TableCell>{row.touches}</TableCell>
                  <TableCell sx={{ display: "flex", width: 100 }}>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEdit(row, "edit");
                      }}
                      sx={{ mr: 2 }}
                    >
                      <VtechIcons icon="edit" />
                    </IconButton>
                    <IconButton
                      disabled={isLoading}
                      onClick={(e) => handleDelete(e, row.id)}
                    >
                      {isLoading && deleteId === row.id ? (
                        <img src="/icons/red-circle.svg" />
                      ) : (
                        <img src="/icons/trash.svg" />
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
          mb: 8,
        }}
      >
        <Box>
          <Tooltip title="Scroll to start">
            <IconButton onClick={() => (tableRef.current.scrollLeft = 0)}>
              <KeyboardDoubleArrowLeftIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Jump left">
            <IconButton onClick={() => (tableRef.current.scrollLeft -= 200)}>
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Jump right">
            <IconButton onClick={() => (tableRef.current.scrollLeft += 200)}>
              <ChevronRightIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Scroll to end">
            <IconButton onClick={() => (tableRef.current.scrollLeft = 10000)}>
              <KeyboardDoubleArrowRightIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          sx={{
            p: 0,
            ".MuiTablePagination-selectLabel": {
              fontWeight: 400,
              mb: "11px",
              fontSize: 12,
            },
          }}
          count={total}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
export default EnhancedTable;
