import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { DatePickers } from "../../../components/widgets/date-time-picker";
import {
  clearOpportunityFilters,
  setOpportunityFilters,
} from "../opportunitiesComponents/oppo-component.slice";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import VtechButton from "../../../components/vtech-button/VtechButton";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { getPrimarySellers } from "../../opportunity-details/opportunity.action";
import { getAllCampaignWOL } from "../../campaigns/campaigns.action";
import { getAllUsersWOL } from "../../users-list/usersListAction";
import { getAllActions } from "../../campaign-details/redux/steps/details.actions";
import { getLabels } from "../opportunitiesListAction";

const motivations = [
  { title: "All", value: "all" },
  { title: "Unknown", value: "unknown" },
  { title: "Cold", value: "cold" },
  { title: "Warm", value: "warm" },
  { title: "Hot", value: "hot" },
];

function FilterComponent({ filterCheck, anchorEl, setAnchorEl }) {
  const dispatch: any = useDispatch();
  const { oppoFilters } = useSelector(
    (state: any) => state.opportunitiesFilters
  );
  const { campaigns } = useSelector((state: any) => state.campaigns);
  const { actions } = useSelector((state: any) => state.campaignDetails);
  const { labels } = useSelector((state: any) => state.opportunities);
  const { users } = useSelector((state: any) => state.users);
  const [campaignIds, setCampaignIds] = useState<any>([]);
  const [selectedDateFrom, setSelectedDateFrom] = useState<any>("");
  const [selectedDateTo, setSelectedDateTo] = useState<any>("");
  const [statusIds, setStatusIds] = useState<any>([]);
  const [labelIds, setLabelIds] = useState<any>([]);
  const [ownerIds, setOwnerIds] = useState<any>([]);
  const [motivationValues, setMotivationValues] = useState<any>([]);
  const [currentActions] = useState<any>([
    1, 2, 10, 12, 14, 15, 17, 18, 19, 20, 21, 24, 26, 27,
  ]);

  // fetch all the data required for the filters only once, when the menu is opened for the first time
  React.useEffect(() => {
    if (anchorEl && campaigns.length === 0) {
      dispatch(getAllCampaignWOL());
      dispatch(getAllUsersWOL());
      dispatch(getAllActions());
    }
  }, [anchorEl]);
  React.useEffect(() => {
    if (anchorEl && labels.length === 0) {
      dispatch(getLabels());
    }
  }, [anchorEl]);

  const handleEnd = () => {
    const tempOwnerIds = ownerIds.map((email) => {
      return users.filter((user) => user.email === email)[0].id;
    });
    const tempCampaignIds = campaignIds.map((title) => {
      return campaigns.filter((campaign) => campaign.title === title)[0].id;
    });
    const tempLabels = labelIds.map((labelId) => {
      return labels.filter((label) => label.label === labelId)[0].id;
    });

    dispatch(
      setOpportunityFilters({
        ...oppoFilters,
        campaignIds: tempCampaignIds,
        statusIds,
        selectedDateFrom,
        selectedDateTo,
        labelIds: tempLabels,
        ownerIds: tempOwnerIds,
        motivationValues,
      })
    );
    setAnchorEl(null);
  };

  useEffect(() => {
    if (oppoFilters) {
      if (users && Object.keys(users).length > 0) {
        const tempOwnerIds = oppoFilters?.ownerIds?.map((ownerId) => {
          return users?.filter((user) => user?.id === ownerId)[0]?.email;
        });
        setOwnerIds(tempOwnerIds);
      } else {
        setOwnerIds([]);
      }
      if (campaigns && Object.keys(campaigns).length > 0) {
        const tempCampaignIds = oppoFilters?.campaignIds?.map((campId) => {
          return campaigns?.filter((campaign) => campaign?.id === campId)[0]
            ?.title;
        });
        setCampaignIds(tempCampaignIds);
      } else {
        setCampaignIds([]);
      }
      if (labels && Object.keys(labels).length > 0) {
        const tempLabels = oppoFilters?.labelIds.map((labelId) => {
          return labels?.filter((label) => label.id === labelId)[0].label;
        });
        setLabelIds(tempLabels);
      } else {
        setLabelIds([]);
      }
      setSelectedDateFrom(oppoFilters?.selectedDateFrom);
      setSelectedDateTo(oppoFilters?.selectedDateTo);
      setStatusIds(oppoFilters?.statusIds);
      setMotivationValues(oppoFilters?.motivationValues);
    }
  }, [oppoFilters, users, campaigns, labels]);

  const clearFilters = () => {
    setCampaignIds([]);
    setStatusIds([]);
    setLabelIds([]);
    setOwnerIds([]);
    setMotivationValues([]);
    setSelectedDateFrom("");
    setSelectedDateTo("");
    dispatch(clearOpportunityFilters());
    setAnchorEl(null);
  };

  const handleCampaignClick = (title) => {
    if (campaignIds.includes(title)) {
      setCampaignIds(campaignIds.filter((item) => item !== title));
    } else {
      setCampaignIds([...campaignIds, title]);
    }
  };

  const handleStatusClick = (status) => {
    if (statusIds.includes(status)) {
      setStatusIds(statusIds.filter((item) => item !== status));
    } else {
      setStatusIds([...statusIds, status]);
    }
  };

  const handleMotivationClick = (value) => {
    if (motivationValues.includes(value)) {
      setMotivationValues(motivationValues.filter((item) => item !== value));
    } else {
      setMotivationValues([...motivationValues, value]);
    }
  };

  const handleLabelClick = (title) => {
    if (labelIds.includes(title)) {
      setLabelIds(labelIds.filter((item) => item !== title));
    } else {
      setLabelIds([...labelIds, title]);
    }
  };

  const handleUserClick = (email) => {
    if (ownerIds.includes(email)) {
      setOwnerIds(ownerIds.filter((item) => item !== email));
    } else {
      setOwnerIds([...ownerIds, email]);
    }
  };

  return (
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Box
        sx={{
          p: 4,
          px: 2,
          maxHeight: 420,
          overflowY: "auto",
          overflowX: "hidden",
          maxWidth: 309,
          ".MuiAccordionSummary-content": {
            maxWidth: 200,
          },
          ".MuiAccordion-root:before": {
            display: "none",
          },
          bgcolor: "background.paper",
        }}
      >
        <Accordion elevation={0} disableGutters>
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            <Typography>Filter By Campaign</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <List dense sx={{ p: 0, pl: 2 }}>
              {campaigns?.map((campaign: any, i) => (
                <ListItem key={campaign + i} sx={{ py: 0 }} disableGutters>
                  <ListItemIcon sx={{ p: 0, minWidth: 0 }}>
                    <Checkbox
                      onChange={() => handleCampaignClick(campaign.title)}
                      checked={campaignIds.includes(campaign.title)}
                    />
                  </ListItemIcon>
                  <ListItemText primary={campaign.title} />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={0} disableGutters>
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            <Typography>Filter By Status</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <List dense sx={{ p: 0, pl: 2 }}>
              {actions?.map(
                (name) =>
                  currentActions.includes(name.id) &&
                  name?.status && (
                    <ListItem key={name.status} sx={{ py: 0 }} disableGutters>
                      <ListItemIcon sx={{ p: 0, minWidth: 0 }}>
                        <Checkbox
                          onChange={() => handleStatusClick(name.status)}
                          checked={statusIds.includes(name.status)}
                        />
                      </ListItemIcon>
                      <ListItemText primary={name.status} />
                    </ListItem>
                  )
              )}
            </List>
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={0} disableGutters>
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            <Typography>Filter By Motivation</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <List dense sx={{ p: 0, pl: 2 }}>
              {motivations.map((name, i) => (
                <ListItem key={i} sx={{ py: 0 }} disableGutters>
                  <ListItemIcon sx={{ p: 0, minWidth: 0 }}>
                    <Checkbox
                      onChange={(e) => handleMotivationClick(name.value)}
                      checked={motivationValues.includes(name.value)}
                    />
                  </ListItemIcon>
                  <ListItemText primary={name.title} />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={0} disableGutters>
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            <Typography>Filter By Label</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <List dense sx={{ p: 0, pl: 2 }}>
              {labels?.map(
                (label) =>
                  label.label && (
                    <ListItem key={label.id} sx={{ py: 0 }} disableGutters>
                      <ListItemIcon sx={{ p: 0, minWidth: 0 }}>
                        <Checkbox
                          onChange={() => handleLabelClick(label.label)}
                          checked={labelIds.includes(label.label)}
                        />
                      </ListItemIcon>
                      <ListItemText primary={label.label} />
                    </ListItem>
                  )
              )}
            </List>
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={0} disableGutters>
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            <Typography>Filter By Date</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* <DatePickers
              fullWidth
              type="date"
              label="Date From"
              value={selectedDateFrom}
              onChange={(value) => setSelectedDateFrom(value)}
            />
            <br />
            <br />
            <DatePickers
              fullWidth
              type="date"
              label="Date To"
              value={selectedDateTo}
              onChange={(value) => setSelectedDateTo(value)}
            /> */}
            <DatePicker
              label="Date From"
              slots={{ openPickerIcon: ArrowDropDownIcon }}
              value={selectedDateFrom ? moment(selectedDateFrom) : null}
              onChange={(value) =>
                setSelectedDateFrom(moment(value).format("YYYY-MM-DD"))
              }
              sx={{ mb: 2 }}
            />
            <DatePicker
              label="Date To"
              slots={{ openPickerIcon: ArrowDropDownIcon }}
              value={selectedDateTo ? moment(selectedDateTo) : null}
              onChange={(value) =>
                setSelectedDateTo(moment(value).format("YYYY-MM-DD"))
              }
            />
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={0} disableGutters>
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            <Typography>Filter By Owner</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <List dense sx={{ p: 0, pl: 2 }}>
              {users?.map((user) => (
                <ListItem key={user.id} sx={{ py: 0 }} disableGutters>
                  <ListItemIcon sx={{ p: 0, minWidth: 0 }}>
                    <Checkbox
                      onChange={() => handleUserClick(user.email)}
                      checked={ownerIds.includes(user.email)}
                    />
                  </ListItemIcon>
                  <ListItemText primary={user.email} />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>

        <Box sx={{ mt: 2, textAlign: "end" }}>
          <VtechButton title="Clear" variant="text" onClick={clearFilters} />
          <VtechButton title="Save" onClick={handleEnd} />
        </Box>
      </Box>
    </Popover>
  );
}

export default FilterComponent;
