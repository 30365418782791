import { Divider, Paper } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import ProgressBar from "../../../components/widgets/progress-bar/index";
import { getAllResponseTemplate } from "../redux/settings/settings.actions";
import Integration from "./integration";
import ResponseTemplate from "./response-template";
// import SettingsDetails from "./setting-destails/index";
// import SettingsMenu from "./sidebar/settings.component";

const Settings = () => {
  // const [activeSettingIndex, setSettingIndex] = useState(0);
  const { isLoading } = useSelector((state: any) => state.campaignSettings);
  const dispatch: any = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getAllResponseTemplate(id));
  }, [dispatch]);

  return (
    <Paper sx={{ p: 4.5, my: 4 }}>
      {isLoading && <ProgressBar />}
      <ResponseTemplate />
      <Divider />
      <Integration />
    </Paper>
  );
};

export default Settings;
