import {
  getAllLabels,
  getAllOpportunities,
  getOpportunityPipeline,
} from "../../services/opportunities";
import {
  fetchOpportunitiesFail,
  fetchOpportunitiesLoading,
  fetchOpportunitiesSuccess,
  labelFetched,
  setPagination,
  setPipeline,
  setOpportunityLoading,
} from "./opportunitesListSlice";

export const fetchAllOpportunities =
  (page, limit, filters, query = "", id) =>
  (dispatch) => {
    dispatch(fetchOpportunitiesLoading());
    getAllOpportunities(page, limit, filters, query, id)
      .then((res) => {
        dispatch(fetchOpportunitiesSuccess(res.data.data));
        dispatch(
          setPagination({
            total: res.data.total,
            limit: res.data.per_page,
          })
        );
      })
      .catch((error) => {
        dispatch(fetchOpportunitiesFail("Something went wrong"));
      });
  };

export const setLoading = () => (dispatch) => {
  dispatch(fetchOpportunitiesLoading());
};

export const settingUpPagination = (payload) => (dispatch) => {
  dispatch(setPagination(payload));
};

export const getLabels = () => (dispatch) => {
  dispatch(fetchOpportunitiesLoading());
  getAllLabels()
    .then((res) => {
      dispatch(labelFetched(res.data.data));
    })
    .catch((err) => {
      dispatch(fetchOpportunitiesFail("Something went wrong"));
      console.log("====================================");
      console.log(err);
      console.log("====================================");
    });
};

export const fetchPipeline = () => (dispatch) => {
  dispatch(setOpportunityLoading(true));
  getOpportunityPipeline().then((res) => {
    dispatch(setPipeline(res.data.data));
    dispatch(setOpportunityLoading(false));
  });
};
