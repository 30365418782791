import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { TextField } from "@mui/material";
import FormButton from "../../../../components/widgets/button/button.widget";
import { useDispatch, useSelector } from "react-redux";
import { addNewSequence } from "../../redux/sequence.actions";

const SequenceAddForm = ({ style, handleCloseAddDialog }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.sequences);
  const [sequenceData, setSequenceData] = useState({
    title: "",
    description: "",
  });
  const [displayForm, setForm] = useState(false);

  const saveData = () => {
    dispatch(addNewSequence(sequenceData));
    setTimeout(() => {
      handleCloseAddDialog();
      setForm(false);
      setSequenceData({
        title: "",
        description: "",
      });
    }, 2000);
  };

  return (
    <>
      <div className={style.addForm} style={{paddingBottom : 30}}>
          <div className={style.addFormBody}>
            <TextField
              id="outlined-basic"
              label="Automation Name *"
              value={sequenceData.title}
              placeholder="Automation Name *"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={(e) =>
                setSequenceData({ ...sequenceData, title: e.target.value })
              }
            />
            <br />
            <br />
            <TextField
              id="outlined-basic"
              label="Automation Description"
              multiline={true}
              value={sequenceData.description}
              rows={4}
              placeholder="Automation Description"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={(e) =>
                setSequenceData({
                  ...sequenceData,
                  description: e.target.value,
                })
              }
            />
            <br />
            <br />
            <FormButton
              title="Add Automation"
              disable={
                (sequenceData.title === "" || isLoading === true) && true
              }
              onClick={saveData}
            />

          </div>
      </div>
    </>
  );
};

export default SequenceAddForm;
