import { http } from "../apiClient/axiosInterceptor";

export const getAllUsers = (page, limit) => {
  return http.get(`/user/get?page=${page}&limit=${limit}`);
};

export const fetchAllUsersWOL = () => {
  return http.get(`/user/get?type=owner`);
};

export const deleteUser = (id) => {
  return http.delete(`/user/delete/${id}`);
};
