import { http } from "../apiClient/axiosInterceptor";

export const getCampaignStatisticsCounts = (type, id, from, to) => {
  let url;
  if (from && to)
    url = `/campaign-statistics-counts?type=${type}&campaign_id=${id}&from=${from}&to=${to}`;
  else url = `/campaign-statistics-counts?type=${type}&campaign_id=${id}`;
  return http.get(url);
};

export const getCampaignStats = (type, id) => {
  return http.get(`/campaign-statistics?type=${type}&campaign_id=${id}`);
};

export const getCampaignOpportunityStats = (type, id) => {
  const url = `/dashboard-campaign-statistics?type=${type}&campaign_id=${id}`;
  return http.get(url);
};
