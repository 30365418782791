import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// import LinearProgress from "@mui/material/LinearProgress";
import AreaGraph from "../graph/area-graph";
import { Box, Typography } from "@mui/material";
import { useDarkMode } from "../../../../utils/useDarkMode";

const ActionEventStats = ({
  title,
  thisStats,
  otherStats,
  dashboardStats,
  filterTitle,
  type,
}) => {
  const darkMode = useDarkMode();

  let percentage: number = 0;
  if (otherStats !== 0) {
    percentage = (thisStats / otherStats) * 100 - 100;
  } else {
    percentage = ((thisStats - otherStats) / thisStats) * 100;
  }
  percentage = parseInt(percentage.toFixed(0));

  const isValidPercentage =
    !isNaN(percentage) && isFinite(percentage) && percentage != 0;

  const [chartDataWeek, setChartDataWeek] = useState([]);
  const [chartDataMonth, setChartDataMonth] = useState([]);
  const [chartDataYear, setChartDataYear] = useState([]);

  const transformData = (data) => {
    // prettier-ignore
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    // prettier-ignore
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    let transformedData;

    if (type === "week") {
      transformedData = days.map((day) => ({
        count: parseInt(data?.find((item) => item.dayname === day)?.count || 0),
        key: day,
      }));
    } else if (type === "month") {
      transformedData = Array.from({ length: 5 }, (_, index) => ({
        count: parseInt(data[`week_${index + 1}`] || 0),
        key: `Week ${index + 1}`,
      }));
    } else if (type === "year") {
      transformedData = months.map((month) => ({
        count:
          parseInt(data?.find((item) => item.monthname === month)?.count) || 0,
        key: month,
      }));
    }
    // if all counts are 0, set all counts to 0.5 to avoid graph area to be at the bottom
    if (transformedData.every((item) => item.count === 0)) {
      transformedData = transformedData.map((item) => ({
        ...item,
        count: 0.5,
      }));
    }
    return transformedData;
  };

  useEffect(() => {
    if (type === "week" && Array.isArray(dashboardStats)) {
      setChartDataWeek(transformData(dashboardStats));
    } else if (type === "month") {
      setChartDataMonth(transformData(dashboardStats));
    } else if (type === "year") {
      setChartDataYear(transformData(dashboardStats));
    }
  }, [dashboardStats]);

  return (
    <Card sx={{ width: 1, py: 2, px: 3 }}>
      <Typography variant="h3">
        {title === "Contracts" ? title + "(under dev.)" : title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ fontSize: 40, fontWeight: 600, mt: 1 }}>
          {thisStats || 0}
        </Typography>
        {filterTitle !== "Custom" && (
          <AreaGraph
            // prettier-ignore
            data={type === "week" ? chartDataWeek : type === "month" ? chartDataMonth : chartDataYear}
            percentage={percentage}
          />
        )}
      </Box>
      {filterTitle !== "Custom" && (
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          {isValidPercentage && (
            <img
              src={`/icons/${
                percentage < 0 ? "red-arrow-down" : "green-arrow-up"
              }.svg`}
              alt="Arrow"
              style={{ marginTop: percentage < 0 ? "-5px" : "0px" }}
            />
          )}
          <Typography
            sx={{
              color: darkMode ? "#FFFFFFB2" : "#5A6479B2",
              fontSize: 10,
              fontWeight: 600,
            }}
          >
            {" "}
            <span
              style={{
                color: percentage < 0 ? "#E87063" : "#289374",
                marginRight: 6,
              }}
            >
              {isNaN(percentage) || !isFinite(percentage)
                ? 0
                : Math.abs(percentage)}{" "}
              %
            </span>
            than last {filterTitle} {filterTitle === "Custom" ? "Date" : ""}
          </Typography>
        </Box>
      )}
    </Card>
  );
};

export default ActionEventStats;
