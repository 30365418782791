// import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
// import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// import DashboardIcon from "@mui/icons-material/Dashboard";
// import DnsIcon from "@mui/icons-material/Dns";
// import HomeIcon from "@mui/icons-material/Home";
// import PeopleIcon from "@mui/icons-material/People";
// import VolumeUpIcon from "@mui/icons-material/VolumeUp";

// export const menuListItems = [
//   {
//     name: "Dashboard",
//     icon: <DashboardIcon className="sideBarIcon" />,
//     route: "/dashboard",
//     roles: ["Agent", "SuperAdmin"],
//   },
//   {
//     name: "Users",
//     icon: <PeopleIcon className="sideBarIcon" />,
//     route: "/users",
//     roles: ["SuperAdmin"],
//   },
//   {
//     name: "Actions",
//     icon: <CheckCircleOutlineIcon className="sideBarIcon" />,
//     route: "/actions",
//     roles: ["Agent", "SuperAdmin"],
//   },
//   {
//     name: "Campaigns",
//     icon: <VolumeUpIcon className="sideBarIcon" />,
//     route: "/campaigns",
//     roles: ["SuperAdmin"],
//   },
//   {
//     name: "Opportunities",
//     icon: <HomeIcon className="sideBarIcon" />,
//     route: "/opportunities",
//     roles: ["Agent", "SuperAdmin"],
//   },
//   {
//     name: "Sequences",
//     icon: <DnsIcon className="sideBarIcon" />,
//     route: "/sequences",
//     roles: ["SuperAdmin"],
//   },
//   {
//     name: "Contacts",
//     icon: <PeopleIcon className="sideBarIcon" />,
//     route: "/contacts",
//     roles: ["Agent", "SuperAdmin"],
//   },
//   {
//     name: "Calendar",
//     icon: <CalendarTodayIcon className="sideBarIcon" />,
//     route: "/calender",
//     roles: ["Agent", "SuperAdmin"],
//   },
// ];

export const newMenuListItems = [
  {
    name: "Home",
    icon: "/icons/home.svg",
    route: "/dashboard",
    roles: ["Agent", "SuperAdmin"],
  },
  {
    name: "Users",
    icon: "/icons/users.svg",
    route: "/users",
    roles: ["SuperAdmin"],
  },
  {
    name: "Operations",
    icon: "/icons/operations.svg",
    route: "/operations",
    roles: ["Agent", "SuperAdmin"],
  },
  {
    name: "Campaigns",
    icon: "/icons/campaigns.svg",
    route: "/campaigns",
    roles: ["SuperAdmin"],
  },
  {
    name: "Opportunities",
    icon: "/icons/opportunities.svg",
    route: "/opportunities",
    roles: ["Agent", "SuperAdmin"],
  },
  {
    name: "Automations",
    icon: "/icons/automations.svg",
    route: "/automations",
    roles: ["SuperAdmin"],
  },
  {
    name: "Contacts",
    icon: "/icons/contacts.svg",
    route: "/contacts/1",
    roles: ["Agent", "SuperAdmin"],
  },
  // {
  //   name: "Chat",
  //   icon: "/icons/chat.svg",
  //   route: "/chat",
  //   roles: ["Agent", "SuperAdmin"],
  // },
  {
    name: "Calendar",
    icon: "/icons/calendar.svg",
    route: "/calender",
    roles: ["Agent", "SuperAdmin"],
  },
  {
    name: "Account Settings",
    icon: "/icons/settings.svg",
    route: "/account-settings",
    roles: ["Agent", "SuperAdmin"],
  },
];
