import { getActionList } from "../../services/action.services";
import {
  fetchActionsFail,
  fetchActionsLoading,
  fetchActionsSuccess,
  setPagination,
} from "./actionsListSlice";

export const fetchAllActions = (filters, activeTab, id) => async (dispatch) => {
  dispatch(fetchActionsLoading());
  getActionList(filters, activeTab, id)
    .then((res) => {
      dispatch(fetchActionsSuccess(res.data.data));
      dispatch(
        setPagination({
          total: res.data.total,
          limit: res.data.per_page,
        })
      );
    })
    .catch((error) => {
      dispatch(fetchActionsFail());
    });
};

export const setLoading = () => (dispatch) => {
  dispatch(fetchActionsLoading());
};

export const settingUpPagination = (payload) => (dispatch) => {
  dispatch(setPagination(payload));
};
