import { http } from "../apiClient/axiosInterceptor";

export const updateProfile = (data) => {
  return http.post("/user/profile/settings", data);
};

export const getAllNotificationPreferences = () => {
  return http.get(`/notification/preference`);
};

export const updateNotificationPreference = (id, data) => {
  return http.post(`/notification/preference/update/${id}`, data);
};
