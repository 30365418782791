import { getCommonLogsbyOpportunityId } from "../../services/commonLogs.services";
import {
  fetchCommonLogsFail,
  fetchCommonLogsLoading,
  fetchCommonLogsSuccess,
} from "./commonLog.slice";

export const getCommonLogsbyOpportunity = (id) => (dispatch) => {
  dispatch(fetchCommonLogsLoading());
  getCommonLogsbyOpportunityId(id)
    .then((res) => {
      dispatch(fetchCommonLogsSuccess(res.data.data));
    })
    .catch((err) => {
      dispatch(fetchCommonLogsFail("Something went wrong"));
    });
};
