import React from "react";
import TextField from "@mui/material/TextField";
import ProgressBar from "../../../components/widgets/progress-bar";
import { Box, InputAdornment, Paper } from "@mui/material";
import VtechButton from "../../../components/vtech-button/VtechButton";
import VtechIcons from "../../../components/icons/VtechIcons";

export const EnhancedTableToolbar = (props) => {
  const { handleFilterCheck, setQuery, isLoading, anchorEl, setAnchorEl } =
    props;

  return (
    <>
      {isLoading && <ProgressBar />}
      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Paper
          elevation={0}
          sx={{
            px: 1.5,
            width: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 44,
          }}
        >
          <TextField
            fullWidth
            // label="Search Operations"
            placeholder="Search Operations ..."
            variant="standard"
            onChange={(e) => setQuery(e.target.value)}
            // size="small"
            inputProps={{
              sx: { "&::placeholder": { color: "text.secondary", opacity: 1 } },
            }}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start" sx={{ marginRight: 1.5 }}>
                  <VtechIcons icon="search" />
                </InputAdornment>
              ),
            }}
          />
        </Paper>
        <Paper elevation={0}>
          <VtechButton
            fullWidth
            variant="text"
            title="Filters"
            sx={{ height: 1, px: 2, color: "text.primary" }}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              handleFilterCheck();
            }}
            startIcon={<VtechIcons icon="filters" />}
          />
        </Paper>
      </Box>
    </>
  );
};
