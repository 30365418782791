import { Box, Button, TextField, Typography, Alert } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createSiteRequest } from "../../../services/userApi";
import { userSignupValidation } from "../../../utils/validations.utils";
import ProgressBar from "../../widgets/progress-bar/circular.progress";
import {
  loginFail,
  loginPending,
  loginSuccess,
  resetResetState,
} from "../login/loginSlice";
import { LIGHT_MODE_TEXT_COLOR_2, PRIMARY_MAIN } from "../../../theme";
import VtechModal from "../../vtech-modal/VtechModal";
import LoggedOutLayout from "../LoggedOutLayout";

export const SignupForm = () => {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, error, successMsg } = useSelector((state) => state.login);
  const successMessage =
    "Thank you for the registration request. Your request has been under process. We will get back to you shortly.";

  const createSite = (values) => {
    dispatch(loginPending());
    createSiteRequest({
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      country: values.country,
      state: values.state,
      details: values.details,
      phone: values.phone,
    })
      .then((res) => {
        if (res.data) {
          dispatch(loginSuccess("Success"));
          setOpen(true);
        }
      })
      .catch((error) => {
        dispatch(
          loginFail(
            error.messages[0].errors.last_name ||
              `Request failed with status code ${error.messages[0].status_code}`
          )
        );
      });
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      country: "",
      state: "",
      phone: "",
      details: "",
    },
    validationSchema: userSignupValidation,
    onSubmit: (values) => {
      createSite(values);
    },
  });

  useEffect(() => {
    dispatch(resetResetState());
  }, []);

  useEffect(() => {
    if (navigate) {
      if (localStorage.getItem("userToken")) {
        navigate("/dashboard");
      }
    }
  }, [navigate]);

  const handleClose = () => {
    setOpen(false);
    navigate("/");
  };

  return (
    <LoggedOutLayout>
      <Box
        component="main"
        sx={{ mt: "182px", width: 1, maxWidth: "446px", mx: "auto", mb: 10 }}
      >
        <Typography component="h1" sx={{ fontWeight: 700, fontSize: "40px" }}>
          Sign up
        </Typography>
        <Typography
          sx={{
            color: LIGHT_MODE_TEXT_COLOR_2,
            mt: 1,
            mb: 2,
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          Please sign up to create your account
        </Typography>

        {(error !== "" || successMsg !== "") && (
          <Alert severity={error ? "error" : "success"}>
            {error ? error : successMsg}
          </Alert>
        )}
        <form noValidate onSubmit={formik.handleSubmit}>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            id="firstName"
            label="First Name"
            name="firstName"
            autoComplete="firstName"
            autoFocus
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />

          <TextField
            sx={{ mt: 2 }}
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
          <TextField
            autoComplete="new-password"
            sx={{ mt: 2 }}
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            name="country"
            label="Country"
            id="country"
            value={formik.values.country}
            onChange={formik.handleChange}
            error={formik.touched.country && Boolean(formik.errors.country)}
            helperText={formik.touched.country && formik.errors.country}
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            name="state"
            label="State"
            id="state"
            value={formik.values.state}
            onChange={formik.handleChange}
            error={formik.touched.state && Boolean(formik.errors.state)}
            helperText={formik.touched.state && formik.errors.state}
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            name="phone"
            label="Phone"
            id="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />
          <TextField
            sx={{ my: 2 }}
            fullWidth
            name="details"
            label="Details"
            id="details"
            value={formik.values.details}
            onChange={formik.handleChange}
            error={formik.touched.details && Boolean(formik.errors.details)}
            helperText={formik.touched.details && formik.errors.details}
          />

          <Button
            sx={{ mt: "6px", height: "47px" }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isLoading}
            startIcon={isLoading && <ProgressBar />}
          >
            Sign up
          </Button>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              fontWeight: 500,
              fontSize: 14,
              mt: 2,
            }}
          >
            Already have an account?
            <Typography
              component="span"
              sx={{
                color: PRIMARY_MAIN,
                ml: "3px",
                cursor: "pointer",
                fontWeight: 500,
                fontSize: 14,
              }}
              onClick={() => navigate("/")}
            >
              Sign in here
            </Typography>
          </Typography>
        </form>
      </Box>
      <VtechModal
        title="Confirmation"
        primaryButtonLabel="Okay"
        buttonPosition="start"
        open={open}
        handleClose={handleClose}
        primaryButtonAction={handleClose}
      >
        {successMessage}
      </VtechModal>
    </LoggedOutLayout>
  );
};

export default SignupForm;
