import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

type Props = {
  tabs: Array<string>;
  activeTab: number;
  setTab: (index: number) => void;
};

const VtechTabs = ({ tabs, activeTab, setTab }: Props) => {
  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        value={activeTab}
        variant="scrollable"
        sx={{
          "& .MuiTabs-indicator": {
            width: "41px !important",
            borderRadius: "5px",
          },
        }}
      >
        {tabs.map((item, index) => (
          <Tab
            sx={{
              mr: 9,
              fontWeight: activeTab === index ? 700 : 600,
              p: 0,
              minWidth: 0,
            }}
            label={item}
            key={index}
            onClick={() => setTab(index)}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default VtechTabs;
