import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allResponse: [],
  isLoading: false,
  error: "",
  isSuccess: false,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    campaignLoading: (state) => {
      state.isLoading = true;
    },
    fetchCampaignSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.allResponse = payload;
    },
    stopLoading: (state) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.error = "";
    },
    hideSnackar: (state) => {
      state.isSuccess = false;
    },
    campaignFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  campaignFail,
  campaignLoading,
  stopLoading,
  hideSnackar,
  fetchCampaignSuccess,
} = settingsSlice.actions;
export default settingsSlice.reducer;
