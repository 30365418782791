import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  seller: [],
  stars: [],
  isLoading: false,
  error: "",
  isSellerLoading: false,
  isEdit: false,
  sellerSnackbar: false,
};

const contactDetailData = createSlice({
  name: "seller",
  initialState,
  reducers: {
    setIsSellerLoading: (state) => {
      state.isSellerLoading = true;
    },
    stopSellerLoading: (state) => {
      state.isSellerLoading = false;
      state.error = "";
    },
    sellersFetched: (state, { payload }) => {
      state.isSellerLoading = false;
      state.seller = payload;
    },
    starsFetched: (state, { payload }) => {
      state.stars = payload;
    },
    handleSellerSnack: (state) => {
      state.sellerSnackbar = !state.sellerSnackbar;
    },
    setIsEdit: (state, { payload }) => {
      state.isEdit = payload;
    },
  },
});

export const {
  setIsSellerLoading,
  sellersFetched,
  stopSellerLoading,
  handleSellerSnack,
  setIsEdit,
  starsFetched,
} = contactDetailData.actions;
export default contactDetailData.reducer;
