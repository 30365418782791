import {
  getCampaignStats,
  getCampaignOpportunityStats,
  getCampaignStatisticsCounts,
} from "../../../services/campaignStats.services";
import {
  fetchCampaignStatsSuccess,
  fetchCampaignStatsLoading,
  fetchThisCampaignStatsSuccess,
  fetchOtherCampaignStatsSuccess,
  fetchCampaignStatsFail,
  fetchThisCampaignOpportunityStatSuccess,
  fetchOtherCampaignOpportunityStatSuccess,
  stopCampaignStatsLoading,
} from "./campaignStatSlice";

export const fetchAllCampaignStats = (type, id) => (dispatch) => {
  dispatch(fetchCampaignStatsLoading());
  getCampaignStats(type, id)
    .then((res) => {
      dispatch(fetchCampaignStatsSuccess(res.data.data.current));
    })
    .catch((error) => {
      dispatch(fetchCampaignStatsFail(error));
    });
};

export const fetchCampaignStatisticsCounts =
  (type, id, from, to) => (dispatch) => {
    dispatch(fetchCampaignStatsLoading());
    getCampaignStatisticsCounts(type, id, from, to)
      .then((res) => {
        dispatch(fetchThisCampaignStatsSuccess(res.data.data.current));
        dispatch(fetchOtherCampaignStatsSuccess(res.data.data.previous));
        if (to && from) {
          dispatch(stopCampaignStatsLoading());
        }
      })
      .catch((error) => {
        dispatch(fetchCampaignStatsFail(error));
      });
  };

export const fetchAllCampaignOpportunityStats = (type, id) => (dispatch) => {
  dispatch(fetchThisCampaignOpportunityStatSuccess([]));
  dispatch(fetchOtherCampaignOpportunityStatSuccess([]));

  dispatch(fetchCampaignStatsLoading());
  getCampaignOpportunityStats(type, id)
    .then((res) => {
      dispatch(fetchThisCampaignOpportunityStatSuccess(res.data.data.current));
      dispatch(
        fetchOtherCampaignOpportunityStatSuccess(res.data.data.previous)
      );
    })
    .catch((error) => {
      dispatch(fetchCampaignStatsFail(error));
    });
};
