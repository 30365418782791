import React, { lazy, Suspense, useEffect, useState } from "react";
import MainLayout from "../../components/MainLayout/MainLayout";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import VtechTabs from "../../components/tabs/VtechTabs";
import ChatTeamsManagement from "./chat-teams-management";

const Dashboard = lazy(() => import("./dashboard/index"));
const ActivityPage = lazy(() => import("./activity-page/index"));
const Assignment = lazy(() => import("./assignment/index"));
const Settings = lazy(() => import("./settings/index"));

const getActiveTabIndex = (url) => {
  return url.includes("activity")
    ? 1
    : url.includes("assignment")
    ? 2
    : url.includes("settings")
    ? 3
    : url.includes("chat-teams")
    ? 4
    : 0;
};

const DashboardPage = () => {
  const navigate = useNavigate();
  const url = window.location.href;
  const { user } = useSelector((state: any) => state.login);
  const [activeTab, setActiveTab] = useState(getActiveTabIndex(url));

  // prettier-ignore
  const tabs =
    user.user_type === "admin"
      ? ["Dashboard", "Activity", "Assignment", "Settings"]
      // ? ["Dashboard", "Activity", "Assignment", "Settings","Chat teams management"]
      : ["Dashboard", "Activity"];

  const handleActiveIndex = (index) => {
    setActiveTab(index);
    switch (index) {
      case 0:
        navigate("/dashboard");
        break;
      case 1:
        navigate("/dashboard/activity/1");
        break;
      case 2:
        navigate("/dashboard/assignment");
        break;
      case 3:
        navigate("/dashboard/settings");
        break;
      case 4:
        navigate("/dashboard/chat-teams");
        break;
    }
  };

  // for when the active tab is not 0 i.e. dashboard and sidebar is clicked
  useEffect(() => {
    if (window.location.pathname === "/dashboard") {
      setActiveTab(0);
    }
  }, [window.location.pathname]);

  return (
    <MainLayout title={tabs[activeTab]}>
      <VtechTabs activeTab={activeTab} setTab={handleActiveIndex} tabs={tabs} />
      <Suspense fallback={<p>Loading..</p>}>
        {activeTab === 0 && <Dashboard />}
        {activeTab === 1 && <ActivityPage />}
        {activeTab === 2 && <Assignment />}
        {activeTab === 3 && <Settings />}
        {/* {activeTab === 4 && <ChatTeamsManagement />} */}
      </Suspense>
    </MainLayout>
  );
};

export default DashboardPage;
