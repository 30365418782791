import React, { useState } from "react";
import ProgressBar from "../../progress-bar/index";
import { EditTaskForm } from "./edit-form/edit-task";
import VtechModal from "../../../vtech-modal/VtechModal";

export const ResponsiveEditTask = ({
  open,
  setOpen,
  id,
  taskId,
  assignId,
  taskData,
}) => {
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <VtechModal
      open={open}
      handleClose={handleClose}
      title="Edit Task"
      hideActionButtons
    >
      {loading && <ProgressBar />}
      <EditTaskForm
        id={id}
        taskId={taskId}
        taskData={taskData}
        assignId={assignId}
        setLoading={setLoading}
        handleClose={handleClose}
      />
    </VtechModal>
  );
};
