import React from "react";
import { MaterialCard } from "../../../../../components/widgets/mat-card";
import { SelectionWidget } from "../../../../../components/widgets/select-widget";
import { Alert, Button, Fab, Typography, useTheme } from "@mui/material";
// import BookmarksIcon from "@mui/icons-material/Bookmarks";
import TextTemplate from "./text-template";
import MailTemplate from "./mail-template";
import VoiceTemplate from "./voicemail-template";
import CustomTemplate from "./custom-template";
import { useSelector } from "react-redux";
import SwitchComponent from "../../../../../components/widgets/switch-component";
import VtechButton from "../../../../../components/vtech-button/VtechButton";

const InitialStepForm = ({
  sequenceData,
  setSequenceData,
  handleChange,
  saveSuccess,
}) => {
  const { initialStep, isLoading } = useSelector((state) => state.sequences);
  const items = [
    { value: "none", title: "None" },
    { value: "email", title: "Send Mail" },
    { value: "text", title: "Send Text" },
    { value: "voicemail", title: "Send Voicemail" },
    { value: "custom_task", title: "Custom Task" },
  ];
  return (
    <>
      <div>
        <div>
          {sequenceData.action === "text" && (
            <TextTemplate
              sequenceData={sequenceData}
              setSequenceData={setSequenceData}
            />
          )}
          {sequenceData.action === "email" && (
            <MailTemplate
              sequenceData={sequenceData}
              setSequenceData={setSequenceData}
            />
          )}
          {sequenceData.action === "voicemail" && (
            <VoiceTemplate
              sequenceData={sequenceData}
              setSequenceData={setSequenceData}
            />
          )}
          {sequenceData.action === "custom_task" && (
            <CustomTemplate
              sequenceData={sequenceData}
              setSequenceData={setSequenceData}
            />
          )}
          <div
            style={{
              width: "100%",
              marginTop: "20px",
            }}
          >
            <SelectionWidget
              items={items}
              label="Operation"
              value={sequenceData.action}
              setValue={(val) =>
                setSequenceData({
                  ...sequenceData,
                  action: val,
                  subject:
                    initialStep.action !== "email" ? "" : initialStep.subject,
                  emailText:
                    initialStep.action !== "email" ? "" : initialStep.message,
                  callback_number:
                    initialStep.action !== "voicemail"
                      ? ""
                      : initialStep.callback_number,
                  voicemail:
                    initialStep.action !== "voicemail"
                      ? ""
                      : initialStep.voicemail,
                  task_name:
                    initialStep.action !== "custom_task"
                      ? ""
                      : initialStep.task_name,
                  task_assignee:
                    initialStep.action !== "custom_task"
                      ? ""
                      : initialStep.task_assignee,
                  message:
                    initialStep.action !== "text" ? "" : initialStep.message,
                })
              }
            />
          </div>
        </div>
      </div>
      <br />
      <br />

      <VtechButton
        title={saveSuccess ? "Saved!" : "Save"}
        startIcon={
          isLoading ? (
            <img src="/icons/circle-dark.svg" />
          ) : saveSuccess ? (
            ""
          ) : (
            <img src="/icons/save.svg" />
          )
        }
        onClick={handleChange}
        color={saveSuccess ? "success" : "primary"}
        sx={{ transition: "all 0.8s ease" }}
      />

      {/* <Button
        variant="contained"
        color="primary"
        onClick={handleChange}
        startIcon={<BookmarksIcon />}
      >
        Save
      </Button> */}
    </>
  );
};

const SequenceInitialSteps = ({
  sequenceData,
  setSequenceData,
  switchCheck,
  setSwitchCheck,
  handleChange,
  saveSuccess,
}) => {
  const theme = useTheme();
  const { initialStepLoading } = useSelector((state) => state.sequences);

  return (
    <>
      <Typography
        variant="body1"
        display="inline"
        sx={{ mr: 2, fontWeight: "bold" }}
      >
        Initial Step
      </Typography>
      <SwitchComponent
        switchCheck={switchCheck}
        setSwitchCheck={(e) => setSwitchCheck(e.target.checked)}
        disabled={initialStepLoading}
      />
      <br />
      <br />
      <Alert severity="info">
        <Typography sx={{ color: theme.palette.text.primary }}>
          As soon as the Automation begins, the first step is promptly
          dispatched.
        </Typography>
      </Alert>
      <br />
      <div style={{ position: "relative" }}>
        {switchCheck && (
          <InitialStepForm
            sequenceData={sequenceData}
            setSequenceData={setSequenceData}
            handleChange={handleChange}
            saveSuccess={saveSuccess}
          />
        )}
      </div>
    </>
  );
};

export default SequenceInitialSteps;
