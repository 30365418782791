import { Checkbox, IconButton, TableCell, TableRow } from "@mui/material";
import React from "react";
import CONTACTS_UTILS from "../contact-components/generalComponent";
import { useNavigate } from "react-router-dom";
import VtechIcons from "../../../components/icons/VtechIcons";
import { formatPhone } from "../../../utils/global.utils";

export const ActionTableRow = ({
  row,
  index,
  isItemSelected,
  handleClick,
  setOpenModal,
  user,
}) => {
  const navigate = useNavigate();

  // const formatPhoneNumber = (phone) =>
  //   phone.slice(0, 3) + "-" + phone.slice(3, 6) + "-" + phone.slice(6, 10);

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      selected={isItemSelected}
      key={row.id}
      onClick={() => navigate(`/contact/${row.id}`)}
      sx={{
        cursor: "pointer",
        ".MuiTableCell-root": { borderBottom: 1, borderColor: "divider" },
        "&.Mui-selected": { bgcolor: (theme) => theme.palette.action.hover },
      }}
    >
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            handleClick(e, row.id);
          }}
          // onClick={(event) => handleSelectAllClick(event)}
          checked={isItemSelected}
        />
      </TableCell>
      <TableCell>{row.first_name}</TableCell>
      <TableCell>{row.last_name}</TableCell>
      <TableCell>{formatPhone(row?.phone.substring(1))}</TableCell>
      <TableCell>{row.email}</TableCell>
      <TableCell align="right">
        <IconButton>
          <VtechIcons icon="edit" />
        </IconButton>
      </TableCell>
      {/* {!isMobileDevice && (
        <TableCell>
          {user.user_type === "admin" &&
            CONTACTS_UTILS.getCheckBox(row, isItemSelected, handleClick)}
        </TableCell>
      )}
      <TableCell component="th" id={labelId}>
        {CONTACTS_UTILS.getSecondColumn(row)}
      </TableCell>
      <TableCell align="right" style={{ width: "200px" }}>
        {CONTACTS_UTILS.getThirdColumn(row)}
      </TableCell> */}
    </TableRow>
  );
};
