import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentActionId: "",
};

const editStateSlice = createSlice({
  name: "editStateSlice",
  initialState,
  reducers: {
    setCurrentId: (state, { payload }) => {
      state.currentActionId = payload;
    },
  },
});

export const { setCurrentId } = editStateSlice.actions;

export default editStateSlice.reducer;
