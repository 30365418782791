import React from "react";
import { darkTheme, lightTheme } from "./theme";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import { useDarkMode } from "./utils/useDarkMode";

function MuiThemeProvider({ children }) {
  const darkMode = useDarkMode();

  // this attribute is used in the index.css file for the scroll bar styles in dark mode
  React.useEffect(() => {
    if (darkMode) document.body.setAttribute("data-theme", "dark");
    else document.body.removeAttribute("data-theme");
  }, [darkMode]);

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

export default MuiThemeProvider;
