import {
  addAttachment,
  addLink,
  deleteAttachment,
  deleteLink,
  getOpportunityById,
  postPropertyDetails,
} from "../../services/opportunities";
import {
  cancelOpportunitySequence,
  getAllOfferByOpportunity,
  updateAndCreateNewAction,
} from "../../services/opportunity-details.service";
import {
  assignSeller,
  createSeller,
  editSeller,
  getAllSellers,
} from "../../services/opportunity-seller.service";
import { showSnackbar } from "../../snackbar/snackbarSlice";
import { fetchAllActions } from "../actions/actionsList.Action";
import { fetchAllOpportunities } from "../opportunities/opportunitiesListAction";
import { getCommonLogsbyOpportunity } from "./commonLog.action";
import {
  fetchOpportunitiesFail,
  fetchOpportunitiesLoading,
  fetchOpportunitiesSuccess,
  handlePropertySnackBar,
  handleOfferSnackBar,
  handleSellerSnack,
  sellersFetched,
  setIsSellerLoading,
  stopSellerLoading,
  updateOpportunityOffers,
  handleCancelSnack,
  stopOpportunitiesLoading,
} from "./opportunity.slice";

export const getOpportunity =
  (id, fetchLogs = true) =>
  (dispatch) => {
    dispatch(fetchOpportunitiesLoading());
    getOpportunityById(id)
      .then((res) => {
        dispatch(fetchOpportunitiesSuccess(res.data.data));
        if (fetchLogs) dispatch(getCommonLogsbyOpportunity(id));
      })
      .catch((err) => {
        dispatch(fetchOpportunitiesFail("Something went wrong"));
      });
  };

export const updatePropertyDetails =
  (data, from = true, setSaveSuccess) =>
  (dispatch) => {
    data = {
      ...data,
      campaign_id: data.campaign_id === 0 ? "" : data.campaign_id,
    };
    dispatch(fetchOpportunitiesLoading());
    postPropertyDetails(data)
      .then((res) => {
        setSaveSuccess(true);
        setTimeout(() => {
          setSaveSuccess(false);
        }, 3000);
        if (from) {
          dispatch(getOpportunity(data.id));
        }
        dispatch(handlePropertySnackBar());
        dispatch(showSnackbar("Property details updated successfully!"));
      })
      .catch((error) => {
        dispatch(fetchOpportunitiesFail("Something went wrong"));
      });
  };

export const addLinkToOpportunity = (data) => (dispatch) => {
  dispatch(fetchOpportunitiesLoading());
  addLink(data)
    .then((res) => {
      dispatch(getOpportunity(data.opportunity_id));
    })
    .catch((error) => {
      dispatch(fetchOpportunitiesFail("Something went wrong"));
    });
};

export const deleteLinkToOpportunity = (data) => (dispatch) => {
  dispatch(fetchOpportunitiesLoading());
  deleteLink(data.linkId)
    .then((res) => {
      dispatch(getOpportunity(data.opportunity_id));
    })
    .catch((error) => {
      dispatch(fetchOpportunitiesFail("Something went wrong"));
    });
};

export const addAttachmentToOpportunity = (data) => (dispatch) => {
  dispatch(fetchOpportunitiesLoading());
  addAttachment(data)
    .then((res) => {
      dispatch(getOpportunity(data.opportunity_id));
    })
    .catch((error) => {
      dispatch(fetchOpportunitiesFail("Something went wrong"));
    });
};

export const deleteAttachmentToOpportunity = (data) => (dispatch) => {
  dispatch(fetchOpportunitiesLoading());
  deleteAttachment(data.attachment_id)
    .then((res) => {
      dispatch(getOpportunity(data.opportunity_id));
    })
    .catch((error) => {
      dispatch(fetchOpportunitiesFail("Something went wrong"));
    });
};

export const getPrimarySellers = () => (dispatch) => {
  dispatch(setIsSellerLoading());
  getAllSellers().then((res) => {
    dispatch(sellersFetched(res.data.data));
  });
};

export const updatePrimarySeller = (id, opId, data) => (dispatch) => {
  dispatch(setIsSellerLoading());
  editSeller(id, data)
    .then((res) => {
      dispatch(stopSellerLoading());
      dispatch(getOpportunity(opId));
      dispatch(getPrimarySellers());
      dispatch(handleSellerSnack());
      dispatch(showSnackbar("Primary seller updated successfully!"));
    })
    .catch((err) => {
      dispatch(stopSellerLoading());
      dispatch(fetchOpportunitiesFail(err.messages[0].errors));
    });
};

export const assignSellerToOpportunity = (opId, sellerId) => (dispatch) => {
  dispatch(setIsSellerLoading());
  assignSeller(opId, sellerId)
    .then((res) => {
      dispatch(stopSellerLoading());
      dispatch(getOpportunity(opId));
      dispatch(handleSellerSnack());
    })
    .catch((err) => {
      dispatch(stopSellerLoading());
    });
};

export const addNewSeller = (opId, data) => (dispatch) => {
  dispatch(setIsSellerLoading());
  createSeller(data)
    .then((res) => {
      dispatch(stopSellerLoading());
      dispatch(getOpportunity(opId));
      dispatch(getPrimarySellers());
      dispatch(handleSellerSnack());
      dispatch(showSnackbar("Seller created successfully!"));
    })
    .catch((err) => {
      dispatch(stopSellerLoading());
      dispatch(fetchOpportunitiesFail(err.messages[0].errors));
    });
};

export const CreateNewAction =
  (data, opId, stepId, setOpen, setSuccess, nav) => (dispatch) => {
    dispatch(fetchOpportunitiesLoading());
    updateAndCreateNewAction(data)
      .then((res) => {
        setSuccess(true);
        dispatch(stopOpportunitiesLoading());
        if (nav) {
          setTimeout(() => {
            nav();
          }, 3000);
        }
        if (setOpen) {
          setTimeout(() => {
            setOpen(false);
          }, 2000);
        }
        if (opId) {
          if (stepId) {
            dispatch(getOpportunityOffers(opId));
          }
          if (data.step_id === 26) {
            dispatch(getOpportunityOffers(opId));
          }
          dispatch(getOpportunity(opId));
        } else {
          dispatch(fetchAllOpportunities(1, 10, ""));
          dispatch(fetchAllActions());
        }
      })
      .catch((error) => {
        dispatch(fetchOpportunitiesFail("Something went wrong"));
      })
      .finally(() => {
        dispatch(showSnackbar("Status updated successfully!"));
      });
  };

export const CreateNewOffer = (data) => (dispatch) => {
  dispatch(fetchOpportunitiesLoading());
  updateAndCreateNewAction(data)
    .then(() => {
      dispatch(handleOfferSnackBar());
    })
    .catch((error) => {
      dispatch(fetchOpportunitiesFail("Something went wrong"));
    });
};

export const getOpportunityOffers = (id) => (dispatch) => {
  getAllOfferByOpportunity(id).then((res) => {
    dispatch(updateOpportunityOffers(res.data.data));
  });
};

export const cancelSequence = (optId, stepHistId) => (dispatch) => {
  dispatch(fetchOpportunitiesLoading());
  cancelOpportunitySequence(stepHistId).then((res) => {
    dispatch(getOpportunity(optId));
    dispatch(handleCancelSnack());
    dispatch(showSnackbar("Sequence cancelled successfully!"));
  });
};
