import { fetchAllUsersWOL, getAllUsers } from "../../services/usersApi";
import {
  fetchUsersFail,
  fetchUsersLoading,
  fetchUsersSuccess,
  setPagination,
} from "./usersListSlice";

export const fetchAllUsers = (page, limit) => async (dispatch) => {
  dispatch(fetchUsersLoading());
  getAllUsers(page, limit)
    .then((res) => {
      dispatch(fetchUsersSuccess(res.data.data));
      dispatch(
        setPagination({
          total: res.data.total,
          limit: res.data.per_page,
        })
      );
    })
    .catch((error) => {
      dispatch(fetchUsersFail());
    });
};

export const getAllUsersWOL = () => (dispatch) => {
  dispatch(fetchUsersLoading());
  fetchAllUsersWOL()
    .then((res) => {
      dispatch(fetchUsersSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(fetchUsersFail());
    });
};

export const setLoading = () => (dispatch) => {
  dispatch(fetchUsersLoading());
};

export const settingUpPagination = (payload) => (dispatch) => {
  dispatch(setPagination(payload));
};
