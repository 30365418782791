import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import React, { useState } from "react";
import FilterComponent from "../filterComponent";
import TablePagination from "@mui/material/TablePagination";
import { EnhancedTableToolbar } from "./enhance-table-toolbar";
import { ActionTableRow } from "./table-row";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Box, Chip, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setAppliedFilters, setSearchFilters } from "../actionsListSlice";

export default function EnhancedTable({
  setFilters,
  filteredArray,
  isLoading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  totalCount,
  query,
  setQuery,
  filterCheck,
  setFilterCheck,
  actions,
}) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const { searchFilters, appliedFilters } = useSelector(
    (state: any) => state.actionList
  );
  const { campaigns } = useSelector((state: any) => state.campaigns);
  const { actions: stateActions } = useSelector((state: any) => state.campaignDetails);
  const { labels } = useSelector((state: any) => state.opportunities);
  const { users } = useSelector((state: any) => state.users);
  const dispatch = useDispatch();

  const handleFilterCheck = () => {
    if (filterCheck) {
      setFilterCheck(false);
    } else {
      setFilterCheck(true);
    }
  };

  const handleChangePage = (event, newPage) => {
    localStorage.setItem("currentPage", parseInt(newPage, 10));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    localStorage.setItem(
      "currentRowsPerPage",
      parseInt(event.target.value, 10)
    );
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const motivations = [
    { title: "All", value: "all" },
    { title: "Unknown", value: "unknown" },
    { title: "Cold", value: "cold" },
    { title: "Warm", value: "warm" },
    { title: "Hot", value: "hot" },
  ];

  return (
    <>
      <EnhancedTableToolbar
        isLoading={isLoading}
        handleFilterCheck={handleFilterCheck}
        opportunities={actions}
        query={query}
        setQuery={setQuery}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
      <FilterComponent
        filterCheck={filterCheck}
        setFilters={setFilters}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
      {searchFilters && (searchFilters.filter((item) => item !== "").length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "end", gap: 1, mt: 3 }}>
          {searchFilters
            .filter((item) => item !== "")
            .map((filter) => (
              <Chip
                label={filter}
                onDelete={() =>{
                  dispatch(setSearchFilters(searchFilters.filter((item) => item !== filter)));

                  const jsonObject = JSON.parse(appliedFilters);
                  const arraysToCheck = {
                    campaignIds: { array: campaigns, key: 'title' },
                    stepIds: { array: stateActions, key: 'title' },
                    labelIds: { array: labels, key: 'label' },
                    userIds: { array: users, key: 'email' },
                    motivationValues: { array: motivations, key: 'value' },
                  };

                  for (const key in arraysToCheck) {
                    if (Array.isArray(arraysToCheck[key].array)) {
                      const searchKey = arraysToCheck[key].key;
                      const filterValue = filter;
                      const matchingItem = arraysToCheck[key].array.find(item => item[searchKey] === filterValue);
                      if (matchingItem) {
                        if (key === 'motivationValues') {
                          jsonObject[key] = jsonObject[key].filter(item => item !== matchingItem.value);
                        } else {
                          jsonObject[key] = jsonObject[key].filter(id => id !== matchingItem.id);
                        }
                      }
                    }
                  }
                  const updatedJsonString = JSON.stringify(jsonObject);
                  dispatch(setAppliedFilters(updatedJsonString));
                }}
                size="small"
                color="primary"
              />
            ))}
        </Box>
      ))}
      <TableContainer>
        <Table sx={{ borderCollapse: "separate", borderSpacing: "0px 16px" }}>
          <TableBody>
            {filteredArray.map((row, index) => {
              let lastTouch =
                row.last_touched !== null
                  ? moment(row.last_touched).local().fromNow()
                  : null;
              lastTouch = lastTouch !== null ? lastTouch.split(" ") : null;
              let createdAt = moment(row.created_at).local().fromNow();
              return (
                <ActionTableRow
                  row={row}
                  index={index}
                  actions={actions}
                  history={navigate}
                  lastTouch={lastTouch}
                  createdAt={createdAt.split(" ")}
                />
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          sx={{ mt: filteredArray.length === 0 ? 2 : 0 }}
          component={Paper}
          count={totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
