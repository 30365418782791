import { FormControlLabel, Switch } from "@mui/material";
import React from "react";

const SwitchComponent = ({ switchCheck, setSwitchCheck, onText, offText, disabled }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          color="primary"
          checked={switchCheck}
          onChange={setSwitchCheck}
          disabled={disabled}
        />
      }
      label={switchCheck ? onText : offText}
    />
  );
};

export default SwitchComponent;
