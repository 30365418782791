import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { forgetPassword } from "../../../services/userApi";
import { resetEmailValidation } from "../../../utils/validations.utils";
import ProgressBar from "../../widgets/progress-bar/circular.progress";
import {
  loginFail,
  loginPending,
  resetPasswordSuccess,
  resetResetState,
} from "../login/loginSlice";
import { LIGHT_MODE_TEXT_COLOR_2, PRIMARY_MAIN } from "../../../theme";
import LoggedOutLayout from "../LoggedOutLayout";

export const ForgotPasswordForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, error, successMsg } = useSelector((state) => state.login);

  const resetPassword = (values) => {
    dispatch(loginPending());
    forgetPassword(values.email)
      .then((res) => {
        dispatch(resetPasswordSuccess(res.data.message));
        setTimeout(() => {
          dispatch(resetResetState());
          formik.resetForm();
        }, 4000);
      })
      .catch((error) => {
        dispatch(loginFail(error.messages[0].message));
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: resetEmailValidation,
    onSubmit: (values) => {
      resetPassword(values);
    },
  });

  useEffect(() => {
    dispatch(resetResetState());
  }, []);

  useEffect(() => {
    if (navigate) {
      if (localStorage.getItem("userToken")) {
        navigate("/dashboard");
      }
    }
  }, [navigate]);

  return (
    <LoggedOutLayout>
      <Box
        component="main"
        sx={{ mt: "182px", width: 1, maxWidth: "446px", mx: "auto" }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontWeight: "bold", fontSize: 40, mb: 1 }}
        >
          Forgot Password
        </Typography>

        <Typography
          sx={{
            color: LIGHT_MODE_TEXT_COLOR_2,
            mb: 2,
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          Forgot your password?
        </Typography>
        {(error !== "" || successMsg !== "") && (
          <Alert severity={error ? "error" : "success"}>
            {error ? error : successMsg}
          </Alert>
        )}
        <form noValidate onSubmit={formik.handleSubmit}>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Typography
              sx={{
                color: PRIMARY_MAIN,
                cursor: "pointer",
                fontWeight: 500,
                fontSize: 14,
                my: 2,
              }}
              onClick={() => navigate("/")}
            >
              Sign in
            </Typography>
          </Box>

          <Button
            sx={{ mt: "6px", height: "47px" }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isLoading}
            startIcon={isLoading && <ProgressBar />}
          >
            Reset Password
          </Button>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              fontWeight: 500,
              fontSize: 14,
              mt: 2,
            }}
          >
            Don't have an account?
            <Typography
              component="span"
              sx={{
                color: PRIMARY_MAIN,
                ml: "3px",
                cursor: "pointer",
                fontWeight: 500,
                fontSize: 14,
              }}
              onClick={() => navigate("/signup")}
            >
              Sign up here
            </Typography>
          </Typography>
        </form>
      </Box>
    </LoggedOutLayout>
  );
};

export default ForgotPasswordForm;
