import React, { useState } from "react";
// import Paper from "@mui/material/Paper";
// import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
// import { setNotiForm } from "./notification.action";
// import CloseIcon from "@mui/icons-material/Close";
// import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import NewNotification from "./newNotification";
import ArchiveNotification from "./archiveNotification";
// import Badge from "@mui/material/Badge";
// import style from "./singleNotification.module.css";
import { Menu, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/material";
import { markAsRead } from "./notification.action";

const Notification = ({ anchorEl, setAnchorEl }) => {
  const { user } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  // const { unReadCount } = useSelector((state) => state.notifications);
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAllMarkAsRead = () => {
    const data = {
      userId: user.id,
    };
    dispatch(markAsRead(data));
    setAnchorEl(null);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={() => setAnchorEl(null)}
      slotProps={{ paper: { sx: { width: "340px" } } }}
      sx={{ mt: 2 }}
    >
      <Box
        sx={{ display: "flex", justifyContent: "space-between", p: 2, pb: 3 }}
      >
        <Typography sx={{ fontWeight: 700 }}>Notifications</Typography>
        <Typography
          sx={{
            color: theme.palette.text.secondary,
            fontSize: 14,
            fontWeight: 600,
            cursor: "pointer",
          }}
          onClick={handleAllMarkAsRead}
        >
          Mark all as read
        </Typography>
      </Box>
      <Box>
        <Tabs
          value={tabValue}
          variant="fullWidth"
          onChange={handleChange}
          sx={{
            borderBottom: `1px solid ${theme.palette.divider}`,
            borderTop: `1px solid ${theme.palette.divider}`,
            // "& .MuiTab-root.Mui-selected": {
            //   color: theme.palette.text.secondary,
            // },
          }}
          // TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
        >
          <Tab label="New" />
          <Tab label="Archive" />
        </Tabs>
        {tabValue === 0 && <NewNotification setAnchorEl={setAnchorEl} />}
        {tabValue === 1 && <ArchiveNotification setAnchorEl={setAnchorEl} />}
      </Box>
    </Menu>
  );
};

export default Notification;
