import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import React, { useEffect, useRef, useState } from "react";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import { getAllAppointments } from "../../services/calender.services";
import { convertTimeFormate, groupByKey } from "../../utils/global.utils";
import { DetailDialog } from "./details.dialog";
import { useDispatch, useSelector } from "react-redux";
import { SelectionWidget } from "../../components/widgets/select-widget/index";
import moment from "moment";
import MainLayout from "../../components/MainLayout/MainLayout";
import Box from "@mui/material/Box";
import { Paper } from "@mui/material";
import CustomButtons from "./custom-buttons";
import { useDarkMode } from "../../utils/useDarkMode";
import "./calendarClasses.css";

const Calender = () => {
  // const dispatch = useDispatch();
  const [appointments, setAppointments] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [open, setOpen] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState({});
  const { user } = useSelector((state) => state.login);
  const calendarRef = useRef(null);
  const [monthValue, setMonthValue] = useState("");
  const [yearValue, setYearValue] = useState("");
  const darkMode = useDarkMode();
  const [eventRef, setEventRef] = useState(null);

  const monthArray = [
    { value: "01", title: "January" },
    { value: "02", title: "February" },
    { value: "03", title: "March" },
    { value: "04", title: "April" },
    { value: "05", title: "May" },
    { value: "06", title: "June" },
    { value: "07", title: "July" },
    { value: "08", title: "August" },
    { value: "09", title: "September" },
    { value: "10", title: "October" },
    { value: "11", title: "November" },
    { value: "12", title: "December" },
  ];

  const yearArray = [
    { value: "2019", title: "2019" },
    { value: "2020", title: "2020" },
    { value: "2021", title: "2021" },
    { value: "2022", title: "2022" },
    { value: "2023", title: "2023" },
    { value: "2024", title: "2024" },
    { value: "2025", title: "2025" },
    { value: "2026", title: "2026" },
    { value: "2027", title: "2027" },
    { value: "2028", title: "2028" },
  ];

  const getAppointments = async (userId, role) => {
    const result = await getAllAppointments(userId, role);
    let allFilters = [];
    let testingArr = [];
    if (result) {
      let groupArr = groupByKey(result.data.data, "opportunity_id");
      for (var key in groupArr) {
        let tempArr = groupArr[key].filter((x) => x.status !== "closed");
        if (tempArr.length === 0) {
          testingArr.push(groupArr[key][groupArr[key].length - 1]);
        } else {
          testingArr.push(tempArr);
        }
      }
      let finalArr = [].concat.apply([], testingArr);
      setOriginalData(result.data.data);
      allFilters = finalArr.map((item) => {
        const time =
          item.due_date_time &&
          moment(item.due_date_time).local().format("hh:mm A");
        const title = ` ${
          item.status === "closed" ? "Completed!" : "Pending!"
        } \n ${item.street_address} ${item.city} ${item.state} ${
          item.zipcode
        } @ ${time}`;
        // const date = item.due_date_time && item.due_date_time.split("T")[0];
        const date = moment(item.due_date_time).local().format("YYYY-MM-DD");
        return {
          title,
          date,
          id: item.appointment_id,
          itemStatusClosed: item.status === "closed",
        };
      });
    }
    return allFilters;
  };

  const getMonth = (val) => {
    if (val > 0 && val <= 9) {
      return `0${val}`;
    } else {
      return val;
    }
  };

  useEffect(() => {
    getAppointments(user.id, user.role[0]).then((data) => {
      setAppointments(data);
    });
    setYearValue(new Date().getFullYear());
    setMonthValue(getMonth(new Date().getMonth() + 1));
  }, []);

  const handleDateClick = (e) => {
    const getDetails = originalData.filter(
      (item) => item.appointment_id === parseInt(e.event._def.publicId)
    );
    setAppointmentDetails(getDetails[0]);
    setOpen(true);
    setEventRef(e.el);
  };

  const handleMonthChange = (val) => {
    setMonthValue(val);
    let calendarApi = calendarRef.current.getApi();
    calendarApi.gotoDate(`${yearValue}-${val}-01`);
  };

  const handleYearChange = (val) => {
    setYearValue(val);
    let calendarApi = calendarRef.current.getApi();
    calendarApi.gotoDate(`${val}-${monthValue}-01`);
  };

  const eventContent = (arg) => {
    const itemStatusClosed = arg.event._def.extendedProps.itemStatusClosed;
    return (
      <Box
        sx={{
          p: 1,
          borderRadius: "2px",
          color: getTextColor(itemStatusClosed),
          backgroundColor: getBackgroundColor(itemStatusClosed),
          whiteSpace: "pre-wrap",
          mb: 0.5,
          fontWeight: 400,
          lineHeight: "20px",
          fontSize: 14,
          fontFamily: "Asap, sans-serif",
        }}
      >
        {arg.event.title}
      </Box>
    );
  };

  const customDayCellContent = (info) => {
    const dayNumber = info.dayNumberText;
    return (
      <Box
        sx={{ p: 1.5, pb: 0, color: darkMode ? "#FFFFFFB2" : "#475569", mb: 4 }}
      >
        {dayNumber}
      </Box>
    );
  };

  const customDayHeaderContent = (info) => {
    const dayName = info.text;
    return (
      <Box
        sx={{ p: 2, color: "text.secondary", fontSize: 16, fontWeight: 700 }}
      >
        {dayName}
      </Box>
    );
  };

  // prettier-ignore
  const getTextColor = (closed) =>
    closed ? darkMode ? "#E87063" : "#DC2626" : darkMode ? "#245EC1" : "#2563EB";

  // prettier-ignore
  const getBackgroundColor = (closed) =>
    closed ? darkMode ? "#2A1616" : "#FDECEC" : darkMode ? "#16172A" : "#EFF5FF";

  return (
    <MainLayout title="Appointments Calender" divider>
      <DetailDialog
        eventRef={eventRef}
        open={open}
        setOpen={setOpen}
        details={appointmentDetails}
      />
      <Paper sx={{ mt: 4, p: 3 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 2,
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2.5,
          }}
        >
          <CustomButtons
            monthValue={monthValue}
            yearValue={yearValue}
            calendarRef={calendarRef}
            setYearValue={setYearValue}
            setMonthValue={setMonthValue}
            getMonth={getMonth}
          />
          <Box
            width={{ xs: 1, md: "auto" }}
            sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
          >
            <SelectionWidget
              sx={{ width: { xs: 1, md: 200 }, mr: 2, mb: { xs: 2, md: 0 } }}
              label="Month"
              items={monthArray}
              value={monthValue}
              setValue={(val) => handleMonthChange(val)}
            />
            <SelectionWidget
              sx={{ width: { xs: 1, md: 140 } }}
              label="Year"
              items={yearArray}
              value={yearValue}
              setValue={(val) => handleYearChange(val)}
            />
          </Box>
        </Box>
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          headerToolbar={false}
          initialView="dayGridMonth"
          eventColor="transparent"
          eventBorderColor="transparent"
          eventClick={handleDateClick}
          eventContent={eventContent}
          dayCellContent={customDayCellContent}
          dayHeaderContent={customDayHeaderContent}
          dayMaxEventRows={2}
          events={appointments}
          contentHeight={1060}
          fixedWeekCount={false}
          dayCellClassNames={darkMode ? "darkMode" : "lightMode"}
        />
      </Paper>
    </MainLayout>
  );
};

export default Calender;
