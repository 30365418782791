import React, { useEffect, useState } from "react";
import { SelectionWidget } from "../../../../../components/widgets/select-widget";
import { Button, TextField, useMediaQuery, useTheme } from "@mui/material";
import { DatePickers } from "../../../../../components/widgets/date-time-picker";
import TextTemplate from "./text-template";
import MailTemplate from "./mail-template";
import VoiceTemplate from "./voicemail-template";
import CustomTemplate from "./custom-template";
// import { Fab } from "@mui/material";
// import SaveIcon from "@mui/icons-material/Save";
import { useDispatch, useSelector } from "react-redux";
import { updateSequenceStep } from "../../../../sequences/redux/sequence.actions";
// import BookmarksIcon from "@mui/icons-material/Bookmarks";
import VtechButton from "../../../../../components/vtech-button/VtechButton";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import { renderTimeViewClock } from "@mui/x-date-pickers";
import { showSnackbar } from "../../../../../snackbar/snackbarSlice";

const QueueForms = ({ sequenceData, setSequenceData, stepDetails }) => {
  const isMobileDevice = useMediaQuery("(max-width: 768px)");
  const items = [
    { value: "none", title: "None" },
    { value: "email", title: "Send Mail" },
    { value: "text", title: "Send Text" },
    { value: "voicemail", title: "Send Voicemail" },
    { value: "custom_task", title: "Custom Task" },
  ];
  const theme = useTheme();
  return (
    <>
      <div>
        <div>
          <TextField
            label="Step Name"
            placeholder="Step Name"
            variant="outlined"
            value={sequenceData.name}
            onChange={(e) =>
              setSequenceData({
                ...sequenceData,
                name: e.target.value,
              })
            }
          />
          {sequenceData.action === "text" && (
            <>
              <br />
              <br />
              <TextTemplate
                sequenceData={sequenceData}
                setSequenceData={setSequenceData}
              />
            </>
          )}
          {sequenceData.action === "email" && (
            <>
              <br />
              <br />
              <MailTemplate
                sequenceData={sequenceData}
                setSequenceData={setSequenceData}
              />
            </>
          )}
          {sequenceData.action === "voicemail" && (
            <>
              <br />
              <br />
              <VoiceTemplate
                sequenceData={sequenceData}
                setSequenceData={setSequenceData}
              />
            </>
          )}
          {sequenceData.action === "custom_task" && (
            <>
              <br />
              <br />
              <CustomTemplate
                sequenceData={sequenceData}
                setSequenceData={setSequenceData}
              />
            </>
          )}
          <div
            style={{
              display: isMobileDevice ? "block" : "flex",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                width: isMobileDevice ? "100%" : "33.3%",
                marginRight: "20px",
                marginBottom: isMobileDevice && "20px",
              }}
            >
              <SelectionWidget
                items={items}
                label="Operation"
                value={sequenceData.action}
                setValue={(val) =>
                  setSequenceData({
                    ...sequenceData,
                    action: val,
                    subject:
                      stepDetails.action !== "email" ? "" : stepDetails.subject,
                    emailText:
                      stepDetails.action !== "email" ? "" : stepDetails.message,
                    callback_number:
                      stepDetails.action !== "voicemail"
                        ? ""
                        : stepDetails.callback_number,
                    voicemail:
                      stepDetails.action !== "voicemail"
                        ? ""
                        : stepDetails.voicemail,
                    task_name:
                      stepDetails.action !== "custom_task"
                        ? ""
                        : stepDetails.task_name,
                    task_assignee:
                      stepDetails.action !== "custom_task"
                        ? ""
                        : stepDetails.task_assignee,
                    message:
                      stepDetails.action !== "text" ? "" : stepDetails.message,
                  })
                }
              />
            </div>
            <div
              style={{
                width: isMobileDevice ? "100%" : "33.3%",
                marginRight: "20px",
                marginBottom: isMobileDevice && "20px",
              }}
            >
              <TextField
                id="outlined-basic"
                fullWidth
                type="number"
                label="Send on day (X)..."
                placeholder="Send on day (X)..."
                variant="outlined"
                value={sequenceData.send_on_day}
                inputProps={{ min: 1 }}
                onChange={(e) =>
                  setSequenceData({
                    ...sequenceData,
                    send_on_day: e.target.value,
                  })
                }
              />
            </div>

            {/* <DatePickers
                label="Select Time"
                type="time"
                value={sequenceData.selected_time}
                fullWidth={true}
                onChange={(val) =>
                  setSequenceData({ ...sequenceData, selected_time: val })
                }
              /> */}
            <div
              style={{
                width: isMobileDevice ? "100%" : "33.3%",
              }}
            >
              <TimePicker
                sx={{ width: "100%" }}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                }}
                label="Select Time"
                value={
                  sequenceData.selected_time
                    ? moment(sequenceData.selected_time, "HH:mm")
                    : null
                }
                slotProps={{
                  popper: {
                    sx: {
                      "& .MuiDialogActions-root .MuiButton-text": {
                        color: theme.palette.text.primary,
                      },
                    },
                  },
                }}
                onChange={(val) =>
                  setSequenceData({
                    ...sequenceData,
                    selected_time: moment(val).format("HH:mm"),
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const QueueForm = ({ stepDetails, onClose, index }) => {
  const dispatch = useDispatch();
  const { currentSequence, isLoading } = useSelector((state) => state.sequences);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [sequenceData, setSequenceData] = useState({
    id: "",
    name: "",
    send_on_day: "",
    selected_time: "",
    subject: "",
    message: "",
    emailText: "",
    action: "",
    type: "other",
    sortBy: "",
    task_name: "",
    task_assignee: "",
    callback_number: "",
    voicemail: "",
    voicemail_name: "No file attached",
  });

  useEffect(() => {
    if (stepDetails) {
      setSequenceData({
        ...sequenceData,
        id: stepDetails.id && stepDetails.id,
        name: stepDetails.name === "New Step" ? "" : stepDetails.name,
        send_on_day:
          stepDetails.send_on_day !== null ? stepDetails.send_on_day : "",
        selected_time:
          stepDetails.selected_time !== null ? stepDetails.selected_time : "",
        subject: stepDetails.subject !== null ? stepDetails.subject : "",
        message: stepDetails.message !== null ? stepDetails.message : "",
        emailText:
          stepDetails.action === "email"
            ? stepDetails.message !== null
              ? stepDetails.message
              : ""
            : "",
        action: stepDetails.action !== null ? stepDetails.action : "",
        type: "other",
        sortBy: stepDetails.sortBy !== null ? stepDetails.sortBy : "",
        task_name: stepDetails.task_name !== null ? stepDetails.task_name : "",
        task_assignee:
          stepDetails.task_assignee !== null ? stepDetails.task_assignee : "",
        callback_number:
          stepDetails.callback_number !== null
            ? stepDetails.callback_number
            : "",
        voicemail:
          stepDetails.voicemail !== null && stepDetails.voicemail !== ""
            ? stepDetails.voicemail
            : "",
        voicemail_name:
          stepDetails.voicemail_name !== null &&
          stepDetails.voicemail_name !== ""
            ? stepDetails.voicemail_name
            : "No file attached",
      });
    }
  }, [stepDetails]);

  const handleSave = () => {
    let message = "";
    if (
      sequenceData.action === "" ||
      sequenceData.action === "none" ||
      sequenceData.name === "" ||
      sequenceData.send_on_day === "" ||
      sequenceData.selected_time === ""
    ) {
      if (sequenceData.name === "") {
        dispatch(showSnackbar("Please enter step name."));
      } else if (sequenceData.action === "" || sequenceData.action === "none") {
        dispatch(showSnackbar("Please select an operation."));
      } else if (sequenceData.send_on_day === "") {
        dispatch(showSnackbar("Please enter send on day."));
      } else if (sequenceData.selected_time === "") {
        dispatch(showSnackbar("Please enter time."));
      }
    } else if (sequenceData.selected_time === "Invalid date") {
      dispatch(showSnackbar("Please enter time."));
    } else if (sequenceData.action === "text" && sequenceData.message === "") {
      dispatch(showSnackbar("Please enter message."));
    } else if (
      sequenceData.action === "custom_task" &&
      (sequenceData.task_name === "" || sequenceData.task_assignee === "")
    ) {
      if (sequenceData.task_name === "" && sequenceData.task_assignee === "") {
        message = "Please enter task name and select it's assignee.";
      } else if (sequenceData.task_name === "") {
        message = "Please enter task name.";
      } else if (sequenceData.task_assignee === "") {
        message = "Please select task assignee.";
      }
      dispatch(showSnackbar(message));
    } else if (
      sequenceData.action === "email" &&
      (sequenceData.emailText === "" || sequenceData.subject === "")
    ) {
      if (sequenceData.emailText === "" && sequenceData.subject === "") {
        message = "Please enter subject and message.";
      } else if (sequenceData.subject === "") {
        message = "Please enter subject.";
      } else if (sequenceData.emailText === "") {
        message = "Please enter message.";
      }
      dispatch(showSnackbar(message));
    } else if (
      sequenceData.action === "voicemail" &&
      sequenceData.callback_number === ""
    ) {
      dispatch(showSnackbar("Please enter callback number."));
    } else {
      let data = {
        id: sequenceData.id,
        name: sequenceData.name,
        send_on_day: sequenceData.send_on_day,
        selected_time: sequenceData.selected_time,
        subject: "",
        message: "",
        emailText: "",
        action: sequenceData.action,
        type: "other",
        sortBy: sequenceData.sortBy,
        task_name: "",
        task_assignee: "",
        callback_number: "",
        voicemail: "",
        voicemail_name: "",
      };
      if (sequenceData.action === "email") {
        data = {
          ...data,
          subject: sequenceData.subject,
          message: sequenceData.emailText,
        };
      } else if (sequenceData.action === "text") {
        data = {
          ...data,
          message: sequenceData.message,
        };
      } else if (sequenceData.action === "voicemail") {
        data = {
          ...data,
          callback_number: sequenceData.callback_number,
          voicemail: sequenceData.voicemail,
          voicemail_name: sequenceData.voicemail_name,
        };
      } else {
        data = {
          ...data,
          task_name: sequenceData.task_name,
          task_assignee: sequenceData.task_assignee,
        };
      }
      dispatch(updateSequenceStep(data.id, data, currentSequence.id));
      setSaveSuccess(true);

      setTimeout(() => {
        setSaveSuccess(false);
      }, 3000);

      setTimeout(() => {
        if (onClose) {
          onClose();
        }
      }, 2000);
    }
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <QueueForms
          sequenceData={sequenceData}
          setSequenceData={setSequenceData}
          stepDetails={stepDetails}
        />
        <br />
        <VtechButton
            title={saveSuccess ? "Saved!" : "Save"}
            startIcon={
              isLoading ? (
                <img src="/icons/circle-dark.svg" />
              ) : saveSuccess ? (
                ""
              ) : (
                <img src="/icons/save.svg" />
              )
            }
            onClick={handleSave}
            color={saveSuccess ? "success" : "primary"}
            sx={{ transition: "all 0.8s ease" }}
          />
        {/* <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            startIcon={<BookmarksIcon />}
          >
            Save
          </Button> */}
      </div>
    </>
  );
};

export default QueueForm;
