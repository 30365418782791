import { Switch, styled } from "@mui/material";

const SwitchNavBar = styled(Switch)(({ theme }) => ({
  // control size of switch
  padding: 8,
  width: 68,
  height: 42,
  // background of switch when in light mode
  "& .Mui-checked + .MuiSwitch-track": {
    background:
      "linear-gradient(100.87deg, #D4D7E2 2.5%, #E5E6E900 158.05%) !important",
    opacity: "1 !important",
  },
  // background of switch when in dark mode
  "& .MuiSwitch-track": {
    borderRadius: 30 / 2,
    background: "#1f1f1f",
    opacity: 1,
    // sun and moon icons y axis position
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    //  sun icon and its x axis position when in light mode
    "&:before": {
      backgroundImage: `url('/icons/switch-sun.svg')`,
      left: 12,
    },
    // moon icon and its x axis position when in dark mode
    "&:after": {
      backgroundImage: `url('/icons/switch-moon.svg')`,
      backgroundRepeat: "no-repeat",
      right: 10,
      top: 22,
    },
  },
  // blue circle on switch in the middle
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    width: 19,
    height: 19,
    margin: 2,
    transform: "translateX(1px)",
  },
  // blue circle position
  "& .Mui-checked": {
    transform: "translateX(25px) !important",
  },
}));

export default SwitchNavBar;
