import React from "react";
import MainLayout from "../../components/MainLayout/MainLayout";
import { Box, useMediaQuery } from "@mui/material";
import Inboxes from "./inboxes";
import Filters from "./filters";
import ChatBox from "./chat-box";
import ContactInfo from "./contact-info";
import CallModal, { callModalRef } from "./call-modal";

function Chat() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const modalRef = React.useRef<callModalRef>(null);

  return (
    <MainLayout title="Chat Hub" subTitle="Sellers and Agents Network">
      {/* <Box
        sx={{
          borderTop: 1,
          borderColor: "divider",
          display: "flex",
          // height: "calc(100vh - 96px - 24px)",
          // overflow: "hidden",
        }}
        mt={1}
        ml={isMobile ? 0 : -6}
        mr={isMobile ? 0 : -4}
      >
        <Inboxes />
        <Filters />
        <ChatBox handleOpen={() => modalRef.current?.openModal()} />
        <ContactInfo />
      </Box>
      <CallModal ref={modalRef} /> */}
      {""}
    </MainLayout>
  );
}

export default Chat;
