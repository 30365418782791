import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  opportunity: [],
  offers: [],
  isLoading: false,
  error: "",
  isSellerLoading: false,
  sellers: [],
  isEdit: false,
  sellerSnackbar: false,
  propertySnackbar: false,
  offerSnackbar: false,
  sequenceCancelSnackbar: false,
};

const opportunityData = createSlice({
  name: "opportunity",
  initialState,
  reducers: {
    fetchOpportunitiesLoading: (state) => {
      state.isLoading = true;
    },
    stopOpportunitiesLoading: (state) => {
      state.isLoading = false;
    },
    fetchOpportunitiesSuccess: (state, action) => {
      state.isLoading = false;
      state.opportunity = action.payload;
    },
    fetchOpportunitiesFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setIsSellerLoading: (state) => {
      state.isSellerLoading = true;
    },
    stopSellerLoading: (state) => {
      state.isSellerLoading = false;
      state.error = "";
    },
    sellersFetched: (state, { payload }) => {
      state.isSellerLoading = false;
      state.sellers = payload;
    },
    handleSellerSnack: (state) => {
      state.sellerSnackbar = !state.sellerSnackbar;
    },
    setIsEdit: (state, { payload }) => {
      state.isEdit = payload;
    },
    handlePropertySnackBar: (state) => {
      state.isLoading = false;
      state.propertySnackbar = !state.propertySnackbar;
    },
    handleOfferSnackBar: (state) => {
      state.offerSnackbar = !state.offerSnackbar;
    },
    resetOpportunitiesDetails: (state) => {
      state.opportunity = [];
    },
    updateOpportunityOffers: (state, { payload }) => {
      state.offers = payload;
    },
    handleCancelSnack: (state) => {
      state.sequenceCancelSnackbar = !state.sequenceCancelSnackbar;
    },
  },
});

export const {
  fetchOpportunitiesLoading,
  stopOpportunitiesLoading,
  fetchOpportunitiesSuccess,
  fetchOpportunitiesFail,
  setIsSellerLoading,
  sellersFetched,
  stopSellerLoading,
  handleSellerSnack,
  setIsEdit,
  handlePropertySnackBar,
  handleOfferSnackBar,
  resetOpportunitiesDetails,
  updateOpportunityOffers,
  handleCancelSnack,
} = opportunityData.actions;
export default opportunityData.reducer;
