import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CircleIcon from "@mui/icons-material/Circle";
import VtechIcons from "../../components/icons/VtechIcons";
import { formatPhone } from "../../utils/global.utils";
import { useDarkMode } from "../../utils/useDarkMode";

export const DetailDialog = ({ eventRef, open, setOpen, details }) => {
  const navigate = useNavigate();
  const darkMode = useDarkMode();
  // const address = `${details.seller_mailing_street_address} ${details.seller_mailing_city} ${details.seller_mailing_state}`;
  const address = `${details.street_address}`;
  const pending = details.status === "pending";

  const handleClose = () => {
    setOpen(false);
  };

  const handleRoute = () => {
    setOpen(false);
    navigate(`/opportunity/${details.opportunity_id}`);
  };

  const getLeft = () => {
    const left = eventRef?.getBoundingClientRect().left; // this is the left side of the event
    const width = window.innerWidth; // this is the width of the screen

    // if the dialog is going to be off the screen to the right side, move it to the left a little
    if (left + 356 > width) {
      return width - 220;
    }
    return left + 60;
  };

  const getTop = () => {
    const top = eventRef?.getBoundingClientRect().top; // this is the top of the event
    const height = window.innerHeight; // this is the height of the screen

    // if the dialog is going to be off the screen at the top, move it down
    if (top < 100) {
      return 130;
    }
    // if the dialog is going to be off the screen at the bottom, move it up
    if (top + 356 > height) {
      return height - 195;
    }
    return top + 100;
  };

  return (
    <>
      {details && (
        <Dialog
          open={open}
          onClose={handleClose}
          sx={{ "& .MuiDialog-paper": { minWidth: 356 } }}
          slotProps={{ backdrop: { sx: { backgroundColor: "transparent" } } }}
          PaperProps={{
            sx: {
              left: getLeft(),
              top: getTop(),
              position: "absolute",
              transform: "translate(-50%, -50%)",
            },
          }}
        >
          <DialogContent>
            <IconButton
              onClick={handleClose}
              sx={{ position: "absolute", right: 12, top: 16 }}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 500,
                display: "flex",
                gap: 1,
                alignItems: "center",
              }}
            >
              <CircleIcon
                style={{ color: pending ? "#2F74EB" : "red", fontSize: 11 }}
              />
              Seller Appointment
            </Typography>
            {details.due_date_time && (
              <Typography fontSize={12} fontWeight={400}>
                {moment(details.due_date_time)
                  .local()
                  .format("dddd, MMMM Do YYYY, hh:mm A")}
              </Typography>
            )}
          </DialogContent>
          <DialogContent
            sx={{
              "& .MuiTypography-root": {
                fontSize: 12,
                fontWeight: 400,
                display: "flex",
                gap: 1.5,
                alignItems: "center",
                mb: 1,
              },
            }}
          >
            <Typography>
              <VtechIcons icon="tick-square" />
              Assigned to {details.assignee_email}
            </Typography>
            <Typography>
              <VtechIcons icon="location-outlined" />
              {address}
            </Typography>
            <Typography>
              <VtechIcons icon="user-tag" />
              Name: {details.seller_name}
            </Typography>
            <Typography>
              <VtechIcons icon="sms" />
              {details.seller_email}
            </Typography>
            <Typography>
              <VtechIcons icon="call" />
              {formatPhone(details?.seller_phone?.substring(1))}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleRoute}
              sx={{ mr: 1, mb: 1, color: darkMode ? "#fff" : "primary" }}
            >
              View Details
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
