import { http } from "../apiClient/axiosInterceptor";

export const getAllSellers = () => {
  return http.get(`/seller/get?type=owner`);
};

export const editSeller = (id, data) => {
  return http.post(`/seller/update/${id}`, data);
};

export const createSeller = (data) => {
  return http.post(`/seller/create/assign/opportunity`, data);
};

export const assignSeller = (opId, sellerId) => {
  return http.post(`/seller/assign/opportunity`, {
    opportunity_id: opId,
    seller_id: sellerId,
  });
};

export const postSeller = (data) => {
  return http.post(`/seller/create`, data);
};
