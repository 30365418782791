import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  actions: [],
  opportunities: [],
  isLoading: false,
  error: "",
  total: 0,
  limit: 10,
  appliedFilters: '',
  searchFilters: [],
  myActions: false,
};

const actionsListSlice = createSlice({
  name: "actionsList",
  initialState,
  reducers: {
    fetchActionsLoading: (state) => {
      state.isLoading = true;
    },
    fetchActionsSuccess: (state, action) => {
      state.isLoading = false;
      state.actions = action.payload;
    },
    fetchActionsFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setPagination: (state, action) => {
      state.total = action.payload.total;
      state.limit = action.payload.limit;
    },
    setOpportunities: (state, { payload }) => {
      state.opportunities = payload
    },
    setAppliedFilters: (state, { payload }) => {
      state.appliedFilters = payload
    },
    setSearchFilters: (state, { payload }) => {
      state.searchFilters = payload
    },
    setMyActions: (state, { payload }) => {
      state.myActions = payload
    },
  },
});

export const {
  fetchActionsLoading,
  fetchActionsSuccess,
  fetchActionsFail,
  setPagination,
  setOpportunities,
  setAppliedFilters,
  setSearchFilters,
  setMyActions,
} = actionsListSlice.actions;
export default actionsListSlice.reducer;
