import { Box, Button, TextField, Typography, Alert } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogin } from "../../../services/userApi";
import { userLoginValidation } from "../../../utils/validations.utils";
import ProgressBar from "../../widgets/progress-bar/circular.progress";
import {
  loginFail,
  loginPending,
  loginSuccess,
  resetResetState,
} from "./loginSlice";
import { LIGHT_MODE_TEXT_COLOR_2, PRIMARY_MAIN } from "../../../theme";
import LoggedOutLayout from "../LoggedOutLayout";

export const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, error, successMsg } = useSelector((state) => state.login);

  const loginUser = (values) => {
    dispatch(loginPending());
    userLogin({ user_name: values.email, password: values.password })
      .then((res) => {
        if (res.data) {
          dispatch(loginSuccess(res.data.data));
          localStorage.setItem("userToken", res.data.data.token);
          navigate("/dashboard");
        }
      })
      .catch((error) => {
        dispatch(
          loginFail(error.message ? error.message : error?.messages[0].message)
        );
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: userLoginValidation,
    onSubmit: (values) => {
      loginUser(values);
    },
  });

  useEffect(() => {
    dispatch(resetResetState());
  }, []);

  useEffect(() => {
    if (navigate) {
      if (localStorage.getItem("userToken")) {
        navigate("/dashboard");
      }
    }
  }, [navigate]);

  return (
    <LoggedOutLayout>
      <Box
        component="main"
        sx={{ mt: "182px", width: 1, maxWidth: "446px", mx: "auto" }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontWeight: "bold", fontSize: 40, mb: 1 }}
        >
          Sign in
        </Typography>
        <Typography
          sx={{
            color: LIGHT_MODE_TEXT_COLOR_2,
            mb: 2,
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          Please sign in to your account
        </Typography>

        {(error !== "" || successMsg !== "") && (
          <Alert severity={error ? "error" : "success"}>
            {error ? error : successMsg}
          </Alert>
        )}
        <form noValidate onSubmit={formik.handleSubmit}>
          <TextField
            autoComplete="new-password"
            sx={{ mt: 2 }}
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoFocus
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            autoComplete="new-password"
            sx={{ mt: 2 }}
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Typography
              sx={{
                color: PRIMARY_MAIN,
                cursor: "pointer",
                fontWeight: 500,
                fontSize: 14,
                my: 2,
              }}
              onClick={() => navigate("/forgot-password")}
            >
              Forgot your password?
            </Typography>
          </Box>
          <Button
            sx={{ mt: "6px", height: "47px" }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isLoading}
            startIcon={isLoading && <ProgressBar />}
          >
            Sign in
          </Button>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              fontWeight: 500,
              fontSize: 14,
              mt: 2,
            }}
          >
            Don't have an account?
            <Typography
              component="span"
              sx={{
                color: PRIMARY_MAIN,
                ml: "3px",
                cursor: "pointer",
                fontWeight: 500,
                fontSize: 14,
              }}
              onClick={() => navigate("/signup")}
            >
              Sign up here
            </Typography>
          </Typography>
        </form>
      </Box>
    </LoggedOutLayout>
  );
};

export default LoginForm;
