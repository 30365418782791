import { getAllActionsOwners } from "../../../../services/assignment.services";
import {
  editCampaignDetails,
  getSingleCampaign,
} from "../../../../services/campaign.services";
import { showSnackbar } from "../../../../snackbar/snackbarSlice";
import {
  campaignFail,
  campaignLoading,
  fetchActions,
  fetchCampaignSuccess,
  stopLoading,
} from "./update-campaign.slice";

export const getCampaignById = (id) => (dispatch) => {
  dispatch(campaignLoading());
  getSingleCampaign(id)
    .then((res) => {
      dispatch(fetchCampaignSuccess(res.data.data));
    })
    .catch((err) => {
      dispatch(campaignFail(err.messages[0].message));
      window.location = "/campaigns";
    });
};

export const updateCampaignDetails = (id, data) => (dispatch) => {
  dispatch(campaignLoading());
  editCampaignDetails(id, data)
    .then((res) => {
      dispatch(getCampaignById(id));
      dispatch(stopLoading());
      dispatch(showSnackbar("Campaign updated Successfully!"));
    })
    .catch((err) => {
      dispatch(campaignFail(err.messages[0].message));
    });
};

export const getCampaignActions = (id) => (dispatch) => {
  getAllActionsOwners(id).then((res) => {
    dispatch(fetchActions(res.data.data));
  });
};
