import { Box, MenuItem, TextField } from "@mui/material";
import React, { useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import RichTextEditor from "react-rte";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import { Editor } from "react-draft-wysiwyg";
import { useDarkMode } from "../../../../../../utils/useDarkMode";

const EmailResponse = ({
  setText,
  textError,
  subjectErr,
  subject,
  setSubject,
  handleMenuItemClick,
}) => {
  const darkMode = useDarkMode();
  const [editorState, setEditorState] = useState();
  // RichTextEditor.createEmptyValue()
  const toolbarCofig = {
    display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "LINK_BUTTONS"],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };
  const onEditorStateChange = (value) => {
    if (typeof value !== "string") {
      setEditorState(value);
      setText(value.getCurrentContent().getPlainText());
    } else {
      // let newVal = RichTextEditor.createValueFromString(
      //   "<p>{{" + value + "}}</p>",
      //   "html"
      // );
      // newVal = editorState.toString("html") + newVal.toString("html");
      // newVal = RichTextEditor.createValueFromString(newVal, "html");
      // setEditorState(newVal);
      // setText(value.getCurrentContent().getPlainText());
    }
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <TextField
          id="outlined-select-currency"
          label="Subject"
          value={subject}
          style={{ width: "80%" }}
          onChange={(e) => setSubject(e.target.value)}
          variant="outlined"
          error={subjectErr}
          helperText={subjectErr && "Enter subject."}
        />
        <div style={{ marginTop: "5px" }}>
          <IconButton
            aria-label="show 4 new mails"
            color="inherit"
            onClick={() => handleMenuItemClick(0)}
          >
            <ClearIcon />
          </IconButton>
        </div>
      </div>
      <h3>Email Body</h3>
      <Box sx={{ border: 1, borderColor: "grey.500", borderRadius: 1, p: 1 }}>
        <Editor
          editorState={editorState}
          toolbarClassName={darkMode ? "darkToolbar" : ""}
          onEditorStateChange={onEditorStateChange}
        />
      </Box>
      {textError && (
        <div style={{ color: "red" }}>
          <p>Please type email.</p>
        </div>
      )}
    </>
  );
};

export default EmailResponse;
