import { Button, Fab, IconButton, TextField } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getOpportunity } from "../../../pages/opportunity-details/opportunity.action";
import { postNoteToOpportunity } from "../../../services/opportunity-details.service";
import EditButton from "../button/button.widget";
import { MaterialCard } from "../mat-card";
import ProgressBar from "../progress-bar/index";
import style from "./note.module.css";

export const NoteBar = ({ setOpenNotes }) => {
  const [open, setOpen] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams();
  const { opportunity } = useSelector((state) => state.opportunityDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    if (opportunity) {
      setNoteText(opportunity.notes);
    }
  }, [opportunity]);

  const handleSubmission = () => {
    setLoading(true);
    postNoteToOpportunity(id, noteText).then((res) => {
      setLoading(false);
      dispatch(getOpportunity(id));
      setEditMode(false);
    });
  };

  return (
    <>
      <Fab
        onClick={() => setOpenNotes(true)}
        color="primary"
        sx={{
          position: "fixed",
          bottom: "75px",
          right: "20px",
          height: "54px",
          width: "134px",
          borderRadius: "250px",
          fontSize: "20px",
          fontWeight: 400,
        }}
      >
        Notes
        <img style={{ marginLeft: "6px" }} src="/icons/edit-dark.svg" />
      </Fab>

      {/* <div className={style.noteBarHeader}>
        <div className={style.noteContent} onClick={() => setOpen(!open)}>
          <span className={style.textNote}>
            {open ? (
              <IconButton style={{ color: "white" }}>
                <ArrowForwardIosIcon />
              </IconButton>
            ) : (
              <IconButton style={{ color: "white" }}>
                <ArrowBackIosIcon />
              </IconButton>
            )}
            Notes
            {open ? (
              <IconButton style={{ color: "white" }}>
                <ArrowForwardIosIcon />
              </IconButton>
            ) : (
              <IconButton style={{ color: "white" }}>
                <ArrowBackIosIcon />
              </IconButton>
            )}
          </span>
        </div>
        <div
          className={style.noteBody}
          style={{ width: open ? "400px" : "0px" }}
        >
          {isLoading && <ProgressBar />}
          <MaterialCard
            title="Note"
            headerStyles={{ background: "#eee" }}
            margin="0px"
            contentPadding="0px"
          >
            <div className={style.noteBarText}>
              {!editMode ? (
                <>
                  <p className={style.preNote}>{opportunity.notes}</p>
                  <EditButton
                    title="Edit Note"
                    styles={{ marginTop: "50px", marginBottom: "20px" }}
                    onClick={() => setEditMode(true)}
                  />
                </>
              ) : (
                <>
                  <TextField
                    id="outlined-multiline-static"
                    label="Enter your note"
                    multiline
                    rows={5}
                    variant="outlined"
                    value={noteText}
                    style={{ marginTop: "20px" }}
                    onChange={(e) => setNoteText(e.target.value)}
                  />
                  <div
                    style={{
                      display: "flex",
                      marginTop: "40px",
                      marginBottom: "20px",
                    }}
                  >
                    <EditButton title="Done" onClick={handleSubmission} />
                    <Button
                      style={{
                        marginLeft: "10px",
                        width: "fit-content",
                        fontSize: "12px",
                      }}
                      onClick={() => setEditMode(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              )}
            </div>
          </MaterialCard>
        </div>
      </div> */}
    </>
  );
};
