import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchAllActions } from "../../../pages/actions/actionsList.Action";
import { getOpportunity } from "../../../pages/opportunity-details/opportunity.action";
import { updateStatus } from "../../../services/tasks.services";
import { ResponsiveChangeStatusDialog } from "./edit-modal/change-status-modal";
import { ResponsiveEditDialog } from "./edit-modal/edit-action";
import { ResponsiveEditTask } from "./edit-modal/edit-task";
import ViewNotes from "./edit-modal/menuModel/notes";

export const EditStatusModals = ({
  id,
  viewNotes,
  notesValue,
  fromPage,
  type,
  taskId,
  taskData,
  assignId,
}: any) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const [openEditModal, setEditModal] = useState(false);
  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);
  const [openEditTask, setOpenEditTask] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (fromPage === "actions" || fromPage === "listing") {
      dispatch(getOpportunity(id, false)); // false for just getting opportunity and not logs
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value) => {
    if (value > 0) {
      if (value === 2) {
        setEditModal(true);
      } else if (value === 1) {
        setOpenChangeStatus(true);
      } else if (value === 3) {
        setOpenNotes(true);
      } else if (value === 4) {
        setOpenEditTask(true);
      }
      setAnchorEl(null);
    } else {
      setAnchorEl(null);
    }
  };

  const handleToggle = () => {
    const data = {
      opportunity_id: id,
      id: taskId,
      status: "completed",
    };

    updateStatus(data).then(() => {
      dispatch(fetchAllActions());
    });
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {type === "task" ? (
          <>
            <MenuItem onClick={() => handleClose(4)}>Edit Task</MenuItem>
            {viewNotes && (
              <MenuItem onClick={() => handleClose(3)}>Edit Notes</MenuItem>
            )}
            <MenuItem onClick={() => handleToggle()}>Complete Task</MenuItem>
          </>
        ) : (
          <>
            <MenuItem onClick={() => handleClose(1)}>Change Status</MenuItem>
            <MenuItem onClick={() => handleClose(2)}>Edit Operation</MenuItem>
            {viewNotes && (
              <MenuItem onClick={() => handleClose(3)}>Edit Notes</MenuItem>
            )}
          </>
        )}
      </Menu>
      <ResponsiveEditDialog
        open={openEditModal}
        setOpen={setEditModal}
        id={id && id}
        fromPage={fromPage}
      />
      <ResponsiveChangeStatusDialog
        id={id && id}
        open={openChangeStatus}
        setOpen={setOpenChangeStatus}
      />
      <ViewNotes
        id={id && id}
        notesValue={notesValue}
        setOpenNotes={setOpenNotes}
        openNotes={openNotes}
      />
      <ResponsiveEditTask
        taskData={taskData}
        taskId={taskId}
        id={id}
        assignId={assignId}
        open={openEditTask}
        setOpen={setOpenEditTask}
      />
    </>
  );
};
