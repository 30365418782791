import { TextField } from "@mui/material";
import React from "react";
import MenuSelector from "../../menu-selection";

const TextTemplate = ({ sequenceData, setSequenceData }) => {
  return (
    <>
      <div style={{ display: "flex", alignItems: 'center' }}>
        <TextField
          id="outlined-multiline-static"
          label="Message"
          placeholder="Message"
          multiline
          rows={4}
          variant="outlined"
          value={sequenceData.message}
          onChange={(e) =>
            setSequenceData({
              ...sequenceData,
              message: e.target.value,
            })
          }
          fullWidth
        />
        <MenuSelector
          onChange={(val) =>
            setSequenceData({
              ...sequenceData,
              message: sequenceData.message + `$${val}`,
            })
          }
        />
      </div>
    </>
  );
};

export default TextTemplate;
