import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { useDispatch, useSelector } from "react-redux";
import { setOpportunityFilters } from "../opportunitiesComponents/oppo-component.slice";

function FiltersChips() {
  const dispatch = useDispatch();
  // prettier-ignore
  const { oppoFilters } = useSelector((state: any) => state.opportunitiesFilters);
  const { users } = useSelector((state: any) => state.users);
  const { campaigns } = useSelector((state: any) => state.campaigns);
  const { labels } = useSelector((state: any) => state.opportunities);
  // prettier-ignore
  const filters = [
      ...campaigns.filter((campaign) => oppoFilters.campaignIds.includes(campaign.id)).map((campaign) => campaign.title),
      ...labels.filter((label) => oppoFilters.labelIds.includes(label.id)).map((label) => label.label),
      ...users.filter((user) => oppoFilters.ownerIds.includes(user.id)).map((user) => user.email),
      ...oppoFilters.statusIds,
      ...oppoFilters.motivationValues,
      oppoFilters.selectedDateFrom && "From: " + oppoFilters.selectedDateFrom,
      oppoFilters.selectedDateTo && "To: " + oppoFilters.selectedDateTo,
    ].filter(Boolean);

  const handleDelete = (filter) => {
    if (filter.includes("From: ") || filter.includes("To: "))
      filter = filter.split(": ")[1];
    // prettier-ignore
    dispatch(
      setOpportunityFilters({
        ...oppoFilters,
        campaignIds:      oppoFilters.campaignIds.filter((campaign) => campaign !== campaigns.find((c) => c.title === filter)?.id),
        labelIds:         oppoFilters.labelIds.filter((label) => label !== labels.find((l) => l.label === filter)?.id),
        ownerIds:         oppoFilters.ownerIds.filter((owner) => owner !== users.find((u) => u.email === filter)?.id),
        statusIds:        oppoFilters.statusIds.filter((status) => status !== filter),
        motivationValues: oppoFilters.motivationValues.filter((motivation) => motivation !== filter),
        selectedDateFrom: oppoFilters.selectedDateFrom === filter ? "" : oppoFilters.selectedDateFrom,
        selectedDateTo:   oppoFilters.selectedDateTo === filter ? "" : oppoFilters.selectedDateTo,
      })
    );
  };

  return (
    <Box
      sx={{ display: "flex", justifyContent: "end", gap: 1, my: 2 }}
      flexWrap={"wrap"}
    >
      {filters.map((f) => (
        <Chip
          label={f}
          onDelete={() => handleDelete(f)}
          size="small"
          color="primary"
        />
      ))}
    </Box>
  );
}

export default FiltersChips;
