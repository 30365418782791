import { Box, FormControl, TextField } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllActions } from "../../../../../pages/actions/actionsList.Action";
import { fetchAllOpportunities } from "../../../../../pages/opportunities/opportunitiesListAction";
import { getOpportunity } from "../../../../../pages/opportunity-details/opportunity.action";
import { updateAction } from "../../../../../services/opportunity-details.service";
import { UserSelection } from "./user-selection";
import VtechButton from "../../../../vtech-button/VtechButton";
import { DateTimePicker } from "@mui/x-date-pickers";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { showSnackbar } from "../../../../../snackbar/snackbarSlice";

export const EditActionForm = ({
  setLoading,
  handleClose,
  loading,
  id,
  fromPage,
}) => {
  const [assignee, setAssignee] = useState({ id: 0, first_name: "" });
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [instruction, setInstruction] = useState("");

  const { limit } = useSelector((state) => state.opportunities);
  const { opportunity } = useSelector((state) => state.opportunityDetails);
  const dispatch = useDispatch();
  const date = opportunity?.action?.due_date_time;

  useEffect(() => {
    if (Object.keys(opportunity).length > 0) {
      let date = moment(opportunity.action.due_date_time)
        .local()
        .format("YYYY-MM-DDTHH:mm:ss");
      date = date.split("T");
      setAssignee(opportunity.assignee);
      setInstruction(opportunity.action.instructions);
      setSelectedDate(date[0]);
      setSelectedTime(date[1]);
    }
  }, [opportunity, id]);

  const handleSubmission = () => {
    if (assignee?.id > 0 && selectedDate !== "" && selectedTime !== "") {
      const data = {
        id: opportunity?.action.id,
        assign_to: assignee?.id,
        instructions: instruction,
        due_date_time: moment(selectedDate + "T" + selectedTime)
          .utc()
          .format("YYYY-MM-DD HH:mm"),
      };
      setLoading(true);
      updateAction(data).then((res) => {
        setLoading(false);
        dispatch(showSnackbar("Operation updated successfully!"));
        if (id) {
          if (fromPage === "actions") {
            dispatch(fetchAllActions());
          } else {
            dispatch(fetchAllOpportunities(1, limit, ""));
          }
        } else {
          dispatch(getOpportunity(opportunity.id));
        }
        handleClose();
      });
    } else {
      if (assignee.id === 0) {
        dispatch(showSnackbar("Please select owner."));
      } else {
        dispatch(showSnackbar("Please select Due Date and Time"));
      }
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", gap: 2 }}>
        <UserSelection
          onSelection={(value) => setAssignee(value)}
          opportunityOwner={id ? assignee : opportunity?.assignee}
        />
        <DateTimePicker
          slots={{ openPickerIcon: ArrowDropDownIcon }}
          slotProps={{
            tabs: {
              hidden: false,
              dateIcon: <CalendarMonthIcon />,
              timeIcon: <ScheduleIcon />,
            },
          }}
          label="Change Due Date and Time"
          sx={{ width: 1 }}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          value={date ? moment(date) : null}
          onChange={(value) => {
            setSelectedDate(moment(value).format("YYYY-MM-DD"));
            setSelectedTime(moment(value).format("HH:mm"));
          }}
        />
      </Box>
      {/* <Box mt={1} display={"flex"} justifyContent={"space-between"}>
        <DatePickers
          type="date"
          label="Change Due Date"
          value={selectedDate}
          onChange={(value) => setSelectedDate(value)}
          min={true}
        />
        <DatePickers
          type="time"
          label="Change Due Time"
          value={selectedTime}
          onChange={(value) => setSelectedTime(value)}
          min={true}
        />
        <TimeMenu onChange={(value) => setSelectedTime(value)} />
      </Box> */}

      <FormControl fullWidth sx={{ mt: 2.5 }}>
        <TextField
          label="Change Instruction"
          value={instruction}
          onChange={(e) => setInstruction(e.target.value)}
          multiline
          rows={4}
        />
      </FormControl>
      {/* <SubmitButton
          title="Update Action"
          styles={{ marginTop: "30px", marginBottom: "20px" }}
          onClick={handleSubmission}
        /> */}
      <VtechButton
        title="Update Operation"
        onClick={handleSubmission}
        disabled={loading}
        sx={{ my: 4 }}
      />
    </>
  );
};
