import { IconButton, useMediaQuery } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { setMenuState } from "../../shared_slice/menu-handler.slice";
import MenuIcon from "@mui/icons-material/Menu";

function BurgerMenu() {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setMenuState(true));
  };
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <IconButton
      onClick={handleClick}
      sx={{
        position: "absolute",
        top: 25,
        left: isMobile ? 0 : 40,
        zIndex: 10,
      }}
    >
      <MenuIcon />
    </IconButton>
  );
}

export default BurgerMenu;
