import React, { useEffect, useState } from "react";
import { checkDateTime } from "../../../../utils/global.utils";
// import style from "./pipline.module.css";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { PRIMARY_MAIN } from "../../../../theme";
import {
  lastRectangleStyle,
  midrectangleStyle,
  rectangleStyle,
} from "../../../opportunities/opportunitiesComponents/stepper/piplineStyles";
import { useDarkMode } from "../../../../utils/useDarkMode";

const Pipeline = ({ actions, currentFilter, setCurrentFilter, topFive }) => {
  const [dues, setDues] = useState({ overDue: 0, dueToday: 0, dueSchedule: 0 });
  const [topActions, setTopActions] = useState([]);
  const [activeIndex, setActiveIndex] = useState(undefined);
  const darkMode = useDarkMode();

  useEffect(() => {
    const overDue = actions.filter((item) =>
      checkDateTime(item.due_date_time, "due")
    ).length;
    const dueToday = actions.filter((item) =>
      checkDateTime(item.due_date_time, "today")
    ).length;
    const dueSchedule = actions.filter((item) =>
      checkDateTime(item.due_date_time, "future")
    ).length;
    setDues({
      ...dues,
      overDue: overDue,
      dueToday: dueToday,
      dueSchedule: dueSchedule,
    });
  }, [actions]);

  useEffect(() => {
    if (topFive) {
      const act = topFive.map((item) =>
        item.name === "tasks" ? "task" : item.name
      );
      setTopActions(act);
    }
  }, [topFive]);

  useEffect(() => {
    if (currentFilter) {
      if (topActions.indexOf(currentFilter) > -1) {
        setActiveIndex(undefined);
      }
    } else if (currentFilter === "") {
      setActiveIndex(undefined);
    }
  }, [currentFilter]);

  useEffect(() => {
    if (localStorage.getItem("pipeLineFilters")) {
      setActiveIndex(parseInt(localStorage.getItem("pipeLineIndex")));
    }
  }, []);

  const handleActive = (method, index) => {
    localStorage.setItem("pipeLineFilters", JSON.stringify(method));
    localStorage.setItem("pipeLineIndex", index);
    localStorage.removeItem("actionEvents");
    localStorage.removeItem("actionIndex");
    setCurrentFilter(method);
    setActiveIndex(index);
  };

  const getBgColor = (i) => {
    return activeIndex === i ? PRIMARY_MAIN : darkMode ? "#2E2E2E" : "#E9EFF9";
  };

  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        gap: 1,
        flexDirection: isSmallScreen ? "column" : "row",
      }}
    >
      <Box
        sx={{
          ...rectangleStyle,
          cursor: "pointer",
          backgroundColor: getBgColor(1),
          color: activeIndex === 1 ? "white" : "unset",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1.5,
          height: 64,
          opacity: activeIndex && activeIndex !== 1 && 0.4,
        }}
        onClick={() => handleActive("due", 1)}
      >
        <Typography
          sx={{
            bgcolor: "#E87063",
            borderRadius: "50%",
            width: 21,
            height: 21,
            color: "white",
            fontSize: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {dues.overDue}
        </Typography>
        <Typography>Overdue</Typography>
      </Box>

      <Box
        sx={{
          ...midrectangleStyle,
          cursor: "pointer",
          backgroundColor: getBgColor(2),
          color: activeIndex === 2 ? "white" : "unset",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1.5,
          height: 64,
          opacity: activeIndex && activeIndex !== 2 && 0.4,
        }}
        onClick={() => handleActive("dueToday", 2)}
      >
        <Typography
          sx={{
            bgcolor: "#289374",
            borderRadius: "50%",
            width: 21,
            height: 21,
            fontSize: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
          }}
        >
          {dues.dueToday}
        </Typography>
        <Typography>Due Today</Typography>
      </Box>

      <Box
        sx={{
          ...lastRectangleStyle,
          cursor: "pointer",
          backgroundColor: getBgColor(3),
          color: activeIndex === 3 ? "white" : "unset",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1.5,
          height: 64,
          opacity: activeIndex && activeIndex !== 3 && 0.4,
        }}
        onClick={() => handleActive("dueSchedule", 3)}
      >
        <Typography
          sx={{
            bgcolor: "#8C919B",
            borderRadius: "50%",
            width: 21,
            height: 21,
            fontSize: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
          }}
        >
          {dues.dueSchedule}
        </Typography>
        <Typography>Scheduled</Typography>
      </Box>
    </Box>
  );
};

export default Pipeline;
