import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  error: "",
  notifications: [],
  isSuccess: false,
};

const userSettingsData = createSlice({
  name: "userSetting",
  initialState,
  reducers: {
    setIsLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
      state.error = "";
      state.isSuccess = true;
    },
    errorInFetch: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    notificationsFetched: (state, { payload }) => {
      state.isLoading = false;
      state.notifications = payload;
    },
  },
});

export const { setIsLoading, notificationsFetched, stopLoading, errorInFetch } =
  userSettingsData.actions;
export default userSettingsData.reducer;
