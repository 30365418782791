import { Box, TextField, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import RichTextEditor from "react-rte";
import MenuSelector from "../../menu-selection";
// import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  // convertToRaw,
  // convertFromRaw,
  EditorState,
  Modifier,
  convertFromHTML,
} from "draft-js";
// import { useSelector } from "react-redux";
import { useDarkMode } from "../../../../../utils/useDarkMode";
import ReactDraftEditor from "../../../../../components/editor/react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";

const MailTemplate = ({ sequenceData, setSequenceData }) => {
  // const theme = useTheme();
  const darkMode = useDarkMode();
  const [editorKey, setEditorKey] = useState(0);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (sequenceData && sequenceData.emailText !== null) {
      try {
        const raw = convertFromHTML(sequenceData.emailText);
        const state = ContentState.createFromBlockArray(
          raw.contentBlocks,
          raw.entityMap
        );
        const newEditorState = EditorState.createWithContent(state);
        // const parsedContentState = JSON.parse(sequenceData.emailText);
        // const newContentState = convertFromRaw(parsedContentState);
        // const newEditorState = EditorState.createWithContent(newContentState);
        setEditorState(newEditorState);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  }, []);

  useEffect(() => {
    if (editorState) {
      const contentState = editorState.getCurrentContent();
      const html = stateToHTML(contentState);
      // const rawContentState = convertToRaw(contentState);
      setSequenceData({
        ...sequenceData,
        emailText: html,
      });
    }
  }, [editorState]);

  const onEditorStateChange = (value) => {
    if (typeof value !== "string") {
      setEditorState(value);
    } else {
      const textToInsert = `$${value}`;
      const currentContent = editorState.getCurrentContent();
      const currentSelection = editorState.getSelection();
      const newContent = Modifier.insertText(
        currentContent,
        currentSelection,
        textToInsert
      );

      const newEditorState = EditorState.push(
        editorState,
        newContent,
        "insert-characters"
      );
      setEditorState(newEditorState);
      setEditorKey(editorKey + 1);
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <TextField
          id="outlined-select-currency"
          label="Subject"
          value={sequenceData.subject}
          style={{ width: "80%" }}
          onChange={(e) =>
            setSequenceData({ ...sequenceData, subject: e.target.value })
          }
          variant="outlined"
        />
        <MenuSelector
          onChange={(value) =>
            setSequenceData({
              ...sequenceData,
              subject: sequenceData.subject + "$" + value,
            })
          }
        />
      </div>
      <p style={{ fontSize: "15px" }}>Create Email Body</p>
      <ReactDraftEditor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        key={editorKey}
        darkMode={darkMode}
      />
      {/* <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            border: 1,
            borderColor: "grey.500",
            borderRadius: 1,
            p: 1,
            cursor: "text",
          }}
        >
          <Editor
            editorState={editorState}
            toolbarClassName={darkMode ? "darkToolbar" : ""}
            onEditorStateChange={onEditorStateChange}
            key={editorKey}
          />
        </Box>
        <MenuSelector onChange={onEditorStateChange} />
      </Box> */}
    </>
  );
};

export default MailTemplate;
