import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  campaigns: [],
  isLoading: false,
  isFormOpen: false,
  isSuccess: false,
  message: "",
  error: "",
  total: 0,
  limit: 10,
  page: 0,
};

const campaignSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    campaignLoading: (state) => {
      state.isLoading = true;
    },
    fetchCampaignSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.campaigns = payload;
    },
    deleteCampaignSuccess: (state) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    openCampaignForm: (state) => {
      state.isFormOpen = !state.isFormOpen;
    },
    stopLoading: (state) => {
      state.isLoading = false;
      state.isFormOpen = false;
      state.isSuccess = true;
      state.error = "";
    },
    hideSnackar: (state) => {
      state.isSuccess = false;
    },
    clearError: (state) => {
      state.error = "";
    },
    campaignFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setCampaignPagination: (state, { payload }) => {
      state.total = payload.total;
      state.limit = payload.limit;
    },
    setCampaignPage: (state, { payload }) => {
      state.page = payload;
    },
    setCampaignLimit: (state, { payload }) => {
      state.limit = payload;
    },
  },
});

export const {
  clearError,
  setCampaignLimit,
  setCampaignPage,
  campaignLoading,
  campaignFail,
  fetchCampaignSuccess,
  deleteCampaignSuccess,
  setCampaignPagination,
  stopLoading,
  hideSnackar,
  openCampaignForm,
} = campaignSlice.actions;
export default campaignSlice.reducer;
