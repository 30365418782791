import { Avatar, Box, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { userLogout } from "../../../services/userApi";
import { logoutPending, logoutUser } from "../login/loginSlice";
import BreadCrumbs from "./bread_crumbs/index";
import NotificationModal from "./notification/index";
import { fetchAllNotifications } from "./notification/notification.action";
import SwitchNavBar from "./SwitchNavBar";
import { toggleDarkMode } from "../../../dark-mode/darkModeActions";
import StyledBadge from "./StyledBadge";
import { useDarkMode } from "../../../utils/useDarkMode";
import VtechIcons from "../../icons/VtechIcons";

export default function PrimarySearchAppBar({ title, crumbs, subTitle }) {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.login);
  // const { unReadCount } = useSelector((state) => state.notifications);
  const darkMode = useDarkMode();
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const handleToggle = () => {
    dispatch(toggleDarkMode());
  };

  useEffect(() => {
    if (user) {
      dispatch(fetchAllNotifications(user.id));
    }
  }, [dispatch]);

  const handleSignOut = () => {
    dispatch(logoutPending());
    userLogout()
      .then((res) => {
        dispatch(logoutUser());
        localStorage.removeItem("userToken");
        if (darkMode) dispatch(toggleDarkMode());
        navigate("/");
      })
      .catch((error) => {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      });
  };

  return (
    <AppBar position="static" elevation={0} color="transparent">
      <Toolbar disableGutters>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: 1,
            flexDirection: { xs: "column-reverse", md: "row" },
            gap: { xs: 1, md: 0 },
          }}
        >
          {crumbs ? (
            <BreadCrumbs crumb={crumbs} />
          ) : (
            <Box alignSelf={"start"}>
              <Typography variant="h1">{title}</Typography>

              {subTitle && (
                <Typography
                  sx={{ fontSize: 12, fontWeight: 400 }}
                  variant="body2"
                >
                  {subTitle}
                </Typography>
              )}
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignSelf: "flex-end",
            }}
          >
            {/* prettier-ignore */}
            <SwitchNavBar sx={{ mr: "5px" }} checked={!darkMode} onChange={() => { handleToggle()}}/>
            <Avatar src={user?.avatar ?? null} alt="Profile pic" />
            <Typography
              sx={{
                ml: 1.5,
                mr: 1,
                color: "text.secondary",
                fontWeight: 600,
                fontSize: 14,
              }}
            >
              {user?.first_name + " " + user?.last_name}
            </Typography>
            <StyledBadge
              // badgeContent={unReadCount}
              invisible={false}
              variant="dot"
            >
              <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                <VtechIcons icon="bell" />
              </IconButton>
            </StyledBadge>
            <IconButton onClick={handleSignOut}>
              <VtechIcons icon="logout" />
            </IconButton>
          </Box>
        </Box>

        <NotificationModal anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      </Toolbar>

      {crumbs && title && (
        <Typography sx={{ fontSize: 16, fontWeight: 600, mb: 1 }}>
          {title}
        </Typography>
      )}
    </AppBar>
  );
}
