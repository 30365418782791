import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  audience: [],
  isLoading: false,
  isFormOpen: false,
  isSuccess: false,
  isModalOpen: false,
  isEdit: false,
  currentAudienceId: 0,
  error: "",
  total: 0,
  limit: 10,
  successMsg: "",
};

const audienceSlice = createSlice({
  name: "audience",
  initialState,
  reducers: {
    campaignLoading: (state) => {
      state.isLoading = true;
    },
    fetchCampaignSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.audience = payload;
    },
    openCampaignForm: (state) => {
      state.isFormOpen = !state.isFormOpen;
    },
    stopLoading: (state, {payload}) => {
      state.isLoading = false;
      state.isFormOpen = false;
      state.isSuccess = true;
      state.error = "";
      state.successMsg = payload
    },
    hideSnackar: (state) => {
      state.isSuccess = false;
      state.successMsg = ""
    },
    campaignFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setCampaignPagination: (state, { payload }) => {
      state.total = payload.total;
      state.limit = payload.limit;
    },
    handleModal: (state, { payload }) => {
      state.isModalOpen = payload;
      state.isEdit = false;
      state.currentAudienceId = 0;
    },
    handleEditModal: (state, { payload }) => {
      state.isModalOpen = true;
      state.isEdit = true;
      state.currentAudienceId = payload;
    },
    handleEditSuccess: (state) => {
      state.isModalOpen = false;
      state.isEdit = false;
      state.currentAudienceId = 0;
    },
  },
});

export const {
  campaignLoading,
  campaignFail,
  fetchCampaignSuccess,
  setCampaignPagination,
  stopLoading,
  hideSnackar,
  openCampaignForm,
  handleModal,
  handleEditModal,
  handleEditSuccess,
} = audienceSlice.actions;
export default audienceSlice.reducer;
