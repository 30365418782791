import { Badge, Box, List } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import { Link } from "react-router-dom";
import "../../../index.css";
// import { getActionCount } from "../../../services/stats.service";
import { newMenuListItems } from "./listItems.json";
import { useSelector } from "react-redux";
import { useDarkMode } from "../../../utils/useDarkMode";

const MainMenuList = () => {
  const { user } = useSelector((state: any) => state.login);
  const darkMode = useDarkMode();
  // const [count, setCount] = useState(0);

  // const actionsCount = async () => {
  //   let count = await getActionCount();
  //   setCount(count.data.data.count);
  // };
  // useEffect(() => {
  //   actionsCount();
  //   // setCount(actionTotal);
  // }, []);

  const handlePipelineClear = () => {
    localStorage.removeItem("pipeLineIndex");
    localStorage.removeItem("pipeLineFilters");
    localStorage.removeItem("actionEvents");
    localStorage.removeItem("actionIndex");
    localStorage.removeItem("currentPage");
    localStorage.removeItem("currentRowsPerPage");
  };

  const checkActiveRoute = (route) => {
    const currentRoute = window.location.pathname;
    if (currentRoute.includes("contact") && route.includes("contact"))
      return true;
    if (currentRoute.includes("opportunit") && route.includes("opportunit"))
      return true;
    const regex = new RegExp(`^${route}(\/|$)`);
    return regex.test(currentRoute);
  };

  return (
    <>
      <List>
        {newMenuListItems.map(
          (item) =>
            user?.role &&
            Object.keys(user.role).length > 0 &&
            item.roles.includes(user.role[0]) && (
              <Link
                to={item.route}
                style={{ textDecoration: "none" }}
                onClick={handlePipelineClear}
                key={item.name}
              >
                <ListItemButton
                  selected={checkActiveRoute(item.route)}
                  sx={{
                    "& .MuiListItemIcon-root": {
                      minWidth: "40px",
                      ml: 2,
                    },
                    "&:hover": {
                      background: darkMode
                        ? "linear-gradient(92.52deg, #1F1F1F -29.74%, #38393D 172.02%)"
                        : "linear-gradient(92.52deg, #131E57 -29.74%, #36427E 172.02%)",
                    },
                    // change background color when clicked
                    "&.Mui-selected": {
                      background: darkMode
                        ? "linear-gradient(92.52deg, #1F1F1F -29.74%, #38393D 172.02%)"
                        : "linear-gradient(92.52deg, #131E57 -29.74%, #36427E 172.02%)",
                    },
                    // change ripple color
                    "& .MuiTouchRipple-root": {
                      color: "#fff",
                    },
                  }}
                >
                  <ListItemIcon>
                    <img src={item.icon} alt={item.name} />
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                  {item.name === "Chat" && (
                    <Badge badgeContent={5} color="error" sx={{ mr: 4 }} />
                  )}
                </ListItemButton>
              </Link>
            )
        )}
      </List>
    </>
  );
};

export default MainMenuList;
