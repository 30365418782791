import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  actions: [],
  isLoading: false,
  error: "",
};

const dashboardDetails = createSlice({
  name: "dashboardDetails",
  initialState,
  reducers: {
    fetchLoading: (state) => {
      state.isLoading = true;
    },
    fetchActions: (state, { payload }) => {
      state.isLoading = false;
      state.actions = payload;
    },
    fetchFail: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { fetchActions, fetchFail, fetchLoading } =
  dashboardDetails.actions;
export default dashboardDetails.reducer;
