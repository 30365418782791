import React from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import VtechIcons from "../../../components/icons/VtechIcons";
import VtechButton from "../../../components/vtech-button/VtechButton";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import ProgressBar from "../../../components/widgets/progress-bar";
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import { getCampaigns } from "../campaigns.action";
import { ResponsiveDateModal } from "../custom-date";
import { setCampaignPage } from "../campaign.slice";

const SearchCampaignsRow = ({
  query,
  setQuery,
  setOpen,
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  setOpenDateModal,
  openDateModal,
}) => {
  const [localQuery, setLocalQuery] = React.useState("");
  const dispatch: any = useDispatch();
  const { limit } = useSelector((state: any) => state.campaigns);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const { isLoading, campaigns } = useSelector((state: any) => state.campaigns);
  const filters = [
    { id: 1, name: "This Week" },
    { id: 2, name: "Last Week" },
    { id: 3, name: "This Month" },
    { id: 4, name: "Last Month" },
    { id: 5, name: "This Year" },
    { id: 6, name: "Last Year" },
    { id: 7, name: "All Time" },
  ];
  const [currentFilter, setFilter] = React.useState(filters[6]);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<any>(null);
  const { page } = useSelector((state: any) => state.campaigns);
  const [pageBeforeSearch, setPageBeforeSearch] = React.useState(page);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setQuery(localQuery);
      dispatch(setCampaignPage(0));
      if (localQuery.length > 0 && query.length === 0)
        setPageBeforeSearch(page);
      if (localQuery === "") dispatch(setCampaignPage(pageBeforeSearch));
    }, 1000);

    return () => clearTimeout(timer);
  }, [localQuery]);

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(mobileMoreAnchorEl)}
      onClose={() => setMobileMoreAnchorEl(null)}
    >
      {filters.map((item) => (
        <MenuItem onClick={() => handleMobileMenuClose(item)}>
          {item.name}
        </MenuItem>
      ))}
      <MenuItem onClick={() => setOpenDateModal(true)}>Custom Date</MenuItem>
    </Menu>
  );
  const handleMobileMenuClose = (item) => {
    let from;
    let to;
    if (item && item.type !== "click") {
      if (item.id === 1) {
        from = moment().startOf("isoWeek").format("YYYY-MM-DD");
        to = moment().endOf("isoWeek").format("YYYY-MM-DD");
      }
      if (item.id === 2) {
        from = moment()
          .subtract(1, "week")
          .startOf("isoWeek")
          .format("YYYY-MM-DD");

        to = moment().subtract(1, "week").endOf("isoWeek").format("YYYY-MM-DD");
      }
      if (item.id === 3) {
        from = moment().startOf("month").format("YYYY-MM-DD");
        to = moment().endOf("month").format("YYYY-MM-DD");
      }
      if (item.id === 4) {
        from = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");

        to = moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD");
      }
      if (item.id === 5) {
        from = moment().startOf("year").format("YYYY-MM-DD");
        to = moment().endOf("year").format("YYYY-MM-DD");
      }
      if (item.id === 6) {
        from = moment()
          .subtract(1, "year")
          .startOf("year")
          .format("YYYY-MM-DD");

        to = moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD");
      }
      if (item.id === 7) {
        from = "2001-01-01";
        to = moment().format("YYYY-MM-DD");
      }
      if (item.id === 8) {
        from = fromDate;
        to = toDate;
      }
      setFilter({ id: item.id, name: item.name });
      dispatch(getCampaigns(1, limit, from, to, ""));
    }
    setMobileMoreAnchorEl(null);
  };

  return (
    <Box
      display="flex"
      alignContent="center"
      sx={{ gap: 2, my: 2, flexDirection: { xs: "column", md: "row" } }}
    >
      <Paper
        sx={{
          px: 1.5,
          width: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 47,
        }}
      >
        <TextField
          fullWidth
          placeholder="Search Campaigns"
          value={localQuery}
          onChange={(e) => setLocalQuery(e.target.value)}
          variant="standard"
          inputProps={{
            sx: { "&::placeholder": { color: "text.secondary", opacity: 1 } },
          }}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <VtechIcons icon="search" />
              </InputAdornment>
            ),
          }}
        />
      </Paper>
      <VtechButton
        title="Add Campaign"
        onClick={() => setOpen(true)}
        sx={{ px: 8 }}
      />
      <VtechButton
        title="Export"
        variant="outlined"
        sx={{
          color: "text.primary",
          borderColor: "text.primary",
          "&:hover": { borderColor: "text.primary", color: "text.primary" },
        }}
        onClick={() => exportToCSV(campaigns, "data")}
      />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography whiteSpace="nowrap">{currentFilter.name}</Typography>
        <IconButton onClick={(e) => setMobileMoreAnchorEl(e.currentTarget)}>
          <MoreVertIcon />
        </IconButton>
      </Box>
      {renderMobileMenu}
      {isLoading && <ProgressBar />}
      <ResponsiveDateModal
        open={openDateModal}
        setOpen={setOpenDateModal}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
        handleMobileMenuClose={handleMobileMenuClose}
      />
    </Box>
  );
};

export default SearchCampaignsRow;
