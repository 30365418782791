import { http } from "../apiClient/axiosInterceptor";

export const userRegistration = (frmData) => {
  return http.post("/user", frmData);
};

export const userRegistrationVerification = (frmData) => {
  return http.patch("/user/verify", frmData);
};

export const userLogin = (frmData) => {
  return http.post("/login", frmData);
};

export const forgetPassword = (email) => {
  return http.post(`/password/create`, { email: email });
};

export const createUser = (data) => {
  return http.post("/user/create", data);
};

export const updateUser = (id, data) => {
  return http.post(`/user/update/${id}`, data);
};

export const fetchUser = () => {
  return http.get("/user");
};

export const fetchNewAccessJWT = () => {
  return http.get("/tokens");
};

export const userLogout = () => {
  return http.delete("/logout");
};

export const validateResetPasswordToken = (token) => {
  return http.get(`/password/find/${token}`);
};

export const resetPassword = (frmData) => {
  return http.post("/password/reset", frmData);
};

export const createSiteRequest = (data) => {
  return http.post("/siterequests/create", data);
};
