import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
// import Pipline from "./contact-components/pipline/index";
import ContactTable from "./contact-table/actionTable";
// import ContactAddForm from "./add-form-modal/index";
import { getContactSellers, setContactForm } from "./contact.action";
import { useDispatch } from "react-redux";
import MainLayout from "../../components/MainLayout/MainLayout";
import { useParams } from "react-router-dom";

const Contacts = () => {
  const dispatch: any = useDispatch();
  const { page: p } = useParams();
  const [page, setPage] = useState(p ? parseInt(p) - 1 : 0);
  const [filters, setFilters] = useState("");
  // const { limit } = useSelector((state: any) => state.contact);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [query, setQuery] = useState("");

  useEffect(() => {
    dispatch(getContactSellers(p, rowsPerPage, filters, query));
    setPage(p ? parseInt(p) - 1 : 0);
  }, [p, rowsPerPage, query]);

  return (
    <MainLayout title={"Contacts"} divider>
      <Box mt={"30px"}>
        <ContactTable
          query={query}
          setQuery={(val) => setQuery(val)}
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Box>
    </MainLayout>
  );
};

export default Contacts;
