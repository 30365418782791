import { Box, Button, Container, TextField, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  loginFail,
  loginPending,
  loginSuccess,
  resetPasswordSuccess,
} from "../../components/layouts/login/loginSlice";
import {
  resetPassword,
  validateResetPasswordToken,
} from "../../services/userApi";
import { setUserToken } from "../../utils/global.utils";
import LoggedOutLayout from "../../components/layouts/LoggedOutLayout";
import { useFormik } from "formik";
import { resetPasswordValidation } from "../../utils/validations.utils";

export const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();

  const { isLoading, error, user, isReset } = useSelector(
    (state: any) => state.login
  );

  const [confirmPassword, setconfirmPassword] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (token) {
      dispatch(loginPending());
      validateResetPasswordToken(token)
        .then((res) => {
          dispatch(loginSuccess(res.data.data));
        })
        .catch((error) => {
          dispatch(loginFail(error.messages[0].message));
        });
    }
  }, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "confirmPassword":
        setconfirmPassword(value);
        break;

      case "password":
        setPassword(value);
        break;

      default:
        break;
    }
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: resetPasswordValidation,
    onSubmit: (values) => {
      handleOnSubmit(values);
    },
  });

  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  //   reset,
  // } = useForm({
  //   defaultValues: {
  //     password: "",
  //     confirmPassword: "",
  //   },
  //   reValidateMode: "onChange",
  //   shouldFocusError: true,
  //   shouldUnregister: true,
  // });

  const handleOnSubmit = async (values) => {
    // reset({ password: "", confirmPassword: "" });
    dispatch(loginPending());
    resetPassword({
      email: user.email,
      token: user.token,
      password: values.password,
      password_confirmation: values.confirmPassword,
    })
      .then((res) => {
        dispatch(loginSuccess(res.data.data));
        dispatch(resetPasswordSuccess("Success"));
        setUserToken(res.data.data.token);
        setTimeout(() => {
          if (res.data.data.role[0] !== "Agent") {
            navigate("/users");
          } else {
            navigate("/dashboard");
          }
        }, 1000);
      })
      .catch((error) => {
        dispatch(loginFail(error.messages[0].message));
      });
  };

  return (
    <LoggedOutLayout>
      <Box
        component="main"
        sx={{ mt: "182px", width: 1, maxWidth: "446px", mx: "auto" }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontWeight: "bold", fontSize: 40, mb: 1 }}
        >
          Create New Password
        </Typography>
        {/* <Typography
          sx={{
            color: LIGHT_MODE_TEXT_COLOR_2,
            mb: 2,
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          Please sign in to your account
        </Typography> */}

        {error && <Alert severity="error">{error}</Alert>}
        {isReset && (
          <Alert severity="success">Password Created Successfully!</Alert>
        )}
        {!error && (
          <form noValidate onSubmit={formik.handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}

              // {...register("password", {
              //   required: "Password is required",
              //   minLength: {
              //     value: 8,
              //     message: "Password must have at least 8 characters",
              //   },
              // })}
              // error={!!errors.password}
              // helperText={errors.password?.message}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
              autoComplete="current-password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }

              // {...register("confirmPassword", {
              //   required: "Confirm Password is required",
              //   minLength: {
              //     value: 8,
              //     message: "Confirm Password must have at least 8 characters",
              //   },
              //   validate: (value) =>
              //     value === password || "The Confirm passwords do not match",
              // })}
              // error={!!errors.confirmPassword}
              // helperText={errors.confirmPassword?.message}
            />
            <Button
              sx={{ mt: "6px", height: "47px" }}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isLoading && isLoading}
            >
              Submit
            </Button>
          </form>
        )}
      </Box>
    </LoggedOutLayout>
  );
};

export default LoginForm;
