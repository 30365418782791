import React from "react";
import styles from "./style.module.css";
import SwitchComponent from "../../../../../../components/widgets/switch-component";

const CustomActionForm = ({customActionData, setCustomAction}) => {
  return (
    <>
      <div className={styles.customAction}>
        <div className={styles.customActionSwitch}>
        <SwitchComponent
          switchCheck={customActionData.appointment}
          setSwitchCheck={(e) => setCustomAction({...customActionData, appointment: e.target.checked})}
          onText="Book appointment now"
          offText="Book appointment now"
          disabled={false}
        />
        </div>
      </div>
    </>
  );
}

export default CustomActionForm
