import React from "react";
import TextField from "@mui/material/TextField";
import { FormControl, InputLabel, MenuItem, Select, useMediaQuery } from "@mui/material";

const CustomAction = ({ nextActionData, setNextActionData }) => {
  const isMobileDevice = useMediaQuery("(max-width: 768px)");
  return (
    <>
      <TextField
        id="outlined-basic"
        label="Title*"
        variant="outlined"
        value={nextActionData.title}
        onChange={(e) =>
          setNextActionData({ ...nextActionData, title: e.target.value })
        }
        style={{ width: "100%" }}
      />

      <TextField
        id="outlined-basic"
        label="Description"
        variant="outlined"
        multiline={true}
        rows={2}
        value={nextActionData.description}
        onChange={(e) =>
          setNextActionData({ ...nextActionData, description: e.target.value })
        }
        style={{ width: "100%" }}
      />

      <FormControl variant="outlined" style={{ width: isMobileDevice ? "100%" : "48%" }}>
        <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          label="Type"
          value={nextActionData.type}
          onChange={(e) =>
            setNextActionData({ ...nextActionData, type: e.target.value })
          }
        >
          <MenuItem value="standard">Standard Operation</MenuItem>
          <MenuItem value="appointment">Appointment</MenuItem>
        </Select>
      </FormControl>

    </>
  );
};

export default CustomAction;
