import {
  getNotifications,
  readNotifications,
} from "../../../../services/notifications.services";
import {
  openNotiForm,
  fetchIsLoading,
  fetchUnReadSuccess,
  fetchArchiveSuccess,
  fetchIsLoadingFail,
  fetchUnReadCount,
} from "./notification.slice";

export const setNotiForm = () => (dispatch) => {
  dispatch(openNotiForm());
};

export const fetchAllNotifications = (userId) => (dispatch) => {
  dispatch(fetchIsLoading());
  getNotifications(userId)
    .then((res) => {
      dispatch(
        fetchUnReadSuccess(res.data.data?.filter((r) => r.read_at === null))
      );
      dispatch(
        fetchArchiveSuccess(res.data.data?.filter((r) => r.read_at !== null))
      );
      dispatch(
        fetchUnReadCount(
          res.data.data
            ?.map((c) => c.read_at === null)
            .reduce((accumulator, current) => accumulator + current, 0)
        )
      );
    })
    .catch((error) => {
      dispatch(fetchIsLoadingFail("Something went wrong"));
    });
};

export const markAsRead = (data) => (dispatch) => {
  dispatch(fetchIsLoading());
  readNotifications("")
    .then(() => {
      dispatch(fetchAllNotifications(data.userId));
    })
    .catch((error) => {
      dispatch(fetchIsLoadingFail("Something went wrong"));
    });
};
