import React, { useEffect, useState } from "react";
import { TextField, Fab, Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateSequenceDetails } from "../../../sequences/redux/sequence.actions";
import SequenceAppBar from "../app-bar";
import VtechButton from "../../../../components/vtech-button/VtechButton";

const SequenceDetails = () => {
  const dispatch = useDispatch();
  const { currentSequence, isLoading } = useSelector((state) => state.sequences);
  const [errorMsg, setError] = useState(false);
  const [sequenceData, setSequenceData] = useState({
    title: "",
    description: "",
  });
  const [saveSuccess, setSaveSuccess] = useState(false);

  useEffect(() => {
    if (currentSequence) {
      setSequenceData({
        ...sequenceData,
        title: currentSequence.title,
        description: currentSequence.description,
      });
    }
  }, [currentSequence]);

  const handleChange = () => {
    if (sequenceData.title !== "") {
      setError(false);
      dispatch(updateSequenceDetails(currentSequence.id, sequenceData));
      setSaveSuccess(true);

      setTimeout(() => {
        setSaveSuccess(false);
      }, 3000);
    } else {
      setError(true);
    }
  };

  return (
    <>
      <SequenceAppBar />
      <Box sx={{ backgroundColor: "background.paper", padding: 3 }}>
        <TextField
          sx={{ mb: 2 }}
          fullWidth
          label="Automation Name"
          placeholder="Automation Name"
          value={sequenceData.title}
          onChange={(e) =>
            setSequenceData({
              ...sequenceData,
              title: e.target.value,
            })
          }
          error={errorMsg}
          helperText={errorMsg && "Please fill out this field."}
        />
        <TextField
          sx={{ mb: 2 }}
          fullWidth
          label="Automation Description"
          placeholder="Automation Description"
          multiline
          rows={4}
          value={sequenceData.description}
          onChange={(e) =>
            setSequenceData({
              ...sequenceData,
              description: e.target.value,
            })
          }
        />

        <VtechButton
            title={saveSuccess ? "Saved!" : "Save"}
            startIcon={
              isLoading ? (
                <img src="/icons/circle-dark.svg" />
              ) : saveSuccess ? (
                ""
              ) : (
                <img src="/icons/save.svg" />
              )
            }
            onClick={handleChange}
            color={saveSuccess ? "success" : "primary"}
            sx={{ transition: "all 0.8s ease" }}
          />
      </Box>
    </>
  );
};

export default SequenceDetails;
