import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetOpportunitiesDetails } from "../opportunity-details/opportunity.slice";
import AddOpportunityDialog from "./opportunitiesComponents/add-opportunity/addOpportunity";
import Stepper from "./opportunitiesComponents/stepper";
import {
  fetchAllOpportunities,
  fetchPipeline,
} from "./opportunitiesListAction";
import OpportunityTable from "./opportunities-table/opportunityTable";
import MainLayout from "../../components/MainLayout/MainLayout";
import { useMediaQuery } from "@mui/material";
import VtechTabs from "../../components/tabs/VtechTabs";
import { useNavigate } from "react-router-dom";

const getActiveTab = (url) => (url.includes("all-opportunities") ? 1 : 0);

const Opportunities = () => {
  const navigate = useNavigate();
  const url = window.location.href;
  const { oppoFilters } = useSelector(
    (state: any) => state.opportunitiesFilters
  );
  const { user } = useSelector((state: any) => state.login);
  const dispatch: any = useDispatch();
  const { limit } = useSelector((state: any) => state.opportunities);
  const { query } = useSelector((state: any) => state.opportunitiesFilters);
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(getActiveTab(url));
  const isTablet = useMediaQuery("(max-width: 900px)");
  const tabs = ["My Opportunities", "All Opportunities"];

  useEffect(() => {
    dispatch(resetOpportunitiesDetails());
    dispatch(fetchPipeline());
  }, []);

  useEffect(() => {
    const localPage = query ? 0 : localStorage.getItem("tablePageNumber");
    const localLimit = localStorage.getItem("tableRowsPerPage");

    dispatch(
      fetchAllOpportunities(
        localPage || 1,
        localLimit || limit,
        oppoFilters,
        query,
        activeTab === 0 ? user.id : null
      )
    );
  }, [dispatch, oppoFilters, query, activeTab]);

  const handleActiveTab = (index) => {
    setActiveTab(index);
    switch (index) {
      case 0:
        navigate("/opportunities");
        break;
      case 1:
        navigate("/opportunities/all-opportunities");
    }
  };

  useEffect(() => {
    if (window.location.pathname === "/opportunities") {
      setActiveTab(0);
    }
  }, [window.location.pathname]);

  return (
    <MainLayout title="Opportunities">
      <VtechTabs tabs={tabs} activeTab={activeTab} setTab={handleActiveTab} />
      <AddOpportunityDialog open={open} setOpen={setOpen} />
      {!isTablet && <Stepper activeTab={activeTab} />}
      <OpportunityTable setOpen={setOpen} />
    </MainLayout>
  );
};

export default Opportunities;
