import {
  Box,
  Button,
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import { MaterialCard } from "../../../../components/widgets/mat-card";
import { useDispatch, useSelector } from "react-redux";
import {
  getSequences,
  updateNextAction,
} from "../../../sequences/redux/sequence.actions";
import { getActionAssignee } from "../../../../services/action.services";
import CustomAction from "./custom-action-form";
import SequenceAppBar from "../app-bar";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import VtechButton from "../../../../components/vtech-button/VtechButton";
import { showSnackbar } from "../../../../snackbar/snackbarSlice";

const SequenceNextActions = () => {
  const dispatch = useDispatch();
  const { actions } = useSelector((state) => state.campaignDetails);
  const { users } = useSelector((state) => state.users);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const { nextAction, sequences, currentSequence, isLoading } = useSelector(
    (state) => state.sequences
  );
  const availableActions = [1, 2, 12, 21, 10, 15, 19, 17, 18, 20, 24];
  const assigneeArr = [1, 2, 12, 21, 10, 15, 17, 18];
  const isMobileDevice = useMediaQuery("(max-width: 768px)");
  const [nextActionData, setNextActionData] = useState({
    id: "",
    title: "",
    description: "",
    type: "standard",
    step_id: "",
    assign_to: "",
    instructions: "",
    sequence_id: "",
    action_sequence_id: "",
  });

  const getAssigneeId = (val) => {
    getActionAssignee(val)
      .then((res) => {
        setNextActionData({
          ...nextActionData,
          assign_to: res.data.userId !== null ? res.data.userId : "",
          step_id: val,
        });
      })
      .catch((err) => {
        console.log("err = ", err);
      });
  };

  useEffect(() => {
    if (nextAction) {
      //dispatch(getSequences("enabled"));
      setNextActionData({
        ...nextActionData,
        title:
          nextAction.title !== null && nextAction.title !== undefined
            ? nextAction.title
            : "",
        description:
          nextAction.description !== null &&
          nextAction.description !== undefined
            ? nextAction.description
            : "",
        type:
          nextAction.type !== null && nextAction.type !== undefined
            ? nextAction.type
            : "standard",
        sequence_id: currentSequence.id,
        action_sequence_id:
          nextAction.action_sequence_id !== null &&
          nextAction.action_sequence_id !== undefined
            ? nextAction.action_sequence_id
            : "",
        step_id: nextAction.step_id !== null ? nextAction.step_id : "",
        assign_to: nextAction.assign_to !== null ? nextAction.assign_to : "",
        instructions:
          nextAction.instructions !== null ? nextAction.instructions : "",
      });
    } else {
      setNextActionData({
        title: "",
        description: "",
        type: "standard",
        step_id: "",
        assign_to: "",
        instructions: "",
        sequence_id: currentSequence.id,
        action_sequence_id: "",
      });
    }
  }, [nextAction]);

  const handleChange = () => {
    if (nextActionData.step_id !== "") {
      if (nextActionData.step_id === 1 && nextActionData.title === "") {
        dispatch(showSnackbar("Please enter title."));
        return;
      } else if (
        nextActionData.step_id === 19 &&
        nextActionData.sequence_id === ""
      ) {
        dispatch(showSnackbar("Please select any sequence."));
        return;
      } else if (
        nextActionData.assign_to === "" &&
        assigneeArr.indexOf(nextActionData.step_id) > -1
      ) {
        dispatch(showSnackbar("Please select assignee."));
        return;
      }
      dispatch(updateNextAction(nextActionData));
      setSaveSuccess(true);

      setTimeout(() => {
        setSaveSuccess(false);
      }, 3000);
    } else {
      dispatch(showSnackbar("Please select both next operation and assignee."));
    }
  };

  return (
    <>
      <SequenceAppBar />
      <MaterialCard>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            mb: 2,
            mt: 2,
            ...(nextActionData.step_id === 1 && { flexWrap: "wrap" }),
          }}
        >
          <FormControl fullWidth>
            <InputLabel>Next Operation</InputLabel>
            <Select
              label="Next Operation"
              value={nextActionData.step_id}
              onChange={(e) => getAssigneeId(e.target.value)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {actions?.map(
                (item) =>
                  availableActions.indexOf(item.id) > -1 && (
                    <MenuItem value={item.id}>{item.title}</MenuItem>
                  )
              )}
            </Select>
          </FormControl>
          {nextActionData.step_id === 1 && (
            <CustomAction
              nextActionData={nextActionData}
              setNextActionData={setNextActionData}
            />
          )}
          {nextActionData.step_id === 19 && (
            <>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select an Automation
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Select an Automation"
                  value={nextActionData.action_sequence_id}
                  onChange={(e) =>
                    setNextActionData({
                      ...nextActionData,
                      action_sequence_id: e.target.value,
                    })
                  }
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {sequences?.map((item) => (
                    <MenuItem value={item.id}>{item.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br />
              <br />
            </>
          )}
          {assigneeArr.indexOf(nextActionData.step_id) > -1 && (
            <>
              <FormControl fullWidth sx={{...(nextActionData.step_id === 1 && !isMobileDevice && { width: "48%" })}}>
                <InputLabel>Assignee</InputLabel>
                <Select
                  id="demo-simple-select-outlined"
                  label="Assignee"
                  value={nextActionData.assign_to}
                  onChange={(e) =>
                    setNextActionData({
                      ...nextActionData,
                      assign_to: e.target.value,
                    })
                  }
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {users?.map((user) => (
                    <MenuItem value={user.id}>{user.email}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </Box>
        <TextField
          label="Add instructions to next operation"
          placeholder="Add instructions to next operation"
          multiline
          rows={3}
          value={nextActionData.instructions}
          onChange={(e) =>
            setNextActionData({
              ...nextActionData,
              instructions: e.target.value,
            })
          }
          fullWidth
        />
        <br />
        <br />
        {/* <Button
          variant="contained"
          color="primary"
          onClick={handleChange}
          startIcon={<BookmarksIcon />}
        >
          Save
        </Button> */}
        <VtechButton
          title={saveSuccess ? "Saved!" : "Save"}
          startIcon={
            isLoading ? (
              <img src="/icons/circle-dark.svg" />
            ) : saveSuccess ? (
              ""
            ) : (
              <img src="/icons/save.svg" />
            )
          }
          onClick={handleChange}
          color={saveSuccess ? "success" : "primary"}
          sx={{ transition: "all 0.8s ease" }}
        />
      </MaterialCard>
    </>
  );
};

export default SequenceNextActions;
