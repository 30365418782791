import React, { useState } from "react";
import { EditActionForm } from "./edit-form";
import VtechModal from "../../../vtech-modal/VtechModal";

export const ResponsiveEditDialog = ({ open, setOpen, id, fromPage }) => {
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <VtechModal
        open={open}
        handleClose={handleClose}
        title="Edit Operation"
        hideActionButtons
      >
        <EditActionForm
          id={id}
          fromPage={fromPage}
          setLoading={setLoading}
          handleClose={handleClose}
          loading={loading}
        />
      </VtechModal>
    </>
  );
};
