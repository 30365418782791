import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import SubmissionButton from "../../../../../components/widgets/button/button.widget";
// import { DatePickers } from "../../../../../components/widgets/date-time-picker";
// import { TimeMenu } from "../../../../../components/widgets/date-time-picker/time-menu";
import { getActionAssignee } from "../../../../../services/action.services";
import ACTION_FORMS from "./actionForms/actionForm";
import CustomActionForm from "./custom-action-form/custom-action-appointment-form";
import VtechButton from "../../../../../components/vtech-button/VtechButton";
import CheckIcon from "@mui/icons-material/Check";
import {
  ArrowDropDownIcon,
  DateTimePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { getSequences } from "../../../../sequences/redux/sequence.actions";

export const StepFive = ({ handleEnd, loading, success }) => {
  const dispatch: any = useDispatch();
  const { filteredSequences } = useSelector((state: any) => state.sequences);
  const { actions } = useSelector((state: any) => state.campaignDetails);
  const { users } = useSelector((state: any) => state.users);
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [duration, setDuration] = useState("");
  const [instructions, setInstruction] = useState("");
  const [stepId, setStepId] = useState(null);
  const [sequenceId, setSequenceId] = useState("");
  const [dueIn, setDueIN] = useState("");
  const [assignTo, setAssignee] = useState("");

  const [flag, setFlag] = useState("");
  const [customActionData, setCustomActionData] = useState({
    title: "",
    description: "",
    type: "standard",
    appointment: "",
    duration: "",
    endTime: "",
  });
  const [availableActions] = useState([
    1, 2, 10, 12, 14, 15, 17, 18, 19, 20, 21, 24,
  ]);

  useEffect(() => {
    if (
      flag === "Place in follow up automation" &&
      filteredSequences.length === 0
    ) {
      dispatch(getSequences("enabled"));
    }
  }, [flag]);

  useEffect(() => {
    setSelectedDate("");
    setSelectedTime("");
    setDueIN("");
    if (stepId) {
      const currentAction = actions.filter((x) => x.id === stepId);
      setFlag(currentAction[0].title);
    }
  }, [stepId]);

  const handleDueIn = (e) => {
    setDueIN(e.target.value);
    if (e.target.value === "today") {
      setSelectedDate(moment().format("MM-DD-YYYY"));
    } else if (e.target.value === "tomorrow") {
      setSelectedDate(moment().add(1, "days").format("MM-DD-YYYY"));
    } else if (e.target.value === "week") {
      setSelectedDate(moment().add(7, "days").format("MM-DD-YYYY"));
    } else if (e.target.value === "month") {
      setSelectedDate(moment().add(1, "M").format("MM-DD-YYYY"));
    } else {
      setSelectedDate("");
    }
    setSelectedTime(moment().format("HH:mm"));
  };

  const gatherData = () => {
    let dateFormat = stepId === 10 ? "YYYY-MM-DDTHH:mm" : "MM-DD-YYYYTHH:mm";
    const data = {
      due_date_time: sequenceId
        ? moment().utc().format("YYYY-MM-DDTHH:mm:ss")
        : `${moment(selectedDate + "T" + selectedTime, dateFormat)
            .utc()
            .format("YYYY-MM-DD HH:mm")}`,
      due_in: dueIn,
      instructions,
      assign_to: assignTo,
      step_id: stepId,
      sequence_id: sequenceId,
      title: stepId === 1 ? customActionData.title : "",
      description: stepId === 1 ? customActionData.description : "",
      type: stepId === 1 ? customActionData.type : "",
      appointment_now: stepId === 1 ? customActionData.appointment : "",
      duration:
        stepId === 10
          ? duration
          : stepId === 1
          ? customActionData.appointment
            ? customActionData.duration
            : ""
          : "",
      end_time:
        stepId === 1 && customActionData.appointment
          ? customActionData.endTime
          : "",
    };
    handleEnd(data);
  };

  const getAssigneeFromAction = (e) => {
    getActionAssignee(e.target.value)
      .then((res) => {
        setAssignee(res.data.userId ? res.data.userId : 1);
        setStepId(e.target.value);
      })
      .catch((err) => {
        console.log("err = ", err);
      });
  };

  const isBtnDisabled =
    (dueIn !== "" && dueIn !== "custom_date") ||
    (dueIn === "custom_date" && selectedDate !== "") ||
    (stepId === 10 && selectedDate !== "") ||
    (stepId === 19 && sequenceId !== "");

  return (
    <>
      <Box
        sx={{ display: "flex", alignItems: "center", gap: 2, pb: 2.5, mt: 4 }}
      >
        <FormControl sx={{ width: "378px" }}>
          <InputLabel>{"Assign next Operation"}</InputLabel>
          <Select
            label={"Assign next Operation"}
            onChange={getAssigneeFromAction}
          >
            {actions &&
              Object.keys(actions).length > 0 &&
              actions.map(
                (item) =>
                  availableActions.includes(item.id) && (
                    <MenuItem value={item.id}>{item.title}</MenuItem>
                  )
              )}
          </Select>
        </FormControl>

        <Box>
          {/* {dueIn !== "" && dueIn !== "custom_date" && (
            <VtechButton
              color={success ? "success" : "primary"}
              title={
                loading
                  ? "Complete opportunity"
                  : success
                  ? "Nice work! Opportunity added successfully!"
                  : "Add opportunity"
              }
              onClick={gatherData}
              startIcon={
                success ? (
                  <></>
                ) : loading ? (
                  <CircularProgress size={25} style={{ color: "#fff" }} />
                ) : (
                  <CheckIcon />
                )
              }
            />
          )}
          {dueIn === "custom_date" && selectedDate !== "" && (
            <VtechButton
              color={success ? "success" : "primary"}
              title={
                loading
                  ? "Complete opportunity"
                  : success
                  ? "Nice work! Opportunity added successfully!"
                  : "Add opportunity"
              }
              onClick={gatherData}
              startIcon={
                loading ? (
                  <CircularProgress size={25} style={{ color: "#fff" }} />
                ) : (
                  <CheckIcon />
                )
              }
            />
          )}
          {stepId === 10 && selectedDate !== "" && (
            <VtechButton
              color={success ? "success" : "primary"}
              title={
                loading
                  ? "Complete opportunity"
                  : success
                  ? "Nice work! Opportunity added successfully!"
                  : "Add opportunity"
              }
              onClick={gatherData}
              startIcon={
                loading ? (
                  <CircularProgress size={25} style={{ color: "#fff" }} />
                ) : (
                  <CheckIcon />
                )
              }
            />
          )}
          {sequenceId !== "" && (
            <VtechButton
              color={success ? "success" : "primary"}
              title={
                loading
                  ? "Complete opportunity"
                  : success
                  ? "Nice work! Opportunity added successfully!"
                  : "Add opportunity"
              }
              onClick={gatherData}
              startIcon={
                loading ? (
                  <CircularProgress size={25} style={{ color: "#fff" }} />
                ) : (
                  <CheckIcon />
                )
              }
            />
          )} */}
          <VtechButton
            color={success ? "success" : "primary"}
            title={
              loading
                ? "Complete opportunity"
                : success
                ? "Nice work! Opportunity added successfully!"
                : "Add opportunity"
            }
            onClick={gatherData}
            disabled={!isBtnDisabled}
            startIcon={
              loading ? (
                <CircularProgress size={25} style={{ color: "#fff" }} />
              ) : (
                <CheckIcon />
              )
            }
          />
        </Box>
      </Box>
      <div style={{ display: stepId === 1 ? "block" : "none" }}>
        <FormControl fullWidth>
          <TextField
            label="Title"
            value={customActionData.title}
            onChange={(e) =>
              setCustomActionData({
                ...customActionData,
                title: e.target.value,
              })
            }
            multiline
            rows={1}
            variant="outlined"
          />
        </FormControl>
        <FormControl style={{ width: "100%", marginTop: "20px" }}>
          <TextField
            label="Description"
            value={customActionData.description}
            onChange={(e) =>
              setCustomActionData({
                ...customActionData,
                description: e.target.value,
              })
            }
            multiline
            rows={2}
            variant="outlined"
          />
        </FormControl>
        <FormControl fullWidth sx={{ my: 2.5 }}>
          <InputLabel>Type</InputLabel>
          <Select
            label="Type"
            value={customActionData.type}
            onChange={(e) =>
              setCustomActionData({ ...customActionData, type: e.target.value })
            }
          >
            <MenuItem value="standard">Standard Operation</MenuItem>
            <MenuItem value="appointment">Appointment</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div
        style={{
          display: flag === "Schedule seller appointment" ? "" : "none",
        }}
      >
        {ACTION_FORMS.getSchedularSellerForm(
          assignTo,
          setAssignee,
          users,
          setSelectedDate,
          setSelectedTime,
          setDuration,
          selectedDate,
          selectedTime,
          duration
        )}
      </div>

      <div
        style={{
          display: flag === "Place in follow up automation" ? "" : "none",
        }}
      >
        {ACTION_FORMS.getSequenceForm(setSequenceId, filteredSequences)}
      </div>

      <div
        style={{
          marginBottom: "20px",
          display:
            flag !== "Schedule seller appointment" &&
            flag !== "Place in follow up automation" &&
            flag !== ""
              ? ""
              : "none",
        }}
      >
        <FormControl style={{ width: "100%" }} variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">
            Assign To
          </InputLabel>
          <Select
            id="outlined-age-native-simple"
            label="Assign To"
            value={assignTo}
            onChange={(e) => setAssignee(e.target.value)}
          >
            {Object.keys(users).length > 0 ? (
              users.map((user) => (
                <MenuItem value={user.id}>{user.email}</MenuItem>
              ))
            ) : (
              <MenuItem value="not found">Records not found</MenuItem>
            )}
          </Select>
        </FormControl>
        <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
          <FormControl fullWidth>
            <InputLabel>Due In</InputLabel>
            <Select label="Due In" value={dueIn} onChange={handleDueIn}>
              <MenuItem value="today">Today</MenuItem>
              <MenuItem value="tomorrow">Tomorrow</MenuItem>
              <MenuItem value="week">In a week</MenuItem>
              <MenuItem value="month">In a month</MenuItem>
              <MenuItem value="custom_date">Select Custom Date</MenuItem>
            </Select>
          </FormControl>

          <DateTimePicker
            slots={{ openPickerIcon: ArrowDropDownIcon }}
            label="Due Date Time"
            value={
              selectedDate
                ? moment(selectedDate + "T" + selectedTime, "MM-DD-YYYYTHH:mm")
                : null
            }
            onChange={(value) => {
              setSelectedDate(moment(value).format("MM-DD-YYYY"));
              setSelectedTime(moment(value).format("HH:mm"));
            }}
            slotProps={{ tabs: { hidden: false } }}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
            sx={{ width: 1 }}
          />
        </Box>
        {stepId === 1 && customActionData.type === "appointment" && (
          <div>
            <CustomActionForm
              customActionData={customActionData}
              setCustomAction={setCustomActionData}
            />
            {customActionData.appointment && (
              <>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    marginTop: "20px",
                    gap: "16px",
                  }}
                >
                  <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Duration
                    </InputLabel>
                    <Select
                      id="outlined-age-native-simple"
                      label="Duration"
                      value={customActionData.duration}
                      onChange={(e) =>
                        setCustomActionData({
                          ...customActionData,
                          duration: e.target.value,
                        })
                      }
                    >
                      <MenuItem value="00:15">15 minutes</MenuItem>
                      <MenuItem value="00:30">Half hour</MenuItem>
                      <MenuItem value="01:00">Full hour</MenuItem>
                      <MenuItem value="12:00">Half day</MenuItem>
                      <MenuItem value="23:59">Full day</MenuItem>
                    </Select>
                  </FormControl>
                  <TimePicker
                    slots={{ openPickerIcon: ArrowDropDownIcon }}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                    }}
                    label="End Time"
                    value={
                      customActionData.endTime
                        ? moment(customActionData.endTime)
                        : null
                    }
                    onChange={(value) =>
                      setCustomActionData({
                        ...customActionData,
                        endTime: moment(value).format("HH:mm"),
                      })
                    }
                    sx={{ width: 1 }}
                  />
                  {/* <div style={{ width: "50%" }}>
                     <DatePickers
                      type="time"
                      label="End Time"
                      value={customActionData.endTime}
                      onChange={(value) =>
                        setCustomActionData({
                          ...customActionData,
                          endTime: value,
                        })
                      }
                      min={true}
                      styles={{ width: "100%", margin: "auto" }}
                    />
                  </div>*/}
                </div>
              </>
            )}
          </div>
        )}
        <FormControl
          variant="outlined"
          style={{ width: "100%", marginTop: "20px" }}
        >
          <TextField
            id="outlined-multiline-static"
            label="Change Instructions"
            value={instructions}
            onChange={(e) => setInstruction(e.target.value)}
            multiline
            rows={2}
            variant="outlined"
          />
        </FormControl>
      </div>
    </>
  );
};
