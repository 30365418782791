import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  actions: [],
  tasks: [],
  historyList: [],
  isLoading: false,
  isTaskLoading: false,
  isSnackOpen: false,
  setHistoryLoading: false,
  snackMessage: "",
};

const actionsSlice = createSlice({
  name: "Actions",
  initialState,
  reducers: {
    fetchActions: (state, { payload }) => {
      state.actions = payload;
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setTaskLoading: (state, { payload }) => {
      state.isTaskLoading = payload;
    },
    fetchTasks: (state, { payload }) => {
      state.tasks = payload;
    },
    startHistoryLoading: (state) => {
      state.setHistoryLoading = true;
    },
    fetchHistory: (state, { payload }) => {
      state.historyList = payload;
      state.setHistoryLoading = false;
    },
    setSnack: (state, { payload }) => {
      state.isSnackOpen = true;
      state.snackMessage = payload;
    },
    closeSnackBar: (state) => {
      state.isSnackOpen = false;
      state.snackMessage = "";
    },
  },
});

export const {
  fetchTasks,
  fetchActions,
  setLoading,
  setTaskLoading,
  setSnack,
  closeSnackBar,
  fetchHistory,
  startHistoryLoading,
} = actionsSlice.actions;
export default actionsSlice.reducer;
