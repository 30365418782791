import Tooltip from "@mui/material/Tooltip";
import React from "react";
import style from "./boxes.module.css";

export const TimeDisplay = ({
  title,
  number,
  daysAgo,
  active,
  tooltipValue,
  width,
}) => {
  return (
    <>
      {active ? (
        <>
          {tooltipValue ? (
            <Tooltip
              title={tooltipValue && tooltipValue}
              placement="bottom-start"
            >
              <div
                className={style.miniDashboardContainer}
                style={{
                  width: width ? "50px" : "80px",
                  marginLeft: title === "LAST TOUCH" ? 0 : "16px",
                }}
              >
                <span style={{ opacity: 0.7, fontSize: 12, fontWeight: 600 }}>
                  {title}
                </span>
                <div className={style.miniDashboardInfo}>
                  <div className={style.miniDashboardContent}>
                    {/* <p className={style.miniDashboardNumber}>{number}</p>
                    <p className={style.miniDashboardDaysInfo}>{daysAgo}</p> */}
                    <p className={style.miniDashboardDaysInfo}>
                      {number} {daysAgo}
                    </p>
                  </div>
                </div>
              </div>
            </Tooltip>
          ) : (
            <div
              className={style.miniDashboardContainer}
              style={{
                width: width ? "50px" : "80px",
                marginLeft: title === "LAST TOUCH" ? 0 : "16px",
              }}
            >
              <span style={{ opacity: 0.7, fontSize: 12, fontWeight: 600 }}>
                {title}
              </span>
              <div className={style.miniDashboardInfo}>
                <div className={style.miniDashboardContent}>
                  <p className={style.miniDashboardNumber}>{number}</p>
                  <p className={style.miniDashboardDaysInfo}>{daysAgo}</p>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div
            className={style.miniDashboardContainer}
            style={{
              marginLeft: title === "LAST TOUCH" ? 0 : "16px",
            }}
          >
            <span className={style.labelDisable}>{title}</span>
            <div className={style.miniDashboardInfoDisable}>
              <div className={style.miniDashboardContent}></div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
