import React from "react";
import Card from "@mui/material/Card";
import { Bar } from "react-chartjs-2";
import { useTheme } from "@mui/material";
import { Chart, registerables } from "chart.js";
import { useDarkMode } from "../../../../utils/useDarkMode";

Chart.register(...registerables);

const OpportunitiesStats = ({ type, thisStats, otherStats }) => {
  const theme = useTheme();
  const darkMode = useDarkMode();
  const Days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const Weeks = ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"];
  //prettier-ignore
  const Months =  ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  let thisDataSet = [];
  let otherDataSet = [];
  let labels;

  if (type === "week") {
    labels = Days;
    thisDataSet = [0, 0, 0, 0, 0, 0, 0];
    otherDataSet = [0, 0, 0, 0, 0, 0, 0];
  } else if (type === "year") {
    labels = Months;
    thisDataSet = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    otherDataSet = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  } else if (type === "month") {
    labels = Weeks;
    thisDataSet = [0, 0, 0, 0, 0];
    otherDataSet = [0, 0, 0, 0, 0];
  }

  const handleMakeData = (xlabels, dataValue, dataset) => {
    if (type !== "month") {
      const name = type === "week" ? "dayname" : "monthname";

      if (dataValue && dataValue.length > 0) {
        xlabels.forEach((value, index) => {
          const count = dataValue.find(
            (r) => r && r[name] && r[name].includes(value)
          );
          if (count) {
            dataset[index] = count.count;
          }
        });
      }
    } else {
      if (dataValue && Object.keys(dataValue).length > 0) {
        xlabels.forEach((value, index) => {
          dataset[index] = dataValue[Object.keys(dataValue)[index]];
        });
      }
    }

    return dataset;
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Current",
        data:
          thisStats && Object.keys(thisStats).length > 0
            ? handleMakeData(labels, thisStats, thisDataSet)
            : thisDataSet,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(0, "#33B892AA");
          gradient.addColorStop(1, "#33B892");
          return gradient;
        },
        // backgroundColor: "#34B993",
        borderRadius: 5,
      },
      {
        label: "Previous",
        data:
          otherStats && Object.keys(otherStats).length > 0
            ? handleMakeData(labels, otherStats, otherDataSet)
            : otherDataSet,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 100);
          gradient.addColorStop(0, "#245EC1AA");
          gradient.addColorStop(1, "#245EC1");
          return gradient;
        },
        // backgroundColor: "#245EC1",
        borderRadius: 5,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "right",
        labels: {
          boxWidth: 17,
          boxHeight: 9,
          useBorderRadius: true,
          borderRadius: 4,
          color: theme.palette.text.secondary,
          font: {
            size: 14,
            weight: 500,
          },
        },
      },
      tooltip: {
        backgroundColor: darkMode ? "#121212" : "#fff",
        titleColor: darkMode ? "#fff" : "#000",
        bodyColor: darkMode ? "#fff" : "#5A6479",
        borderColor: darkMode ? "none" : "#ddd",
        borderWidth: 1,
        bodyFont: {
          size: 12,
          weight: 400,
        },
        padding: 16,
        cornerRadius: 6,
        usePointStyle: true,

        callbacks: {
          labelColor: (context) => ({
            borderColor: "transparent",
            backgroundColor:
              context.dataset.label === "Current" ? "#33B892" : "#245EC1",
          }),
          labelPointStyle: (context) => ({
            pointStyle: "circle",
            pointWidth: 10,
          }),
        },
      },
    },

    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: darkMode ? "#FFFFFFB2" : "#5A6479B2",
        },
      },
      y: {
        border: {
          display: false,
        },
        grid: {
          color: darkMode ? "#FFFFFF0F" : "#19243B0F",
        },
        ticks: {
          color: darkMode ? "#FFFFFFB2" : "#5A6479B2",
        },
      },
    },
  };

  return (
    <Card sx={{ pt: 6, pb: 5, pl: { xs: 1, sm: 8 }, pr: { xs: 1, sm: 5 } }}>
      <Bar data={data} options={options} height={250} />
    </Card>
  );
};

export default OpportunitiesStats;
