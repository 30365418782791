import React from "react";
import { ChangeStatusForm } from "./change-status/edit-modal";
import VtechModal from "../../../vtech-modal/VtechModal";

export const ResponsiveChangeStatusDialog = ({ open, setOpen, id }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <VtechModal
        open={open}
        handleClose={handleClose}
        title="Change Status"
        hideActionButtons
        sx={{ minWidth: { xs: 300, sm: "707px" }, width: 1, margin: 3 }}
      >
        <ChangeStatusForm id={id} setOpen={setOpen} />
      </VtechModal>
    </>
  );
};
