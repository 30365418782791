import { Box, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import VtechButton from "../../../../../components/vtech-button/VtechButton";

export const StepTwoExtend = ({
  handleNext,
  onSubmission,
  sellerId,
  setSellerData,
}) => {
  const { sellers } = useSelector((state) => state.opportunityDetails);
  const [tempSellers, setSellers] = useState([]);
  const [searchValue, setSearch] = useState({ id: 0, title: "" });

  useEffect(() => {
    setSellers([]);
    if (sellers && Object.keys(sellers).length > 0) {
      sellers.map((seller) => {
        const tempTitle =
          seller.first_name +
          " " +
          seller.last_name +
          " - " +
          seller.phone +
          " - " +
          seller.email;
        setSellers((tempSellers) =>
          tempSellers.concat({ id: seller.id, title: tempTitle })
        );
      });
    }
    if (sellerId !== null) {
      let getSeller = sellers.filter((seller) => seller.id === sellerId);
      let tempSeller =
        getSeller[0].first_name +
        " - " +
        getSeller[0].last_name +
        " - " +
        getSeller[0].phone +
        " - " +
        getSeller[0].email;
      setSearch({ id: sellerId, title: tempSeller });
    }
  }, [sellers, sellerId]);

  const handleChange = (value) => {
    if (value !== null) {
      onSubmission(value.id);
      setSellerData({
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        mailingAddress: "",
        mailingCity: "",
        mailingState: "",
        mailingZipCode: "",
      });
      handleNext();
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <Autocomplete
        options={tempSellers}
        getOptionLabel={(option) => option.title}
        style={{ width: "378px" }}
        value={searchValue}
        onChange={(_, value) => handleChange(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search by name, email, or phone"
            placeholder="Search by name, email, or phone"
            variant="outlined"
          />
        )}
      />
      <VtechButton title="Next" onClick={handleNext} />
    </Box>
  );
};
