import { Alert, Box, MenuItem, TextField, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
// import SubmitButton from "../../../../components/widgets/button/button.widget";
// import SnackBar from "../../../../components/widgets/snackbar/index";
import {
  createCampaignResponse,
  updateCampaignResponse,
} from "../../redux/settings/settings.actions";
// import { hideSnackar } from "../../redux/settings/settings.slice";
import EmailResponse from "./email-response";
import TextResponse from "./text-response";
import VtechButton from "../../../../components/vtech-button/VtechButton";

const ResponseTemplate = () => {
  const [type, setType] = useState(-1);
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState("");
  const [createText, setText] = useState("");
  const [subject, setSubject] = useState("");
  const [editableId, setEditableId] = useState(0);
  const [editable, setEditable] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  // const [snackMessage, setSnackMsg] = useState("");

  const [resError, setResErr] = useState(false);
  const [textError, setTextErr] = useState(false);
  const [subjectErr, setSubjectErr] = useState(false);

  const { isLoading, isSuccess, allResponse } = useSelector(
    (state: any) => state.campaignSettings
  );
  const { id }: any = useParams();
  const dispatch: any = useDispatch();

  const resetForm = () => {
    setEditable(false);
    setEditableId(0);
    setMessage("");
    setResponse("");
    setType(-1);
    setSubject("");
    setText("");
    setTextErr(false);
    setResErr(false);
    setSubjectErr(false);
  };

  const handleFormSubmittion = () => {
    if (response.length === 0) {
      setResErr(true);
    }
    if (createText.length === 0) {
      setTextErr(true);
    }
    if (type === 2 && subject.length === 0) {
      setSubjectErr(true);
      return;
    }
    if (response.length > 0 && createText.length > 0) {
      const data = {
        campaign_id: parseInt(id),
        name: response,
        type: type === 2 ? "email" : "text",
        subject: subject,
        message: createText,
      };
      if (!editable) {
        dispatch(createCampaignResponse(data));
      } else {
        dispatch(updateCampaignResponse(editableId, data));
      }
      setSaveSuccess(true);

      setTimeout(() => {
        setSaveSuccess(false);
      }, 3000);
      resetForm();
    }
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
    setEditable(false);
    setEditableId(0);
    setMessage("");
    setResponse("");
    setSubject("");
    setText("");
    setTextErr(false);
    setResErr(false);
    setSubjectErr(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        pb: 5,
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Box flex={1}>
        <Typography variant="h2">Response template</Typography>
      </Box>
      <Box flex={3}>
        <Box maxWidth={450}>
          <Alert
            severity="info"
            icon={<InfoOutlinedIcon style={{ color: "#0288D1" }} />}
            sx={{ mb: 3.5 }}
          >
            Select a message that will be delivered to the seller when a
            campaign-generated opportunity arises.
          </Alert>
          <TextField
            select
            label="Select response type"
            value={type}
            fullWidth
            onChange={(e) => handleTypeChange(e)}
            sx={{ mb: 2.5 }}
          >
            <MenuItem value={1}>None</MenuItem>
            <MenuItem value={2}>Email</MenuItem>
            <MenuItem value={3}>Text</MenuItem>
          </TextField>
          {type === 2 && (
            <EmailResponse
              message={message}
              response={response}
              // createText={createText}
              subject={subject}
              setMessage={setMessage}
              setResponse={setResponse}
              setText={setText}
              setSubject={setSubject}
              subjectErr={subjectErr}
              resError={resError}
              textError={textError}
              allResponse={allResponse}
              setEditable={setEditable}
              setEditableId={setEditableId}
            />
          )}
          {type === 3 && (
            <TextResponse
              message={message}
              response={response}
              createText={createText}
              setMessage={setMessage}
              setResponse={setResponse}
              setText={setText}
              resError={resError}
              textError={textError}
              allResponse={allResponse}
              setEditable={setEditable}
              setEditableId={setEditableId}
            />
          )}
          <VtechButton
            title={editable ? "Update" : saveSuccess ? "Saved!" : "Save"}
            startIcon={
              isLoading ? (
                <img src="/icons/circle-dark.svg" />
              ) : saveSuccess ? (
                ""
              ) : (
                <img src="/icons/save.svg" />
              )
            }
            onClick={handleFormSubmittion}
            // disabled={isLoading}
            color={saveSuccess ? "success" : "primary"}
            sx={{ transition: "all 0.8s ease" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ResponseTemplate;
