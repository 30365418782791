import Alert from "@mui/material/Alert";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { EditOpportunityStepper } from "./stepper";
import { Typography } from "@mui/material";

export const ChangeStatusForm = ({ id, setOpen }) => {
  const { opportunity } = useSelector((state) => state.opportunityDetails);
  return (
    <>
      <Alert severity="info">
        <Typography sx={{ fontWeight: 500, fontSize: 16 }}>
          Important
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: 14 }}>
          This will automatically complete active Operation of "Due diligence"
          assigned to {opportunity?.assignee?.email} due on{" "}
          {moment(opportunity?.action?.due_date_time)
            .local()
            .format("MM-DD-YYYY")}
        </Typography>
      </Alert>
      <EditOpportunityStepper id={id} setOpen={setOpen} />
    </>
  );
};
