// import Dialog from "@mui/material/Dialog";
// import DialogContent from "@mui/material/DialogContent";
// import DialogTitle from "@mui/material/DialogTitle";
// import { DatePickers } from "../../../components/widgets/date-time-picker";
// import SelectButton from "../../../components/widgets/button/button.widget";
import React from "react";
// import style from "./modal.module.css";
import { ArrowDropDownIcon, DatePicker } from "@mui/x-date-pickers";
import VtechModal from "../../../components/vtech-modal/VtechModal";
import { Box } from "@mui/material";
import moment from "moment";

export const ResponsiveDateModal = ({
  open,
  setOpen,
  setToDate,
  toDate,
  setFromDate,
  fromDate,
  handleMobileMenuClose,
}) => {
  const handleClose = () => {
    handleMobileMenuClose({ id: 8, name: "Custom Date" });
    setOpen(false);
  };

  return (
    <>
      <VtechModal
        title="Custom Date"
        open={open}
        handleClose={handleClose}
        primaryButtonLabel={"Select"}
        primaryButtonAction={handleClose}
        buttonPosition="start"
        hideXButton={true}
        sx={{ width: "432px" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            width: 1,
          }}
        >
          <DatePicker
            slots={{ openPickerIcon: ArrowDropDownIcon }}
            sx={{ width: 1 }}
            label="Select date from"
            value={moment(fromDate)}
            onChange={(x) => setFromDate(moment(x).format("YYYY-MM-DD"))}
          />
          <DatePicker
            slots={{ openPickerIcon: ArrowDropDownIcon }}
            sx={{ width: 1 }}
            label="Select date to"
            value={moment(toDate)}
            onChange={(x) => setToDate(moment(x).format("YYYY-MM-DD"))}
          />
        </Box>
      </VtechModal>

      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        classes={{ paperWidthSm: style.modalWidth }}
      >
        <DialogTitle id="responsive-dialog-title">{"Custom Date"}</DialogTitle>
        <DialogContent>
          <>
            <div style={{ marginTop: "20px" }}>
              <DatePickers
                type="date"
                label="Select date from"
                value={fromDate}
                onChange={(value) => setFromDate(value)}
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <DatePickers
                type="date"
                label="Select date to"
                value={toDate}
                onChange={(value) => setToDate(value)}
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <SelectButton title="Select" onClick={handleClose} />
            </div>
          </>
        </DialogContent>
      </Dialog> */}
    </>
  );
};
