import React from "react";
import { Button, Container, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";

export const ForbiddenPage = () => {
  return (
    <Container component="main" maxWidth="xs">
      <Typography variant="h1">Oops!</Typography>
      <Typography variant="h6" gutterBottom>
        404 - THE PAGE CAN'T BE FOUND
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<HomeIcon />}
        href="/"
      >
        Go Back
      </Button>
    </Container>
  );
};

export default ForbiddenPage;
