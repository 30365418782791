import {
  createCampaign,
  getAllCampaigns,
  getCampaignsWOL,
  deleteCampaign,
} from "../../services/campaign.services";
import { showSnackbar } from "../../snackbar/snackbarSlice";
import {
  campaignFail,
  campaignLoading,
  deleteCampaignSuccess,
  fetchCampaignSuccess,
  openCampaignForm,
  setCampaignPagination,
  stopLoading,
} from "./campaign.slice";

export const getCampaigns = (page, limit, from, to, query) => (dispatch) => {
  dispatch(campaignLoading());
  getAllCampaigns(page, limit, from, to, query)
    .then((res) => {
      dispatch(fetchCampaignSuccess(res.data.data));
      dispatch(
        setCampaignPagination({
          total: res.data.total,
          limit: res.data.per_page,
        })
      );
    })
    .catch((err) => {
      console.log("====================================");
      console.log(err);
      console.log("====================================");
    });
};

export const getAllCampaignWOL = () => (dispatch) => {
  getCampaignsWOL()
    .then((res) => {
      dispatch(fetchCampaignSuccess(res.data.data));
      dispatch(
        setCampaignPagination({
          total: res.data.total,
          limit: res.data.per_page,
        })
      );
    })
    .catch((err) => {
      console.log("====================================");
      console.log(err);
      console.log("====================================");
    });
};

export const addCampaign =
  (data, page, limit, from, to, handleClose, resetForm) => (dispatch) => {
    dispatch(campaignLoading());
    createCampaign(data)
      .then((res) => {
        dispatch(stopLoading());
        dispatch(showSnackbar("Campaign added successfully!"));
        dispatch(getCampaigns(page, limit, from, to, ""));
        handleClose();
        resetForm();
      })
      .catch((error) => {
        dispatch(campaignFail(error.messages[0].errors.title));
        // dispatch(
        //   showSnackbar({
        //     message: error.messages[0].errors.title,
        //     severity: "error",
        //   })
        // );
      });
  };

export const setCampaignForm = () => (dispatch) => {
  dispatch(openCampaignForm());
};

export const deleteCampaignById = (id, page, limit, from, to) => (dispatch) => {
  dispatch(campaignLoading());
  deleteCampaign(id)
    .then((res) => {
      dispatch(deleteCampaignSuccess());
      dispatch(showSnackbar("Campaign deleted successfully!"));
      dispatch(getCampaigns(page, limit, from, to, ""));
    })
    .catch((error) => {
      dispatch(campaignFail(error.messages[0].errors.title));
      dispatch(showSnackbar("Something went wrong!"));
    });
};
