import React, { useState, useEffect } from "react";
import SingleNotification from "./singleNotification";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { useDarkMode } from "../../../../utils/useDarkMode";
// import { markAsRead, setNotiForm } from "./notification.action";

const NewNotification = ({ setAnchorEl }) => {
  // const { user } = useSelector((state) => state.login);
  // const dispatch = useDispatch();
  const darkMode = useDarkMode();
  const {
    unRead,
    // isLoading
  } = useSelector((state) => state.notifications);
  const [rows, setRows] = useState([]);
  let count = 10;

  useEffect(() => {
    if (unRead && Object.keys(unRead).length > 0) {
      setRows((rows) => rows.concat(unRead.slice(0, 10)));
    }
  }, [unRead]);

  const handleLoadmore = () => {
    if (rows && Object.keys(rows).length > 0) {
      setRows((rows) => rows.concat(unRead.slice(count, count + 10)));
      count = count + 10;
    }
  };

  return (
    <>
      {Object.keys(unRead).length === 0 && (
        <>
          {" "}
          <div style={{ textAlign: "center" }}>
            <p style={{ fontSize: "15px" }}>
              You don't have any new notifications.
            </p>{" "}
            <Button
              onClick={() => setAnchorEl(null)}
              sx={{ my: 1, color: darkMode ? "#fff" : "primary" }}
            >
              Close Notifications
            </Button>
          </div>
        </>
      )}

      <div>
        {Object.keys(unRead).length > 0 &&
          rows &&
          Object.keys(rows).length > 0 &&
          rows.map((r, i) => {
            return <SingleNotification data={r} key={i} />;
          })}
        <div style={{ borderTop: "1px solid #E8E8E8", textAlign: "center" }}>
          {rows && Object.keys(rows).length < Object.keys(unRead).length && (
            <Button
              sx={{
                fontWeight: 600,
                mt: 1,
                color: darkMode ? "#fff" : "primary",
              }}
              onClick={handleLoadmore}
            >
              Load More
            </Button>
          )}

          {/* {unRead && Object.keys(unRead).length > 0 && (
            <Button
              style={{
                textTransform: "none",
              }}
              onClick={handleAllMarkAsRead}
            >
              Mark all as read
            </Button>
          )} */}
        </div>
      </div>
    </>
  );
};
export default NewNotification;
