import {
  getSellerContactById,
  getAllContactStars,
} from "../../services/contact.services";
import {
  sellersFetched,
  setIsSellerLoading,
  stopSellerLoading,
  starsFetched,
} from "./contactDetail.slice";

export const getContactStars = () => (dispatch) => {
  getAllContactStars()
    .then((res) => {
      dispatch(starsFetched(res.data.data));
    })
    .catch((err) => {
      console.log("====================================");
      console.log(err);
      console.log("====================================");
    });
};

export const getSellerById = (id) => (dispatch) => {
  dispatch(setIsSellerLoading());
  getSellerContactById(id)
    .then((res) => {
      dispatch(sellersFetched(res.data.data));
      dispatch(stopSellerLoading());
      dispatch(getContactStars());
    })
    .catch((err) => {
      dispatch(stopSellerLoading());
      console.log("====================================");
      console.log(err);
      console.log("====================================");
    });
};
