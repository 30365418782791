import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    error: "",
    successMsg: ""
};

const userSlice =  createSlice({
    name: "newUser",
    initialState,
    reducers:{
        addUserPending : (state) =>{
            state.isLoading = true;
            state.error = "";
            state.successMsg = "";
        },
        addUserSuccess : (state, action) =>{
            state.isLoading = false;
            state.successMsg = action.payload;
            state.error = "";
        },
        addUserFail : (state, action) =>{
            state.isLoading = false;
            state.successMsg = "";
            state.error = action.payload;
        },
        resetAlertMsg: (state) => {
            state.isLoading = false;
            state.successMsg = "";
            state.error = "";
        }
    }
});

export const {addUserPending, addUserSuccess, addUserFail, resetAlertMsg} = userSlice.actions;
export default userSlice.reducer;

