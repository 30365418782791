import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isAuth: false,
  error: "",
  successMsg: "",
  isReset: false,
  user: {},
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginPending: (state) => {
      state.isLoading = true;
    },
    loginSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.isAuth = true;
      state.error = "";
      state.user = payload;
    },
    loginFail: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    resetPasswordSuccess: (state, {payload}) => {
      state.isReset = true;
      state.error = "";
      state.successMsg = payload;
      state.isLoading = false;
    },
    resetResetState: (state) => {
      state.isReset = false;
      state.error = "";
      state.successMsg = "";
    },
    logoutPending: (state) => {
      state.isLoading = true;
    },
    logoutUser: (state) => {
      localStorage.removeItem("persist:root");
      state.isLoading = false;
      state = undefined;
    },
    resetAllFields: (state) => {
      state.isAuth = false;
      state.error = "";
      state.successMsg = "";
      state.isReset = false;
      state.user = {};
    }
  },
});

export const {
  loginPending,
  loginSuccess,
  loginFail,
  logoutUser,
  logoutPending,
  resetPasswordSuccess,
  resetResetState,
  resetAllFields,
} = loginSlice.actions;

export default loginSlice.reducer;
