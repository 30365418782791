import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import FilterComponent from "../filterComponent";
import TablePagination from "@mui/material/TablePagination";
// import { EnhancedTableToolbar } from "./enhance-table-toolbar";
import { ActionTableRow } from "./table-row";
import { getContactSellers } from "../contact.action";
import {
  Box,
  Button,
  Checkbox,
  InputAdornment,
  Paper,
  Stack,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import VtechButton from "../../../components/vtech-button/VtechButton";
import { Toolbar, Typography } from "@mui/material";
import ContactAddForm from "../add-form-modal";
import {
  deleteSeller,
  exportContacts,
} from "../../../services/contact.services";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { useNavigate, useParams } from "react-router-dom";
import VtechIcons from "../../../components/icons/VtechIcons";
import { useDarkMode } from "../../../utils/useDarkMode";
import { showSnackbar } from "../../../snackbar/snackbarSlice";

export default function EnhancedTable({
  query,
  setQuery,
  page,
  rowsPerPage,
  setRowsPerPage,
}) {
  const navigate = useNavigate();
  const { sellers, isSellerLoading, total } = useSelector(
    (state: any) => state.contact
  );
  const { user } = useSelector((state: any) => state.login);
  const [selected, setSelected] = useState<any>([]);
  // const [filterCheck, setFilterCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sellerList, setSellerList] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const columns = [
    { id: "firstName", label: "First Name" },
    { id: "lastName", label: "Last Name" },
    { id: "userType", label: "Phone Number" },
    { id: "Email", label: "Email" },
    { id: "action", label: " " },
  ];
  useEffect(() => {
    if (sellers && Object.keys(sellers).length > 0) {
      setSellerList(sellers.data);
    }
  }, [sellers]);

  const isSelected = (name) => {
    const check = selected.indexOf(name);
    return check === -1 ? false : true;
  };

  const handleChangePage = (e, newPage) => {
    navigate(`/contacts/${newPage + 1}`); // update the url with the new page number, data will be loaded in the useEffect in the parent component
  };

  const handleClick = (event, name: never) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    navigate(`/contacts/1`);
    // dispatch(getContactSellers(1, parseInt(event.target.value)));
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = sellerList.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  return (
    <>
      <ContactAddForm open={openModal} setOpenModal={setOpenModal} />
      <SearchAddSellers
        setQuery={setQuery}
        setOpenModal={setOpenModal}
        query={query}
      />
      <Paper sx={{ my: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selected={selected}
          setSelected={setSelected}
          loading={loading}
          setLoading={setLoading}
        />

        <TableContainer>
          <Table>
            <TableHead
              sx={{
                "& .MuiTableCell-head": {
                  bgcolor: "primary.main",
                  color: "#fff",
                },
              }}
            >
              <TableRow role="checkbox">
                <TableCell padding="checkbox">
                  <Checkbox
                    sx={{
                      color: "#fff",
                      "&.Mui-checked": { color: "white" },
                    }}
                    onClick={(event) => handleSelectAllClick(event)}
                    checked={
                      sellerList.length > 0 &&
                      selected.length === sellerList.length
                    }
                  />
                </TableCell>
                {columns.map((c) => (
                  <TableCell key={c.id} sx={{ width: 219, fontWeight: 600 }}>
                    {c.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!isSellerLoading ? (
                sellerList.map((row: any, index) => {
                  const isItemSelected = isSelected(row.id);
                  return (
                    <ActionTableRow
                      row={row}
                      user={user}
                      handleClick={handleClick}
                      isItemSelected={isItemSelected}
                      index={index}
                      setOpenModal={setOpenModal}
                    />
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}

function SearchAddSellers({ query, setQuery, setOpenModal }) {
  const [localQuery, setLocalQuery] = useState("");
  const navigate = useNavigate();
  const { page } = useParams();
  const [pageBeforeSearch, setPageBeforeSearch] = useState(page);

  useEffect(() => {
    const timer = setTimeout(() => {
      // set query once user stops typing to avoid re rendering on the whole component on every key stroke(api call will be made in the parent component)
      setQuery(localQuery);

      if (localQuery.length > 0) navigate(`/contacts/1`); // if user is searching, navigate to page 1

      // store the page number before search so that we can navigate back to that page when user clears the search
      if (localQuery.length > 0 && query.length === 0)
        setPageBeforeSearch(page);

      // if user clears the search, navigate back to the page before search
      if (localQuery.length === 0) {
        navigate(`/contacts/${pageBeforeSearch}`);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [localQuery]);

  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      alignItems={"center"}
      gap={2}
    >
      <Paper sx={{ width: 1 }} elevation={0}>
        <TextField
          sx={{ height: "47px", px: 2, py: 1 }}
          value={localQuery}
          onChange={(e) => setLocalQuery(e.target.value)}
          variant="standard"
          fullWidth
          placeholder="Search contact"
          inputProps={{
            sx: { "&::placeholder": { color: "#5A6479", opacity: 0.5 } },
          }}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <VtechIcons icon="search" />
              </InputAdornment>
            ),
          }}
        />
      </Paper>
      <VtechButton
        title={"Add new seller"}
        onClick={() => setOpenModal(true)}
        sx={{
          width: { xs: "100%", md: "auto" },
        }}
      />
    </Stack>
  );
}

function EnhancedTableToolbar(props) {
  const { numSelected, selected, setSelected, loading, setLoading } = props;
  const dispatch: any = useDispatch();
  const darkMode = useDarkMode();
  const { page } = useParams();

  const exportToCSV = (type) => {
    let dataToExport;
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    exportContacts(type, selected)
      .then((res) => {
        dataToExport = res.data.data;
        dataToExport.forEach(function (v) {
          delete v.id;
          delete v.created_at;
          delete v.deleted_at;
          delete v.updated_at;
        });
        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "contacts" + fileExtension);
      })
      .catch((err) => {
        dispatch(showSnackbar(err.messages[0].message));
      });
  };

  const handleDelete = async () => {
    if (selected.length > 0) {
      const deleted = await selected.map((r) => {
        setLoading(true);
        deleteSeller(r)
          .then((res) => {
            if (res.data.status_code === 200) {
              dispatch(showSnackbar("Contact deleted successfully!"));
              dispatch(getContactSellers(page, 10, null, "")); // TODO: limit should be dynamic from the state
              setSelected([]);
            }
          })
          .catch((err) => {
            dispatch(showSnackbar(err.messages[0].message));
          })
          .finally(() => {
            setLoading(false);
          });
      });

      if (deleted) {
        setLoading(false);
      }
    }
  };

  return (
    <Toolbar
      sx={{
        bgcolor: darkMode ? "#2E2E2E" : "#EEEEEE",
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "5px",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: 1, color: "text.secondary", fontWeight: 600 }}>
          {numSelected} item{numSelected > 1 ? "s" : ""} selected
        </Typography>
      ) : (
        <Box />
      )}
      <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}>
        <Button
          sx={{ color: "#E87063", mr: 2, px: 2 }}
          startIcon={
            <img
              src={`/icons/trash${numSelected === 0 ? "-disabled" : ""}.svg`}
            />
          }
          fullWidth
          onClick={handleDelete}
          disabled={loading || numSelected === 0}
        >
          Delete
        </Button>
        <Button
          sx={{ color: (theme) => theme.palette.text.secondary, px: 2 }}
          fullWidth
          startIcon={
            <VtechIcons
              icon={`export${numSelected === 0 ? "-disabled" : ""}`}
            />
          }
          onClick={() => exportToCSV("selected")}
          disabled={loading || numSelected === 0}
        >
          Export
        </Button>
      </Box>
    </Toolbar>
  );
}
