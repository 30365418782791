import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useSelector, useDispatch } from "react-redux";
import { closeSnackbar } from "../../../snackbar/snackbarSlice";

export default function PositionedSnackbar() {
  const dispatch = useDispatch();
  const { severity } = useSelector((state: any) => state.snackbar);
  const { openSnackBar } = useSelector((state: any) => state.snackbar);
  const { message } = useSelector((state: any) => state.snackbar);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={openSnackBar}
      autoHideDuration={5000}
      onClose={() => dispatch(closeSnackbar())}
      sx={{ "&.MuiSnackbar-root": { top: 55, right: 56 }, maxWidth: 320 }}
      ClickAwayListenerProps={{ onClickAway: () => null }}
    >
      <Alert
        severity={severity}
        variant="filled"
        sx={{
          "&.MuiAlert-filledSuccess": { bgcolor: "#29B6F6" },
          color: "#fff",
          minWidth: "320px",
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
