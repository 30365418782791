import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
// import axios from "axios";
import MainMenuList from "./ListItems";
import {
  DARK_MODE_PAPER_BACKROUND,
  LIGHT_MODE_DRAWER_BACKGROUND,
} from "../../../theme";
import { useDarkMode } from "../../../utils/useDarkMode";

const MenuAppBar = () => {
  // const [image, setImage] = useState({ preview: "", raw: "" });
  // const [isImage, setIsImage] = useState(false);
  const darkMode = useDarkMode();
  // useEffect(() => {
  //   if (user) {
  //     if (user.avatar !== "") {
  //       setImage({ preview: user.avatar, raw: "" });
  //       axios
  //         .get(`${user.avatar}`)
  //         .then(() => {
  //           setIsImage(true);
  //         })
  //         .catch(() => {
  //           setImage({ preview: user.avatar, raw: "" });
  //           setIsImage(true);
  //         });
  //     } else {
  //       setImage({ preview: user.avatar, raw: "" });
  //     }
  //   }
  // }, [user]);

  return (
    <Drawer
      variant="permanent"
      sx={{
        "& .MuiDrawer-paper": {
          minWidth: "240px",
          borderRight: "none",
          bgcolor: darkMode
            ? DARK_MODE_PAPER_BACKROUND
            : LIGHT_MODE_DRAWER_BACKGROUND,
        },
        "& .MuiTypography-root": {
          fontSize: "14px",
          fontWeight: 600,
          color: "#fff",
        },
      }}
    >
      <Box
        component={"img"}
        sx={{ width: 200, mx: "auto", mt: 2, mb: 4 }}
        src="/images/vital-home-logo-light.png"
        alt="logo"
      />
      <MainMenuList />
    </Drawer>
  );
};

export default MenuAppBar;
