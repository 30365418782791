import { http } from "../apiClient/axiosInterceptor";

export const sendAutomatedResponse = (data) => {
  return http.post(`/campaign/settings/create`, data);
};

export const getResponseTemplate = (id) => {
  return http.get(`/campaign/settings/get?campaign_id=${id}`);
};

export const updateResponseTemplate = (id, data) => {
  return http.post(`/campaign/settings/update/${id}`, data);
};
