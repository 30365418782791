import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  graphStats: [],
  thisCampaignStats: [],
  otherCampaignStats: [],
  isCampaignStatsLoading: false,
  thisCampaignOpportunityStats: [],
  otherCampaignOpportunityStats: [],
  error: "",
};

const campaignStatsSlice = createSlice({
  name: "campaignStats",
  initialState,
  reducers: {
    fetchCampaignStatsLoading: (state) => {
      state.isCampaignStatsLoading = true;
    },
    stopCampaignStatsLoading: (state) => {
      state.isCampaignStatsLoading = false;
    },
    fetchCampaignStatsSuccess: (state, action) => {
      state.graphStats = action.payload;
      state.isCampaignStatsLoading = false;
    },
    fetchThisCampaignStatsSuccess: (state, action) => {
      state.thisCampaignStats = action.payload;
    },
    fetchThisCampaignOpportunityStatSuccess: (state, action) => {
      state.thisCampaignOpportunityStats = action.payload;
    },
    fetchOtherCampaignOpportunityStatSuccess: (state, action) => {
      state.otherCampaignOpportunityStats = action.payload;
    },
    fetchOtherCampaignStatsSuccess: (state, action) => {
      state.otherCampaignStats = action.payload;
    },
    fetchCampaignStatsFail: (state, action) => {
      state.isCampaignStatsLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  stopCampaignStatsLoading,
  fetchCampaignStatsSuccess,
  fetchCampaignStatsLoading,
  fetchThisCampaignStatsSuccess,
  fetchOtherCampaignStatsSuccess,
  fetchCampaignStatsFail,
  fetchThisCampaignOpportunityStatSuccess,
  fetchOtherCampaignOpportunityStatSuccess,
} = campaignStatsSlice.actions;
export default campaignStatsSlice.reducer;
