import { http } from "../apiClient/axiosInterceptor";

export const getAllCampaigns = (page, limit, from, to, query) => {
  if (query) {
    return http.get(
      `/campaign/get?page=${page}&limit=${limit}&from=${from}&to=${to}&query=${query}`
    );
  }
  return http.get(
    `/campaign/get?page=${page}&limit=${limit}&from=${from}&to=${to}`
  );
};

export const getCampaignsWOL = () => {
  return http.get(`/campaign/get?type=owner`);
};

export const getSingleCampaign = (id) => {
  return http.get(`/campaign/get/${id}`);
};

export const deleteCampaign = (id) => {
  return http.delete(`/campaign/delete/${id}`);
};

export const createCampaign = (data) => {
  return http.post(`/campaign/create`, data);
};

export const getActions = () => {
  return http.get(`/steps/get`);
};

export const editCampaignDetails = (id, data) => {
  return http.post(`/campaign/update/${id}`, data);
};

export const getLabels = (id) => {
  return http.get(`/campaign/label/get?campaign_id=${id}`);
};

export const postLabel = (id, name) => {
  return http.post(`/campaign/label/create`, { campaign_id: id, name: name });
};

export const deleteLabel = (id) => {
  return http.delete(`/campaign/label/delete/${id}`);
};
