import React, { useState } from "react";
// import MenuTabs from "./menu-tabs";
// import SequenceAppBar from "./app-bar";
import SequenceDetails from "./sequence-details";
import SequenceSteps from "./sequence-steps";
import SequenceNextAction from "./sequence-next-action";
import { Box } from "@mui/material";
import VtechTabs from "../../../components/tabs/VtechTabs";

export default function SequenceHeader() {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = ["Automation details", "Steps", "Next Operation"];
  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* <MenuTabs activeTab={activeTab} setActiveTab={setActiveTab} /> */}
      <VtechTabs tabs={tabs} activeTab={activeTab} setTab={setActiveTab} />
        <Box sx={{ maxWidth: { xs: 1, md: '70%' } }}>
          {activeTab === 0 && <SequenceDetails />}
          {activeTab === 1 && <SequenceSteps />}
          {activeTab === 2 && <SequenceNextAction />}
        </Box>
    </Box>
  );
}
