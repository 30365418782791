import { Box, MenuItem, TextField } from "@mui/material";
import React from "react";
import MenuSelector from "../menu-selection/index";

const TextResponse = ({
  message,
  response,
  createText,
  setMessage,
  setResponse,
  setText,
  resError,
  textError,
  allResponse,
  setEditable,
  setEditableId,
}) => {
  const handleMessageChange = (e) => {
    if (e.target.value === "Create New Message") {
      setMessage(e.target.value);
      setEditableId(0);
      setResponse("");
      setText("");
      setEditable(false);
    } else {
      const tempObj = e.target.value;
      setEditableId(tempObj.id);
      setMessage(tempObj);
      setResponse(tempObj.name);
      setText(tempObj.message.toString());
      setEditable(true);
    }
  };
  return (
    <>
      <TextField
        id="outlined-select-currency"
        select
        label="Choose Message"
        value={message}
        style={{ width: "100%" }}
        onChange={(e) => handleMessageChange(e)}
        variant="outlined"
      >
        <MenuItem value="Create New Message">Create New Message</MenuItem>
        {allResponse &&
          Object.keys(allResponse).length > 0 &&
          allResponse.map(
            (item) =>
              item.type === "text" && (
                <MenuItem value={item}>{item.name}</MenuItem>
              )
          )}
      </TextField>
      <br />
      <br />
      {message !== "" && (
        <>
          <TextField
            id="outlined-select-currency"
            label="Automated Seller Response Name"
            value={response}
            style={{ width: "100%" }}
            onChange={(e) => setResponse(e.target.value)}
            variant="outlined"
            error={resError}
            helperText={resError && "Please enter seller name. *"}
          />
          <br />
          <br />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              label="Message"
              multiline
              rows={4}
              fullWidth
              value={createText}
              onChange={(e) => setText(e.target.value)}
              error={textError}
              helperText={textError && "Please enter message."}
            />
            <MenuSelector
              onChange={(value) => setText(createText + " " + "$" + value)}
            />
          </Box>
          <br />
        </>
      )}
    </>
  );
};

export default TextResponse;
