import React, { useState } from "react";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  clearOpportunityFilters,
  setOpportunityFilters,
} from "../oppo-component.slice";
import { Box, Paper, Typography } from "@mui/material";
import VtechButton from "../../../../components/vtech-button/VtechButton";
import {
  TypographySpanStyles,
  lastRectangleStyle,
  midrectangleStyle,
  rectangleStyle,
} from "./piplineStyles";
import { useDarkMode } from "../../../../utils/useDarkMode";

const Stepper = ({ activeTab }) => {
  const dispatch = useDispatch();
  const darkMode = useDarkMode();
  const [followUpPipeline, setFollowUpPipeline] = useState(false);
  const { oppoFilters } = useSelector(
    (state: any) => state.opportunitiesFilters
  );
  const { pipeline: pipelineCount } = useSelector(
    (state: any) => state.opportunities
  );
  const pipeline =
    activeTab === 1 ? pipelineCount?.all_oppts : pipelineCount?.my_oppts;
  const handleFollowUpPipeline = () => {
    setFollowUpPipeline(!followUpPipeline);
  };

  const handleCheckBoxes = (key) => {
    let tempObject = Object.assign({}, oppoFilters);
    dispatch(
      setOpportunityFilters({
        ...tempObject,
        checkBoxes: {
          ...tempObject["checkBoxes"],
          [key]: !tempObject["checkBoxes"][key],
        },
      })
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
          gap: 1,
          my: 2,
        }}
      >
        <VtechButton
          title="Active opportunities"
          onClick={handleFollowUpPipeline}
          variant="text"
          endIcon={<UnfoldMoreIcon />}
          sx={{
            color: "text.primary",
            px: 1,
            width: { xs: "100%", md: "auto" },
          }}
        />
        <VtechButton
          title="Clear Filters"
          onClick={() => dispatch(clearOpportunityFilters())}
          sx={{ width: { xs: "100%", md: "auto" } }}
        />
      </Box>

      <Paper sx={{ p: 2, background: darkMode ? "#1f1f1f" : "" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1.5 }}>
          <Box
            sx={{
              ...rectangleStyle,
              bgcolor: !oppoFilters.checkBoxes.qualification
                ? darkMode
                  ? "#12121266"
                  : "#EEEEEE"
                : darkMode
                ? "#4D4D4D4D"
                : "#E9EFF9",
              // border: darkMode ? "1px solid #898989" : "none",
            }}
            position={"relative"}
          >
            <Checkbox
              sx={{ position: "absolute", top: 0, left: 2 }}
              checked={oppoFilters.checkBoxes.qualification}
              onChange={() => handleCheckBoxes("qualification")}
            />
            <Typography
              sx={{
                ...TypographySpanStyles,
                bgcolor: !oppoFilters.checkBoxes.qualification
                  ? "#939393"
                  : "#2F74EB",
              }}
            >
              {pipeline?.qualification}
            </Typography>
            <Typography>Lead Qualification</Typography>
          </Box>
          <Box
            sx={{
              ...midrectangleStyle,
              bgcolor: !oppoFilters.checkBoxes.pursue
                ? darkMode
                  ? "#12121266"
                  : "#EEEEEE"
                : darkMode
                ? "#4D4D4D66"
                : "#DCE6F9",
            }}
            position={"relative"}
          >
            <Checkbox
              sx={{ position: "absolute", top: 0, left: 30 }}
              checked={oppoFilters.checkBoxes.pursue}
              onChange={() => handleCheckBoxes("pursue")}
            />
            <Typography
              sx={{
                ...TypographySpanStyles,
                bgcolor: !oppoFilters.checkBoxes.pursue ? "#939393" : "#2F74EB",
              }}
            >
              {pipeline?.pursue}
            </Typography>
            <Typography>Pursue</Typography>
          </Box>
          <Box
            sx={{
              ...midrectangleStyle,
              bgcolor: !oppoFilters.checkBoxes.transaction
                ? darkMode
                  ? "#12121266"
                  : "#EEEEEE"
                : darkMode
                ? "#4D4D4D99"
                : "#C7D9FC",
            }}
            position={"relative"}
          >
            <Checkbox
              sx={{ position: "absolute", top: 0, left: 30 }}
              checked={oppoFilters.checkBoxes.transaction}
              onChange={() => handleCheckBoxes("transaction")}
            />
            <Typography
              sx={{
                ...TypographySpanStyles,
                bgcolor: !oppoFilters.checkBoxes.transaction
                  ? "#939393"
                  : "#2F74EB",
              }}
            >
              {pipeline?.transaction}
            </Typography>
            <Typography>Transaction</Typography>
          </Box>
          <Box
            sx={{
              ...lastRectangleStyle,
              bgcolor: !oppoFilters.checkBoxes.follow_up
                ? darkMode
                  ? "#12121266"
                  : "#EEEEEE"
                : darkMode
                ? "#4D4D4D"
                : "#ADC7FA",
            }}
            position={"relative"}
          >
            <Checkbox
              sx={{ position: "absolute", top: 0, left: 30 }}
              checked={oppoFilters.checkBoxes.follow_up}
              onChange={() => handleCheckBoxes("follow_up")}
            />
            <Typography
              sx={{
                ...TypographySpanStyles,
                bgcolor: !oppoFilters.checkBoxes.follow_up
                  ? "#939393"
                  : "#2F74EB",
              }}
            >
              {pipeline?.follow_up}
            </Typography>
            <Typography>Follow up</Typography>
          </Box>
        </Box>
        {followUpPipeline && (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                ...rectangleStyle,
                bgcolor: !oppoFilters.checkBoxes.realtor
                  ? darkMode
                    ? "#12121266"
                    : "#EEEEEE"
                  : darkMode
                  ? "#2E2E2E"
                  : "#E9EFF9",
              }}
              position={"relative"}
            >
              <Checkbox
                sx={{ position: "absolute", top: 0, left: 30 }}
                checked={oppoFilters.checkBoxes.realtor}
                onChange={() => handleCheckBoxes("realtor")}
              />
              <Typography
                sx={{
                  ...TypographySpanStyles,
                  bgcolor: !oppoFilters.checkBoxes.realtor
                    ? "#939393"
                    : "#F3BB2B",
                }}
              >
                {pipeline?.realtor}
              </Typography>
              <Typography>Realtor</Typography>
            </Box>
            <Box
              sx={{
                ...midrectangleStyle,
                bgcolor: !oppoFilters.checkBoxes.opt_out
                  ? darkMode
                    ? "#12121266"
                    : "#EEEEEE"
                  : darkMode
                  ? "#2E2E2E"
                  : "#E9EFF9",
              }}
              position={"relative"}
            >
              <Checkbox
                sx={{ position: "absolute", top: 0, left: 30 }}
                checked={oppoFilters.checkBoxes.opt_out}
                onChange={() => handleCheckBoxes("opt_out")}
              />
              <Typography
                sx={{
                  ...TypographySpanStyles,
                  bgcolor: !oppoFilters.checkBoxes.opt_out
                    ? "#939393"
                    : "#8C919B",
                }}
              >
                {pipeline?.opt_out}
              </Typography>
              <Typography>Withdraw</Typography>
            </Box>
            <Box
              sx={{
                ...lastRectangleStyle,
                bgcolor: !oppoFilters.checkBoxes.dead
                  ? darkMode
                    ? "#12121266"
                    : "#EEEEEE"
                  : darkMode
                  ? "#2E2E2E"
                  : "#E9EFF9",
              }}
              position={"relative"}
            >
              <Checkbox
                sx={{ position: "absolute", top: 0, left: 30 }}
                checked={oppoFilters.checkBoxes.dead}
                onChange={() => handleCheckBoxes("dead")}
              />
              <Typography
                sx={{
                  ...TypographySpanStyles,
                  bgcolor: !oppoFilters.checkBoxes.dead ? "#939393" : "#E87063",
                }}
              >
                {pipeline?.dead}
              </Typography>
              <Typography>Dead</Typography>
            </Box>
          </Box>
        )}
      </Paper>
    </>
  );
};

export default Stepper;
