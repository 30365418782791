import { TextField } from "@mui/material";
import React, { useRef } from "react";
import Alert from "@mui/material/Alert";
import InfoIcon from "@mui/icons-material/Info";
import UploadButton from "../../../../../components/widgets/button/button.widget";

const VoiceMailTemplate = ({ sequenceData, setSequenceData }) => {
  const inputFile = useRef(null);

  const handleChange = (e) => {
    if (e.target.files.length) {
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onloadend = function () {
        setSequenceData({
          ...sequenceData,
          voicemail: reader.result,
          voicemail_name: e.target.files[0].name,
        });
        setTimeout(() => {
          inputFile.current.value = "";
        }, 5000);
      };
    }
  };

  const handleUpload = () => {
    inputFile.current.click();
  };

  return (
    <>
      <div>
        <TextField
          id="outlined-basic"
          type="number"
          label="Callback Number"
          placeholder="Callback Number"
          variant="outlined"
          value={sequenceData.callback_number}
          onChange={(e) =>
            setSequenceData({
              ...sequenceData,
              callback_number: e.target.value,
            })
          }
        />
        <br />
        <br />
        <input
          type="file"
          id="upload-button"
          style={{ display: "none" }}
          ref={inputFile}
          accept=".wav"
          onChange={handleChange}
        />
        <UploadButton
          title="Upload File"
          onClick={handleUpload}
          styles={{
            fontSize: "10px",
            width: "fit-content",
          }}
        />
        {`   ${sequenceData.voicemail_name}`}
        <br />
        <br />
        <Alert
          severity="error"
          variant="filled"
          icon={<InfoIcon />}
          style={{
            backgroundColor: "#a9a9a9",
            fontWeight: 700,
            width: "fit-content",
          }}
        >
          Files must be less than 60 seconds and a .wav format
        </Alert>
      </div>
    </>
  );
};

export default VoiceMailTemplate;
