// use this component to render dark/light mode icons if they are available in the /public/icons folder
import React from "react";
import { useDarkMode } from "../../utils/useDarkMode";
import { IconName } from "./iconList"; // Import the list of available icons which has -dark icon available

type Props = {
  icon: IconName;
};

function VtechIcons({ icon }: Props) {
  const darkMode = useDarkMode();
  return <img src={`/icons/${icon}${darkMode ? "-dark" : ""}.svg`} />;
}

export default VtechIcons;
