import { Box, MenuItem, TextField } from "@mui/material";
import React, { useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import MenuSelector from "../menu-selection";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  Modifier,
  convertFromHTML,
  ContentState,
} from "draft-js";
import { useDarkMode } from "../../../../../utils/useDarkMode";
import "./editorClasses.css";
import { stateToHTML } from "draft-js-export-html";
import ReactDraftEditor from "../../../../../components/editor/react-draft-wysiwyg";

const EmailResponse = ({
  message,
  response,
  setMessage,
  setResponse,
  setText,
  resError,
  textError,
  subjectErr,
  subject,
  setSubject,
  allResponse,
  setEditable,
  setEditableId,
}) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const darkMode = useDarkMode();

  React.useEffect(() => {
    const contentState = editorState.getCurrentContent();
    const html = stateToHTML(contentState);
    // const rawContentState = convertToRaw(contentState);
    setText(html);
  }, [editorState]);

  const onEditorStateChange = (value) => {
    if (typeof value !== "string") {
      setEditorState(value);
    } else {
      const textToInsert = `$${value}`;
      const currentContent = editorState.getCurrentContent();
      const currentSelection = editorState.getSelection();
      const newContent = Modifier.insertText(
        currentContent,
        currentSelection,
        textToInsert
      );
      setEditorState(
        EditorState.push(editorState, newContent, "insert-characters")
      );
    }
  };
  const handleMessageChange = (e) => {
    if (e.target.value === 1) {
      setMessage(e.target.value);
      setEditableId(0);
      setResponse("");
      setSubject("");
      setText("");
      setEditorState(EditorState.createEmpty());
      setEditable(false);
    } else {
      const tempObj = e.target.value;
      setEditableId(tempObj.id);
      setMessage(tempObj);
      setResponse(tempObj.name);
      setText(tempObj.message);
      setSubject(tempObj.subject);
      setEditorState("");
      try {
        const raw = convertFromHTML(tempObj.message);
        const state = ContentState.createFromBlockArray(
          raw.contentBlocks,
          raw.entityMap
        );
        const newEditorState = EditorState.createWithContent(state);
        setEditorState(newEditorState);

        // const contentBlock = convertFromRaw(JSON.parse(tempObj.message));
        // setEditorState(EditorState.createWithContent(contentBlock));
      } catch (e) {
        // setEditorState(EditorState.createEmpty());
        console.log(e);
      }

      setEditable(true);
    }
  };
  return (
    <>
      <TextField
        select
        label="Choose Message"
        value={message}
        fullWidth
        onChange={(e) => handleMessageChange(e)}
        sx={{ mb: 2.5 }}
      >
        <MenuItem value={1}>Create New Message</MenuItem>
        {allResponse &&
          Object.keys(allResponse).length > 0 &&
          allResponse.map(
            (item) =>
              item.type === "email" && (
                <MenuItem value={item}>{item.name}</MenuItem>
              )
          )}
      </TextField>
      {message !== "" && (
        <>
          <TextField
            label="Name of the automated seller response"
            value={response}
            fullWidth
            sx={{ mb: 2.5 }}
            onChange={(e) => setResponse(e.target.value)}
            error={resError}
            helperText={resError && "Please enter seller name."}
          />
          <Box sx={{ display: "flex", alignItems: "center", mb: 2.5 }}>
            <TextField
              label="Subject"
              value={subject}
              fullWidth
              onChange={(e) => setSubject(e.target.value)}
              error={subjectErr}
              helperText={subjectErr && "Enter subject."}
            />

            <MenuSelector
              onChange={(value) => setSubject(subject + "$" + value)}
            />
          </Box>
          <ReactDraftEditor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            key={1}
            darkMode={darkMode}
          />
          {/* <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                border: 1,
                borderColor: darkMode ? "#535353" : "#c4c4c4",
                borderRadius: 1,
                p: 1,
                mb: 1,
                maxWidth: 410,
              }}
            >
              <Editor
                editorState={editorState}
                toolbarClassName={darkMode ? "darkToolbar" : "toolbarClassName"}
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
              />
            </Box>
            <MenuSelector onChange={onEditorStateChange} />
          </Box> */}
          {textError && (
            <div style={{ color: "red" }}>
              <p>Please type email.</p>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default EmailResponse;
