import React, { useState, useEffect } from "react";
import SingleNotification from "./singleNotification";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { setNotiForm } from "./notification.action";
import { useDarkMode } from "../../../../utils/useDarkMode";

const ArchiveNotification = ({ setAnchorEl }) => {
  const dispatch = useDispatch();
  const darkMode = useDarkMode();
  const { archive } = useSelector((state) => state.notifications);
  const [rows, setRows] = useState([]);
  let count = 0;

  useEffect(() => {
    if (archive && Object.keys(archive).length > 0) {
      setRows((rows) => rows.concat(archive.slice(0, 10)));
    }
  }, [archive]);

  const handleLoadmore = () => {
    if (rows && Object.keys(rows).length > 0) {
      setRows((rows) => rows.concat(archive.slice(count, count + 10)));
      count = count + 10;
    }
  };

  return (
    <>
      {Object.keys(archive).length === 0 && (
        <>
          {" "}
          <div style={{ textAlign: "center" }}>
            <p style={{ fontSize: "15px" }}>You don't have notifications.</p>{" "}
            <Button
              onClick={() => setAnchorEl(null)}
              sx={{ my: 1, color: darkMode ? "#fff" : "primary" }}
            >
              Close Notifications
            </Button>
          </div>
        </>
      )}
      <div>
        {rows &&
          Object.keys(rows).length > 0 &&
          rows.map((r, i) => {
            return <SingleNotification data={r} key={i} />;
          })}
        <div style={{ borderTop: "1px solid #E8E8E8", textAlign: "center" }}>
          {rows && Object.keys(rows).length < Object.keys(archive).length && (
            <Button
              sx={{
                fontWeight: 600,
                mt: 1,
                color: darkMode ? "#fff" : "primary",
              }}
              onClick={handleLoadmore}
            >
              Load More
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
export default ArchiveNotification;
