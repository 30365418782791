import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sequences: [],
  filteredSequences: [],
  currentSequence: {},
  activeSequence: 0,
  stepsQueue: {},
  initialStep: {},
  nextAction: {},
  isLoading: false,
  snackMsg: "",
  snackOpen: false,
  snackSeverity: "",
  initialStepLoading: false,
};

const SequenceSlice = createSlice({
  name: "SequenceSlice",
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    fetchingSequence: (state, { payload }) => {
      state.sequences = payload;
    },
    fetchingSteps: (state, { payload }) => {
      state.stepsQueue = payload;
    },
    setFilteredSequences: (state, {payload}) => {
      state.filteredSequences = payload;
    },
    setCurrentSequence: (state, { payload }) => {
      state.currentSequence = payload;
    },
    setInitialStep: (state, { payload }) => {
      state.initialStep = payload;
    },
    setNextAction: (state, { payload }) => {
      state.nextAction = payload;
    },
    setSnackMsg: (state, { payload }) => {
      state.snackMsg = payload.msg;
      state.snackSeverity = payload.severity;
      state.snackOpen = true;
    },
    closeSnackbar: (state) => {
      state.snackMsg = "";
      state.snackSeverity = "";
      state.snackOpen = false;
    },
    setActiveSequence: (state, { payload }) => {
      state.activeSequence = payload
    },
    setInitialStepLoading: (state, { payload }) => {
      state.initialStepLoading = payload
    }
  },
});

export const {
  fetchingSequence,
  setIsLoading,
  setCurrentSequence,
  setSnackMsg,
  closeSnackbar,
  fetchingSteps,
  setInitialStep,
  setNextAction,
  setFilteredSequences,
  setActiveSequence,
  setInitialStepLoading,
} = SequenceSlice.actions;

export default SequenceSlice.reducer;
