import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  groups: [],
  isLoading: false,
  error: "",
  isFail: false,
  message: "",
  isAssignedUserToGroup: false,
  isDeleteLoading: false,
  isDeleteSuccess: false,
  isSuccess: false,
};

const assignments = createSlice({
  name: "assignments",
  initialState,
  reducers: {
    assignmentLoading: (state) => {
      state.isLoading = true;
    },
    fetchGroups: (state, { payload }) => {
      state.isLoading = false;
      state.groups = payload;
    },
    stopAssignmentLoading: (state) => {
      state.isLoading = false;
    },
    stopLoading: (state) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.error = "";
    },
    deleteLoading: (state) => {
      state.isDeleteLoading = true;
    },
    stopDeletion: (state, { payload }) => {
      state.isDeleteLoading = false;
      state.isDeleteSuccess = true;
      state.message = payload;
    },
    stopAssignment: (state, { payload }) => {
      state.message = payload;
      state.isLoading = false;
    },
    hideSnackar: (state) => {
      state.isFail = false;
      state.isSuccess = false;
      state.isDeleteSuccess = false;
      state.message = "";
    },
    assignmentFail: (state, action) => {
      state.isLoading = false;
      state.isFail = true;
      state.error = action.payload;
    },
  },
});

export const {
  assignmentLoading,
  assignmentFail,
  stopLoading,
  hideSnackar,
  stopAssignment,
  fetchGroups,
  deleteLoading,
  stopDeletion,
  stopAssignmentLoading,
} = assignments.actions;
export default assignments.reducer;
