import { http } from "../apiClient/axiosInterceptor";

export const getAllSellersContact = (page, limit, filters, query) => {
  const ratingValues = filters && filters.ratingValues;
  let url = `/seller/get?page=${page}&limit=${limit}`;

  if (query) {
    url = url + `&query=${query}`;
  }

  if (ratingValues) {
    if (ratingValues !== "all") {
      url = url + `&rating=${ratingValues}`;
    }
  }

  return http.get(url);
};

export const exportContacts = (type, ids) => {
  let url = `/seller/get?type=owner`;
  if (type !== "all") {
    ids.map((r) => (url = url + `&id[]=${r}`));
  }

  return http.get(url);
};

export const createSeller = (data) => {
  return http.post(`/seller/create`, data);
};

export const updateRatingOfSeller = (data) => {
  return http.post(`/stars/contact`, data);
};

export const getSellerContactById = (id) => {
  return http.get(`/seller/get/${id}`);
};

export const updateSeller = (id, data) => {
  return http.post(`/seller/update/${id}`, data);
};

export const getSellerLabels = (id) => {
  return http.get(`/contact/label/get/${id}`);
};

export const postSellerLabel = (id, name) => {
  return http.post(`/contact/label/create`, {
    contact_id: id,
    name: name,
  });
};

export const deleteSellerLabel = (id) => {
  return http.delete(`/contact/label/delete/${id}`);
};

export const deleteSeller = (id) => {
  return http.delete(`/seller/delete/${id}`);
};

export const getAllContactStars = () => {
  let url = `/stars`;
  return http.get(url);
};

export const updateStarsValues = (data) => {
  return http.post(`/stars/update`, data);
};
