export function chipColors(darkMode: boolean) {
  return {
    "under contract": {
      bg: darkMode ? "#1B162A" : "#F9F5FF",
      text: darkMode ? "#8364CC" : "#6941C6",
      dot: darkMode ? "#7337FF" : "#5A13FF",
    },
    "follow up offer": {
      bg: darkMode ? "#192B16" : "#ECFDF3",
      text: "#027A48",
      dot: "#12B76A",
    },
    dead: {
      bg: darkMode ? "#2A1616" : "#FDECEC",
      text: "#D75244",
      dot: "#FF3F2B",
    },
    "pending appointment": {
      bg: darkMode ? "#212314" : "#FFFCDC",
      text: darkMode ? "#A9A9A9" : "#5A6479",
      dot: "#ECD400",
    },
    "new lead": {
      bg: darkMode ? "#16172A" : "#EEF4FF",
      text: "#2F74EB",
      dot: "#2F74EB",
    },
    "auxiliary action": {
      bg: darkMode ? "#241D0E" : "#FFF3DC",
      text: "#DA6900",
      dot: "#DA6900",
    },
    "offer creation": {
      bg: darkMode ? "#0D2126" : "#DBF8FF",
      text: "#0099BE",
      dot: "#0099BE",
    },
    "following up": {
      bg: darkMode ? "#281524" : "#FDECF9",
      text: "#D118BF",
      dot: "#D118BF",
    },
    closed: {
      bg: darkMode ? "#212021" : "#EBEBEB",
      text: darkMode ? "#7A7A7A" : "#535353",
      dot: darkMode ? "#7A7A7A" : "#464646",
    },
    "pursuing first contact attempt 2": {
      bg: darkMode ? "#1A2208" : "#F5FFDF",
      text: "#628C06",
      dot: "#628C06",
    },
    "outsourced to realtor": {
      bg: darkMode ? "#09211B" : "#E3FFF8",
      text: "#3A9E86",
      dot: "#3A9E86",
    },
    "in follow up automation": {
      bg: darkMode ? "#0C0A39" : "#E0DFFF",
      text: "#6762FD",
      dot: "#6762FD",
    },
    "due diligence": {
      bg: darkMode ? "#231616" : "#FFDFDF",
      text: darkMode ? "#A77C7C" : "#846262",
      dot: darkMode ? "#A77C7C" : "#846262",
    },
    cancelled: {
      // same as closed
      bg: darkMode ? "#212021" : "#EBEBEB",
      text: darkMode ? "#7A7A7A" : "#535353",
      dot: darkMode ? "#7A7A7A" : "#464646",
    },
    "analyzing property": {
      // same as due diligence
      bg: darkMode ? "#231616" : "#FFDFDF",
      text: darkMode ? "#A77C7C" : "#846262",
      dot: darkMode ? "#A77C7C" : "#846262",
    },
    released: {
      // same as follow up offer
      bg: darkMode ? "#192B16" : "#ECFDF3",
      text: "#027A48",
      dot: "#12B76A",
    },
    pending: {
      // same as dead
      bg: darkMode ? "#2A1616" : "#FDECEC",
      text: "#D75244",
      dot: "#FF3F2B",
    },
    active: {
      // same as follow up offer
      bg: darkMode ? "#192B16" : "#ECFDF3",
      text: "#027A48",
      dot: "#12B76A",
    },
  };
}
