import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  error: "",
  isFormOpen: false,
  isSellerLoading: false,
  sellers: [],
  total: 0,
  limit: 10,
  sellerSnackbar: false,
  isSuccess: false,
};

const sellerData = createSlice({
  name: "contact",
  initialState,
  reducers: {
    setIsSellerLoading: (state) => {
      state.isSellerLoading = true;
    },
    openContactForm: (state) => {
      state.isFormOpen = !state.isFormOpen;
    },
    stopSellerLoading: (state) => {
      state.isSellerLoading = false;
      state.error = "";
      state.isSuccess = true;
    },
    errorInFetch: (state, { payload }) => {
      state.isSellerLoading = false;
      state.error = payload;
    },
    sellersFetched: (state, { payload }) => {
      state.isSellerLoading = false;
      state.sellers = payload;
    },
    handleSellerSnack: (state) => {
      state.sellerSnackbar = !state.sellerSnackbar;
    },
    setPagination: (state, action) => {
      state.total = action.payload.total;
      state.limit = action.payload.limit;
    },
  },
});

export const {
  setIsSellerLoading,
  sellersFetched,
  stopSellerLoading,
  handleSellerSnack,
  openContactForm,
  errorInFetch,
  setPagination,
} = sellerData.actions;
export default sellerData.reducer;
