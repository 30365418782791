import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

type Props = {
  sx?: any;
  value: any;
  setValue: any;
  items: any;
  label: string;
  disable?: boolean;
};

export const SelectionWidget = ({
  sx,
  value,
  setValue,
  items,
  label,
  disable,
}: Props) => {
  return (
    <FormControl fullWidth sx={{ ...sx }}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        disabled={disable}
        onChange={(e) => setValue(e.target.value)}
        label={label}
      >
        {items?.map((item) => (
          <MenuItem value={item.value}>{item.title}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
