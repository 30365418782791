import React, { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProgressBar from "../../components/widgets/progress-bar/index";
import SnackBar from "../../components/widgets/snackbar/index";
import { getSellerById } from "./contactDetail.action";
import { useParams } from "react-router";
import MainLayout from "../../components/MainLayout/MainLayout";

const InfoTab = lazy(() => import("./info-tab"));

const OpportunityDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { seller } = useSelector((state) => state.contactDetails);
  const title = `${seller[0]?.first_name} ${seller[0]?.last_name}`;
  const crumbs = ["Contacts", "Contact details"];

  useEffect(() => {
    dispatch(getSellerById(id));
  }, [dispatch, id]);

  return (
    <MainLayout divider title={title} crumbs={crumbs}>
      <Suspense fallback={<ProgressBar />}>
        <InfoTab />
      </Suspense>
    </MainLayout>
  );
};

export default OpportunityDetails;
