import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import TextField from "@mui/material/TextField";
import React from "react";
import SubmissionButton from "../../../../../components/widgets/button/button.widget";
import { USA_STATE } from "../../../../../utils/usa-states";
import { PlaceSearchByGoogle } from "../../../../opportunity-details/property-details/primary-tabs/propertyTabs/place-search-google-map/SearchLocationInput";
import VtechButton from "../../../../../components/vtech-button/VtechButton";

export const StepOne = ({
  handleNext,
  setPropertyAddress,
  propertyAddress,
}) => {
  const handleGoogleSearch = (val) => {
    let splitArray = val.split(", ");
    let getState = USA_STATE.filter(
      (s) => s.abbreviation === splitArray[2].split(" ")[0]
    );
    setPropertyAddress({
      ...propertyAddress,
      street_address: splitArray[0],
      state: getState.length > 0 ? getState[0].name : "",
      city: splitArray[1],
      zipcode: splitArray[2].split(" ")[1],
    });
  };

  return (
    <>
      <Box mt={4}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            gap: 2,
            mb: 2,
          }}
        >
          <PlaceSearchByGoogle
            setPropertyAddress={(val) => handleGoogleSearch(val)}
            defaultValue={propertyAddress.street_address}
          />
          <TextField
            fullWidth
            label="City"
            name="city"
            placeholder="City"
            value={propertyAddress.city}
            onChange={(e) =>
              setPropertyAddress({ ...propertyAddress, city: e.target.value })
            }
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            gap: 2,
          }}
        >
          <FormControl fullWidth>
            <InputLabel>State</InputLabel>
            <Select
              label="State"
              value={propertyAddress.state}
              onChange={(e) =>
                setPropertyAddress({
                  ...propertyAddress,
                  state: e.target.value,
                })
              }
            >
              {USA_STATE?.map((s) => (
                <MenuItem value={s.name}>{s.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            type="number"
            id="zipcode"
            label="Zip code"
            name="zipcode"
            placeholder="Zip code"
            variant="outlined"
            value={propertyAddress.zipcode}
            onChange={(e) =>
              setPropertyAddress({
                ...propertyAddress,
                zipcode: e.target.value,
              })
            }
          />
        </Box>
        {/* <SubmissionButton title="Next" onClick={handleNext} /> */}
        {/* <VtechButton title="Next" onClick={handleNext} /> */}
      </Box>
    </>
  );
};
