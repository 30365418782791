import { http } from "../apiClient/axiosInterceptor"

export const getAllSequence = (type) => {
  return http.get(`/sequence?type=${type}`);
}

export const getStepsDetails = (id) => {
  return http.get(`/sequence/step/${id}`);
}

export const getInitialStep = (id) => {
  return http.get(`/sequence/step/initial/${id}`);
}

export const getNextAction = (id) => {
  return http.get(`/sequence/step/nextAction/${id}`);
}

export const createSequence = (data) => {
  return http.post(`/sequence/create`, data);
}

export const getSequenceById = (id) => {
  return http.get(`/sequence/${id}`)
}

export const editSequenceDetails = (id, data) => {
  return http.post(`/sequence/update/${id}`, data);
}

export const editNextAction = (data) => {
  return http.post(`/sequence/assign/action`, data);
}

export const editInitialStep = (id, data) => {
  return http.post(`/sequence/step/initial/update/${id}`, data);
}

export const removeSequence = (id) => {
  return http.delete(`/sequence/delete/${id}`);
}

export const copySequence = (id) => {
  return http.get(`/sequence/duplicate/${id}`);
};

export const addNewStep = (data) => {
  return http.post(`/sequence/step/create`, data);
}

export const removeQueueStep = (id) => {
  return http.delete(`/sequence/step/delete/${id}`);
}

export const editSequenceStep = (id, data) => {
  return http.post(`/sequence/step/update/${id}`, data);
}

export const replaceSteps = (currentStepId, replaceStepId) => {
  return http.post(`/sequence/step/shuffle`, {
    current_step_id: currentStepId,
    last_step_id: replaceStepId,
  });
}

export const getFirstSequenceId = () => {
  return http.get(`/sequence/first`);
}