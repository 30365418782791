import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activityLogs: [],
  isLoading: false,
  error: "",
  total: 0,
  limit: 10,
};

const activityLogData = createSlice({
  name: "activityLogs",
  initialState,
  reducers: {
    fetchActivityLogsLoading: (state) => {
      state.isLoading = true;
    },
    fetchActivityLogsSuccess: (state, action) => {
      state.isLoading = false;
      state.activityLogs = action.payload;
    },
    fetchActivityLogsFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setPagination: (state, action) => {
      state.total = action.payload.total;
      state.limit = action.payload.limit;
    },
  },
});

export const {
  fetchActivityLogsLoading,
  fetchActivityLogsSuccess,
  fetchActivityLogsFail,
  setPagination,
} = activityLogData.actions;
export default activityLogData.reducer;
