import { http } from "../apiClient/axiosInterceptor";

export const createTask = (data) => {
  return http.post(`/opportunity/task/create`, data);
};

export const getTasks = (id) => {
  return http.get(`/opportunity/task/get?opportunity_id=${id}`);
};

export const updateTasks = (data) => {
  return http.post(`/opportunity/task/update`, data);
};

export const updateStatus = (data) => {
  return http.post(`/opportunity/task/updateStatus`, data);
};
