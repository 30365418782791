import { Badge, styled } from "@mui/material";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#E87063",
    right: 13,
    top: 13,
    pointerEvents: "none",
  },
}));

export default StyledBadge;
