import Checkbox from "@mui/material/Checkbox";
import * as FileSaver from "file-saver";
import React from "react";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import {
  deleteOpportunity,
  exportOpportunities,
} from "../../../services/opportunities";
import { fetchAllOpportunities, setLoading } from "../opportunitiesListAction";
import { Box, FormControlLabel, Typography } from "@mui/material";
import VtechButton from "../../../components/vtech-button/VtechButton";
import VtechIcons from "../../../components/icons/VtechIcons";
import { useDarkMode } from "../../../utils/useDarkMode";

function EnhancedTableHead(props) {
  const dispatch: any = useDispatch();
  const darkMode = useDarkMode();
  const { onSelectAllClick, numSelected, rowCount, selected, user } = props;

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (type) => {
    let dataToExport;
    exportOpportunities(type, selected)
      .then((res) => {
        dataToExport = res.data.data;
        dataToExport.forEach(function (v) {
          delete v.id;
          delete v.seller_id;
          delete v.campaign_id;
        });
        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "Opportunities" + fileExtension);
      })
      .catch((err) => {
        console.log(err.messages[0].message);
      });
  };

  const handleDelete = async () => {
    if (selected.length > 0) {
      dispatch(setLoading());
      deleteOpportunity(selected)
        .then((res) => {
          if (res.data.status_code === 200) {
            dispatch(fetchAllOpportunities(1, 10));
          }
        })
        .catch((err) => {
          console.log("====================================");
          console.log(err);
          console.log("====================================");
        });
    }
  };

  return (
    <>
      <Box
        sx={{
          border: `1px solid ${darkMode ? "#6B6B6B" : "#DCDCDC"}`,
          borderRadius: "5px",
          bgcolor: darkMode ? "#2E2E2E" : "#EEEEEE",
          display: "flex",
          justifyContent: "space-between",
          px: 2,
          // flexDirection: { xs: "column", md: "row" },
          py: 1,
        }}
      >
        <FormControlLabel
          label={<Typography noWrap>Select all</Typography>}
          control={
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          }
        />

        <Box>
          <VtechButton
            title="Delete"
            onClick={handleDelete}
            variant="text"
            startIcon={
              <img
                src={`/icons/trash${numSelected === 0 ? "-disabled" : ""}.svg`}
              />
            }
            sx={{ color: "#E87063", px: 1, mr: 1 }}
            disabled={selected.length === 0}
          />
          <VtechButton
            title="Export"
            onClick={() => exportToCSV("export")}
            variant="text"
            startIcon={
              <VtechIcons
                icon={`export${numSelected === 0 ? "-disabled" : ""}`}
              />
            }
            sx={{ color: "text.secondary", px: 1 }}
            disabled={selected.length === 0}
          />
        </Box>
      </Box>
    </>
  );
}

export default EnhancedTableHead;
