import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import ProgressBar from "../../../components/widgets/progress-bar/index";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import { PRIMARY_MAIN } from "../../../theme";
import { Typography } from "@mui/material";

const headCells = [
  { id: "profit", numeric: true, label: "Campaign Profit" },
  { id: "deal", numeric: true, label: "Deal Profit" },
  { id: "cost", numeric: true, label: "Cost" },
  { id: "audienceSize", numeric: true, label: "Audience Size" },
  { id: "cpd", numeric: true, label: "CPD" },
  { id: "closedDeals", numeric: true, label: "Close Deals" },
  { id: "cancelledDeals", numeric: true, label: "Cancelled Deals" },
  { id: "cpc", numeric: true, label: "CPC" },
  { id: "contract", numeric: true, label: "Contracts" },
  { id: "offers", numeric: true, label: "Offers" },
  { id: "Appointments", numeric: true, label: "Appointments" },
  { id: "cpo", numeric: true, label: "CPO" },
  { id: "opportunity", numeric: true, label: "Opportunity" },
  { id: "touches", numeric: true, label: "Touches" },
];

const handleToolTip = (value) => {
  if (value === "profit") {
    return "This is your overall profit. The calculation is deal profit - cost";
  }
  if (value === "deal") {
    return "This is a sum of the profit fields for all closed deals during the time period selected in the top right.";
  }
  if (value === "cost") {
    return "This is a sum of all campaign cost entries during the time period selected in the top right.";
  }
  if (value === "cpd") {
    return "This is the cost per deal. The calculation is cost divided by number of closed deals.";
  }
  if (value === "cpc") {
    return "This is the cost per signed contracts (when an opportunity is marked as a pending transaction). The calculation is cost divided by number of signed contracts.";
  }
  if (value === "cpo") {
    return "This is the cost per opportunities. The calculation is cost divided by number of opportunities.";
  }
};

function EnhancedTableHead() {
  const infoColumns = "profit deal cost cpd cpc cpo";
  const underDevelopment = "cpc contracts";
  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell
            sx={{
              minWidth: 200,
              bgcolor: PRIMARY_MAIN,
              position: "sticky",
              left: 0,
              zIndex: 1000,
            }}
          />
          {headCells.map((headCell, i) => (
            <TableCell
              key={headCell.id}
              sx={{
                bgcolor: PRIMARY_MAIN,
                color: "white",
                minWidth: 200,
                py: 1.6,
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {headCell.label}{" "}
                {underDevelopment.includes(headCell.id) && " (under dev.)"}{" "}
                {infoColumns.includes(headCell.id) && (
                  <Tooltip title={handleToolTip(headCell.id)}>
                    <InfoOutlinedIcon
                      style={{ color: "#29B6F6", marginLeft: 12 }}
                    />
                  </Tooltip>
                )}
              </div>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

const CampaignTotal = ({ table2Ref, handleScrollSecond }) => {
  const { isLoading, campaigns } = useSelector((state: any) => state.campaigns);

  const divideIfNotZero = (numerator, denominator) => {
    if (
      denominator === 0 ||
      isNaN(denominator) ||
      numerator === 0 ||
      isNaN(numerator)
    ) {
      return 0;
    } else {
      return (numerator / denominator).toFixed(2);
    }
  };

  const handleTotal = (value) => {
    if (value === "cpo") {
      return campaigns
        ?.map((c) =>
          c["cost"] / c["opportunities"]
            ? (c["cost"] / c["opportunities"]).toFixed(2)
            : 0
        )
        .reduce((accumulator, current) => accumulator + current, 0);
    } else if (value === "campaign_profit") {
      return campaigns
        ?.map((c) =>
          c["deal_profit"] - c["cost"] ? c["deal_profit"] - c["cost"] : 0
        )
        .reduce((accumulator, current) => accumulator + current, 0);
    } else if (value === "cpd") {
      return campaigns
        ?.map((c) =>
          divideIfNotZero(c["cost"], c["closed_deals"]) === 0
            ? 0
            : divideIfNotZero(c["cost"], c["closed_deals"])
        )
        .reduce(
          (accumulator, current) =>
            parseFloat(accumulator) + parseFloat(current),
          0
        );
    } else {
      return campaigns
        ?.map((c) => c[value])
        .reduce((accumulator, current) => accumulator + current, 0);
    }
  };

  return (
    <>
      {isLoading && <ProgressBar />}

      <TableContainer
        ref={table2Ref}
        onScroll={handleScrollSecond}
        component={Paper}
      >
        <Table>
          <EnhancedTableHead />
          <TableBody>
            <TableRow hover style={{ cursor: "pointer" }}>
              <TableCell
                sx={{
                  minWidth: 200,
                  position: "sticky",
                  left: 0,
                  backgroundColor: "background.paper",
                  zIndex: 1000,
                }}
                component="th"
                scope="row"
              >
                <Typography sx={{ opacity: 0.7, fontWeight: 600 }}>
                  Total
                </Typography>
              </TableCell>

              <TableCell>${handleTotal("campaign_profit")}</TableCell>
              <TableCell>${handleTotal("deal_profit")}</TableCell>
              <TableCell>${handleTotal("cost")}</TableCell>
              <TableCell>{handleTotal("audience_size")}</TableCell>
              <TableCell>${handleTotal("cpd")}</TableCell>
              <TableCell>{handleTotal("closed_deals")}</TableCell>
              <TableCell>{handleTotal("cancelled_deals")}</TableCell>
              <TableCell>${handleTotal("cpc")}</TableCell>
              <TableCell>{handleTotal("contracts")}</TableCell>
              <TableCell>{handleTotal("offers")}</TableCell>
              <TableCell>{handleTotal("appointments")}</TableCell>
              <TableCell>${parseFloat(handleTotal("cpo"))}</TableCell>
              <TableCell>{handleTotal("opportunities")}</TableCell>
              <TableCell>{handleTotal("touches")}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CampaignTotal;
