import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  query: '',
  oppoFilters: {
    checkBoxes: {
      qualification: true,
      pursue: true,
      transaction: true,
      follow_up: true,
      realtor: true,
      opt_out: true,
      dead: true,
    },
    campaignIds: [],
    statusIds: [],
    selectedDateFrom: '',
    selectedDateTo: '',
    statusIds: [],
    labelIds: [],
    ownerIds: [],
    motivationValues: [],
  },
};

const opportunityFiltersSlice = createSlice({
  name: 'opportunitiesFilters',
  initialState,
  reducers: {
    setOpportunityFilters: (state, { payload }) => {
      state.oppoFilters = payload;
    },
    setQueryFilter: (state, { payload }) => {
      state.query = payload;
    },
    clearOpportunityFilters: () => initialState,
  },
});

export const {
  setOpportunityFilters,
  clearOpportunityFilters,
  setQueryFilter,
} = opportunityFiltersSlice.actions;
export default opportunityFiltersSlice.reducer;
