import React, { useState } from "react";
import ChangeButton from "../../../../../components/widgets/button/button.widget";
import { StepTwo } from "./step-2";
import { StepTwoExtend } from "./step-2-extend";
import VtechButton from "../../../../../components/vtech-button/VtechButton";
import { Box } from "@mui/material";

export const SellerSelection = ({
  handleNext,
  onSubmission,
  setLoading,
  sellerData,
  setSellerData,
  sellerId,
}) => {
  const [selection, setSelection] = useState(false);

  return (
    <>
      <Box sx={{ display: "flex", gap: 1, mb: 5, mt: 2, flexWrap: "wrap" }}>
        <VtechButton
          title="Create New Seller"
          onClick={() => setSelection(!selection)}
          sx={{ mr: 2 }}
        />
        <VtechButton
          title="Choose Exiting Seller"
          onClick={() => setSelection(!selection)}
          variant="outlined"
        />
      </Box>
      {/* <ChangeButton
        title="Create New Seller"
        styles={{ margin: "10px" }}
        isWhite={selection === true ? true : false}
        onClick={() => setSelection(!selection)}
      />
      <ChangeButton
        title="Choose Exiting Seller"
        styles={{ margin: "10px" }}
        onClick={() => setSelection(!selection)}
        isWhite={selection === false ? true : false}
      /> */}
      {!selection ? (
        <StepTwo
          handleNext={handleNext}
          setLoading={setLoading}
          sellerData={sellerData}
          setSellerData={setSellerData}
          onSubmission={onSubmission}
        />
      ) : (
        <StepTwoExtend
          handleNext={handleNext}
          onSubmission={onSubmission}
          sellerId={sellerId}
          setSellerData={setSellerData}
        />
      )}
    </>
  );
};
