import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
// import TopNavbar from "../../components/layouts/top_nav_bar/index";
// import { setNotiForm } from "../../components/layouts/top_nav_bar/notification/notification.action";
import Assignment from "./assignment/index";
// import CampaignLabels from "./campaign-labels/index";
import Dashboard from "./dashboard/index";
// import style from "./details.module.css";
import EditPage from "./edit-page/index";
// import MenuTabs from "./menu-tabs/menu-tabs.component";
import {
  getCampaignActions,
  getCampaignById,
} from "./redux/edit-campaign/update-campaign.action";
import { getAllActions } from "./redux/steps/details.actions";
import Settings from "./settings/index";
import MainLayout from "../../components/MainLayout/MainLayout";
import { useNavigate } from "react-router-dom";
import VtechTabs from "../../components/tabs/VtechTabs";

const getActiveTabIndex = (url) => {
  return url.includes("edit")
    ? 1
    : url.includes("assignment")
    ? 2
    : url.includes("settings")
    ? 3
    : 0;
};
const CampaignDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const url = window.location.href;
  const [activeTab, setActiveTab] = useState(getActiveTabIndex(url));
  const { actions } = useSelector((state: any) => state.campaignDetails);
  const { campaign } = useSelector((state: any) => state.currentCampaign);
  const crumbs = ["Campaigns", campaign.title + " details"];
  const tabs = ["Dashboard", "Edit", "Assignment", "Settings"];

  const handleActiveTab = (index) => {
    setActiveTab(index);
    switch (index) {
      case 0:
        navigate("/campaigns/" + id);
        break;
      case 1:
        navigate("/campaigns/" + id + "/edit");
        break;
      case 2:
        navigate("/campaigns/" + id + "/assignment");
        break;
      case 3:
        navigate("/campaigns/" + id + "/settings");
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getCampaignById(id));
      dispatch(getCampaignActions(id));
    }
    if (actions.length <= 0) {
      dispatch(getAllActions());
    }
  }, [dispatch]);

  return (
    <MainLayout crumbs={crumbs} title="">
      <VtechTabs tabs={tabs} activeTab={activeTab} setTab={handleActiveTab} />
      {activeTab === 0 && <Dashboard />}
      {activeTab === 1 && <EditPage campaignId={id} />}
      {activeTab === 2 && <Assignment campaignId={id} />}
      {activeTab === 3 && <Settings />}
    </MainLayout>
  );
};

export default CampaignDetail;
