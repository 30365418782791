import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  thisDashboardStats: [],
  otherDashboardStats: [],
  isDashboardStatsLoading: false,
  thisOpportunityStats: [],
  otherOpportunityStats: [],
  dashboardStats: [],
  error: "",
};

const dashboardStatsSlice = createSlice({
  name: "dashboardStats",
  initialState,
  reducers: {
    fetchDashboardStatsLoading: (state) => {
      state.isDashboardStatsLoading = true;
    },
    stopDashboardStatsLoading: (state) => {
      state.isDashboardStatsLoading = false;
    },
    fetchThisDashboardStatsSuccess: (state, action) => {
      state.thisDashboardStats = action.payload;
    },
    fetchOtherDashboardStatsSuccess: (state, action) => {
      state.otherDashboardStats = action.payload;
    },
    fetchThisOpportunityStatSuccess: (state, action) => {
      state.thisOpportunityStats = action.payload;
    },
    fetchOtherOpportunityStatSuccess: (state, action) => {
      state.otherOpportunityStats = action.payload;
    },
    fetchDashboardStatsSuccess: (state, action) => {
      state.dashboardStats = action.payload;
      state.isDashboardStatsLoading = false;
    },
    fetchDashboardStatsFail: (state, action) => {
      state.isDashboardStatsLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  stopDashboardStatsLoading,
  fetchDashboardStatsLoading,
  fetchThisDashboardStatsSuccess,
  fetchOtherDashboardStatsSuccess,
  fetchDashboardStatsFail,
  fetchThisOpportunityStatSuccess,
  fetchOtherOpportunityStatSuccess,
  fetchDashboardStatsSuccess,
} = dashboardStatsSlice.actions;
export default dashboardStatsSlice.reducer;
