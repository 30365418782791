import React from "react";
import moment from "moment";
import { Box, Paper, TableCell, TableRow, Typography } from "@mui/material";
import { TimeDisplay } from "../../../components/widgets/time-boxes";
import { EditStatusModals } from "../../../components/widgets/Edit-status-action";
import FUNCTION_UTILS from "./opportunityColumns";
import { useNavigate } from "react-router-dom";
import MuiChipWithDot from "../../../components/chip/MuiChipWithDot";
import { useDarkMode } from "../../../utils/useDarkMode";

export const ActionTableRow = (props) => {
  const navigate = useNavigate();
  const darkMode = useDarkMode();
  const { handleClick, row, isItemSelected, lastTouch, createdAt, user } =
    props;

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      selected={isItemSelected}
      key={row.id}
      component={Paper}
      elevation={0}
      sx={{
        position: "relative",
        "& td:first-child": {
          borderTopLeftRadius: "6px",
          borderBottomLeftRadius: "6px",
        },
        "& td:nth-last-child(2)": {
          borderTopRightRadius: "6px",
          borderBottomRightRadius: "6px",
        },
        ".MuiTableCell-root": {
          verticalAlign: "baseline",
          pt: 1,
        },
      }}
    >
      <TableCell sx={{ p: "6px" }}>
        {/* {user.user_type === "admin" && */}
        {FUNCTION_UTILS.getCheckBox(row, isItemSelected, handleClick)}
        {/* } */}
      </TableCell>

      <TableCell sx={{ pl: 1 }}>
        {FUNCTION_UTILS.getSellerColumn(row, navigate)}
      </TableCell>
      <TableCell>{FUNCTION_UTILS.getActionColumn(row)}</TableCell>
      <TableCell>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0 auto",
            padding: "10px",
          }}
        >
          <div>
            <TimeDisplay
              title="LAST TOUCH"
              number={lastTouch !== null && lastTouch[0]}
              daysAgo={lastTouch !== null && `${lastTouch[1]} ${lastTouch[2]}`}
              active={lastTouch !== null ? true : false}
              tooltipValue={moment(row.updated_at).format("MM-DD-YYYY")}
            />
          </div>
          <div>
            <TimeDisplay title="TOUCHES" number={row.touches} active="true" />
          </div>
          <div>
            <TimeDisplay
              title="CREATED"
              number={createdAt[0]}
              daysAgo={`${createdAt[1]} ${createdAt[2]}`}
              active={true}
              tooltipValue={moment(row.created_at).format("MM-DD-YYYY")}
            />
          </div>
        </div>
      </TableCell>
      <TableCell>
        <Typography sx={{ opacity: 0.7, fontSize: 12, fontWeight: 600 }}>
          STATUS
        </Typography>

        <MuiChipWithDot
          label={
            row?.action.step_id === 26 &&
            Object.keys(row.offers).length > 0 &&
            row.offers[0]?.status &&
            row.offers[0]?.status !== "pending"
              ? row.offers[0]?.status
              : row.action?.step_status !== "pending"
              ? row.action?.step_status
              : row.action?.status
          }
          dot
        />
      </TableCell>

      <TableCell>
        <EditStatusModals
          id={row.id}
          viewNotes={true}
          notesValue={row.notes}
          fromPage="listing"
        />
      </TableCell>
      <Box
        component={TableCell}
        sx={{
          position: "absolute",
          right: 0,
          bottom: 0,
          bgcolor: darkMode ? "#434343" : "#E9EFF9",
          borderTopLeftRadius: "47px",
          px: 3,
          py: 1,
          color: "text.secondary",
          fontSize: 12,
          fontWeight: 600,
          width: "200px",
          textAlign: "center",
          borderBottomRightRadius: "6px",
        }}
      >
        {row?.action.step_id === 26 &&
        row.offers &&
        Object.keys(row.offers).length > 0 &&
        row.offers[0]?.status !== "pending"
          ? "Transaction / Completed"
          : row?.action.sub_title}
      </Box>
    </TableRow>
  );
};
