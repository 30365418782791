import React, { useEffect, useState } from "react";
// import style from "../pipeline/pipline.module.css";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { PRIMARY_MAIN } from "../../../../theme";
import {
  lastRectangleStyle,
  midrectangleStyle,
  rectangleStyle,
} from "../../../opportunities/opportunitiesComponents/stepper/piplineStyles";
import { useDarkMode } from "../../../../utils/useDarkMode";

const ActionEvents = ({ topFive, onClick, currentFilter }) => {
  const darkMode = useDarkMode();
  const [activeIndex, setActiveIndex] = useState(undefined);
  const handleActive = (name, index) => {
    if (onClick) {
      onClick(name, index);
    }
    setActiveIndex(index);
  };
  useEffect(() => {
    if (
      (currentFilter && currentFilter === "due") ||
      currentFilter === "dueToday" ||
      currentFilter === "dueSchedule" ||
      currentFilter === ""
    ) {
      setActiveIndex(undefined);
    }
  }, [currentFilter]);

  // useEffect(() => {
  //   if (JSON.parse(localStorage.getItem('actionEvents'))) {
  //     setActiveIndex(parseInt(localStorage.getItem('actionIndex')));
  //   }
  // }, []);

  const getBgColor = (i) =>
    i === 0
      ? darkMode
        ? "#4D4D4D4D"
        : "#F4F6F9"
      : i === 1
      ? darkMode
        ? "#4D4D4D66"
        : "#E9EFF9"
      : i === 2
      ? darkMode
        ? "#4D4D4D99"
        : "#DCE6F9"
      : i === 3
      ? darkMode
        ? "#4D4D4DCC"
        : "#C7D9FC"
      : i === 4
      ? darkMode
        ? "#4D4D4D"
        : "#ADC7FA"
      : "";

  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  const rectStyles = (index) => {
    if (index === 0) {
      return rectangleStyle;
    } else if (index === topFive.length - 1) {
      return lastRectangleStyle;
    } else {
      return midrectangleStyle;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        gap: 1,
        mt: 1.5,
        flexDirection: isSmallScreen ? "column" : "row",
        overflowX: "auto",
      }}
    >
      {topFive?.map((item, index) => (
        <Box
          sx={{
            ...rectStyles(index),
            height: 64,
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: activeIndex === index + 1 ? "white" : "unset",
            bgcolor:
              activeIndex === index + 1 ? PRIMARY_MAIN : getBgColor(index),
            opacity: activeIndex && activeIndex !== index + 1 && 0.4,
          }}
          onClick={() => handleActive(item.name, index + 1)}
          key={index}
        >
          <Typography
            sx={{
              bgcolor: "#2F74EB",
              color: "white",
              borderRadius: "50%",
              width: 24,
              height: 24,
              fontSize: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {item.length}
          </Typography>
          <Typography width={100}>
            {" "}
            {item.name == "Schedule seller appointment"
              ? "Schedule seller appt."
              : item.name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default ActionEvents;
