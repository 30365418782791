import TextField from "@mui/material/TextField";
import React, { useEffect, useRef, useState } from "react";
import Geocode from "react-geocode";
let autoComplete;
Geocode.setApiKey(process.env.REACT_APP_MAP_API_KEY);

export const PlaceSearchByGoogle = ({
  onChange,
  setPropertyAddress,
  defaultValue,
}) => {
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);
  const [geoCoords, setCoords] = useState({});

  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { types: ["geocode"] }
    );
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery)
    );
  };

  const handlePlaceSelect = async (updateQuery) => {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    setPropertyAddress(query);
    Geocode.fromAddress(addressObject.formatted_address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setCoords({
          latitude: lat,
          longitude: lng,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  useEffect(() => {
    if (Object.keys(geoCoords).length > 0) {
      if (onChange) {
        onChange(geoCoords);
      }
    }
    if (defaultValue) {
      setQuery(defaultValue);
    }
  }, [geoCoords, defaultValue]);

  return (
    <>
      <TextField
        inputRef={autoCompleteRef}
        value={query}
        label="Street address"
        onChange={(event) => setQuery(event.target.value)}
        fullWidth
        name={Math.random().toString(36).substring(2, 15)}
        autoComplete="new-password"
        InputProps={{
          autoComplete: "new-password",
        }}
      />
    </>
  );
};
