import { Box, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { AssigneeSelection } from "../../../assignee-selection/user-selection";
import { updateTasks } from "../../../../../services/tasks.services";
import { fetchAllActions } from "../../../../../pages/actions/actionsList.Action";
import VtechButton from "../../../../vtech-button/VtechButton";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { ArrowDropDownIcon, renderTimeViewClock } from "@mui/x-date-pickers";

export const EditTaskForm = ({
  setLoading,
  handleClose,
  id,
  taskId,
  assignId,
  taskData,
}) => {
  const dispatch = useDispatch();
  const [userSelection, setUserSelection] = useState("");
  const [newTask, setTask] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const { users } = useSelector((state) => state.users);

  useEffect(() => {
    if (taskId) {
      setTask(taskData.name);
      setSelectedDate(moment(taskData.due_date_time).format("MM-DD-YYYY"));
      setSelectedTime(moment(taskData.due_date_time).format("HH:mm"));
      setUserSelection(users.filter((user) => user.id === assignId)[0]);
    }
  }, [taskId]);

  useEffect(() => {
    setUserSelection(users.filter((user) => user.id === assignId)[0]);
  }, [users]);

  const handleSubmission = () => {
    const dateTime = selectedDate + "T" + selectedTime;
    const data = {
      id: taskId,
      opportunity_id: id,
      name: newTask,
      assign_id: Object.keys(userSelection).length > 0 ? userSelection.id : 1,
      due_date_time: dateTime,
    };
    setLoading(true);
    updateTasks(data)
      .then(() => {
        dispatch(fetchAllActions());
        setLoading(false);
        handleClose();
      })
      .catch((err) => {
        setLoading(false);
        console.log("err = ", err);
      });
  };

  return (
    <>
      <TextField
        type="text"
        label="Create new task"
        placeholder="Create new task"
        variant="outlined"
        value={newTask}
        onChange={(e) => setTask(e.target.value)}
      />
      <AssigneeSelection
        userSelection={userSelection}
        setUserSelection={setUserSelection}
      />
      <Box sx={{ display: "flex", gap: 2 }}>
        <DatePicker
          slots={{ openPickerIcon: ArrowDropDownIcon }}
          label="Due Date"
          value={selectedDate ? moment(selectedDate) : null}
          onChange={(val) => setSelectedDate(moment(val).format("YYYY-MM-DD"))}
        />
        <TimePicker
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
          }}
          slots={{ openPickerIcon: ArrowDropDownIcon }}
          label="Due Time"
          value={selectedTime ? moment(selectedTime, "HH:mm") : null}
          onChange={(val) => setSelectedTime(moment(val).format("HH:mm"))}
        />
      </Box>
      <VtechButton
        sx={{ my: 2 }}
        title="Update Task"
        onClick={handleSubmission}
      />
    </>
  );
};
