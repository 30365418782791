import React, { useState } from "react";
import { AddOpportunityForm } from "./add-opportunity-component/edit-modal";
import VtechModal from "../../../../components/vtech-modal/VtechModal";

export const ResponsiveAddOpportunityDialog = ({ open, setOpen }) => {
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const handleClose = () => {
    setOpen(false);
    setActiveStep(0);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <VtechModal
      open={open}
      handleClose={handleClose}
      title="Add Opportunity"
      primaryButtonLabel="Next"
      primaryButtonAction={handleNext}
      hideActionButtons={activeStep !== 0}
      sx={{
        minWidth: { xs: 300, lg: 900 },
        maxHeight: "unset",
        pb: activeStep !== 0 ? 7 : 0,
        overflowX: "hidden",
      }}
      scroll="body"
    >
      <AddOpportunityForm
        loading={loading}
        setLoading={setLoading}
        handleClose={handleClose}
        setActiveStep={setActiveStep}
        activeStep={activeStep}
        handleNext={handleNext}
      />
    </VtechModal>
  );
};

export default ResponsiveAddOpportunityDialog;
