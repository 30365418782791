import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { addNotesAgainstOpportunity } from "../../../../../services/opportunities";
import VtechModal from "../../../../vtech-modal/VtechModal";
import { getOpportunity } from "../../../../../pages/opportunity-details/opportunity.action";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../../../../snackbar/snackbarSlice";

export default function NotesModal({
  setOpenNotes,
  openNotes,
  id,
  notesValue,
}) {
  const [notes, setNotes] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch: any = useDispatch();

  const updateNotes = () => {
    setIsLoading(true);
    addNotesAgainstOpportunity({ opportunity_id: id, notes })
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(getOpportunity(id));
          setIsLoading(false);
          setOpenNotes(false);
          dispatch(showSnackbar("Notes updated successfully!"));
        }
      })
      .catch((err) => {
        setIsLoading(false);
        dispatch(showSnackbar("Something went wrong!"));
      });
  };

  useEffect(() => {
    setNotes(notesValue);
  }, [notesValue]);

  return (
    <>
      <VtechModal
        open={openNotes}
        handleClose={() => setOpenNotes(false)}
        title="Edit Notes"
        primaryButtonLabel={"Done"}
        primaryButtonAction={updateNotes}
        buttonPosition="start"
        isLoading={isLoading}
        sx={{ minWidth: { xs: 300, sm: 600 }, width: 1 }}
      >
        <TextField
          label="Enter your note"
          multiline
          fullWidth
          rows={8}
          onChange={(e) => setNotes(e.target.value)}
          value={notes}
        />
      </VtechModal>
    </>
  );
}
