import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  // FormControl,
  // InputLabel,
  List,
  ListItem,
  ListItemIcon,
  // Menu,
  // MenuItem,
  Popover,
  // Select,
  Typography,
  useTheme,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// import SubmissionButton from "../../components/widgets/button/button.widget";
// import { DatePickers } from "../../components/widgets/date-time-picker";
import VtechButton from "../../components/vtech-button/VtechButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { setAppliedFilters, setSearchFilters } from "./actionsListSlice";
import { getAllActions } from "../campaign-details/redux/steps/details.actions";
import { getAllUsersWOL } from "../users-list/usersListAction";
import { getAllCampaignWOL } from "../campaigns/campaigns.action";
import { getLabels } from "../opportunities/opportunitiesListAction";

const motivations = [
  { title: "All", value: "all" },
  { title: "Unknown", value: "unknown" },
  { title: "Cold", value: "cold" },
  { title: "Warm", value: "warm" },
  { title: "Hot", value: "hot" },
];

const sortAction = [
  { title: "Operation Due Date", value: " " },
  { title: "Due Today Operation", value: moment().format("YYYY-MM-DD") },
];

function FilterComponent({ filterCheck, setFilters, anchorEl, setAnchorEl }) {
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const { campaigns } = useSelector((state: any) => state.campaigns);
  const { actions } = useSelector((state: any) => state.campaignDetails);
  const { labels } = useSelector((state: any) => state.opportunities);
  const { users } = useSelector((state: any) => state.users);
  const { user } = useSelector((state: any) => state.login);
  const [campaignIds, setCampaignIds] = useState<any>([]);
  const [stepIds, setStepIds] = useState<any>([]);

  const [isTask, setIsTask] = useState(false);
  const [labelIds, setLabelIds] = useState<any>([]);
  const [ownerIds, setOwnerIds] = useState<any>([]);
  const [motivationValues, setMotivationValues] = useState<any>([]);
  const [sortActionValues, setSortActionValues] = useState<any>("");
  const [currentActions] = useState([
    1, 2, 10, 12, 14, 15, 17, 18, 19, 20, 21, 24, 26, 27,
  ]);

  const dispatch: any = useDispatch();

  const { appliedFilters, myActions } = useSelector(
    (state: any) => state.actionList
  );

  const handleEnd = () => {
    const tempOwnerIds = ownerIds.map((email) => {
      return users.filter((user) => user.email === email)[0].id;
    });
    const tempCampaignIds = campaignIds.map((title) => {
      return campaigns.filter((campaign) => campaign.title === title)[0].id;
    });
    const tempActionIds = stepIds.map((title) => {
      return actions.filter((action) => action.title === title)[0].id;
    });
    const tempLabels = labelIds.map((labelId) => {
      return labels.filter((label) => label.label === labelId)[0].id;
    });
    const data = {
      campaignIds: tempCampaignIds,
      stepIds: tempActionIds,
      labelIds: tempLabels,
      userIds: tempOwnerIds,
      sortActionValues,
      motivationValues,
      isTask,
    };
    dispatch(setAppliedFilters(JSON.stringify(data)));

    const updatedOwnerIds = ownerIds.filter(
      (ownerId) => ownerId !== user.email
    );
    dispatch(
      setSearchFilters([
        ...campaignIds,
        ...stepIds,
        ...labelIds,
        ...updatedOwnerIds,
        ...motivationValues,
        sortActionValues,
      ])
    );
    setFilters(data);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (campaigns.length === 0 && open) {
      dispatch(getAllActions());
      dispatch(getAllUsersWOL());
      dispatch(getAllCampaignWOL());
      dispatch(getLabels());
    }
  }, [open]);

  useEffect(() => {
    const searchFilters = JSON.parse(appliedFilters || "{}");
    if (searchFilters && campaigns && labels && users && actions) {
      setCampaignIds(
        searchFilters?.campaignIds
          ? searchFilters?.campaignIds?.map((campId) => {
              return campaigns?.filter((campaign) => campaign.id === campId)[0]
                ?.title;
            })
          : []
      );
      setLabelIds(
        searchFilters?.labelIds
          ? searchFilters?.labelIds?.map((labelId) => {
              return labels?.filter((label) => label.id === labelId)[0]?.label;
            })
          : []
      );
      setStepIds(
        searchFilters?.stepIds
          ? searchFilters?.stepIds?.map((stepId) => {
              return actions?.filter((action) => action.id === stepId)[0]
                ?.title;
            })
          : []
      );
      setOwnerIds(
        searchFilters?.userIds
          ? searchFilters?.userIds?.map((userId) => {
              return users?.filter((user) => user.id === userId)[0]?.email;
            })
          : []
      );
      setMotivationValues(
        searchFilters?.motivationValues ? searchFilters.motivationValues : []
      );
      setSortActionValues(
        searchFilters?.sortActionValues ? searchFilters.sortActionValues : ""
      );
    }
  }, [campaigns, labels, users, actions, appliedFilters]);

  // useEffect(() => {
  //   if (ownerIds.length === 0) {
  //     setMyAction(true);
  //   }
  // }, [myActions, ownerIds]);

  const handleTaskType = () => {
    if (isTask) {
      setIsTask(false);
    } else {
      setIsTask(true);
    }
  };

  const resetFilters = () => {
    setFilters({ loggedInUser: user.id });
    setCampaignIds([]);
    setStepIds([]);
    setMotivationValues([]);
    setLabelIds([]);
    setOwnerIds([]);
    setSortActionValues("");
    setAnchorEl(null);

    const userIds = myActions ? [user.id] : [];

    dispatch(setAppliedFilters(JSON.stringify({ userIds })));
    dispatch(setSearchFilters([]));
  };
  const handleClose = () => setAnchorEl(null);

  const handleCampaignClick = (title) => {
    if (campaignIds.includes(title)) {
      setCampaignIds(campaignIds.filter((item) => item !== title));
    } else {
      setCampaignIds([...campaignIds, title]);
    }
  };

  const handleOperationClick = (title) => {
    if (stepIds.includes(title)) {
      setStepIds(stepIds.filter((item) => item !== title));
    } else {
      setStepIds([...stepIds, title]);
    }
  };

  const handleMotivationClick = (title) => {
    if (motivationValues.includes(title)) {
      setMotivationValues(motivationValues.filter((item) => item !== title));
    } else {
      setMotivationValues([...motivationValues, title]);
    }
  };

  const handleLabelClick = (title) => {
    if (labelIds.includes(title)) {
      setLabelIds(labelIds.filter((item) => item !== title));
    } else {
      setLabelIds([...labelIds, title]);
    }
  };

  const handleUserClick = (email) => {
    if (ownerIds.includes(email)) {
      setOwnerIds(ownerIds.filter((item) => item !== email));
    } else {
      setOwnerIds([...ownerIds, email]);
    }
  };

  const handleSortActionClick = (action) => {
    if (sortActionValues === action.title) {
      setSortActionValues("");
    }
    if (sortActionValues !== action.title) {
      setSortActionValues(action.value);
    }
  };
  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box
        sx={{
          pt: 4,
          pb: 2.5,
          maxHeight: 420,
          overflowY: "auto",
          overflowX: "hidden",
          width: 309,
          ".MuiAccordionSummary-content": {
            maxWidth: 200,
          },
          ".MuiAccordion-root:before": {
            display: "none",
          },
          bgcolor: "background.paper",
        }}
      >
        {/* <FormControl fullWidth variant="outlined">
          <InputLabel>Filter By Campaign</InputLabel>
          <Select
            multiple
            value={campaignIds}
            onChange={(e) => setCampaignIds(e.target.value)}
            input={<OutlinedInput label="Filter By Campaign" />}
            renderValue={(selected) => selected.join(", ")}
            // MenuProps={MenuProps}
          >
            {campaigns.map((campaign) => (
              <MenuItem key={campaign.id} value={campaign.title}>
                <Checkbox checked={campaignIds.indexOf(campaign.title) > -1} />
                <ListItemText primary={campaign.title} />
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}

        <Accordion elevation={0} disableGutters>
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            <Typography>Filter By Campaign</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <List dense sx={{ p: 0, pl: 2 }}>
              {campaigns?.map((campaign: any, i) => (
                <ListItem key={campaign + i} sx={{ py: 0 }}>
                  <ListItemIcon sx={{ p: 0, minWidth: 0 }}>
                    <Checkbox
                      onChange={() => handleCampaignClick(campaign.title)}
                      checked={campaignIds.includes(campaign.title)}
                    />
                  </ListItemIcon>
                  <ListItemText primary={campaign.title} />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>

        {/* <FormControl fullWidth variant="outlined">
          <InputLabel id="demo-simple-select-outlined-label">
            Filter by Action
          </InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            value={stepIds}
            onChange={(e) => setStepIds(e.target.value)}
            input={<OutlinedInput label="Filter by Action" />}
            renderValue={(selected) => selected.join(", ")}
            // MenuProps={MenuProps}
          >
            {actions &&
              Object.keys(actions).length > 0 &&
              actions.map(
                (item) =>
                  currentActions.includes(item.id) && (
                    <MenuItem key={item.id} value={item.title}>
                      <Checkbox checked={stepIds.includes(item.title)} />
                      <ListItemText primary={item.title} />
                    </MenuItem>
                  )
              )}
            <MenuItem value={"Task"}>
              <Checkbox checked={isTask} onChange={handleTaskType} />
              <ListItemText primary={"Task"} />
            </MenuItem>
          </Select>
        </FormControl> */}

        <Accordion elevation={0} disableGutters>
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            <Typography>Filter by Operation</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <List dense sx={{ p: 0, pl: 2 }}>
              {actions?.map(
                (item, i) =>
                  currentActions.includes(item.id) && (
                    <ListItem key={item + i} sx={{ py: 0 }}>
                      <ListItemIcon sx={{ p: 0, minWidth: 0 }}>
                        <Checkbox
                          onChange={(e) => handleOperationClick(item.title)}
                          checked={stepIds.includes(item.title)}
                        />
                      </ListItemIcon>
                      <ListItemText primary={item.title} />
                    </ListItem>
                  )
              )}
              <ListItem sx={{ py: 0 }}>
                <ListItemIcon sx={{ p: 0, minWidth: 0 }}>
                  <Checkbox checked={isTask} onChange={handleTaskType} />
                </ListItemIcon>
                <ListItemText primary={"Task"} />
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>

        {/* <FormControl fullWidth variant="outlined">
          <InputLabel id="demo-simple-select-outlined-label">
            Filter By Motivation
          </InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple/
            value={motivationValues}
            onChange={(e) => setMotivationValues(e.target.value)}
            input={<OutlinedInput label="Filter By Motivation" />}
            renderValue={(selected) => selected.join(", ")}
            // MenuProps={MenuProps}
          >
            {motivations.map((name) => (
              <MenuItem key={name.value} value={name.value}>
                <Checkbox checked={motivationValues.indexOf(name.value) > -1} />
                <ListItemText primary={name.title} />
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}

        <Accordion elevation={0} disableGutters>
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            <Typography>Filter By Motivation</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <List dense sx={{ p: 0, pl: 2 }}>
              {motivations.map((name, i) => (
                <ListItem key={i} sx={{ py: 0 }}>
                  <ListItemIcon sx={{ p: 0, minWidth: 0 }}>
                    <Checkbox
                      onChange={(e) => handleMotivationClick(name.value)}
                      checked={motivationValues.includes(name.value)}
                    />
                  </ListItemIcon>
                  <ListItemText primary={name.title} />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>

        {/* <FormControl fullWidth variant="outlined">
          <InputLabel id="demo-simple-select-outlined-label">
            Filter By Label
          </InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            value={labelIds}
            onChange={(e) => setLabelIds(e.target.value)}
            input={<OutlinedInput label="Filter By Label" />}
            renderValue={(selected) => selected.join(", ")}
            // MenuProps={MenuProps}
          >
            {labels.map(
              (label) =>
                label.label && (
                  <MenuItem key={label.id} value={label.label}>
                    <Checkbox checked={labelIds.indexOf(label.label) > -1} />
                    <ListItemText primary={label.label} />
                  </MenuItem>
                )
            )}
          </Select>
        </FormControl> */}

        <Accordion elevation={0} disableGutters>
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            <Typography>Filter By Label</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <List dense sx={{ p: 0, pl: 2 }}>
              {labels.map((label, i) => (
                <ListItem key={i} sx={{ py: 0 }}>
                  <ListItemIcon sx={{ p: 0, minWidth: 0 }}>
                    <Checkbox
                      onChange={() => handleLabelClick(label.label)}
                      checked={labelIds.includes(label.label)}
                    />
                  </ListItemIcon>
                  <ListItemText primary={label.label} />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>

        {/* <FormControl fullWidth variant="outlined">
          <InputLabel id="demo-simple-select-outlined-label">
            Filter by users
          </InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            value={ownerIds}
            onChange={(e) =>
              e.target.value.indexOf(user.email) < 0 &&
              setOwnerIds(e.target.value)
            }
            input={<OutlinedInput label="Filter by users" />}
            renderValue={(selected) => selected.join(", ")}
            // MenuProps={MenuProps}
          >
            <MenuItem value={user.email}>
              <Checkbox
                checked={myActions}
                onChange={(e, checked) => setMyAction(checked)}
              />
              <ListItemText primary={"MyActions"} />
            </MenuItem>
            {users.map(
              (currentUser) =>
                user.id !== currentUser.id && (
                  <MenuItem key={currentUser.id} value={currentUser.email}>
                    <Checkbox
                      checked={ownerIds.indexOf(currentUser.email) > -1}
                    />
                    <ListItemText primary={currentUser.email} />
                  </MenuItem>
                )
            )}
          </Select>
        </FormControl> */}

        <Accordion elevation={0} disableGutters>
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            <Typography>Filter by users</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <List dense sx={{ p: 0, pl: 2 }}>
              {users.map(
                (currentUser, i) =>
                  user.id !== currentUser.id && (
                    <ListItem key={i} sx={{ py: 0 }}>
                      <ListItemIcon sx={{ p: 0, minWidth: 0 }}>
                        <Checkbox
                          onChange={() => handleUserClick(currentUser.email)}
                          checked={ownerIds.includes(currentUser.email)}
                        />
                      </ListItemIcon>
                      <ListItemText primary={currentUser.email} />
                    </ListItem>
                  )
              )}
            </List>
          </AccordionDetails>
        </Accordion>

        {/* <FormControl fullWidth variant="outlined">
          <InputLabel id="demo-simple-select-outlined-label">
            Sort Actions
          </InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            value={sortActionValues}
            onChange={(e) => setSortActionValues(e.target.value)}
            input={<OutlinedInput label="Sort Actions" />}
            // MenuProps={MenuProps}
          >
            {sortAction.map((name) => (
              <MenuItem key={name.value} value={name.value}>
                {name.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}

        <Accordion elevation={0} disableGutters>
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            <Typography>Sort Actions</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <List dense sx={{ p: 0, pl: 2 }}>
              {sortAction.map((action, i) => (
                <ListItem key={i} sx={{ py: 0 }}>
                  <ListItemIcon sx={{ p: 0, minWidth: 0 }}>
                    <Checkbox
                      onChange={() => handleSortActionClick(action)}
                      checked={sortActionValues === action.value}
                    />
                  </ListItemIcon>
                  <ListItemText primary={action.title} />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>

        {/* <SubmissionButton title="Clear" isWhite={true} onClick={resetFilters} />
        <SubmissionButton title="Save" onClick={handleEnd} /> */}
        <Box sx={{ textAlign: "end", mt: 3, px: 2 }}>
          <VtechButton
            sx={{ color: "text.primary" }}
            title="Clear"
            onClick={resetFilters}
            variant="text"
          />
          <VtechButton title="Save" onClick={handleEnd} />
        </Box>
      </Box>
    </Popover>
  );
}

export default FilterComponent;
