import Box from "@mui/material/Box";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProgressBar from "../../components/widgets/progress-bar/index";
import AddUserModal from "./add_user_modal/user-modal.component";
import { resetAlertMsg } from "./userSlice";
import { fetchAllUsers } from "./usersListAction";
import SnackBar from "../../components/widgets/snackbar";
import VtechButton from "../../components/vtech-button/VtechButton";
import MainLayout from "../../components/MainLayout/MainLayout";
const UserListTable = lazy(() => import("./userTable.component"));

export default function UsersList() {
  // const [openSnack, setOpenSnack] = useState(false);
  const dispatch = useDispatch();
  const [isEdit, setEdit] = useState(false);
  const [openAddUserDialog, setAddUserDialog] = useState(false);
  const [uId, setUid] = useState(0);
  const { limit } = useSelector((state) => state.users);
  // const [msg, setMsg] = useState("");

  useEffect(() => {
    dispatch(fetchAllUsers(1, limit));
  }, [dispatch]);

  const handleAddUserDialogOpen = () => {
    setEdit(false);
    setAddUserDialog(true);
  };

  const handleAddUserDialogClose = () => {
    setAddUserDialog(false);
    dispatch(resetAlertMsg());
  };

  const handleEditEvent = (id) => {
    setUid(id);
    setAddUserDialog(true);
    setEdit(true);
  };

  return (
    <MainLayout title="List of users" divider>
      <Box textAlign="end" my={2.5}>
        <VtechButton onClick={handleAddUserDialogOpen} title="Add User" />
      </Box>
      <Box>
        <Suspense fallback={<ProgressBar />}>
          <UserListTable onEditClick={(id) => handleEditEvent(id)} />
        </Suspense>
      </Box>
      <AddUserModal
        handleAddUserDialogClose={handleAddUserDialogClose}
        openAddUserDialog={openAddUserDialog}
        isEdit={isEdit}
        uid={uId}
        setEdit={setEdit}
      />
    </MainLayout>
  );
}
