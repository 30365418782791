import moment from "moment";
import { http } from "../apiClient/axiosInterceptor";

export const getOpportunityLabels = (id) => {
  return http.get(`/opportunity/label/get/${id}`);
};

export const postOppoLabel = (id, name) => {
  return http.post(`/opportunity/label/create`, {
    opportunity_id: id,
    name: name,
  });
};

export const deleteLabel = (id) => {
  return http.delete(`/opportunity/label/delete/${id}`);
};

export const updateAction = (data) => {
  return http.post(`/opportunity/updateAction`, data);
};

export const postNoteToOpportunity = (id, text) => {
  return http.post(`/opportunity/add/note`, {
    opportunity_id: id,
    notes: text,
  });
};

export const updateAndCreateNewAction = (data) => {
  return http.post("/opportunity/createNewAction", data);
};

export const UpdateOfferAgainstOpportunity = (data, id) => {
  let currentTime = moment().format("HH:mm:ss");
  data["offer_expiration_date"] = moment(data.offer_expiration_date + "T" + currentTime).utc().format("YYYY-MM-DD HH:mm:ss");
  return http.post(`/opportunity/offer/update/${id}`, data);
};


export const getAllOfferByOpportunity = (id) => {
  return http.get(`/opportunity/${id}/offer`);
}

export const cancelOpportunitySequence = (id) => {
  return http.get(`/sequence/cancel/${id}`);
}