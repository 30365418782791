import React from "react";
import Router from "./Router";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import MuiThemeProvider from "./ThemeProvider";
import { ErrorBoundary } from "react-error-boundary";

const ErrorFallback = <div>Something went wrong!</div>;

const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiThemeProvider>
        <ErrorBoundary fallback={ErrorFallback}>
          <Router />
        </ErrorBoundary>
      </MuiThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
