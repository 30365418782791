import React from "react";
import SequenceListing from "./seq-listing";
import MainLayout from "../../components/MainLayout/MainLayout";

const Automations = () => {
  return (
    <MainLayout title="Automations" divider>
      <SequenceListing />
    </MainLayout>
  );
};

export default Automations;
