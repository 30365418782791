import OpportunitiesStats from "../../../components/widgets/stats/opportunityLogs/index";
import ActionEventStats from "../../../components/widgets/stats/action-events/index";
import React, { useState, useEffect } from "react";
import {
  fetchAllCampaignStats,
  fetchAllCampaignOpportunityStats,
  fetchCampaignStatisticsCounts,
} from "./campaignStatAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";
import { ResponsiveDateModal } from "./custom-date/index";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { LIGHT_MODE_TEXT_COLOR_2, PRIMARY_MAIN } from "../../../theme";
import { useDarkMode } from "../../../utils/useDarkMode";
import { fetchCampaignStatsSuccess } from "./campaignStatSlice";

const Dashbard = () => {
  // const { campaign } = useSelector((state) => state.currentCampaign);
  const { id } = useParams();
  const darkMode = useDarkMode();
  const {
    graphStats,
    thisCampaignStats,
    otherCampaignStats,
    isCampaignStatsLoading,
    thisCampaignOpportunityStats,
    otherCampaignOpportunityStats,
  } = useSelector((state: any) => state.campaignStats);

  const dispatch: any = useDispatch();
  const isMobileDevice = useMediaQuery("(max-width: 768px)");
  const [openDateModal, setOpenDateModal] = useState(false);

  const [currentFilter, setFilter] = useState({
    id: 1,
    name: "Compare Week",
  });

  const [filterTitle, setFilterTitle] = useState("week");

  const filters = [
    { id: 1, name: "Weekly", type: "week" },
    { id: 2, name: "Monthly", type: "month" },
    { id: 3, name: "Yearly", type: "year" },
    { id: 4, name: "Custom Date", type: "custom_date" },
  ];

  useEffect(() => {
    if (id) {
      dispatch(fetchCampaignStatisticsCounts("week", id, null, null));
      dispatch(fetchAllCampaignStats("week", id));
      dispatch(fetchAllCampaignOpportunityStats("week", id));
      setFilterTitle("week");
      setFilter({ id: 1, name: "Weekly" });
    }
  }, [dispatch]);

  // temp crash prevention for campaign stats
  useEffect(() => {
    return () => {
      dispatch(fetchCampaignStatsSuccess([]));
    };
  }, []);

  const handleClick = (item) => {
    if (item.id === 1) {
      setFilterTitle("week");
      dispatch(fetchAllCampaignOpportunityStats("week", id));
    }
    if (item.id === 2) {
      setFilterTitle("month");
      dispatch(fetchAllCampaignOpportunityStats("month", id));
    }
    if (item.id === 3) {
      setFilterTitle("year");
      dispatch(fetchAllCampaignOpportunityStats("year", id));
    }
    setFilter({ id: item.id, name: item.name });
    dispatch(fetchAllCampaignStats(item.type, id));
    dispatch(fetchCampaignStatisticsCounts(item.type, id, null, null));
  };

  const handleFilterClick = (item) => {
    if (item.id !== 4) {
      handleClick(item);
    } else {
      setOpenDateModal(true);
    }
  };

  const handleCustomDateFilter = (type, from, to) => {
    setFilter({ id: 4, name: "Custom Date" });
    setFilterTitle("Custom");
    dispatch(fetchCampaignStatisticsCounts(type, id, from, to));
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: "30px",
            mb: 3,
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 1, md: 0 },
          }}
        >
          <Typography
            variant="h2"
            sx={{ fontSize: "20px", fontWeight: 700, lineHeight: "27px" }}
          >
            {filterTitle === "Custom" ? "Statistics" : "Opportunities"}
          </Typography>
          <Box>
            {filters.map((filter, i) => (
              <Button
                key={filter.id}
                onClick={() => handleFilterClick(filter)}
                disabled={isCampaignStatsLoading}
                // prettier-ignore
                sx={{
                  fontWeight: 600,
                  mr: i !== (filters.length - 1) ? 3 : 0,
                  color: currentFilter.id === filter.id ? "primary.main" : "text.primary",
                  backgroundColor: currentFilter.id === filter.id ? darkMode? "#16172A" : "#E9EFF9" : "",
                }}
              >
                {filter.name}
              </Button>
            ))}
          </Box>
        </Box>
        <Box>
          {!isMobileDevice && filterTitle !== "Custom" && (
            <OpportunitiesStats
              thisStats={thisCampaignOpportunityStats}
              otherStats={otherCampaignOpportunityStats}
              type={filterTitle}
            />
          )}
          {filterTitle !== "Custom" && (
            <Typography variant="h2" sx={{ mt: "30px", mb: 3 }}>
              Statistics
            </Typography>
          )}
        </Box>

        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid item xs={12} md={6} lg={4}>
            <ActionEventStats
              filterTitle={filterTitle}
              thisStats={thisCampaignStats?.completed_actions}
              otherStats={otherCampaignStats?.completed_actions}
              dashboardStats={graphStats?.completed_actions}
              title="Completed Operations"
              type={filterTitle}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ActionEventStats
              filterTitle={filterTitle}
              thisStats={thisCampaignStats?.touches_made}
              otherStats={otherCampaignStats?.touches_made}
              dashboardStats={graphStats?.touches_made}
              title="Touches made"
              type={filterTitle}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ActionEventStats
              filterTitle={filterTitle}
              thisStats={thisCampaignStats?.sequence_started}
              otherStats={otherCampaignStats?.sequence_started}
              dashboardStats={graphStats?.sequence_started}
              title="Sequences Started"
              type={filterTitle}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ActionEventStats
              filterTitle={filterTitle}
              thisStats={thisCampaignStats?.new_opportunities}
              otherStats={otherCampaignStats?.new_opportunities}
              dashboardStats={graphStats?.new_opportunities}
              title="New Opportunities"
              type={filterTitle}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ActionEventStats
              filterTitle={filterTitle}
              thisStats={thisCampaignStats?.appointments_booked}
              otherStats={otherCampaignStats?.appointments_booked}
              dashboardStats={graphStats?.appointments_booked}
              title="Appointments Booked"
              type={filterTitle}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ActionEventStats
              filterTitle={filterTitle}
              thisStats={thisCampaignStats?.offers_sent}
              otherStats={otherCampaignStats?.offers_sent}
              dashboardStats={graphStats?.offers_sent}
              title="Offers Sent"
              type={filterTitle}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ActionEventStats
              filterTitle={filterTitle}
              thisStats={thisCampaignStats?.contracts}
              otherStats={otherCampaignStats?.contracts}
              dashboardStats={[]}
              title="Contracts"
              type={filterTitle}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ActionEventStats
              filterTitle={filterTitle}
              thisStats={thisCampaignStats?.cancelled_deals}
              otherStats={otherCampaignStats?.cancelled_deals}
              dashboardStats={graphStats?.cancelled_deals}
              title="Cancelled Deals"
              type={filterTitle}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ActionEventStats
              filterTitle={filterTitle}
              thisStats={thisCampaignStats?.closed_deals}
              otherStats={otherCampaignStats?.closed_deals}
              dashboardStats={graphStats?.closed_deals}
              title="Closed Deals"
              type={filterTitle}
            />
          </Grid>
        </Grid>
      </Box>
      <ResponsiveDateModal
        open={openDateModal}
        setOpen={setOpenDateModal}
        handleCustomDateFilter={handleCustomDateFilter}
      />
    </>
  );
};

export default Dashbard;
