import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CardActions,
  Button,
  Box,
} from "@mui/material";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSequence,
  duplicateSequence,
  getInitialStepDetails,
  getNextActionDetails,
  getSequenceDetails,
  getSequenceSteps,
} from "../redux/sequence.actions";
import CancelAlert from "./cancel-alert";
import { getAllActions } from "../../campaign-details/redux/steps/details.actions";
import { getAllUsersWOL } from "../../users-list/usersListAction";
import { getFirstSequenceId } from "../../../services/sequences.service";
import { setActiveSequence } from "../redux/sequence.slice";
import SequencesList from ".";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { addTouch } from "../../../services/touches.service";
import AnimationIcon from "@mui/icons-material/Animation";
import VtechIcons from "../../../components/icons/VtechIcons";
import { useDarkMode } from "../../../utils/useDarkMode";

const CardContainer = styled("div")({
  position: "relative",
  "&:hover .overlay": {
    display: "block",
  },
  height: "100%",
  minHeight: "180px",
  flexDirection: "column",
});

const Overlay = styled("div")({
  display: "none",
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.5)",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 5,
});

const OverlayButtons = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 7,
  alignItems: "center",
});

const ButtonStyle = {
  width: "auto",
};

const SequenceListItem = ({ query }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const darkMode = useDarkMode();
  const [open, setOpen] = useState(false);
  const { sequences, activeSequence, isLoading } = useSelector(
    (state) => state.sequences
  );

  // useEffect(() => {
  //   if (activeSequence === 0) {
  //     getFirstSequenceId().then((res) => {
  //       if (res.data.data && res.data.data.id) {
  //         getCurrentActiveSequence(0, res.data.data.id);
  //       }
  //     });
  //   }
  // }, [activeSequence]);

  const getCurrentActiveSequence = (index, id) => {
    dispatch(setActiveSequence(index));
    dispatch(getSequenceDetails(id));
    dispatch(getSequenceSteps(id));
    dispatch(getInitialStepDetails(id));
    dispatch(getNextActionDetails(id));
    dispatch(getAllActions());
    dispatch(getAllUsersWOL());
  };

  const handleDetailsClick = (index, id) => {
    getCurrentActiveSequence(index, id);
    navigate(`/automations/${id}`);
  };

  const handleDuplicateClick = (index, id) => {
    dispatch(duplicateSequence(id));
  };

  const handleDeleteClick = (index, id) => {
    dispatch(deleteSequence(id));
  };

  return (
    !isLoading && (
      <>
        {sequences?.length > 0 ? (
          <Grid container spacing={3}>
            {sequences?.map(
              (item, index) =>
                item.title.toLowerCase().includes(query.toLowerCase()) && (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
                    <CardContainer>
                      <Card
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <CardActionArea
                          onClick={() =>
                            getCurrentActiveSequence(index, item.id)
                          }
                        >
                          <CardContent style={{ flex: 1 }}>
                            {/* <AnimationIcon sx={{
                                marginBottom: '20px',
                                backgroundColor: '#E9EFF9',
                                borderRadius: '40%',
                                padding: '5px'
                              }}
                                color="primary"
                                fontSize="large"
                              /> */}
                            <VtechIcons icon="automation" />
                            <Typography
                              gutterBottom
                              variant="subtitle1"
                              sx={{
                                fontWeight: "bold",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                              }}
                              component="div"
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              variant="body1"
                              component="div"
                              sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                              }}
                            >
                              {item.description}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                        <Overlay className="overlay">
                          <OverlayButtons sx={{ paddingTop: 3 }}>
                            <Button
                              variant="contained"
                              style={{ ...ButtonStyle, width: "50%" }}
                              startIcon={<VisibilityIcon />}
                              sx={{
                                backgroundColor: "white",
                                color: "black",
                                "&:hover": {
                                  backgroundColor: "white",
                                  color: "black",
                                },
                              }}
                              onClick={() => handleDetailsClick(index, item.id)}
                            >
                              Details
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ ...ButtonStyle, width: "50%" }}
                              startIcon={<AddIcon />}
                              onClick={() =>
                                handleDuplicateClick(index, item.id)
                              }
                            >
                              Duplicate
                            </Button>
                            <Button
                              variant="contained"
                              color="error"
                              style={{ ...ButtonStyle, width: "50%" }}
                              startIcon={<DeleteIcon />}
                              onClick={() => handleDeleteClick(index, item.id)}
                            >
                              Delete
                            </Button>
                          </OverlayButtons>
                        </Overlay>
                      </Card>
                    </CardContainer>
                  </Grid>
                )
            )}
          </Grid>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            sx={{ alignItems: "center", gap: 2 }}
          >
            <img
              style={{ width: "50%" }}
              src={`/images/no-automation${darkMode ? "-dark" : ""}.png`}
            />
            <Typography variant="h2">
              You don't have any Automation yet.
            </Typography>
          </Box>
        )}
      </>
    )
  );
};

export default SequenceListItem;
