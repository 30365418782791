import { Box, IconButton, TextField, Typography, Alert } from "@mui/material";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import React, { useState } from "react";
import VtechButton from "../../../../components/vtech-button/VtechButton";

const Integration = () => {
  const [webhooks, setWebhooks] = useState(
    "https://us-central1-investorfuse-prod.cloudfunctions.net/webhooks-request-request?apiKey=eyJ0ZWFtX2lkIjoia0p3cm00ZUh2dnpjME01U1AzRjMiLCJjYW1wYWlnbl9pZCI6IlNTV3Jla0NoVjB6cm1YQTlTSkM3In0="
  );
  const [tracking, setTrackingNumber] = useState("");
  const [copySuccess, setCopySuccess] = useState("");

  const copyToClipboard = (e) => {
    if (navigator.clipboard && navigator.clipboard !== undefined) {
      navigator.clipboard.writeText(webhooks);
    } else {
      var textField = document.createElement("textarea");
      textField.innerText = webhooks;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
    }
    setCopySuccess("Copied!");
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        pt: 4.5,
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Box flex={1}>
        <Typography variant="h2">Integration </Typography>
      </Box>
      <Box flex={3}>
        <Box maxWidth={450}>
          <Alert severity="info">
            Integration with other platforms is Coming Soon.
          </Alert>
          {/* <Typography variant="body2" fontSize={16} mb={1.5}>
            Webhooks
          </Typography>
          <Typography mb={2.5}>
            Webhooks enable you to import contacts and opportunities directly
            into your campaign. Configure your apps to post data to the
            following endpoint:
          </Typography>
          <TextField
            multiline
            rows={4}
            value={webhooks}
            fullWidth
            sx={{ mb: 2.5 }}
          />
          <VtechButton
            onClick={copyToClipboard}
            title="Copy"
            startIcon={<img src="/icons/copy-dark.svg" />}
          />
          <Typography style={{ color: "green" }} mb={5}>
            {copySuccess}
          </Typography>
          <Typography variant="body2" fontSize={16} mb={2.5}>
            Callrail
          </Typography>
          <TextField
            label="Webhook tracking numer"
            type="number"
            value={tracking}
            fullWidth
            onChange={(e) => setTrackingNumber(e.target.value)}
            helperText="10 digit tracking number, only numbers please"
            sx={{ mb: 2.5 }}
          />

          <VtechButton
            // onClick={copyToClipboard}
            title="Save"
            startIcon={<img src="/icons/save.svg" />}
          />*/}
        </Box>
      </Box>
    </Box>
  );
};

export default Integration;
