import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";

const SequenceAppBar = () => {
  const { currentSequence } = useSelector((state) => state.sequences);
  return (
    <>
      <AppBar
        position="static"
        style={{
          background: "#245EC1",
          boxShadow: "none",
          marginTop: 30,
          color: "white",
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
        }}
      >
        <Toolbar variant="dense" sx={{padding: 1}}>
          <Typography
            variant="body1"
            sx={{ fontSize: "16px", fontWeight: "600" }}
            noWrap
          >
            {currentSequence.title}
          </Typography>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default SequenceAppBar;
