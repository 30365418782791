import React from "react";
import VtechModal from "../../../../components/vtech-modal/VtechModal";
import { Box } from "@mui/material";
import { ArrowDropDownIcon, DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

export const ResponsiveDateModal = ({
  open,
  setOpen,
  handleCustomDateFilter,
}) => {
  const [toDate, setToDate] = React.useState<any>(moment(new Date()));
  const [fromDate, setFromDate] = React.useState<any>(moment(new Date()));

  const handleClose = () => {
    const from = moment(fromDate).format("YYYY-MM-DD");
    const to = moment(toDate).format("YYYY-MM-DD");
    handleCustomDateFilter("custom_date", from, to);
    setOpen(false);
  };

  return (
    <>
      <VtechModal
        title="Custom Date"
        open={open}
        handleClose={handleClose}
        primaryButtonLabel={"Select"}
        primaryButtonAction={handleClose}
        buttonPosition="start"
        hideXButton={true}
        sx={{ width: "432px" }}
      >
        <Box>
          <DatePicker
            slots={{ openPickerIcon: ArrowDropDownIcon }}
            sx={{ width: 1, mb: 2 }}
            label="Select date from"
            value={fromDate}
            onChange={(x) => setFromDate(x)}
          />
          <DatePicker
            slots={{ openPickerIcon: ArrowDropDownIcon }}
            sx={{ width: 1 }}
            label="Select date to"
            value={toDate}
            onChange={(x) => setToDate(x)}
          />
        </Box>
      </VtechModal>
    </>
  );
};
