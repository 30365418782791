import { Box, Paper, TableCell, TableRow, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { EditStatusModals } from "../../../components/widgets/Edit-status-action";
import { TimeDisplay } from "../../../components/widgets/time-boxes";
import GENERIC_UTILS from "../actionComponents/genericComponents";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { checkDateTime, convertTimeFormate } from "../../../utils/global.utils";
import { useSelector } from "react-redux";
// import { getOpportunitiesForActions } from "../../../services/opportunities";
import MuiChipWithDot from "../../../components/chip/MuiChipWithDot";
import { useDarkMode } from "../../../utils/useDarkMode";

export const ActionTableRow = ({
  row,
  index,
  actions,
  history,
  lastTouch,
  createdAt,
}) => {
  const { opportunities } = useSelector((state) => state.actionList);
  const darkMode = useDarkMode();

  const getStatus = (row) => {
    let opp = opportunities?.find(
      (element) => element.id === row.opportunity.id
    );
    return opp?.action.sub_title;
  };

  const getSubStatus = (row) => {
    let opp = opportunities?.find(
      (element) => element.id === row.opportunity.id
    );
    let status =
      opp?.offers?.length > 0 && opp?.offers[0]?.status !== "pending"
        ? opp?.offers[0]?.status
        : opp?.action.status;
    return status;
  };

  return (
    <TableRow
      hover
      role="checkbox"
      key={index}
      component={Paper}
      elevation={0}
      sx={{
        position: "relative",
        borderBottomLeftRadius: "8px",
        "& .MuiTableCell-root": { verticalAlign: "baseline" },
      }}
    >
      <TableCell
        sx={{
          width: "20%",
          position: "relative",
          pl: 4,
          "::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "10px",
            height: "100%",
            bgcolor: checkDateTime(row.due_date_time, "due")
              ? "#E87063"
              : checkDateTime(row.due_date_time, "today")
              ? "#289374"
              : "#8C919B",
            borderRadius: "6px 19px 0px 19px",
          },
        }}
      >
        {GENERIC_UTILS.getFirstColumn(row, row?.due_date_time, history)}
      </TableCell>
      <TableCell sx={{ width: "25%" }}>
        {GENERIC_UTILS.getSecondColumn(row)}
      </TableCell>

      <>
        <TableCell sx={{ width: "30%" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
            <Box>
              <TimeDisplay
                title="LAST TOUCH"
                number={lastTouch !== null && lastTouch[0]}
                daysAgo={
                  lastTouch !== null && `${lastTouch[1]} ${lastTouch[2]}`
                }
                active={lastTouch !== null ? true : false}
                tooltipValue={moment(row.due_date_time).format("MM-DD-YYYY")}
              />
            </Box>
            <Box>
              <TimeDisplay
                title="TOUCH"
                number={row.touches}
                active="true"
                width
              />
            </Box>
            <Box>
              <TimeDisplay
                title="CREATED"
                number={createdAt[0]}
                daysAgo={`${createdAt[1]} ${createdAt[2]}`}
                active={true}
                tooltipValue={moment(row.due_date_time).format("MM-DD-YYYY")}
              />
            </Box>
          </Box>
        </TableCell>
        <TableCell sx={{ width: "15%" }}>
          <Box>
            <Typography
              sx={{
                opacity: 0.7,
                fontSize: 12,
                fontWeight: 600,
                lineHeight: "24px",
                mb: 0.5,
              }}
            >
              STATUS
            </Typography>
            <MuiChipWithDot
              label={
                row.type === "task"
                  ? getSubStatus(row)
                  : row.status === "closed"
                  ? row.status
                  : row.step_status
              }
              dot
            />
          </Box>
        </TableCell>
        <TableCell sx={{ width: "5%" }}>
          <EditStatusModals
            id={row.opportunity.id}
            taskId={row.id}
            type={row.type}
            taskData={{
              due_date_time: row.due_date_time,
              name: row.name,
            }}
            assignId={row.assignee.id}
            fromPage="actions"
            viewNotes={true}
            notesValue={row.opportunity.notes}
          />
        </TableCell>
        <Box
          component={TableCell}
          sx={{
            position: "absolute",
            right: 0,
            bottom: 0,
            bgcolor: darkMode ? "#2E2E2E" : "#E9EFF9",
            borderTopLeftRadius: "47px",
            px: 3,
            py: 1,
            color: "text.secondary",
            fontSize: 12,
            fontWeight: 600,
            width: "200px",
            textAlign: "center",
          }}
        >
          {row.type === "step" ? row?.sub_title : getStatus(row)}
        </Box>
      </>
    </TableRow>
  );
};
