import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCampaignValidation } from "../../../utils/validations.utils";
import { addCampaign } from "../campaigns.action";
import AddForm from "./campaign-form.add";
import VtechModal from "../../../components/vtech-modal/VtechModal";
import VtechButton from "../../../components/vtech-button/VtechButton";
import { Alert } from "@mui/material";
import { clearError } from "../campaign.slice";

const CampaignAddForm = ({ fromDate, toDate, open, handleClose }) => {
  const dispatch: any = useDispatch();
  const { isLoading, error, isFormOpen, limit } = useSelector(
    (state: any) => state.campaigns
  );

  const formik = useFormik({
    initialValues: {
      campaignName: "",
      purpose: "",
    },
    validationSchema: addCampaignValidation,
    onSubmit: (values, { resetForm }) => {
      dispatch(
        addCampaign(
          { title: values.campaignName, description: values.purpose },
          1,
          limit,
          fromDate,
          toDate,
          handleClose,
          resetForm
        )
      );
      // resetForm();
      // handleClose();
    },
  });

  return (
    <VtechModal
      title="Add Campaign"
      open={open}
      handleClose={() => {
        handleClose();
        formik.resetForm();
        dispatch(clearError());
      }}
      hideActionButtons
      sx={{ maxWidth: 426 }}
    >
      {error && <Alert severity="error">{error}</Alert>}
      <AddForm formik={formik} />
      <VtechButton
        title="Add campaign"
        onClick={formik.handleSubmit}
        disabled={isLoading}
        sx={{ my: 4 }}
      />
    </VtechModal>
  );
};

export default CampaignAddForm;
