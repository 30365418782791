import { Box, IconButton, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { addTouch } from "../../../services/touches.service";
import { PRIMARY_MAIN } from "../../../theme";

const formatPhone = (phone) => {
  if (!phone) return "";
  return (
    phone.slice(0, 2) +
    "-" +
    phone.slice(2, 5) +
    "-" +
    phone.slice(5, 9) +
    "-" +
    phone.slice(9, 15)
  );
};

const GENERIC_UTILS = {
  getActionColumn: (props) => {
    return (
      <>
        <strong style={{ fontSize: 12 }}>{`Next Operation: `}</strong>
        <Typography style={{ fontSize: 12, fontWeight: 400 }}>
          Assigned to
        </Typography>
        <Typography style={{ fontSize: 12, fontWeight: 400 }}>Due: </Typography>
      </>
    );
  },

  getFirstColumn: (props, time, history) => {
    const routeTo = () => {
      addTouch({
        opportunity_id: "",
        campaign_id: "",
        opportunity_step_history_id: props.opportunity_step_history_id,
      });
      history(`/opportunity/${props.opportunity.id}?newIndex=2`);
    };
    return (
      <div onClick={routeTo} style={{ cursor: "pointer" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "24px",
            mb: 1,
          }}
        >{`${props.type === "task" ? "Task :" : ""} ${props.name}`}</Typography>

        <Typography
          sx={{ fontSize: "12px", fontWeight: 400, lineHeight: "24px" }}
        >
          <span>Due</span> {moment(time).local().fromNow()} @
          {moment(time).local().format("hh:mm A")}
        </Typography>
        <Typography
          sx={{ fontSize: "12px", lineHeight: "24px", fontWeight: 400 }}
        >
          <span style={{ color: PRIMARY_MAIN }}>Assigned to</span>:{" "}
          {props.assignee.email}
        </Typography>
      </div>
    );
  },

  getSecondColumn: (props) => {
    const opp = props.opportunity;
    const address = `${opp.street_address} ${opp.city} ${opp.state} ${opp.zipcode} `;

    return (
      <Box>
        <Typography
          sx={{
            opacity: 0.7,
            fontSize: 12,
            fontWeight: 600,
            lineHeight: "24px",
          }}
        >
          DETAILS
        </Typography>

        <Typography
          style={{ fontSize: 12, fontWeight: 400, lineHeight: "24px" }}
        >
          <span style={{ color: PRIMARY_MAIN }}>Address: </span>
          {address}
        </Typography>

        <Typography
          style={{ fontSize: 12, fontWeight: 400, lineHeight: "24px" }}
        >
          <span style={{ color: PRIMARY_MAIN }}>Campaign: </span>
          {props.campaign.title}
        </Typography>

        <Typography
          style={{ fontSize: 12, fontWeight: 400, lineHeight: "24px" }}
        >
          <span style={{ color: PRIMARY_MAIN }}>Seller Contact: </span>
          {formatPhone(props.seller.phone)}
          {/* <a href={`mailto:${props.seller.email}`}>{props.seller.email}</a> */}
        </Typography>
      </Box>
    );
  },

  getMenuList: (
    anchorEl,
    handleOpenMenu,
    handleCloseMenu,
    setOpenNotes,
    setOpenStatus,
    setOpenAction,
    id
  ) => {
    const open = Boolean(anchorEl);
    const options = ["Change Status", "Edit Operation", "View Notes"];
    const handleOpenNotes = (e) => {
      if (e.target.innerText === "Change Status") {
        setOpenAction(false);
        setOpenNotes(false);
        setOpenStatus(true);
      } else if (e.target.innerText === "Edit Operation") {
        setOpenAction(true);
        setOpenNotes(false);
        setOpenStatus(false);
      } else {
        setOpenAction(false);
        setOpenStatus(false);
        setOpenNotes(true);
      }
    };

    return (
      <>
        <div>
          <IconButton onClick={handleOpenMenu}>
            <MoreVertIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
            {options.map((option) => (
              <MenuItem key={option} onClick={(e) => handleOpenNotes(e)}>
                {option}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </>
    );
  },
};

export default GENERIC_UTILS;
