import React, { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import ProgressBar from "../../components/widgets/progress-bar/index";
// import SnackBar from "../../components/widgets/snackbar/index";
import { updateOwner } from "../../services/opportunities";
import { getAllActions } from "../campaign-details/redux/steps/details.actions";
import { getAllUsersWOL } from "../users-list/usersListAction";
import qs from "qs";
import OpportunityDetailsTabs from "./menu-tabs/menu-tabs.component";
// import {
//   OpportunityForMobile,
//   OpportunityOwner,
//   OpportunitySeller,
//   UserSelection,
// } from "./opportunity-fields/index";
// import OpportunityLabels from "./opportunity-labels";
import { getOpportunity, getOpportunityOffers } from "./opportunity.action";
import { getSequences } from "../sequences/redux/sequence.actions";
// import { setNotiForm } from "../../components/layouts/top_nav_bar/notification/notification.action";
import MainLayout from "../../components/MainLayout/MainLayout";
import { Box } from "@mui/material";
import { NextPrevBtn } from "../../components/widgets/next-prev";
import { NoteBar } from "../../components/widgets/note-bar";
import NotesModal from "../../components/widgets/Edit-status-action/edit-modal/menuModel/notes";
import { showSnackbar } from "../../snackbar/snackbarSlice";
import VtechTabs from "../../components/tabs/VtechTabs";

const InfoTab = lazy(() => import("./info-tab"));
const PropertyDetailTab = lazy(() => import("./property-details"));
const ActionsTab = lazy(() => import("./actions"));
const Offers = lazy(() => import("./offer"));

const OpportunityDetails = () => {
  const crumb = ["Opportunities", "Opportunities details"];
  const tabs = ["Opportunity info", "Property Details", "Operations", "Offers"];
  const [activeTab, setActiveTab] = useState(0);
  const dispatch: any = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const { newIndex } = qs.parse(location.search.split("?")[1]);
  const [selectionEnable, setSelectionEnable] = useState(false);
  const { isLoading, opportunity } = useSelector(
    (state: any) => state.opportunityDetails
  );
  const { opportunities } = useSelector((state: any) => state.opportunities);
  const [nextPrev, setNextPrev] = useState({ prev: 0, next: 0 });
  const [loading, setLoading] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);

  useEffect(() => {
    dispatch(getAllUsersWOL());
    dispatch(getOpportunity(id));
    dispatch(getOpportunityOffers(id));
    dispatch(getAllActions());
    dispatch(getSequences("enabled"));
  }, [dispatch, id]);

  useEffect(() => {
    if (newIndex) {
      setActiveTab(parseInt(newIndex));
    }
  }, []);

  // scroll to top on page change(bug fix)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleUserAssignment = (value) => {
    setLoading(true);
    updateOwner(opportunity.id, value.id)
      .then((res) => {
        setLoading(false);
        dispatch(showSnackbar("Owner updated successfully!"));
        dispatch(getOpportunity(id));
      })
      .catch((err) => {
        dispatch(showSnackbar("something went wrong"));
        setLoading(false);
      });
    setSelectionEnable(false);
  };

  useEffect(() => {
    setTimeout(() => {
      if (
        opportunities !== undefined &&
        (Object.keys(opportunities).length > 0 || opportunities.length > 0)
      ) {
        const getIndex = opportunities.findIndex((x) => x.id === parseInt(id));
        if (
          getIndex !== 0 &&
          getIndex !== Object.keys(opportunities).length - 1
        ) {
          setNextPrev({
            prev:
              opportunities[getIndex - 1]?.id &&
              opportunities[getIndex - 1]?.id,
            next:
              opportunities[getIndex + 1]?.id &&
              opportunities[getIndex + 1]?.id,
          });
        } else if (getIndex === 0 && Object.keys(opportunities).length === 1) {
          setNextPrev({
            prev: undefined,
            next: undefined,
          });
        } else if (getIndex === Object.keys(opportunities).length - 1) {
          setNextPrev({
            prev:
              opportunities[getIndex - 1].id && opportunities[getIndex - 1].id,
            next: undefined,
          });
        } else {
          setNextPrev({
            prev: undefined,
            next:
              opportunities[getIndex + 1].id && opportunities[getIndex + 1].id,
          });
        }
      }
    }, 3000);
  }, [opportunities, id]);

  return (
    <MainLayout title="" crumbs={crumb}>
      <Box mb={10}>
        {/* <OpportunityDetailsTabs
          activeVal={activeIndex}
          onChange={(val) => setActiveIndex(val)}
        /> */}
        <VtechTabs tabs={tabs} activeTab={activeTab} setTab={setActiveTab} />
        <Suspense fallback={<ProgressBar />}>
          {activeTab === 0 && (
            <InfoTab
              setActiveIndex={setActiveTab}
              handleUserAssignment={handleUserAssignment}
            />
          )}
          {activeTab === 1 && <PropertyDetailTab />}
          {activeTab === 2 && <ActionsTab setActiveIndex={setActiveTab} />}
          {activeTab === 3 && <Offers />}
        </Suspense>

        <NoteBar setOpenNotes={setOpenNotes} />
        {Object.keys(opportunities).length > 0 && (
          <NextPrevBtn nextPrev={nextPrev} />
        )}
        <NotesModal
          id={id && id}
          notesValue={opportunity.notes}
          setOpenNotes={setOpenNotes}
          openNotes={openNotes}
        />
      </Box>
    </MainLayout>
  );
};

export default OpportunityDetails;
