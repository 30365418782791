import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { campaignEditForm } from "../../../utils/validations.utils";
import { updateCampaignDetails } from "../redux/edit-campaign/update-campaign.action";
import CampaignLogs from "./campaign-log/index";
import CampaignDeatils from "./details-section/index";
import CampaignModal from "./modal/index.modal";
import { Box } from "@mui/material";

const EditPage = ({ campaignId }) => {
  const dispatch = useDispatch();
  const { campaign, isLoading } = useSelector((state) => state.currentCampaign);

  const formik = useFormik({
    initialValues: {
      campaignName: campaign?.title ?? "",
      purpose: campaign?.description ?? "",
      campaignStatus: campaign?.status ?? "",
    },
    enableReinitialize: true,
    validationSchema: campaignEditForm,
    onSubmit: (values) => {
      const data = {
        title: values.campaignName,
        description: values.purpose,
        status: values.campaignStatus,
      };
      dispatch(updateCampaignDetails(campaignId, data));
    },
  });

  return (
    <Box
      sx={{
        py: 4,
        gap: 6.5,
        display: "flex",
        flexDirection: { xs: "column", lg: "row" },
      }}
    >
      <CampaignDeatils formik={formik} loading={isLoading} />
      <CampaignLogs />
      <CampaignModal campaignId={campaignId} />
    </Box>
  );
};

export default EditPage;
