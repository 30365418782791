import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import React from "react";
import SubmissionButton from "../../../../components/widgets/button/button.widget";
import ProgressBar from "../../../../components/widgets/progress-bar";
import { Box, Typography } from "@mui/material";

const CampaignDeatils = ({ formik, loading }) => {
  return (
    <Box sx={{ flex: 1 }}>
      <Typography variant="h2" mb={2.5}>
        Update campaign details
      </Typography>
      {loading && <ProgressBar />}
      <TextField
        sx={{ mb: 2.5 }}
        fullWidth
        label="Campaign Name"
        name="campaignName"
        InputLabelProps={{ shrink: true }}
        value={formik.values.campaignName}
        onChange={formik.handleChange}
        error={
          formik.touched.campaignName && Boolean(formik.errors.campaignName)
        }
        helperText={formik.touched.campaignName && formik.errors.campaignName}
      />
      <TextField
        fullWidth
        sx={{ mb: 2.5 }}
        label="Purpose"
        name="purpose"
        InputLabelProps={{ shrink: true }}
        value={formik.values.purpose}
        onChange={formik.handleChange}
        error={formik.touched.purpose && Boolean(formik.errors.purpose)}
        helperText={formik.touched.purpose && formik.errors.purpose}
      />
      <TextField
        sx={{ mb: 2.5 }}
        fullWidth
        select
        label="Status"
        name="campaignStatus"
        value={formik.values.campaignStatus}
        onChange={(e) => {
          formik.handleChange(e);
          // formik.handleSubmit();
        }}
        error={
          formik.touched.campaignStatus && Boolean(formik.errors.campaignStatus)
        }
        helperText={
          formik.touched.campaignStatus && formik.errors.campaignStatus
        }
      >
        <MenuItem value="active">Active</MenuItem>
        <MenuItem value="planned">Planned</MenuItem>
        <MenuItem value="complete">Complete</MenuItem>
        <MenuItem value="in_active">Inactive</MenuItem>
      </TextField>
      <SubmissionButton title="Update" onClick={formik.handleSubmit} />
    </Box>
  );
};

export default CampaignDeatils;
