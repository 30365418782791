import { Box, Button, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React from "react";
import moment from "moment";

const CustomButtons = ({
  calendarRef,
  monthValue,
  setMonthValue,
  yearValue,
  setYearValue,
  getMonth,
}) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ display: "flex", gap: 1, mr: 3 }}>
        <Typography variant="h2" sx={{ color: "text.secondary" }}>
          {moment()
            .month(monthValue - 1)
            .format("MMMM")}
        </Typography>{" "}
        <Typography variant="h2" sx={{ color: "text.secondary" }}>
          {yearValue}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Button
          sx={{ px: 0, minWidth: 0, color: "text.secondary" }}
          onClick={() => {
            let calendarApi = calendarRef.current.getApi();
            calendarApi.prev();
            setMonthValue(
              getMonth(new Date(calendarApi.getDate()).getMonth() + 1)
            );
            setYearValue(new Date(calendarApi.getDate()).getFullYear());
          }}
        >
          <ChevronLeftIcon />
        </Button>
        <Button
          sx={{ px: 0, minWidth: 0, color: "text.secondary" }}
          onClick={() => {
            let calendarApi = calendarRef.current.getApi();
            calendarApi.next();
            setMonthValue(
              getMonth(new Date(calendarApi.getDate()).getMonth() + 1)
            );
            setYearValue(new Date(calendarApi.getDate()).getFullYear());
          }}
        >
          <ChevronRightIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default CustomButtons;
