import {
  addNewStep,
  copySequence,
  createSequence,
  editInitialStep,
  editNextAction,
  editSequenceDetails,
  editSequenceStep,
  getAllSequence,
  getInitialStep,
  getNextAction,
  getSequenceById,
  getStepsDetails,
  removeQueueStep,
  removeSequence,
  replaceSteps,
} from "../../../services/sequences.service";
import { showSnackbar } from "../../../snackbar/snackbarSlice";
import {
  fetchingSequence,
  fetchingSteps,
  setActiveSequence,
  setCurrentSequence,
  setFilteredSequences,
  setInitialStep,
  setInitialStepLoading,
  setIsLoading,
  setNextAction,
  setSnackMsg,
} from "./sequence.slice";

export const getSequences =
  (type = "") =>
  (dispatch) => {
    dispatch(setIsLoading(true));
    getAllSequence(type).then((res) => {
      if (type === "") {
        dispatch(fetchingSequence(res.data.data));
      } else {
        dispatch(setFilteredSequences(res.data.data));
      }
      dispatch(setIsLoading(false));
    });
  };

export const getSequenceSteps = (id) => (dispatch) => {
  dispatch(setIsLoading(true));
  getStepsDetails(id).then((res) => {
    dispatch(fetchingSteps(res.data.data));
    dispatch(setIsLoading(false));
  });
};

export const getInitialStepDetails = (id) => (dispatch) => {
  dispatch(setInitialStepLoading(true));
  dispatch(setIsLoading(true));
  getInitialStep(id).then((res) => {
    dispatch(setInitialStep(res.data.data));
    dispatch(setIsLoading(false));
    dispatch(setInitialStepLoading(false));
  });
};

export const getNextActionDetails = (id) => (dispatch) => {
  dispatch(setIsLoading(true));
  getNextAction(id).then((res) => {
    dispatch(setNextAction(res.data.data));
    dispatch(setIsLoading(false));
  });
};

export const addNewSequence = (data) => (dispatch) => {
  dispatch(setIsLoading(true));
  createSequence(data)
    .then((res) => {
      // dispatch(
      //   setSnackMsg({
      //     msg: "Automation added successfully.",
      //     severity: "success",
      //   })
      // );
      dispatch(showSnackbar("Automation added successfully!"));
      dispatch(getSequences());
      // dispatch(getSequenceDetails(res.data.data.id));
      // dispatch(getSequenceSteps(res.data.data.id));
      // dispatch(getInitialStepDetails(res.data.data.id));
      // dispatch(getNextActionDetails(res.data.data.id));
    })
    .catch((err) => {
      // dispatch(setSnackMsg({ msg: "Something went wrong.", severity: "error" }));
      dispatch(showSnackbar("Something went wrong."));
    });
};

export const getSequenceDetails = (id) => (dispatch) => {
  dispatch(setIsLoading(true));
  getSequenceById(id).then((res) => {
    dispatch(setCurrentSequence(res.data.data));
    dispatch(setIsLoading(false));
  });
};

export const updateSequenceDetails = (id, data) => (dispatch) => {
  dispatch(setIsLoading(true));
  editSequenceDetails(id, data).then((res) => {
    // dispatch(
    //   setSnackMsg({ msg: "Automation updated successfully.", severity: "success" })
    // );
    dispatch(showSnackbar("Automation updated successfully!"));
    dispatch(getSequenceDetails(id));
    //dispatch(getSequences());
  }).catch((err) => {
    dispatch(setIsLoading(false));
    dispatch(showSnackbar(JSON.stringify(err.messages[0].errors.description)));
  });
};

export const updateNextAction = (data) => (dispatch) => {
  dispatch(setIsLoading(true));
  editNextAction(data)
    .then((res) => {
      // dispatch(
      //   setSnackMsg({
      //     msg: "Next Operation updated successfully.",
      //     severity: "success",
      //   })
      // );
      dispatch(showSnackbar("Next Operation updated successfully!"));
      dispatch(getNextActionDetails(data.sequence_id));
    })
    .catch((err) => {
      dispatch(setIsLoading(false));
      dispatch(showSnackbar(JSON.stringify(err.messages[0].message)));

      // dispatch(
      //   setSnackMsg({
      //     msg: JSON.stringify(err.messages[0].message),
      //     severity: "error",
      //   })
      // );
    });
};

export const updateInitialStep = (id, data) => (dispatch) => {
  dispatch(setInitialStepLoading(true));
  dispatch(setIsLoading(true));
  editInitialStep(id, data)
    .then((res) => {
      // dispatch(
      //   setSnackMsg({
      //     msg: "Initial step updated successfully.",
      //     severity: "success",
      //   })
      // );
      dispatch(showSnackbar("Initial step updated successfully!"));
      dispatch(setIsLoading(false));
      dispatch(getInitialStepDetails(id));
    })
    .catch((err) => {
      dispatch(setIsLoading(false));
      dispatch(showSnackbar(JSON.stringify(err.messages[0].message)));
      // dispatch(
      //   setSnackMsg({
      //     msg: JSON.stringify(err.messages[0].message),
      //     severity: "error",
      //   })
      // );
    });
};

export const deleteSequence = (id) => (dispatch) => {
  dispatch(setIsLoading(true));
  removeSequence(id)
    .then((res) => {
      // dispatch(
      //   setSnackMsg({
      //     msg: "Automation deleted successfully.",
      //     severity: "success",
      //   })
      // );
      dispatch(showSnackbar("Automation deleted successfully!"));
      dispatch(setActiveSequence(0));
      dispatch(setCurrentSequence({}));
      dispatch(getSequences());
    })
    .catch((err) => {
      dispatch(setIsLoading(false));
      // dispatch(
      //   setSnackMsg({
      //     msg: JSON.stringify(err.messages[0].message),
      //     severity: "error",
      //   })
      // );
      dispatch(showSnackbar(JSON.stringify(err.messages[0].message)));
    });
};

export const duplicateSequence = (id) => (dispatch) => {
  dispatch(setIsLoading(true));
  copySequence(id).then((res) => {
    // dispatch(
    //   setSnackMsg({
    //     msg: "Automation copied successfully.",
    //     severity: "success",
    //   })
    // );
    dispatch(showSnackbar("Automation copied successfully!"));
    dispatch(getSequences());
    dispatch(setActiveSequence(0));
  });
};

export const createNewStep = (data) => (dispatch) => {
  dispatch(setIsLoading(true));
  addNewStep(data).then((res) => {
    dispatch(getSequenceSteps(data.sequence_id));
    // dispatch(
    //   setSnackMsg({
    //     msg: "New step added successfully.",
    //     severity: "success",
    //   })
    // );
    dispatch(showSnackbar("New step added successfully!"));
  });
};

export const deleteQueueStep = (id, seqId) => (dispatch) => {
  dispatch(setIsLoading(true));
  removeQueueStep(id)
    .then((res) => {
      dispatch(getSequenceSteps(seqId));
      // dispatch(
      //   setSnackMsg({
      //     msg: "Step deleted successfully.",
      //     severity: "success",
      //   })
      // );
      dispatch(showSnackbar("Step deleted successfully!"));
    })
    .catch((err) => {
      dispatch(setIsLoading(false));
      // dispatch(
      //   setSnackMsg({
      //     msg: JSON.stringify(err.messages[0].message),
      //     severity: "error",
      //   })
      // );
      dispatch(showSnackbar(JSON.stringify(err.messages[0].message)));
    });
};

export const updateSequenceStep = (id, data, seqId) => (dispatch) => {
  dispatch(setIsLoading(true));
  editSequenceStep(id, data)
    .then((res) => {
      dispatch(getSequenceSteps(seqId));
      // dispatch(
      //   setSnackMsg({
      //     msg: "Step updated successfully.",
      //     severity: "success",
      //   })
      // );
      dispatch(showSnackbar("Step updated successfully!"));
    })
    .catch((err) => {
      dispatch(setIsLoading(false));
      // dispatch(
      //   setSnackMsg({
      //     msg: JSON.stringify(err.messages[0].message),
      //     severity: "error",
      //   })
      // );
      dispatch(showSnackbar(JSON.stringify(err.messages[0].message)));
    });
};

export const shuffleSteps = (seqId, currentId, replaceId) => (dispatch) => {
  dispatch(setIsLoading(true));
  replaceSteps(currentId, replaceId)
    .then((res) => {
      dispatch(getSequenceSteps(seqId));
      // dispatch(
      //   setSnackMsg({
      //     msg: "Step moved successfully.",
      //     severity: "success",
      //   })
      // );
      dispatch(showSnackbar("Step moved successfully!"));
    })
    .catch((err) => {
      dispatch(setIsLoading(false));
      // dispatch(
      //   setSnackMsg({
      //     msg: JSON.stringify(err.messages[0].message),
      //     severity: "error",
      //   })
      // );
      dispatch(showSnackbar(JSON.stringify(err.messages[0].message)));
    });
};
