import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
  isLoading: false,
  error: "",
  total: 0,
  limit: 10,
};

const usersListSlice = createSlice({
  name: "usersList",
  initialState,
  reducers: {
    fetchUsersLoading: (state) => {
      state.isLoading = true;
    },
    fetchUsersSuccess: (state, action) => {
      state.isLoading = false;
      state.users = action.payload;
    },
    fetchUsersFail: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setPagination: (state, action) => {
      state.total = action.payload.total;
      state.limit = action.payload.limit;
    },
  },
});

export const {
  fetchUsersLoading,
  fetchUsersSuccess,
  fetchUsersFail,
  setPagination,
} = usersListSlice.actions;
export default usersListSlice.reducer;
