export const rectangleStyle = {
  display: "flex",
  justifyContent: "center",
  gap: 1,
  width: 1,
  alignItems: "center",
  bgcolor: "#E9EFF9",
  borderRadius: "3px",
  height: "64px",
  clipPath: "polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%)",
};
export const midrectangleStyle = {
  display: "flex",
  justifyContent: "center",
  width: 1,
  gap: 1,
  alignItems: "center",
  bgcolor: "#E9EFF9",
  height: "64px",
  borderRadius: "3px",
  clipPath: "polygon(95% 0, 100% 50%, 95% 100%, 0% 100%, 5% 50%, 0% 0%)",
};
export const lastRectangleStyle = {
  display: "flex",
  justifyContent: "center",
  width: 1,
  gap: 1,
  alignItems: "center",
  bgcolor: "#E9EFF9",
  height: "64px",
  borderRadius: "3px",
  clipPath: "polygon(100% 0, 100% 100%, 0% 100%, 5% 50%, 0% 0%)",
};
export const TypographySpanStyles = {
  bgcolor: "#2F74EB",
  borderRadius: "50%",
  color: "white",
  width: 21,
  height: 21,
  fontSize: 10,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  lineHeight: 1,
};
