import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllUsersWOL } from "../../../../../pages/users-list/usersListAction";

export const UserSelection = ({ onSelection, opportunityOwner }) => {
  const dispatch = useDispatch();
  const [ownerSelection, setOwnerSelection] = useState("");
  const { users } = useSelector((state) => state.users);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (users.length === 0) dispatch(getAllUsersWOL());
  }, []);

  useEffect(() => {
    if (Object.keys(users).length > 0 && users.length && opportunityOwner) {
      let getOwner = users.filter((user) => user.id === opportunityOwner.id);
      setOwnerSelection(getOwner[0]);
    }
  }, [opportunityOwner, users]);

  const handleDeliveryChange = (event) => {
    setOwnerSelection(event.target.value);
    if (onSelection) {
      onSelection(event.target.value);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <FormControl fullWidth>
        <InputLabel>Select Owner</InputLabel>
        <Select
          MenuProps={{ sx: { maxHeight: 300, maxWidth: 244 } }}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={ownerSelection}
          label="Select Owner"
          onChange={handleDeliveryChange}
        >
          {Object.keys(users).length > 0 ? (
            users.map((user) => <MenuItem value={user}>{user.email}</MenuItem>)
          ) : (
            <MenuItem value="not found">Records not found</MenuItem>
          )}
        </Select>
      </FormControl>
    </>
  );
};
