import { Box, FormControl, TextField } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CreateNewAction } from "../../../../../pages/opportunity-details/opportunity.action";
import { getActionAssignee } from "../../../../../services/action.services";
// import CompleteAction from "../../../button/button.widget";
// import { DatePickers } from "../../../date-time-picker";
// import { TimeMenu } from "../../../date-time-picker/time-menu";
import { SelectionWidget } from "../../../select-widget";
import NEXT_ACTION_FORMS from "./actionForms/actionForm";
import { AddDropDown } from "./dropdown";
import EmailResponse from "./email-response";
import VtechButton from "../../../../vtech-button/VtechButton";
import { getSequences } from "../../../../../pages/sequences/redux/sequence.actions";
import { CircularProgress } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { getAllUsersWOL } from "../../../../../pages/users-list/usersListAction";

export const AssignNextAction = ({ currentActionId, setOpen }) => {
  const dispatch: any = useDispatch();
  const [success, setSuccess] = useState(false);
  const { id } = useParams();
  const { users } = useSelector((state) => state.users);
  const { actions } = useSelector((state) => state.campaignDetails);
  const { opportunity } = useSelector((state) => state.opportunityDetails);
  const { isLoading } = useSelector((state) => state.opportunityDetails);
  const { filteredSequences: sequences } = useSelector(
    (state) => state.sequences
  );
  const offerItems = [
    { value: 1, title: "Make Offer Now" },
    { value: 2, title: "Make Offer Later" },
  ];
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [instructions, setInstruction] = useState("");
  const [duration, setDuration] = useState("");
  const [assignee, setAssignee] = useState("");
  const [dueIn, setDueIN] = useState("");
  const [sequenceId, setSequenceId] = useState(null);
  const [stepId, setStepId] = useState("");
  const [flag, setFlag] = useState("");
  const [offerNow, setOfferNow] = useState(1);
  const deadActions = ["Opt Out: remove from list", "Mark as dead"];
  const [emailText, setEmailText] = useState("");
  const [subject, setSubject] = useState("");
  const [notes, setNotes] = useState(opportunity.notes && opportunity.notes);
  const [textMessage, setTextMessage] = useState("");
  const [comment, setComment] = useState("");
  const [textError, setTextErr] = useState(false);
  const [subjectErr, setSubjectErr] = useState(false);
  const actionsList = [4, 5, 6, 7, 8, 9, 19, 13, 25, 26];

  const [dropDownMenu, setDropDownMenu] = useState([
    { name: "Send an Email", inMenu: false },
    { name: "Send Text Message", inMenu: false },
    { name: "Add Comment", inMenu: false },
    { name: "Update note", inMenu: false },
  ]);

  useEffect(() => {
    if (currentActionId) {
      setStepId(currentActionId);
      getAssigneeFromAction(currentActionId);
    }
  }, [currentActionId]);

  useEffect(() => {
    if (users.length === 0) {
      dispatch(getAllUsersWOL());
    }
  }, []);

  useEffect(() => {
    if (flag === "Place in follow up automation" && sequences.length === 0) {
      dispatch(getSequences("enabled"));
    }
  }, [flag]);

  useEffect(() => {
    if (stepId !== "") {
      const currentAction = actions.filter((x) => x.id === stepId);
      if (stepId >= 2 && stepId <= 9) {
        setFlag("Call new lead");
      } else {
        if (currentAction.length >= 1) {
          setFlag(currentAction[0].title);
        }
      }
    }
  }, [stepId]);

  const handleMenuItemClick = (index) => {
    setDropDownMenu(
      dropDownMenu.map((r, position) =>
        index === position ? { ...r, inMenu: false } : r
      )
    );
  };

  const handleDueIn = (e) => {
    setDueIN(e.target.value);
    if (e.target.value === "today") {
      setSelectedDate(moment().format("MM-DD-YYYY"));
    } else if (e.target.value === "tomorrow") {
      setSelectedDate(moment().add(1, "days").format("MM-DD-YYYY"));
    } else if (e.target.value === "week") {
      setSelectedDate(moment().add(7, "days").format("MM-DD-YYYY"));
    } else if (e.target.value === "month") {
      setSelectedDate(moment().add(1, "M").format("MM-DD-YYYY"));
    } else setSelectedDate(moment().format("MM-DD-YYYY"));
    setSelectedTime(moment().format("HH:mm"));
  };

  const handleCreateNewAction = () => {
    const formattedDate = selectedDate
      ? moment(selectedDate, "MM-DD-YYYY")
      : moment();
    const formattedTime = selectedTime
      ? moment(selectedTime, "HH:mm")
      : moment();

    const combinedDateTime = formattedDate
      .hour(formattedTime.hour())
      .minute(formattedTime.minute())
      .utc()
      .format("YYYY-MM-DD HH:mm");

    const due_date_time =
      stepId === 10
        ? moment(`${selectedDate} ${selectedTime}`, "YYYY-MM-DD HH:mm")
            .utc()
            .format("YYYY-MM-DD HH:mm")
        : combinedDateTime;

    const data = {
      id: opportunity.action.id,
      step_id: stepId,
      action_response: "",
      instructions,
      due_in: dueIn,
      due_date_time: due_date_time,
      assign_to: assignee ? assignee : opportunity.assignee.id,
      offer_amount: "",
      expected_sale_price: "",
      expected_profit: "",
      offer_date: "",
      offer_expiration_date: "",
      deliver_offer: "",
      offer_status: "",
      note: "",
      duration,
      comment: dropDownMenu[2].inMenu && comment,
      notes: dropDownMenu[3].inMenu && notes,
      text: dropDownMenu[1].inMenu && textMessage,
      email: dropDownMenu[0].inMenu,
      subject: dropDownMenu[0].inMenu && subject,
      message: dropDownMenu[0].inMenu && emailText,
      sequence_id: sequenceId,
    };

    dispatch(CreateNewAction(data, id, null, setOpen, setSuccess));
  };

  const onChecked = (e) => {
    if (e.target.checked) {
      setAssignee(opportunity.owner.id);
    } else {
      setAssignee("");
    }
  };

  const getAssigneeFromAction = (id) => {
    getActionAssignee(id)
      .then((res) => {
        setAssignee(res.data.userId ? res.data.userId : opportunity.owner.id);
      })
      .catch((err) => {
        console.log("err = ", err);
      });
    setStepId(id);
  };
  // TODO: refactor this to be more readable
  const isButtonDisabled =
    (stepId === 19 && sequenceId === null) ||
    !(
      stepId === 20 ||
      stepId === 24 ||
      stepId === 19 ||
      (selectedDate !== "" && selectedDate !== null) ||
      (dueIn !== "" && dueIn !== null)
    );
  return (
    <>
      <FormControl variant="outlined" fullWidth>
        <TextField label="Selected Operation" value={flag} disabled />
      </FormControl>

      {parseInt(stepId) === 14 && (
        <Box sx={{ mt: 2.5 }}>
          <SelectionWidget
            value={offerNow}
            setValue={(val) => {
              if (val === 1) {
                setOfferNow(1);
              } else {
                setOfferNow(2);
              }
            }}
            label="Choose one"
            items={offerItems}
          />
        </Box>
      )}

      {flag === "Schedule seller appointment" &&
        NEXT_ACTION_FORMS.getSchedularSellerForm(
          setAssignee,
          assignee,
          opportunity.owner.email,
          users,
          selectedDate,
          setSelectedDate,
          selectedTime,
          setSelectedTime,
          setDuration,
          onChecked,
          instructions,
          setInstruction
        )}
      {flag === "Place in follow up automation" &&
        NEXT_ACTION_FORMS.getSequenceForm(sequenceId, setSequenceId, sequences)}

      {flag !== "Schedule seller appointment" &&
        flag !== "Offer Accepted and put under contract" &&
        flag !== "Place in follow up automation" &&
        !deadActions.includes(flag) &&
        NEXT_ACTION_FORMS.getGenericForm(
          setAssignee,
          assignee,
          opportunity.owner.email,
          users,
          selectedDate,
          setSelectedDate,
          selectedTime,
          setSelectedTime,
          onChecked,
          instructions,
          setInstruction,
          handleDueIn,
          setDueIN,
          dueIn
        )}

      {dropDownMenu[0].inMenu && (
        <div style={{ marginTop: "20px" }}>
          <EmailResponse
            setText={setEmailText}
            textError={textError}
            setTextErr={setTextErr}
            handleMenuItemClick={handleMenuItemClick}
            setSubject={setSubject}
            subject={subject}
            subjectErr={subjectErr}
            setSubjectErr={setSubjectErr}
          />
        </div>
      )}
      {dropDownMenu[1].inMenu &&
        NEXT_ACTION_FORMS.getTextMessage(
          setTextMessage,
          textMessage,
          handleMenuItemClick
        )}

      {dropDownMenu[2].inMenu &&
        NEXT_ACTION_FORMS.getComment(setComment, comment, handleMenuItemClick)}

      {dropDownMenu[3].inMenu &&
        NEXT_ACTION_FORMS.getNote(notes, setNotes, handleMenuItemClick)}

      {(stepId === 20 || stepId === 24 || stepId === 19) && (
        <FormControl
          variant="outlined"
          style={{ width: "100%", marginTop: "20px" }}
        >
          <TextField
            label="Add instruction for the next operation"
            value={instructions}
            onChange={(e) => setInstruction(e.target.value)}
            multiline
            rows={3}
            variant="outlined"
          />
        </FormControl>
      )}

      <Box
        sx={{
          display: "flex",
          marginTop: "20px",
          gap: 2,
          flexDirection: { xs: "column", sm: "row" },
          mb: 2,
        }}
      >
        <AddDropDown
          dropDownMenu={dropDownMenu}
          setDropDownMenu={setDropDownMenu}
        />

        {/* {(stepId === 20 ||
          stepId === 24 ||
          stepId === 26 ||
          (selectedDate !== "" && selectedDate !== null) ||
          (dueIn !== "" && dueIn !== null)) && (
          <VtechButton
            onClick={handleCreateNewAction}
            title={
              success
                ? "Nice work! Status changed successfully!"
                : "Complete Status Change"
            }
            color={success ? "success" : "primary"}
            startIcon={
              isLoading ? (
                <CircularProgress size={25} style={{ color: "#fff" }} />
              ) : (
                <CheckIcon />
              )
            }
          />
        )}
        {stepId === 19 && (
          <VtechButton
            onClick={handleCreateNewAction}
            title={
              success
                ? "Nice work! Status changed successfully!"
                : "Complete Status Change"
            }
            color={success ? "success" : "primary"}
            startIcon={
              isLoading ? (
                <CircularProgress size={25} style={{ color: "#fff" }} />
              ) : (
                <CheckIcon />
              )
            }
          />
        )} */}
        <VtechButton
          onClick={handleCreateNewAction}
          title={
            success
              ? "Nice work! Status changed successfully!"
              : "Complete Status Change"
          }
          color={success ? "success" : "primary"}
          startIcon={
            isLoading ? (
              <CircularProgress size={25} style={{ color: "#fff" }} />
            ) : (
              <CheckIcon />
            )
          }
          disabled={isButtonDisabled}
        />
      </Box>
    </>
  );
};
