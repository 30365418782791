import {
  createAudience,
  getAllAudience,
  updateAudience,
} from "../../../../services/audience-logs.services";
import { showSnackbar } from "../../../../snackbar/snackbarSlice";
import {
  campaignFail,
  campaignLoading,
  fetchCampaignSuccess,
  handleEditSuccess,
  handleModal,
  hideSnackar,
  setCampaignPagination,
  stopLoading,
} from "./edit.slice";

export const audienceCreation = (data, limit) => (dispatch) => {
  dispatch(campaignLoading());
  createAudience(data)
    .then((res) => {
      dispatch(stopLoading("Campaign log added Successfully!"));
      dispatch(showSnackbar("Campaign log added Successfully!"));
      dispatch(handleModal(false));
      dispatch(getAudienceList(1, limit, data.campaign_id));
    })
    .catch((err) => {
      dispatch(campaignFail("something went wrong."));
    });
};

export const audienceUpdating = (id, data, limit) => (dispatch) => {
  dispatch(campaignLoading());
  updateAudience(id, data)
    .then((res) => {
      dispatch(stopLoading("Campaign log update Successfully!"));
      dispatch(showSnackbar("Campaign log update Successfully!"));
      dispatch(handleEditSuccess());
      dispatch(getAudienceList(1, limit, data.campaign_id));
    })
    .catch((err) => {
      dispatch(campaignFail("something went wrong."));
    });
};

export const getAudienceList = (page, limit, id) => (dispatch) => {
  dispatch(campaignLoading());
  getAllAudience(page, limit, id)
    .then((res) => {
      dispatch(fetchCampaignSuccess(res.data.data));
      dispatch(
        setCampaignPagination({
          total: res.data.total,
          limit: res.data.per_page,
        })
      );
    })
    .catch((err) => {
      dispatch(hideSnackar());
      console.log("====================================");
      console.log(err);
      console.log("====================================");
    });
};
