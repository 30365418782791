import {
  getAllSellersContact,
  createSeller,
} from "../../services/contact.services";

import {
  sellersFetched,
  setIsSellerLoading,
  stopSellerLoading,
  openContactForm,
  errorInFetch,
  setPagination,
} from "./contact.slice";

export const getContactSellers =
  (page, limit, filters, query) => (dispatch) => {
    dispatch(setIsSellerLoading());
    getAllSellersContact(page, limit, filters, query)
      .then((res) => {
        dispatch(sellersFetched(res.data.data));

        dispatch(
          setPagination({
            total: res.data.total,
            limit: res.data.per_page,
          })
        );
      })
      .catch((err) => {
        dispatch(stopSellerLoading());
        console.log("====================================");
        console.log("err = ", err);
        console.log("====================================");
      });
  };

export const addContact = (data) => (dispatch) => {
  dispatch(setIsSellerLoading());
  createSeller(data)
    .then((res) => {
      dispatch(stopSellerLoading());
      dispatch(openContactForm());
      dispatch(getContactSellers(1, 10, null, ""));
    })
    .catch((error) => {
      dispatch(errorInFetch(error.messages[0].errors.phone));
    });
};

export const setContactForm = () => (dispatch) => {
  dispatch(openContactForm());
};

export const settingUpPagination = (payload) => (dispatch) => {
  dispatch(setPagination(payload));
};
