import { Box, TextField } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import {
  editSeller,
  postSeller,
} from "../../../../../services/opportunity-seller.service";
import { primarySellerValidation } from "../../../../../utils/validations.utils";
import { getPrimarySellers } from "../../../../opportunity-details/opportunity.action";
import VtechButton from "../../../../../components/vtech-button/VtechButton";
import { showSnackbar } from "../../../../../snackbar/snackbarSlice";

export const StepTwo = ({
  handleNext,
  setLoading,
  sellerData,
  setSellerData,
  onSubmission,
}) => {
  const dispatch = useDispatch();

  const addSellerToOpportunity = (data) => {
    setLoading(true);
    postSeller(data)
      .then((res) => {
        if (setSellerData) {
          // setSeverity("success");
          // setMessage("Seller created successfully!");
          // setSnack(true);
          dispatch(showSnackbar("Seller created successfully!"));
          setSellerData({
            ...sellerData,
            id: res.data.data.id,
            firstName: res.data.data.first_name,
            lastName: res.data.data.last_name,
            email: res.data.data.email,
            phone: res.data.data.phone,
            mailingAddress: res.data.data.mailing_street_address,
            mailingCity: res.data.data.mailing_city,
            mailingState: res.data.data.mailing_state,
            mailingZipCode: res.data.data.mailing_zipcode,
          });
          onSubmission(null);
          dispatch(getPrimarySellers());
        }
        setTimeout(() => {
          handleNext();
          setLoading(false);
        }, 1500);
      })
      .catch((err) => {
        // setSeverity("error");
        setLoading(false);
        dispatch(showSnackbar(err.messages[0].errors.phone));

        // setMessage(err.messages[0].errors.phone);
        // setSnack(true);
      });
  };

  const updateSeller = (data) => {
    setLoading(true);
    editSeller(sellerData.id, data).then((res) => {
      if (setSellerData) {
        // setSeverity("success");
        // setMessage("Seller updated successfully!");
        // setSnack(true);
        dispatch(showSnackbar("Seller updated successfully!"));
        setSellerData({
          ...sellerData,
          firstName: data.first_name,
          lastName: data.last_name,
          email: data.email,
          phone: data.phone,
          mailingAddress: data.mailing_street_address,
          mailingCity: data.mailing_city,
          mailingState: data.mailing_state,
          mailingZipCode: data.mailing_zipcode,
        });
        onSubmission(null);
        dispatch(getPrimarySellers());
      }
      setTimeout(() => {
        handleNext();
        setLoading(false);
      }, 1500);
    });
  };

  const formik = useFormik({
    initialValues: {
      firstName: sellerData && sellerData.firstName,
      lastName: sellerData && sellerData.lastName,
      email: sellerData && sellerData.email,
      phone: sellerData && sellerData.phone,
      mailingAddress: sellerData && sellerData.mailingAddress,
      mailingCity: sellerData && sellerData.mailingCity,
      mailingState: sellerData && sellerData.mailingState,
      mailingZipCode: sellerData && sellerData.mailingZipCode,
    },
    validationSchema: primarySellerValidation,
    enableReinitialize: true,
    onSubmit: (values) => {
      const data = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        phone: values.phone,
        mailing_street_address: values.mailingAddress,
        mailing_city: values.mailingCity,
        mailing_state: values.mailingState,
        mailing_zipcode: values.mailingZipCode,
      };
      if (sellerData.id) {
        updateSeller(data);
      } else {
        addSellerToOpportunity(data);
      }
    },
  });

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          <TextField
            fullWidth
            label="First Name *"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Last Name *"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </Box>
        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            label="Email Address"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Phone Number"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            placeholder="12345678901"
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
            type="number"
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 11);
            }}
          />
        </Box>
        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            label="Mailing Street Address"
            name="mailingAddress"
            value={formik.values.mailingAddress}
            onChange={formik.handleChange}
            error={
              formik.touched.mailingAddress &&
              Boolean(formik.errors.mailingAddress)
            }
            helperText={
              formik.touched.mailingAddress && formik.errors.mailingAddress
            }
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Mailing City"
            name="mailingCity"
            value={formik.values.mailingCity}
            onChange={formik.handleChange}
            error={
              formik.touched.mailingCity && Boolean(formik.errors.mailingCity)
            }
            helperText={formik.touched.mailingCity && formik.errors.mailingCity}
          />
        </Box>
        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            label="Mailing State"
            name="mailingState"
            value={formik.values.mailingState}
            onChange={formik.handleChange}
            error={
              formik.touched.mailingState && Boolean(formik.errors.mailingState)
            }
            helperText={
              formik.touched.mailingState && formik.errors.mailingState
            }
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Mailing Zip Code"
            name="mailingZipCode"
            value={formik.values.mailingZipCode}
            onChange={formik.handleChange}
            error={
              formik.touched.mailingZipCode &&
              Boolean(formik.errors.mailingZipCode)
            }
            helperText={
              formik.touched.mailingZipCode && formik.errors.mailingZipCode
            }
          />
        </Box>
        {/* <SubmissionButton
          title={sellerData.id ? "Update and Next" : "Next"}
          onClick={formik.handleSubmit}
        /> */}
        <Box textAlign={"end"} mt={4}>
          <VtechButton title={"Next"} onClick={formik.handleSubmit} />
        </Box>
      </Box>
    </>
  );
};
