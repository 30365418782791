import { Box, Button } from "@mui/material";
// import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
// import style from "./next-prev.module.css";

export const NextPrevBtn = ({
  nextPrev,
  offerEdit,
  setOfferEdit,
  setOfferInfo,
}) => {
  const navigate = useNavigate();
  const open = useSelector((state: any) => state.menuHandler.isOpen);

  const handleButtonClick = (direction) => {
    if (offerEdit) {
      setOfferEdit(false);
      setOfferInfo({
        offerAmount: "",
        salePrice: "",
        profit: "",
        offerDate: "",
        expireDate: "",
        offerDelivery: "",
        offerStatus: "",
        offerNotes: "",
      });
      if (direction === "prev") {
        navigate(`/opportunity/${nextPrev.prev}`);
      } else {
        navigate(`/opportunity/${nextPrev.next}`, { replace: true });
      }
    } else {
      if (direction === "prev") {
        navigate(`/opportunity/${nextPrev.prev}`);
      } else {
        navigate(`/opportunity/${nextPrev.next}`, { replace: true });
      }
    }
  };

  return (
    <>
      {/* <div className={style.header}>
        <div className={style.main}>
          <div className={style.content}>
            <Button
              className={style.buttonPrev}
              classes={{ label: style.prevLabel }}
              disabled={nextPrev.prev === undefined && true}
              style={{
                backgroundImage: nextPrev.prev
                  ? `linear-gradient(to left, rgb(30, 136, 229), rgb(116, 188, 223))`
                  : `linear-gradient(to right, rgb(138, 138, 138), rgb(188, 185, 185))`,
              }}
              onClick={() => handleButtonClick("prev")}
            >
              <ArrowBackIos className={style.icons} />
              Previous Opportunity
            </Button>
          </div>
          <div className={style.content}>
            <Button
              className={style.buttonNext}
              classes={{ label: style.nextLabel }}
              disabled={nextPrev.next === undefined && true}
              style={{
                backgroundImage: nextPrev.next
                  ? `linear-gradient(to left, rgb(30, 136, 229), rgb(116, 188, 223))`
                  : `linear-gradient(to right, rgb(138, 138, 138), rgb(188, 185, 185))`,
              }}
              onClick={() => handleButtonClick("next")}
            >
              Next Opportunity
              <ArrowForwardIos className={style.icons} />
            </Button>
          </div>
        </div>
      </div> */}
      <Box
        sx={{
          height: 62,
          width: open ? "calc(100% - 240px)" : 1,
          background:
            "linear-gradient(87.58deg, #245EC1 -23.23%, rgba(171, 0, 0, 0.94) 146.95%)",
          position: "fixed",
          bottom: 0,
          left: 0,
          display: "flex",
          justifyContent: "space-between",
          zIndex: 100,
          ml: open ? 30 : 0,
        }}
      >
        <Button
          sx={{ color: "white", px: 6, borderRadius: 0 }}
          onClick={() => handleButtonClick("prev")}
          startIcon={<img src="/icons/left-arrow.svg" />}
          disabled={nextPrev.prev === undefined && true}
        >
          Previous Opportunity
        </Button>
        <Button
          sx={{ color: "white", px: 6, borderRadius: 0 }}
          onClick={() => handleButtonClick("next")}
          endIcon={<img src="/icons/right-arrow.svg" />}
          disabled={nextPrev.next === undefined && true}
        >
          Next Opportunity
        </Button>
      </Box>
    </>
  );
};
