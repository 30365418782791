import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import VtechButton from "../vtech-button/VtechButton";

type VtechModalProps = {
  title: string;
  primaryButtonLabel?: string;
  primaryButtonAction?: () => void;
  secondaryButtonLabel?: string;
  secondaryButtonAction?: () => void;
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  isLoading?: boolean;
  buttonPosition?: "start" | "end";
  hideXButton?: boolean;
  hideActionButtons?: boolean;
  sx?: object;
  alignCenter?: boolean;
  scroll?: "paper" | "body";
  fullWidth?: boolean;
};

function VtechModal({
  title,
  primaryButtonLabel,
  primaryButtonAction,
  secondaryButtonLabel,
  secondaryButtonAction,
  open,
  handleClose,
  children,
  isLoading,
  buttonPosition = "end",
  hideXButton = false,
  hideActionButtons = false,
  sx,
  scroll = "paper",
  fullWidth = false,
}: VtechModalProps) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={scroll}
      fullWidth={fullWidth}
      sx={{
        "& .MuiDialog-paper": {
          width: "50%",
          minWidth: "300px",
          maxHeight: "70vh",
          mx: 0,
          ...sx,
        },
      }}
    >
      <DialogTitle
        sx={{
          background:
            "linear-gradient(87.58deg, #245EC1 -23.23%, rgba(171, 0, 0, 0.94) 146.95%)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          color: "#fff",
          height: "53px",
          fontWeight: 600,
          fontSize: "16px",
          px: 7,
        }}
      >
        {title}
        {!hideXButton && (
          <IconButton onClick={handleClose} sx={{ color: "#fff" }}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent sx={{ px: 7, pt: "16px !important", pb: 0 }}>
        {children}
      </DialogContent>
      {!hideActionButtons && (
        <DialogActions sx={{ justifyContent: buttonPosition, px: 7, py: 4 }}>
          <VtechButton
            onClick={primaryButtonAction}
            title={primaryButtonLabel ?? ""}
            disabled={isLoading}
          />
          {secondaryButtonLabel && (
            <VtechButton
              onClick={secondaryButtonAction}
              title={secondaryButtonLabel}
              variant="text"
              disabled={isLoading}
              sx={{ color: "text.primary" }}
            />
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}

export default VtechModal;
