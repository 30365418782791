import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openSnackBar: false,
  message: "",
  severity: "success",
};

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    showSnackbar: (state, action) => {
      if (typeof action.payload === "string") {
        state.openSnackBar = true;
        state.severity = "success";
        state.message = action.payload;
      } else if (typeof action.payload === "object") {
        state.openSnackBar = true;
        state.message = action.payload.message;
        state.severity = action.payload.severity;
      }
    },
    closeSnackbar: (state) => {
      state.openSnackBar = false;
      state.message = "";
    },
  },
});

export const { showSnackbar, closeSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
