import { IconButton, Menu, MenuItem } from "@mui/material";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";
import React, { useState } from "react";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const MenuSelection = ({ onChange }) => {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const mobileMenuId = "primary-search-account-menu-mobile";

  const filters = [
    { id: 1, name: "First Name", value: "first_name" },
    { id: 2, name: "Last Name", value: "last_name" },
    { id: 3, name: "Opportunity Address", value: "opportunity_address" },
    { id: 4, name: "Current Date", value: "current_date" },
    { id: 5, name: "Last Contact Date", value: "last_contact_date" },
    { id: 6, name: "Last Offer Date", value: "last_offer_date" },
    { id: 7, name: "Last Offer Amount", value: "last_offer_amount" },
    {
      id: 8,
      name: "Opportunity Owner First Name",
      value: "opportunity_owner_first_name",
    },
  ];

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = (item) => {
    if (item && item.type !== "click") {
      if (onChange) {
        onChange(item.value);
      }
    }
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {filters.map((item) => (
        <MenuItem onClick={() => handleMobileMenuClose(item)}>
          <p>{item.name}</p>
        </MenuItem>
      ))}
    </Menu>
  );
  return (
    <>
      <IconButton
        aria-label="show more"
        aria-controls={mobileMenuId}
        aria-haspopup="true"
        onClick={handleMobileMenuOpen}
        color="inherit"
      >
        <MoreHorizIcon />
      </IconButton>
      {renderMobileMenu}
    </>
  );
};

export default MenuSelection;
