import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import style from "./list.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getSequences } from "../redux/sequence.actions";
import SequenceListItem from "./list.component";
import SequenceAddForm from "./seq-add-form";
import { Box, InputAdornment, Paper } from "@mui/material";
import VtechIcons from "../../../components/icons/VtechIcons";
import VtechButton from "../../../components/vtech-button/VtechButton";
import ProgressBar from "../../../components/widgets/progress-bar/index";
import { setCurrentSequence } from "../redux/sequence.slice";
import VtechModal from "../../../components/vtech-modal/VtechModal";

const SequencesList = () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  useEffect(() => {
    dispatch(getSequences());
    dispatch(setCurrentSequence({}));
  }, [dispatch]);

  const { isLoading } = useSelector((state) => state.sequences);

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          mt: 4,
          mb: 3,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Paper
          elevation={0}
          sx={{
            px: 1.5,
            width: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 44,
          }}
        >
          <TextField
            name="search automations"
            fullWidth
            placeholder="Search Automations"
            variant="standard"
            onChange={(e) => setQuery(e.target.value)}
            inputProps={{
              sx: { "&::placeholder": { color: "#5A6479", opacity: 0.5 } },
            }}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start" sx={{ marginRight: 1.5 }}>
                  <VtechIcons icon="search" />
                </InputAdornment>
              ),
            }}
          />
        </Paper>
        <VtechButton
          sx={{ height: 44 }}
          title="Add Automation"
          onClick={handleOpenAddDialog}
        />
      </Box>

      {isLoading && <ProgressBar />}

      <SequenceListItem query={query} />

      <VtechModal
        open={openAddDialog}
        handleClose={handleCloseAddDialog}
        title="Add Automation"
        sx={{
          width: { xs: 300, md: 500 },
          maxHeight: "unset",
          overflowX: "hidden",
        }}
        scroll="body"
        hideActionButtons={true}
      >
        <SequenceAddForm
          handleCloseAddDialog={handleCloseAddDialog}
          style={style}
        />
      </VtechModal>
    </>
  );
};

export default SequencesList;
