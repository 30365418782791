import React from "react";
import Button from "@mui/material/Button";
import { SxProps } from "@mui/material";

type VtechButtonProps = {
  variant?: "text" | "outlined" | "contained";
  color?: "inherit" | "primary" | "secondary" | "success" | "error";
  title: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  onClick?: (x: any) => void;
  disabled?: boolean;
  sx?: SxProps;
  fullWidth?: boolean;
};

function VtechButton({
  variant = "contained",
  color = "primary",
  title,
  startIcon,
  endIcon,
  onClick,
  disabled,
  sx,
  fullWidth,
}: VtechButtonProps) {
  return (
    <Button
      fullWidth={fullWidth}
      variant={variant}
      color={color}
      disabled={disabled}
      onClick={onClick}
      sx={{
        borderRadius: "6px",
        fontWeight: 600,
        fontSize: "14px",
        height: 47,
        px: 4,
        whiteSpace: "nowrap",
        ...sx,
      }}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {title}
    </Button>
  );
}

export default VtechButton;
