import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: true,
};

const MenuHandler = createSlice({
  name: "MenuHandler",
  initialState,
  reducers: {
    isOpenChange: (state, { payload }) => {
      state.isOpen = payload ? payload : !state.isOpen;
    },
    setMenuState: (state, { payload }) => {
      state.isOpen = payload;
    },
  },
});

export const {isOpenChange, setMenuState} = MenuHandler.actions;

export default MenuHandler.reducer;
