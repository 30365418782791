import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProgressBar from "../../components/widgets/progress-bar/index";
// import SnackBar from "../../components/widgets/snackbar/index";
import MainLayout from "../../components/MainLayout/MainLayout";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  getSequenceDetails,
  getSequenceSteps,
  getInitialStepDetails,
  getNextActionDetails,
  getSequences,
} from "../sequences/redux/sequence.actions";
import SequenceHeader from "./seq-update-form";
import { setActiveSequence } from "../sequences/redux/sequence.slice";
import { getAllActions } from "../campaign-details/redux/steps/details.actions";
import { getAllUsersWOL } from "../users-list/usersListAction";

const SequenceDetails = () => {
  const crumb = ["Automations", "Automation details"];
  // const { snackMsg, snackOpen, snackSeverity } = useSelector(
  //   (state) => state.sequences
  // );
  const { sequences, isLoading, currentSequence } = useSelector(
    (state) => state.sequences
  );
  const dispatch = useDispatch();
  const { sequenceId } = useParams();

  useEffect(() => {
    dispatch(getSequences());
  }, [dispatch]);

  useEffect(() => {
    if (sequenceId && sequences.length > 0) {
      const sequenceIndex = sequences.findIndex(
        (sequence) => sequence.id == sequenceId
      );
      if (sequenceIndex !== -1) {
        const sequence = sequences[sequenceIndex];
        getCurrentActiveSequence(sequenceIndex, sequence.id);
      }
    }
  }, [sequenceId, sequences]);

  const getCurrentActiveSequence = (index, id) => {
    dispatch(setActiveSequence(index));
    dispatch(getSequenceDetails(id));
    dispatch(getSequenceSteps(id));
    dispatch(getInitialStepDetails(id));
    dispatch(getNextActionDetails(id));
    dispatch(getAllActions());
    dispatch(getAllUsersWOL());
  };

  return (
    <>
      <MainLayout title="" crumbs={crumb}>
        <Box mb={10}>
          {isLoading && <ProgressBar />}
          {Object.keys(currentSequence).length > 0 && <SequenceHeader />}
        </Box>
      </MainLayout>
    </>
  );
};

export default SequenceDetails;
