import { http } from "../apiClient/axiosInterceptor";

export const getNotifications = (id) => {
  return http.get(`/notifications?user_id=${id}`);
};

export const readNotifications = (id) => {
  return http.post(`/notifications/markAsRead`, {
    id,
  });
};
