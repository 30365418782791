import TextField from "@mui/material/TextField";
import React from "react";

const AddForm = ({ formik }) => {
  return (
    <>
      <TextField
        sx={{ my: 2 }}
        fullWidth
        name="campaignName"
        label="Campaign Name"
        variant="outlined"
        value={formik.values.campaignName}
        onChange={formik.handleChange}
        error={
          formik.touched.campaignName && Boolean(formik.errors.campaignName)
        }
        helperText={formik.touched.campaignName && formik.errors.campaignName}
      />
      <TextField
        fullWidth
        name="purpose"
        label="Purpose"
        variant="outlined"
        value={formik.values.purpose}
        onChange={formik.handleChange}
        error={formik.touched.purpose && Boolean(formik.errors.purpose)}
        helperText={formik.touched.purpose && formik.errors.purpose}
      />
    </>
  );
};

export default AddForm;
