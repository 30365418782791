import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import { setQueryFilter } from "../opportunitiesComponents/oppo-component.slice";
import { Box, InputAdornment, Paper } from "@mui/material";
import VtechButton from "../../../components/vtech-button/VtechButton";
import VtechIcons from "../../../components/icons/VtechIcons";

export const SearchAddFilterOpportunities = (props) => {
  const { handleFilterCheck, query, setOpen, setPage } = props;
  const dispatch = useDispatch();
  const [localQuery, setLocalQuery] = useState("");

  useEffect(() => {
    setLocalQuery(query);
  }, [query]);

  const handleQuery = (e) => {
    setLocalQuery(e.target.value);
    setTimeout(() => {
      dispatch(setQueryFilter(e.target.value));
      setPage(0);
    }, 1500);
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        my: 2,
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Paper
        sx={{
          px: 1.5,
          width: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 44,
        }}
      >
        <TextField
          fullWidth
          placeholder="Search opportunities"
          value={localQuery}
          variant="standard"
          onChange={handleQuery}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <VtechIcons icon="search" />
              </InputAdornment>
            ),
          }}
        />
      </Paper>
      <VtechButton
        title="Add opportunity"
        sx={{ height: 44 }}
        onClick={() => setOpen(true)}
      />
      <Paper>
        <VtechButton
          sx={{ height: 1, px: 2, color: "text.primary" }}
          title="Filters"
          variant="text"
          onClick={handleFilterCheck}
          fullWidth
          startIcon={<VtechIcons icon="filters" />}
        />
      </Paper>
    </Box>
  );
};

export default SearchAddFilterOpportunities;
