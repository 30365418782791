import React from "react";
import moment from "moment";
import parse from "html-react-parser";
import { readNotifications } from "../../../../services/notifications.services";
import { useNavigate } from "react-router";
import { setNotiForm } from "./notification.action";
import { useDispatch } from "react-redux";
import { Box, Typography, useTheme } from "@mui/material";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import { useDarkMode } from "../../../../utils/useDarkMode";

const SingleNotification = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const darkMode = useDarkMode();
  const handleRead = () => {
    readNotifications(data.id).then(() => {
      dispatch(setNotiForm());
      navigate(`/opportunity/${data.opportunity_id}?newIndex=2`);
    });
  };

  return (
    <Box
      onClick={handleRead}
      sx={{
        display: "flex",
        p: 1,
        cursor: "pointer",
        ":hover": {
          bgcolor: (theme) => theme.palette.action.hover,
        },
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: darkMode ? "#2E2E2E" : "#E9EFF9",
            borderRadius: "16px",
            width: 37,
            height: 37,
            mb: 2.5,
          }}
        >
          {darkMode ? (
            <img src={"/icons/notification-dark.png"} style={{ width: 20 }} />
          ) : (
            <img src={"/icons/notification-text-icon.svg"} />
          )}
        </Box>
      </Box>
      <Box sx={{ width: 1, flex: 3 }}>
        <Box>
          <Typography sx={{ lineHeight: "20px", fontSize: "14px" }}>
            {data && parse(data.details)}
          </Typography>
        </Box>
        <Box display={"flex"} mt={1}>
          {darkMode ? (
            <QueryBuilderIcon sx={{ fontSize: "14px", mt: 0.1 }} />
          ) : (
            <img src="/icons/clock.svg" alt="clock" />
          )}
          <Typography
            sx={{
              color: theme.palette.text.secondary,
              fontSize: "12px",
              ml: 1,
            }}
          >
            About {data && moment(data.created_at).fromNow()}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SingleNotification;
