/**
 *
 * PrivateRoutes
 *
 */
import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

// Utils
const PrivateRoute = ({ roles, children }) => {
  var session_token = localStorage.getItem("userToken");
  const { user } = useSelector((state) => state.login);

  return session_token !== null && roles?.includes(user?.role[0]) ? (
    <>{children}</>
  ) : (
    <Navigate to="/" replace />
  );
};

export default PrivateRoute;
